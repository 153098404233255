import React from "react";
import PropTypes from "prop-types";
import './index.css'
const Button = (props)=>(
    <div className="button-container" onClick={props.onClick}><a className="button"> <span
        className={`icon ${props.className}`}>
            </span><span>{props.buttonText}</span></a>{props.children}
    </div>);

Button.defaultProps = {
    className: '',
    buttonText: '',
    onClick: ()=> {
    },
    children: null
}
;
Button.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    supportedTypes: PropTypes.array,
    children: PropTypes.object
};
export default Button;