import React from "react";
import PropTypes from "prop-types";
import {DropdownButton, MenuItem} from "react-bootstrap";
import './staticDropdown.css';


export const FilterView = (props)=> {
    const definedTitle = props.selectedValue;
    const title = definedTitle || props.title;
    const withUnit = `${title} ${props.unit}`;
    
    return <DropdownButton className="dropdownWrapper"
        title={props.unit ? withUnit : title}
        id="dropdown-size-medium">
        {!props.useData && props.menues &&
        Object.keys(props.menues).map(menuKey=> {
            return <MenuItem key={props.menues[menuKey].key} eventKey={props.menues[menuKey].key} onSelect={(value)=> {
                if (!props.menues[menuKey].submenues) {
                    props.onSelect(props.menues[menuKey]);
                }
            }}>
                <div
                    className="menu-item  menu-container">{props.menues[menuKey].value}
                    {props.menues[menuKey].submenues &&
                    <SubmenuView menues={props.menues[menuKey].submenues} onChange={(value)=> {
                        props.onSelect(props.menues[menuKey], value)
                    }}/>}
                </div>
            </MenuItem>
        })
        }
        {props.useData && props.data && props.showAll &&
        <MenuItem eventKey="all" onSelect={props.onSelect}>
            <div className="menu-item">All</div>
        </MenuItem>}
        {props.useData && (props.data &&
        props.data.map(dataItem=> {
            return <MenuItem key={props.dataKey ? dataItem[props.dataKey] : dataItem}
                             eventKey={props.dataKey ?props.dataKey=='name'?dataItem: dataItem[props.dataKey] : dataItem}  onSelect={props.onSelect}>
                <div
                    className="menu-item">{props.dataKey ? dataItem[props.dataKey] : dataItem}</div>
            </MenuItem>
        }))
        }
        {props.useData && (!props.data || props.data.length == 0) &&
        <MenuItem eventKey="" disabled>No {props.title || 'Data'} Exists </MenuItem>
        }
        {props.useData && props.data && props.selectedValue && props.showReset &&
        <MenuItem eventKey="reset" onSelect={props.onSelect}>
            <div className="menu-item">Reset</div>
        </MenuItem>}

    </DropdownButton>
};

FilterView.propTypes = {
    title:PropTypes.string.isRequired,
    useData:PropTypes.bool,
    unit:PropTypes.string

}

export const SubmenuView = ({menues, onChange})=> {
    return (<ul className="sub-menu">
        {Object.keys(menues).map((menuKey)=> {
            return <li key={menues[menuKey].value} onClick={(e)=> {
                onChange(menues[menuKey]);
            }}>{menues[menuKey].value}</li>
        })}
    </ul>)
}