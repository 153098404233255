import React from "react";
import {ResponsiveBar} from "@nivo/bar";

export class GraphViewComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            axisLeftTickValues: []
        }
    }

    componentWillReceiveProps(nextProps) {
        /**
         * updated y axis grid on data change
         */
        if (this.props.data != nextProps.data) {

           this.updateYAxisGrid(nextProps);
        }
    }

    updateYAxisGrid(nextProps) {
        let interval = this.getInterval(nextProps.data);
        let a;
        if(interval)a= [0, interval, 2 * interval, 3 * interval];
        else {a=[0]}
        this.setState({
            axisLeftTickValues: a
        })
    }

    /**
     * calculate y axis grid interval
     * @param data
     * @returns {number}
     */
    getInterval(data) {
        let maxValue;
        let totalGrids = 4;
        if (data.length == 1) {
            maxValue = data[0].count;
        } else {
            maxValue = data.reduce((a, b)=> {
                let p;
                if (typeof a == 'object') {
                    p = a.count
                } else {
                    p = a;
                }
                return Math.max(p, b.count);
            });
        }
        return Math.round(maxValue / totalGrids);
    }

    render() {
        return <ResponsiveBar
            data={this.props.data}
            keys={[
                "count"
            ]}
            indexBy="scale"
            margin={{
                "top": 20,
                "right": 20,
                "bottom": 50,
                "left": 40
            }}
            padding={0.3}
            colorBy={function (e) {
                var t = e.id;
                return e.data[t + "Color"]
            }}
            borderColor="inherit:darker(1.6)"
            axisBottom={{
                "orient": "bottom",
                "tickSize": 0,
                "tickPadding": 15,
                "tickRotation": 0
            }}
            axisLeft={{
                "orient": "left",
                "tickSize": 0,
                "tickPadding": 15,
                "tickRotation": 0,
                "tickValues": this.state.axisLeftTickValues
            } }
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#fff"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            gridYValues={this.state.axisLeftTickValues}
        />
    }
}
