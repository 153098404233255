import React from "react";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import {
  templateStrings,
  MessageType,
  dateFormat,
} from "../../../../../Constant";
import { ImportButton } from "../../../../../shared/components";
import { routes } from "../../../../../routing/routes";
import {
  fetchBSDImportData,
  downloadBSDById,
} from "../../../../../services/BSDService";
import * as moment from "moment";
import { Paginator, Search as SearchBar } from "../../../../../shared/widgets";

import {
  AuthenticationService,
  OPERATIONS,
} from "../../../../../services/authorization";
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import { ButtonToolbar } from "react-bootstrap";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";

class BSDImport extends React.Component {
  constructor(props) {
    super(props);
    let authorities = localStorage.getItem("authorities");
    this.authService = AuthenticationService.getInstance();
    this.state = {
      authorities: authorities,
      content: [],
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
      searchString: "",
      activeTab: "BSD",
      selectedCity: "MUMBAI",
    };
    this.tabFilter = [
      {
        key: "BSD",
        value: "BSD IMPORTS",
      },
      {
        key: "STOP",
        value: "STOP IMPORTS",
      },
    ];
    this.supportedTypes = [".xlsx"];
    this.getList = this.getList.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    let auth = JSON.parse(this.state.authorities);
    this.mounted = true;
    this.updateRightsForRegion(Object.values(auth).toString());
    this.fetchMasterData("city", null);
    this.fetchBSDImportData(
      this.state.selectedCity,
      this.state.searchString,
      0,
      this.state.activeTab
    );
  }

  updateRightsForRegion(region) {
    this.setState({
      canWrite: this.authService.hasRightForOperationOnRouteInRegion(
        routes.BSD,
        region,
        OPERATIONS.WRITE
      ),
    });
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          this.setState({ cities: res.content });
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  render() {
    const templatestrings = templateStrings.BSDImportDownload;
    return (
      <div>
        <div className="tab-container">
          <div className="inner-tab">
            <ul className="sub-tab">
              {this.tabFilter.map((tab) => {
                return (
                  <li
                    key={tab.key}
                    onClick={() => {
                      if (this.state.activeTab !== tab.key) {
                        this.handleTabChange(tab.key);
                      }
                    }}
                    className={
                      this.state.activeTab === tab.key ? "activeTab" : ""
                    }
                  >
                    {tab.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <PageWrapper.Header
          heading={
            this.state.activeTab == "BSD"
              ? "BSD File Import"
              : "Stop File Import"
          }
          hideS={true}
        >
          <div className="pull-right">
            <a
              onClick={() => {
                this.fetchBSDImportData(
                  this.state.selectedCity,
                  this.state.searchString,
                  0,
                  this.state.activeTab
                );
              }}
            >
              <span className="span-icon-refresh refresh-icon" />
            </a>
          </div>
          <div className="pull-right search-container">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
          {this.state.canWrite && (
            <ImportButton
              importKey={this.state.activeTab == "BSD" ? "BSD" : "Stop"}
              supportedTypes={this.supportedTypes}
              onMessage={(e, msg) => {
                if (e == MessageType.SUCCESS) {
                  this.fetchBSDImportData(
                    this.state.selectedCity,
                    this.state.searchString,
                    0,
                    this.state.activeTab
                  );
                  this.props.initiateNotification({ type: e, message: msg });
                } else if (e == MessageType.ERROR && msg === "Invalid token") {
                  this.props.setTokenExpired(true);
                } else {
                  this.props.initiateNotification({ type: e, message: msg });
                }
              }}
            />
          )}
        </PageWrapper.Header>
        <PageWrapper.Body>
          {this.state.activeTab !== "BSD" && (
            <PageWrapper.BodyHeader>
              <div className="header-button-container">
                <div className="form-group">
                  <p className="filters-label">Filter By:</p>
                  <div
                    className="dataTables_length wrapper-gps"
                    id="dataTables-example_length"
                  >
                    <ButtonToolbar>
                      <SelectFilter
                        value={this.state.selectedCity}
                        onChange={(value) => {
                          this.onFilterChange(value);
                        }}
                        clearable={false}
                        valueKey="name"
                        labelKey="name"
                        placeholder="City"
                        options={this.state.cities}
                      />
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </PageWrapper.BodyHeader>
          )}

          <TableWrapper>
            <TableWrapper.Header columns={templatestrings} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.length > 0 &&
                  this.state.content.map((data) => {
                    return this.displayList(data, templatestrings);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length === 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          {this.state.content && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  displayList(item) {
    return (
      <tr key={item.id} className="gradeA odd" role="row">
        <td className="list-item">{item.fileName}</td>

        <td className="list-item">
          <span className="protocol">
            {item.uploadedBy ? item.uploadedBy : "NA"}
          </span>
          <div className="protocol-list">
            {item.uploadedBy ? item.uploadedBy : "NA"}
          </div>
        </td>
        <td className="list-item">
          {item.uploadStartTime
            ? moment(
                new Date(
                  EpochtoEpochTZ(
                    parseInt(item.uploadStartTime),
                    localStorage.getItem("timeZone")
                  )
                )
              ).format(dateFormat)
            : "NA"}
        </td>
        <td className="list-item">
          {item.uploadEndTime
            ? moment(
                new Date(
                  EpochtoEpochTZ(
                    parseInt(item.uploadEndTime),
                    localStorage.getItem("timeZone")
                  )
                )
              ).format(dateFormat)
            : "NA"}
        </td>
        <td
          className={
            item.fileStatus === "Processed"
              ? "list-item-green"
              : item.fileStatus === "Partially Processed"
              ? "list-item-red"
              : item.fileStatus === "InProgress"
              ? "list-item-yellow"
              : "list-item"
          }
        >
          {item.fileStatus}
        </td>
        <td className="list-item">
          {item.fileStatus === "Partially Processed" && (
            <span className="table-icons edit-icon">
              <a
                onClick={() => {
                  this.fileDownloadIndividual(item);
                }}
              >
                <span className="download-icon" />
                <span className="protocol-list">Download File</span>
              </a>
            </span>
          )}
        </td>
      </tr>
    );
  }

  handleTabChange(value) {
    this.setState({ activeTab: value }, () => {
      this.fetchBSDImportData(
        this.state.selectedCity,
        '',
        0,
        this.state.activeTab
      );
    });
  }

  onFilterChange(value) {
    if (value) {
      this.setState(
        {
          selectedCity: value && value.name ? value.name : "",
        },
        () => {
          this.fetchBSDImportData(
            this.state.selectedCity,
            this.state.searchString,
            0,
            this.state.activeTab
          );
        }
      );
    }
  }

  handleSearchChange(e) {
    this.setState({ searchString: e }, () => {
      this.fetchBSDImportData(
        this.state.selectedCity,
        this.state.searchString,
        0,
        this.state.activeTab
      );
    });
  }

  fileDownloadIndividual(data) {
    let importId = data.id;
    downloadBSDById(importId, this.state.activeTab)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        this.props.initiateNotification({
          type: "error",
          message: err.description
            ? err.description
            : "Error while downloading file",
        });
        console.log("err", err);
      });
  }

  fetchBSDImportData(city, searchKey, pageNo, activeTab) {
    if (this.mounted) this.props.updateLoader(true);
    fetchBSDImportData(city, searchKey, pageNo, activeTab)
      .then((res) => {
        if (this.mounted) {
          this.setState({
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
            totalItems: res.totalItems,
            searchString:searchKey
          });
        }
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.setState({
          content: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
          searchString:searchKey
        });
        this.errorHandler(err);
        console.log("Err", err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.props.updateLoader(false);
      this.setState({ content: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching data",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchBSDImportData(
        this.state.selectedCity,
        this.state.searchString,
        pageNo,
        this.state.activeTab
      );
    }
  }
}

export default connect(null, defaultMapDispatchToProps)(BSDImport);
