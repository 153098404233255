import { actions } from '../../actions/action-list';
import initialState from "./initialState";

function Auth(state = initialState, action) {
    switch (action.type) {
        case actions.TOKEN_EXPIRE:
            return Object.assign({}, state, {
                isTokenExpired: action.value
            });
        case actions.UPDATE_AUTHORITIES:
            return Object.assign({}, state, {
                authorities: action.value
            });
    
        default:
            return state;
    }
}
export default Auth;