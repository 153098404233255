import React from "react";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../redux/map-to-props";
import { PageWrapper, TableWrapper } from "../../../shared/layout";
import { templateStrings, MessageType } from "../../../Constant";
import { Modal, ButtonToolbar } from "react-bootstrap";
import { RegionSelector } from "../../../shared/components";
import { routes } from "../../../routing/routes";
import { fetchTripsForRegion } from "../../../services/tripDistanceService";
import { DatePicker } from "../../../shared/widgets/datepickers/dateRangePickers";
import * as moment from "moment";
import { TripCreate } from './views/create/createVIew'
import { AuthenticationService ,OPERATIONS} from "../../../services/authorization";
import { Paginator, Search as SearchBar } from "../../../shared/widgets";
import {convertIntoKm} from "../../../shared/utils"

class TripDistanceList extends React.Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        console.log("hddhg",this.authService.shouldShowAll())
        let m = moment(new Date());
        this.state = {
            showHeaderButton: true,
            pageNo: 0,
            totalPages: 0,
            isUserAViewer: false,
            date: m,
            todaySelectable:true,
            backdrop:false
        };
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.getList = this.getList.bind(this);

    }

    componentDidMount() {
        this.mounted = true;
        this.updateRightsForRegion(this.state.selectedRegion);
    }

    updateRightsForRegion(region) {
        this.setState({
            canWrite: this.authService.hasRightForOperationOnRouteInRegion(routes.TRIP, region, OPERATIONS.WRITE),
        });
    }

    updateStateForRegion(regionId) {
        this.updateRightsForRegion(regionId);
    }
    
    render() {
        return (
            <div>
                <PageWrapper.Header heading="Trip" onAdd={this.open.bind(this, null)}
                    showButton={this.state.showHeaderButton && this.state.canWrite}
                >
                </PageWrapper.Header>
                <PageWrapper.Body>
                    <PageWrapper.BodyHeader>
                        <div className="header-button-container">
                            <div className="form-group"><p className="filters-label">Filter By:</p>
                                <div className="dataTables_length wrapper-gps"
                                    id="dataTables-example_length">
                                    <ButtonToolbar>
                                        <RegionSelector filterKey={routes.TRIP}
                                            onRegionChange={this.handleRegionChange} />
                                        <DatePicker date={this.state.date} onDateChange={this.dateChange} 
                                        todaySelectable={this.state.todaySelectable}  withoutDefaultBorder={false}/>
                                    </ButtonToolbar>
                                </div>
                            </div>
                        </div>
                    </PageWrapper.BodyHeader>
                    <TableWrapper>
                        <TableWrapper.Header columns={templateStrings.trips} />
                        {<tbody>
                            {this.state.content && this.state.content.map((data) => {
                                return this.displayList(data);
                            })}
                            {(!this.state.content || (this.state.content && this.state.content.length == 0)) &&
                                <tr className="gradeA odd noDataStyle" role="row">
                                    <td className="sorting_1" colSpan="6">No Data Found !</td>
                                </tr>
                            }
                        </tbody>}
                    </TableWrapper>
                    <Paginator pageNo={this.state.pageNo}
                        totalPages={this.state.totalPages}
                        getList={this.getList} />
                    {this.displayModal()}
                </PageWrapper.Body>

            </div>
        )
    }

    /**
     * fetches trips
     */
    fetchTrips(region, key, date, pageNo) {
        let formatedDate = date.format("DD-MM-YYYY");
        this.props.updateLoader(true);
        fetchTripsForRegion(region, key, formatedDate, pageNo).then((res => {
            if (this.mounted) this.setState({
                content: res.items,
                totalPages: res.totalPages,
                pageNo: res.currentPage,
            })
            this.props.updateLoader(false);

        })).catch(err => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else if (this.mounted) {
                this.props.updateLoader(false);
                this.setState({ content: [], totalPages: 0, pageNo: 0 });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching vehicles"
                });
            }
        });
    }

    handleRegionChange(region) {
        this.setState({ selectedRegion: region }, () => {
            this.fetchTrips(this.state.selectedRegion, null, (this.state.selectedDate || this.state.date), this.state.pageNo);
            this.updateStateForRegion(this.state.selectedRegion)

        })
    }
    dateChange(date) {
        this.setState({ selectedDate: date,date:date });
        this.fetchTrips(this.state.selectedRegion, null, date, this.state.pageNo);
    }


    /**
    * Call when Add/Update modal Open.
    */
    open(data) {
        this.setState({
            updateSelection: data,
            showModal: true
        });
    }

    /**
     * Used to show Modal for "Add new device" or "Update device"
     */
    displayModal() {
        return (
            <Modal show={this.state.showModal || this.state.showInfo} onHide={this.close.bind(this)}
                backdrop={this.state.backdrop}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.showModal ? 'Add New Trip' : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.showModal && < TripCreate selectedRegion={this.state.selectedRegion}
                        onComplete={(e, msg) => {
                            if (e == MessageType.SUCCESS) {
                                this.fetchTrips(this.state.selectedRegion, null,(this.state.selectedDate || this.state.date), 0)
                                this.props.initiateNotification({
                                    type: e,
                                    message: msg
                                });
                            }else if(e === MessageType.ERROR && msg==="Invalid token"){
                                this.props.setTokenExpired(true)
                            }else{
                                this.props.initiateNotification({
                                    type: e,
                                    message: msg
                                });
                            }
                            
                            this.close();
                        }}
                        onClose={this.close.bind(this)}
                    />}
                </Modal.Body>
            </Modal>
        );


    }

    /**
     * Call when Add/Update modal Close.
     */
    close() {
        this.setState({
            showModal: false,
            showInfo: false,
            updateSelection: null,
            value: []
        });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    /**
     * API call to get devices.
     * @param pageNo current page number
     */
    getList(pageNo) {
        if (pageNo >= 0 && pageNo <= this.state.totalPages) {
            this.fetchTrips(this.state.selectedRegion, null, pageNo);
        }
    }

    /**
    * Used to show device in row in List view
    * @param item  Object of item to display
    */
    displayList(item) {
        return (
            <tr key={item.vehicleNo}>
                <td> {item.vehicleNo}</td>
                <td >{item.deviceId}</td>
                <td >{item.tripId}</td>
                <td>{item.fromTimestamp}</td>
                <td>{item.toTimestamp}</td>
                <td>{item.status}</td>
                <td>{convertIntoKm(item.distance)}</td>
            </tr>
        );
    }

}

export default connect(null, defaultMapDispatchToProps)(TripDistanceList);
