import {MapComponent} from "./mapComponent";
import React from "react";
import {Loader} from "../../../shared/widgets";
import {getGeoCoder, getUserCurrentLocation} from "./mapUtils";
import {defaultLatitude, packetStatus, defaultLongitude, MessageType} from "../../../Constant";
import "./mapWrapper.css";
import {googleProperties} from "../../../services/login/properties";

export class MapWrapperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: props.center,
            data: props.data
        };

    }

    componentDidMount() {
        this.mounted = true;
        this.updateCurrentLocationInState();
        var lat = parseFloat(this.props.data.latitude);
        var lng = parseFloat(this.props.data.longitude);
        if (this.props.data.packetStatus && this.props.data.packetStatus == packetStatus.VALID)
            this.updateAddress(lat, lng, null, null);
        else
            this.updateAddress(lat, lng, null, null);
    }

    updateCurrentLocationInState() {
        getUserCurrentLocation().then(pos=> {
            if (this.mounted) {
                this.setState({
                    currentLocation: pos
                });
                if (this.props.defaultCurrentLocation) {
                    this.setState({
                        center: pos,
                    })
                }
            }
        }).catch((err)=> {
                console.error('err', err);
                if (this.props.onMessage)
                    this.props.onMessage({type: MessageType.ERROR, message: "Unable to fetch user's location"});
            }
        );
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            this.setState({
                data: nextProps.data
            });
            var lat = parseFloat(nextProps.data.latitude);
            var lng = parseFloat(nextProps.data.longitude);
            var prevLat = parseFloat(this.props.data.latitude);
            var prevLng = parseFloat(this.props.data.longitude);
            if (this.props.data.packetStatus && this.props.data.packetStatus == packetStatus.VALID)
                this.updateAddress(lat, lng, prevLat, prevLng);
            else
                this.updateAddress(lat, lng, prevLat, prevLng);
        }

        if (this.props.center != nextProps.center) {
            if (nextProps.center.lat && nextProps.center.lng) {
                this.setState({
                    center: nextProps.center
                })
            } else {
                if (this.props.onMessage)
                    this.props.onMessage({type: MessageType.ERROR, message: "Device's location can't be located"});
                if (this.state.currentLocation) {
                    this.setState({
                        center: {...this.state.currentLocation}
                    })
                } else {
                    this.setState({
                        center: {
                            lat: defaultLatitude, lng: defaultLongitude
                        }
                    })
                }


            }

        }
    }

    updateAddress(lat, lng, prevLat, prevLng) {
        if (lat && lat != prevLat && lng && lng != prevLng) {
            this.getLocation({
                lat: lat,
                lng: lng
            })
        }
    }

    render() {
        const props = this.props;
        const hideRecenter = !(parseFloat(this.props.data.latitude));
        let url = `https://maps.googleapis.com/maps/api/js?key=${googleProperties.map.api_key}`;
        return <div className="map-wrapper-component">
            { this.state.currentLocation &&
            <div className="re-center-button" onClick={()=> {
                this.setState((prevState)=> {
                        return {
                            center: {... prevState.currentLocation}
                        }
                    }
                )
            }}>
                <span className="span-icon current-icon"/>
            </div>}
            { !hideRecenter &&
            <div className={this.state.currentLocation ? "re-center-button re-locate" : "re-center-button"}
                 onClick={()=> {
                     this.setState((prevState)=> {
                             return {
                                 center: {
                                     lat: parseFloat(prevState.data.latitude),
                                     lng: parseFloat(prevState.data.longitude)
                                 }
                             }
                         }
                     )
                 }}>
                <span className="span-icon recenter-icon"/>
            </div>}
            <MapComponent
                isMarkerShown
                googleMapURL={url}
                loadingElement={<div style={{height: `100%`}}><Loader loading={true}/></div>}
                containerElement={<div style={{height: props.height || `375px`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
                data={this.props.data}
                address={this.state.address}
                center={this.state.center}
                icon={this.props.icon}
            />
        </div>
    }

    getLocation(latlng) {
        console.log("getting location");
        return getGeoCoder().then((geocoder)=> {
            geocoder.geocode({'location': latlng}, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        let address = results[0].formatted_address;
                        this.setState({address: address});
                    } else {
                        this.setState({address: 'No results found'});
                    }
                } else {
                    console.log("status", status)
                    this.setState({address: `GeoCoder failed due to: ${status}`});
                }
            });
        })
    }

    componentWillUnmount() {
        this.mounted = false;
    }

}



