import React from "react";
import PageHeader from '../../components/pageheader/pageHeader';
import {BarLoader} from '././../../widgets';
import './pageWrapper.css';
class PageWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {children}=this.props;
        return <div className="container-fluid page-wrapper clearfix" id="page-wrapper">
            {children}
        </div>
    }
}


const PageWrapperHeader = (props)=> {
    return <PageHeader {...props}>
        {props.children}
    </PageHeader>
};
PageWrapper.Header = PageWrapperHeader;

const PageWrapperBody = (props)=> {
    return <div className="panel">
        <BarLoader scope="header"/>
        <div className="panel-body">
            <div className="dataTables_wrapper form-inline">
                {props.children}
            </div>
        </div>
    </div>

};
PageWrapper.Body = PageWrapperBody;

const PageWrapperBodyHeader = (props)=> {
    return <div className="row filter-wrapper">
        <div className="col-sm-12">
            {props.children}
        </div>
    </div>
};
PageWrapper.BodyHeader = PageWrapperBodyHeader;

export  default PageWrapper;


