import React from "react";
import { ButtonToolbar } from "react-bootstrap";
import {
  refreshTimestamp,
  templateStrings,
  MessageType,
  DeviceStatusFilterValues,
  DeviceSortValues,
} from "../../../../../Constant";
import { Paginator, Search as SearchBar } from "../../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { RegionSelector } from "../../../../../shared/components";
import { FrequencyListItem } from "../../../devices/views/frequencyListItem";
import { connect } from "react-redux";
import { setServerTime } from "../../../../../redux/actions/settingActions";
import {
  fetchCurrentServerTime,
  fetchDevices,
  deleteDevices,
} from "../../../../../services/deviceService";
import {
  AuthenticationService,
  OPERATIONS,
} from "../../../../../services/authorization";
import { routes } from "../../../../../routing/routes";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";

class FrequencyDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    this.state = {
      showModal: false,
      content: [],
      pageNo: 0,
      sortBy: true,
      modalType: "add",
      showDeviceInfoModal: false,
      deviceInfo: {},
      showHeaderBUtton: false,
      searchString: "",
      placeHolder: "Search Devices",
      selectedSort: DeviceSortValues.DELAY,
      selectedStatus: DeviceStatusFilterValues.ALL,
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.fetchDeviceByRegion = this.fetchDeviceByRegion.bind(this);
    this.filterByProtocol = this.filterByProtocol.bind(this);
    this.getList = this.getList.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.updateStateForRegion = this.updateStateForRegion.bind(this);
    this.supportedTypes = [".csv"];
  }

  componentWillMount() {
    if (!this.props.serverTimeOffset) {
      fetchCurrentServerTime().then((response) => {
        let time = new Date().getTime();
        let serverTime = parseInt(response.currentTime);
        let offset = serverTime - time;
        this.props.setServerTime(offset);
      });
    }
    this.fetchMasterData("depot", null);
    this.mounted = true;
  }

  componentWillReceiveProps(props) {
    console.log("zwill xyx", props);
    if (props.selectedRegion != this.props.selectedRegion) {
      this.updateStateForRegion(props.selectedRegion);
    }
  }

  /**
   * API call to get devices.
   * @param pageNo Current Page Number
   */
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.initiateDevicesPolling(
        this.state.searchString,
        this.state.selectedRegion,
        pageNo,
        this.state.selectedSort,
        this.state.depotId
      );
    }
  }

  /**
   * Used to get devices list by filter Protocol
   * @param {Filter Name} value
   */
  filterByProtocol(value) {
    let selectedProtocol = value;
    if (selectedProtocol == "reset") {
      selectedProtocol = null;
    }
    this.setState(
      {
        selectedProtocol: selectedProtocol,
        pageNo: 0,
      },
      () => {
        if (value)
          this.initiateDevicesPolling(
            this.state.searchString,
            this.state.selectedRegion,
            this.state.pageNo,
            this.state.selectedSort,
            this.state.depotId
          );
      }
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      type: "add",
      showModal: false,
    });
  }

  /**
   * Call when Add/Update modal Open.
   */
  open(type, data) {
    if (type !== "add")
      this.setState({
        modalType: type,
        showModal: true,
      });
    else this.setState({ modalType: type, showModal: true });
  }

  /*will update state for user's rights*/
  updateStateForRegion(regionId) {
    this.updateRightsForRegion(regionId);
  }

  updateRightsForRegion(region) {
    this.setState({
      canWrite: this.authService.hasRightForOperationOnRouteInRegion(
        routes.DEVICE,
        region,
        OPERATIONS.WRITE
      ),
    });
  }

  /**
   * fetches master data
   */

  onFilterChange(value) {
    this.setState(
      {
        selectedDepot: value.name ? value.name : null,
        depotId: value.id ? value.id : null,
        pageNo: 0,
      },
      () => {
        // this.fetchMasterData(this.state.pageNo)
        this.initiateDevicesPolling(
          this.state.searchString,
          this.state.selectedRegion,
          0,
          this.state.selectedSort,
          this.state.depotId
        );
      }
    );
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          this.setState({ depots: res.content });
        }
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.updateErrorState(err);
      });
  }

  render() {
    // let {typeId}=useParams();
    // console.log("ths is typ id",typeId)
    return (
      <div className="device-page">
        <PageWrapper.Header
          heading="Device-wise Frequency "
          hideS={true}
          onAdd={this.open.bind(this, "add", null)}
          showButton={this.state.showHeaderBUtton}
        >
          <div className="pull-right">
            <a
              onClick={() => {
                if (this.state.searchString) {
                  this.setState({
                    selectedProtocol: null,
                    selectedStatus: {},
                  });
                }
                this.initiateDevicesPolling(
                  this.state.searchString,
                  this.state.selectedRegion,
                  this.state.pageNo,
                  this.state.selectedSort,
                  this.state.depotId
                );
              }}
            >
              <span className="span-icon refresh-icon" />
            </a>
          </div>
          {/* <div className="pull-right">
                        <ChangeTimeButton />
                    </div> */}
          {/* {this.state.canWrite &&
                    <ImportButton importKey="device"
                                  regionId={this.state.selectedRegion}
                                  supportedTypes={this.supportedTypes}
                                  onMessage={(e, msg)=> {
                                      if (e == MessageType.SUCCESS)
                                          this.initiateDevicesPolling(this.state.searchString, this.state.selectedRegion, 0, this.state.selectedSort);
                                      this.props.initiateNotification({type: e, message: msg});

                                  }}/>
                    } */}

          {/*<DeviceConfigure flag={CONFIG_CMDS.FRESH_CONFIG} setNotification={this.props.initiateNotification}/>*/}
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
          {this.state.deviceCount && (
            <span className="cluster-ip">
              Device Count : {this.state.deviceCount}
            </span>
          )}
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container ">
              <div className="form-group">
                <p className="filters-label">Filter By:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <RegionSelector
                      filterKey={routes.REPORT}
                      onRegionChange={this.handleRegionChange}
                      showProtocols={true}
                      onProtocolChange={this.filterByProtocol}
                      selectedProtocol={this.state.selectedProtocol}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={templateStrings.frequency}
              timeInterval={this.props.timeInterval}
              timeParse={this.props.timeParse}
            />
            <tbody>
              {this.state.content &&
                this.state.content.map((data) => {
                  return (
                    <FrequencyListItem
                      key={data.deviceId}
                      item={data}
                      timeInterval={this.props.timeInterval}
                      highLightString={this.state.highLightString}
                      navigateToDetailFrequency={
                        this.props.navigateToDetailFrequency
                      }
                      region={this.state.selectedRegion}
                    />
                  );
                })}

              {(!this.state.content ||
                (this.state.content && this.state.content.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.content && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  handleRegionChange(regionId) {
    // this.setState({selectedRegion:regionId})
    this.fetchDeviceByRegion(regionId);
    this.updateStateForRegion(regionId);
  }

  handleSearchChange(e) {
    this.setState(
      {
        searchString: e,
      },
      () => {
        this.initiateDevicesPolling(
          this.state.searchString,
          this.state.selectedRegion,
          0,
          this.state.selectedSort,
          this.state.depotId
        );
      }
    );
  }

  sortByField(value) {
    if (value == "reset") {
      value = null;
    }
    this.setState({
      selectedSort: value,
    });
    this.initiateDevicesPolling(
      this.state.searchString,
      this.state.selectedRegion,
      0,
      value,
      this.state.depotId
    );
  }

  fetchDeviceByRegion(regionId) {
    this.setState(
      {
        selectedRegion: regionId,
        selectedProtocol: null,
        selectedStatus: DeviceStatusFilterValues.ALL,
        filterStatus: null,
        searchString: "",
        selectedDepot: null,
        depotId: null,
        pageNo: 0,
      },
      () => {
        this.initiateDevicesPolling(
          null,
          regionId,
          0,
          this.state.selectedSort,
          this.state.depotId
        );
      }
    );
  }

  initiateDevicesPolling(str, regionId, pageNo, sortkey, depot) {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    let intervalId;
    this.fetchDevices(str, regionId, pageNo, sortkey, depot);
    intervalId = setInterval(() => {
      if (!document.hidden)
        this.fetchDevices(str, regionId, pageNo, sortkey, depot);
    }, refreshTimestamp);
    this.setState({
      intervalId: intervalId,
    });
  }

  fetchDevices(str, regionId, pageNo, sortBy, depot) {
    this.props.updateLoader(true);
    fetchDevices(
      str,
      regionId,
      pageNo,
      sortBy.key,
      this.state.selectedProtocol,
      this.props.timeInterval,
      this.state.selectedStatus.key,
      this.state.filterStatus,
      depot
    )
      .then((response) => {
        this.props.updateLoader(false);
        this.updateComponentState(response, str);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.updateErrorState(err);
      });
  }

  updateErrorState(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.updateComponentState(null, null);
      this.props.initiateNotification({
        type: MessageType.ERROR,
        message: err.description
          ? err.description
          : "Error while searching data",
      });
    }
    console.log(err);
  }

  updateComponentState(data, highLightString) {
    if (this.mounted) {
      this.setState({
        totalPages: data ? data.totalPages : 0,
        pageNo: data ? data.currentPage : 0,
        content: data ? data.items : null,
        deviceCount: data ? data.totalItems : null,
        highLightString: highLightString,
      });
    }
  }

  deleteDevice(deviceId) {
    deleteDevices(deviceId, this.state.selectedRegion).then(() => {
      this.initiateDevicesPolling(
        this.state.searchString,
        this.state.selectedRegion,
        this.state.pageNo,
        this.state.selectedSort,
        this.state.depotId
      );
    });
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this.mounted = false;
  }
}
function mapStateToProps(state) {
  return {
    timeParse: state.Settings.timeParse,
    selectedRegion: state.Settings.selectedRegion,
    timeInterval: state.Settings.timeInterval,
    serverTimeOffset: state.Settings.serverTimeOffset,
  };
}
const mapDispatchToProps = (dispatch, state) => ({
  ...defaultMapDispatchToProps(dispatch, state),
  setServerTime: (value) => {
    dispatch(setServerTime(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrequencyDeviceList);
