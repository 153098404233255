import React from 'react';
import GPSQualityContainer from '../containers/gpsQualityReport';
import {Route, Redirect, Switch} from 'react-router-dom';
import DetailViewContainer from "../containers/vehicleDetailContaner";

export class ReportsWrapper extends React.Component {
    render() {
        const {match}=this.props;
        return <Switch>
            <Route exact path={`${match.url}/:typeId`} component={GPSQualityContainer}/>
            <Route path={`${match.url}/:typeId/:vehicleId`} component={DetailViewContainer}/>
            <Redirect from="/" to={`${match.url}/gpsQuality`}/>
        </Switch>

    }
}