import React, { useEffect, useState } from "react";
import { getCityData } from "../../../services/vehicleMasterService";
import { SelectFilter } from "../../widgets";

export const CityFilterDropDown = (props) => {
    const [cities, setCities] = useState([]);
    const {rowClass,cityLabelClass,cityOptionClass,selectedCity,setSelectedCity} = props;

  useEffect(() => {
    fetchCityNames();
  }, []);

  const fetchCityNames = () => {
    getCityData().then((response) => {
      if (response.content.length) {
        const data = [];
        response.content.forEach((item) => {
            data.push({ name: item.name, cityId: item.id });
        });
        setCities(data);
      } else {
        setCities([]);
      }
    });
  };
  return (
    <div className={rowClass}>
     <div className={cityLabelClass}>
      <label htmlFor="city" className="form-label">
        <span>City</span>
      </label>
     </div>
      <div className={cityOptionClass}>
        <SelectFilter
          value={selectedCity}
          type="city"
          onChange={(value) => { setSelectedCity(value) }}
          valueKey="name"
          labelKey="name"
          clearable={true}
          placeholder="Select City"
          options={cities}
        />
      </div>
    </div>
  );
};
