import React from "react";
import { templateStrings } from "../../../../../../Constant";
import * as moment from "moment";

const FilterRouteIds = (val) => {
  console.log(val.toString())
  return val.toString();
};


export const DeviceInfoItem = (props) => (
  <div className="row">
    <div>
      <span className="info-label"> {templateStrings[props.label]} :</span>
      {props.label != "stopName" && <span className="info-value">
        {props.label === "createdAt" ||
        props.label === "updatedAt" ||
        props.label === "lastPacketArrivedAt"
          ? moment(props.value).format("DD MMM YYYY")
          : props.label === "deviceStop"
          ? props.value && props.value.stopId
            ? props.value.stopId
            : "NA"
          : props.label === "routeIds"
          ? props.value && props.value.length
            ? FilterRouteIds(props.value)
            : "NA"
          : props.value && props.value.name 
          ? props.value.name
          : props.value ? props.value : "NA"}
      </span>}
      {props.label == "stopName" && <span className="info-value">
        {props.value && props.value.stopName ? props.value.stopName :"N/A"}
        </span>
      }
    </div>
  </div>
);
