import {combineReducers} from "redux";
import {loadingBarReducer} from "./../../shared/widgets/bar-loader/src";
import Settings from "./settingsReducer";
import User from "./userReducer";
import UserForm from "./userFormReducer";
import Auth from "./authReducer";
import Notification from "./notificationReducer";
import SideBarToggle from "./sideToggleReducer";
import MetaData from "./metaDataReducer";
import Regions from "./regionReducer";
import Timezones from "./timezoneReducer";
import Protocols from "./protocolReducer";
import DelayMatrix from "./delayMatrixReducer";
import PostingError from "./postingErrorReducer";
import PaginatorReducer from "../../shared/widgets/paginator/redux/reducer";
import CityReducer from "./cityReducer";
import {reducer as formReducer} from "redux-form";
import {actions} from "./../actions/action-list";
import initialState from "./initialState";
import Alert from "./alertReducer";

const appReducer = combineReducers({
    Auth,
    DelayMatrix,
    Protocols,
    Regions,
    Timezones,
    User,
    Notification,
    SideBarToggle,
    CityReducer,
    form: formReducer,
    UserForm,
    Settings,
    paginator: PaginatorReducer,
    PostingError,
    loadingBar: loadingBarReducer,
    MetaData,
    Alert
});

export default function rootReducer(state, action) {
    let appState = state;
    if (action.type == actions.LOG_OUT) {
        appState = initialState
    }
    return appReducer(appState, action);
}