import {actions} from '../../actions/action-list';
import  initialState from "./initialState";

function MetaData(state = initialState, action) {
    switch (action.type) {
        case actions.FETCHED_CITY_META:
            state['cityMetaData'][action.city] = action.value;
            return Object.assign({}, state);
        default:
            return state;
    }
}
export default MetaData;