import React from "react";
import { Route, Switch } from "react-router-dom";
import StopList from "./views/StopList";
import BSDList from "./views/BSDList";
import BSDLoglist from "./views/deviceLog/BSDLoglist";
import StopLoglist from "./views/deviceLog/StopLoglist";
import BSDImport from "./views/importFile/BSDImport";

export class BSDWrapper extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}/detail`} component={BSDList} />
        <Route exact path={`${match.url}/stop`} component={StopList} />
        <Route exact path={`${match.url}/:deviceId`} component={BSDLoglist} />
        <Route exact path={`${match.url}/stops/:stopId`} component={StopLoglist} />
        <Route exact path={`${match.url}/file/Import`} component={BSDImport} />
      </Switch>
    );
  }
}
