import {sortOption} from "./sortingView";
export const sortData = (data, key, order)=> {
    return data.sort((a, b)=> {
        let returnValue;
        if (order === sortOption.DESC) {
            returnValue = b[key] - a[key];
        } else if (order === sortOption.ASC) {
            returnValue = a[key] - b[key];
        }
        return returnValue;
    })
};
