import React from "react";
import { Button } from "react-bootstrap";
import validateTrip from '../../validator/tripValidator';
import { SingleDateTimePicker } from '../../../../../shared/widgets';
import { MessageType } from "../../../../../Constant";
import { createTrip } from "../../../../../services/tripDistanceService";

export class TripCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            date: new Date()
        };
        this.toDateChange = this.toDateChange.bind(this);
        this.fromDateChange = this.fromDateChange.bind(this);

    }

    componentDidMount() {
        this.mounted = true;
        this.updateFormErrorState();
    }

    render() {
        return (
            <div className="row">
                <form className="form-horizontal">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>Vehicle No</span></label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="vehicleNo"
                                    placeholder="Enter Vehicle No"
                                    onChange={(value) => {
                                        this.setState({ vehicleNo: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Device Id</label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="deviceId"
                                    placeholder="Enter Device Id"
                                    onChange={(value) => {
                                        this.setState({ deviceId: value.target.value.trim() }, () => {
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Trip Id</label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="tripId"
                                    placeholder="Enter Trip Id"
                                    onChange={(value) => {
                                        this.setState({ tripId: value.target.value.trim() })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>Start Time</span></label>
                            <div className="col-sm-10 ">
                                <SingleDateTimePicker id="startTime" date={this.state.date} onDateChange={this.fromDateChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>End Time</span></label>
                            <div className="col-sm-10 ">
                            <SingleDateTimePicker id="endTime" date={this.state.date} onDateChange={this.toDateChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.fetchAddUpdate()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}>{this.state.loading ? 'Saving...' : 'Save changes'}</Button>
                    </div>
                </form>
            </div>)
    }

    /**
     * validates trip form data
     * @param target
     */
    updateFormErrorState() {
        let formState = validateTrip(this.state);
        this.setState({
            form: formState
        });
    }

    fromDateChange(date) {
        this.setState({ startTime: date.format("DD-MM-YYYY HH:mm") },()=>{ 
            this.updateFormErrorState()});
            console.log("fromdate",this.state.startTime)
           }

    toDateChange(date) {
        this.setState({ endTime: date.format("DD-MM-YYYY HH:mm") },()=>{
             this.updateFormErrorState()});
    }

    /**
     * Used to Add trip
     */
    fetchAddUpdate() {
        createTrip(this.props.selectedRegion,  this.state).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ( "Invalid token"));
            } else if (this.mounted) {
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));

            }

        });
    }
    componentWillUnmount() {
        this.mounted = false;
    }
}