import React from "react";
import { Button } from "react-bootstrap";
import * as moment from "moment";
import { RangePicker } from '../../../../shared/widgets/datepickers/dateRangePickers'
import { downLoadDistanceData ,downLoadFrequencyDistanceData, exportVehicleInOut} from '../services/reportService';
import {MessageType} from '../../../../Constant';
import { exportBsdAvailablityReport,exportEtaAckMatrixReport } from '../services/reportService'
export class CustomExport extends React.Component {
    constructor(props) {
        super(props);
        let m1 = moment(new Date());
        let m2 = moment(new Date());
        this.state = {
            form: {},
            endDate: m1.subtract(1, 'day'),
            startDate: m2.subtract(7, 'days')
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    render() {
        return (
            <div className="row">
                <form className="form-horizontal">

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-3 form-label"><span>Select Range</span></label>
                            <div className="col-sm-9 ">
                                <RangePicker startDate={this.state.startDate} endDate={this.state.endDate} monthsToDisplay={1}
                                    onDatesChange={this.handleDatesChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.downloadDistance()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}>{this.state.loading ? 'Exporting...' : 'Export'}</Button>
                    </div>
                </form>
            </div>)
    }

    handleDatesChange(dates) {
        let fromDate;
        let toDate;
        if (dates.startDate && dates.endDate) {
            fromDate = parseFloat(dates.startDate.startOf('day').format('x'));
            toDate = parseFloat(dates.endDate.endOf('day').format('x'))+1;

            this.setState({
                startDate: fromDate,
                endDate: toDate
            })
        }
    }


    downloadDistance() {
        this.setState({ loading: false });
        if(this.props.type == 'gpsQuality' || this.props.type=='distance'){
            downLoadDistanceData(this.props.type, this.props.selectedRegion, this.state.startDate, this.state.endDate).then((res) => {
                console.log(res)
                this.setState({ loading: false });
    
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
    
            }).catch((err) => {
                console.log(err);
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
    
                if ((err.status === 401 || err.message==='Invalid token')) {
                    console.log('unauthorized', err.description)
                }
            });
        } else if(this.props.type == 'bsdAvailabilityReport'){
            const { pageNo, selectedProtocol, selectedStatus, searchString } = this.props.bsdAvailabilityState;
            exportBsdAvailablityReport(this.state.startDate, this.state.endDate,pageNo,selectedProtocol,selectedStatus,searchString)
            .then((res)=>{
                this.setState({ loading: false });
    
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }).catch((err)=>{
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
    
                if ((err.status === 401 || err.message==='Invalid token')) {
                    console.log('unauthorized', err.description)
                }
            })
        }else if (this.props.type == 'etaAckMatrix'){
            const { pageNo, selectedCity, searchString,sortOrder,sortKey } = this.props.etaAckMatrixState;
            exportEtaAckMatrixReport(
                this.state.startDate,
                this.state.endDate,
                pageNo,
                selectedCity,
                searchString,
                sortKey,
                sortOrder
                )
            .then((res)=>{
                this.setState({ loading: false });
    
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }).catch((err)=>{
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
    
                if ((err.status === 401 || err.message==='Invalid token')) {
                    console.log('unauthorized', err.description)
                }
            })
        } else if(this.props.type == 'vehicleInOutEvent'){
            const data = {
                ...this.props.state,
                startDate:this.state.startDate,
                endDate:this.state.endDate
            }

            exportVehicleInOut(data)
            .then((res) => { this.setState({ loading: false }); this.props.onComplete(MessageType.SUCCESS, "Successfully done "); }).catch((err) => { if (err.status === 401 || err.message === "Invalid token") { console.log(err) } })
        } else{
            downLoadFrequencyDistanceData(this.props.selectedRegion, this.state.startDate, this.state.endDate).then((res) => {
                console.log(res)
                this.setState({ loading: false });
    
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
    
            }).catch((err) => {
                console.log(err);
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
    
                if ((err.status === 401 || err.message==='Invalid token')) {
                    console.log('unauthorized', err.description)
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}