import {actions} from '../../actions/action-list';
import  initialState from "./initialState";

function PostingError(state = initialState, action) {
    switch (action.type) {
        case actions.POSTING_ERROR.DATA_FETCHED:
            return Object.assign({}, state, {
                displayData: action.value
            });
        case actions.POSTING_ERROR.LOADING:
            return Object.assign({}, state, {
                loading: action.value
            });
        default:
            return state;
    }
}

export default PostingError;