import {actions} from "./action-list";
export function regionsFetched(value) {
    return {
        type: actions.REGIONS.DATA_FETCHED,
        value: value
    }
}
export function searchRegions(value) {
    return {
        type: actions.REGIONS.SEARCH,
        value: value
    }
}