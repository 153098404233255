import React from "react";
import * as moment from "moment";
import { ButtonToolbar } from "react-bootstrap";
import { HealthStatusFilterValues } from "../../../../../Constant";
import {
  Search as SearchBar,
  DatePicker,
  FilterView,
  DropdownWrapper,
} from "../../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { RegionSelector } from "../../../../../shared/components";
import { HealthListItem } from "../health-report/HealthListItem";
import { AuthenticationService } from "../../../../../services/authorization";
import { routes } from "../../../../../routing/routes";
import {
  fetchHealthReport,
  exportHealthReportData,
} from "../../services/reportService";
import { health } from "../../utils";
import { Modal, Button } from "react-bootstrap";
import { Paginator } from "../../../../../shared/widgets";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { connect } from "react-redux";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";

class HealthReport extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    let m = moment(new Date());
    this.state = {
      content: [],
      exportOptions: [
        {
          key: "default",
          value: "Export Health Report",
        },
      ],
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
      sortBy: true,
      endDate: "",
      startDate: "",
      searchString: "",
      placeHolder: "Search vehicle",
      filteredData: [],
      selectedDate: m.subtract(1, "day"),
      filterStatus:null,
      showSelectModal: false,
      distance: 50,
      sortOrder: "ASC",
      sortKey: "futureDatePoints",
      frequency: 80,
      avgdelay: 15,
      gpspoint: 3000,
      pastPoints: 10,
      futurePoints: 10,
      min90thPercentileDelay: 10
    };
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.fetchHealthReport = this.fetchHealthReport.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.filterByProtocol = this.filterByProtocol.bind(this);
    this.handleDataSorting = this.handleDataSorting.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.getList = this.getList.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchMasterData("city", null);
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          switch (masterType) {
            case "city": {
              this.setState({ cities: res.content });
              break;
            }
            case "agency": {
              this.setState({ agencies: res.content });
              break;
            }
            case "operator": {
              this.setState({ operators: res.content });
              break;
            }
            case "depot": {
              this.setState({ depots: res.content });
              break;
            }
          }
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching data",
      });
    }
  }

  onFilterChange(value, masterType) {
    switch (masterType) {
      case "city": {
        this.setState(
          {
            selectedCity: value && value.name ? value.name : null,
            cityId: value && value.id ? value.id : null,
            agencies: [],
            selectedAgency: null,
            agencyId: null,
            depotId: null,
            selectedDepot: null,
            depots: [],
            operatorId: null,
            operators: [],
            selectedOperator: null,
            searchString: "",
          },
          () => {
            this.fetchHealthReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              this.state.selectedProtocol,
              0,
              this.state.searchString,
              this.state.filterStatus,
              this.state.sortKey,
              this.state.sortOrder,
              this.state.distance,
              this.state.frequency,
              this.state.avgdelay,
              this.state.gpspoint,
              this.state.futurePoints,
              this.state.pastPoints,
              this.state.min90thPercentileDelay
            );
            if (this.state.cityId) {
              this.fetchMasterData("agency", { cityId: this.state.cityId });
              this.fetchMasterData("operator", { cityId: this.state.cityId });
              this.fetchMasterData("depot", { cityId: this.state.cityId });
            }
          }
        );
        break;
      }
      case "agency": {
        this.setState(
          {
            selectedAgency: value && value.name ? value.name : null,
            agencyId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHealthReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              this.state.selectedProtocol,
              0,
              this.state.searchString,
              this.state.filterStatus,
              this.state.sortKey,
              this.state.sortOrder,
              this.state.distance,
              this.state.frequency,
              this.state.avgdelay,
              this.state.gpspoint,
              this.state.futurePoints,
              this.state.pastPoints,
              this.state.min90thPercentileDelay
            );
          }
        );
        break;
      }
      case "operator": {
        this.setState(
          {
            selectedOperator: value && value.name ? value.name : null,
            operatorId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHealthReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              this.state.selectedProtocol,
              0,
              this.state.searchString,
              this.state.filterStatus,
              this.state.sortKey,
              this.state.sortOrder,
              this.state.distance,
              this.state.frequency,
              this.state.avgdelay,
              this.state.gpspoint,
              this.state.futurePoints,
              this.state.pastPoints,
              this.state.min90thPercentileDelay
            );
          }
        );
        break;
      }
      case "depot": {
        this.setState(
          {
            selectedDepot: value && value.name ? value.name : null,
            depotId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHealthReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              this.state.selectedProtocol,
              0,
              this.state.searchString,
              this.state.filterStatus,
              this.state.sortKey,
              this.state.sortOrder,
              this.state.distance,
              this.state.frequency,
              this.state.avgdelay,
              this.state.gpspoint,
              this.state.futurePoints,
              this.state.pastPoints,
              this.state.min90thPercentileDelay
            );
          }
        );
        break;
      }
    }
  }
  render() {
    return (
      <div className="device-page">
        <PageWrapper.Header heading="Health Report " hideS={true}>
          <div className="pull-right">
            <span>
              <a
                onClick={() => {
                  this.openSelectModal();
                }}
              >
                <span className="span-icon settings-icon" />
              </a>
              {this.displaySelectModal()}
            </span>
          </div>
          <div className="pull-right">
            <DropdownWrapper
              title="Export"
              menue={this.state.exportOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    this.exportHealthReportData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div>
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
          {this.state.filteredData && (
            <span className="cluster-ip">
              Device Count :{" "}
              {this.state.totalItems ? this.state.totalItems : "0"}
            </span>
          )}
          <div
            className="dataTables_length wrapper-gps"
            id="dataTables-example_length"
          >
            <ButtonToolbar>
              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.dateChange}
                withoutDefaultBorder={false}
              />
            </ButtonToolbar>
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="pull-right">
              <b>Healthy Device [</b>
              <b>Distance</b>={this.state.distance}Km,<b>Freq %</b>=
              {this.state.frequency},<b>Avg Delay</b>={this.state.avgdelay}sec,
              <b>GPS Point</b>={this.state.gpspoint},<b>Future Point</b>=
              {this.state.futurePoints}%,<b>Past Point</b>=
              {this.state.pastPoints}%,
              <b>Delay 90 (%)</b>=
              {this.state.min90thPercentileDelay}<b>
                ]</b>
            </div>
            <p className="filters-label">Filter By:</p>
            <div className="header-button-container ">
              <div className="form-group">
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <br />
                  <ButtonToolbar>
                  <RegionSelector
                    filterKey={routes.REPORT}
                    onRegionChange={this.handleRegionChange}
                    showProtocols={true}
                    onProtocolChange={this.filterByProtocol}
                    selectedProtocol={this.state.selectedProtocol}
                  />
                    {/* <RegionSelector
                      filterKey={routes.REPORT}
                      onRegionChange={this.handleRegionChange}
                      showProtocols={true} onProtocolChange={this.filterByProtocol}
                      selectedProtocol={this.state.selectedProtocol} /> */}
                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        console.log("value", value);
                        this.onFilterChange(value, "city");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />
                    <SelectFilter
                      value={this.state.selectedAgency}
                      onChange={(value) => {
                        this.onFilterChange(value, "agency");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Agency"
                      options={this.state.agencies}
                      disabled={!this.state.selectedCity}
                    />
                    <SelectFilter
                      value={this.state.selectedOperator}
                      onChange={(value) => {
                        this.onFilterChange(value, "operator");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Operator"
                      options={this.state.operators}
                      disabled={!this.state.selectedCity}
                    />
                    <SelectFilter
                      value={this.state.selectedDepot}
                      onChange={(value) => {
                        this.onFilterChange(value, "depot");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Depot"
                      options={this.state.depots}
                      disabled={!this.state.selectedCity}
                    />

                    <SelectFilter
                      value={this.state.filterStatus}
                      onChange={(value) => {
                        if(!value){
                          value = null;
                        }else{
                          value = value.name;
                        }
                          this.handleHealthFilter(value);
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Health"
                      options={HealthStatusFilterValues}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={health}
              timeInterval={this.props.timeInterval}
              timeParse={this.props.timeParse}
              onSortToggle={this.handleDataSorting}
              type={"health"}
            />
            <tbody>
              {this.state.filteredData &&
                this.state.filteredData.map((data) => {
                  return <HealthListItem key={data.deviceId} item={data} />;
                })}

              {(!this.state.filteredData ||
                (this.state.filteredData &&
                  this.state.filteredData.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.filteredData && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchHealthReport(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedRegion,
        this.state.selectedProtocol,
        pageNo,
        this.state.searchString,
        this.state.filterStatus,
        this.state.sortKey,
        this.state.sortOrder,
        this.state.distance,
        this.state.frequency,
        this.state.avgdelay,
        this.state.gpspoint,
        this.state.futurePoints,
        this.state.pastPoints,
        this.state.min90thPercentileDelay
      );
    }
  }

  handleHealthFilter(filterStatus) {
    this.setState({ filterStatus: filterStatus },()=>{
      this.fetchHealthReport(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedRegion,
        this.state.selectedProtocol,
        0,
        this.state.searchString,
        filterStatus,
        this.state.sortKey,
        this.state.sortOrder,
        this.state.distance,
        this.state.frequency,
        this.state.avgdelay,
        this.state.gpspoint,
        this.state.futurePoints,
        this.state.pastPoints,
        this.state.min90thPercentileDelay
      );
    });
  }

  handleSearchChange(key) {
    this.setState({
      searchString: key,
      filterStatus: null,
    });
    this.fetchHealthReport(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      this.state.selectedProtocol,
      0,
      key,
      this.state.filterStatus,
      this.state.sortKey,
      this.state.sortOrder,
      this.state.distance,
      this.state.frequency,
      this.state.avgdelay,
      this.state.gpspoint,
      this.state.futurePoints,
      this.state.pastPoints,
      this.state.min90thPercentileDelay
    );
  }
  exportHealthReportData() {
    let masterType = {
      cityId: this.state.selectedCity,
      agencyId: this.state.selectedAgency,
      operatorId: this.state.selectedOperator,
      depotId: this.state.selectedDepot,
    };
    exportHealthReportData(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      this.state.selectedProtocol,
      0,
      this.state.searchString,
      this.state.filterStatus,
      this.state.sortKey,
      this.state.sortOrder,
      this.state.distance,
      this.state.frequency,
      this.state.avgdelay,
      this.state.gpspoint,
      this.state.futurePoints,
      this.state.pastPoints,
      masterType,
      this.state.min90thPercentileDelay
    )
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
        }
      });
  }

  /**
   * Used to get devices list by filter Protocol
   * @param {Filter Name} value
   */
  filterByProtocol(value) {
    let selectedProtocol = value;
    if (selectedProtocol == "reset") {
      selectedProtocol = null;
    }
    this.setState(
      {
        selectedProtocol: selectedProtocol,
      },
      () => {
        if (value)
          this.fetchHealthReport(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedRegion,
            this.state.selectedProtocol,
            0,
            this.state.searchString,
            this.state.filterStatus,
            this.state.sortKey,
            this.state.sortOrder,
            this.state.distance,
            this.state.frequency,
            this.state.avgdelay,
            this.state.gpspoint,
            this.state.futurePoints,
            this.state.pastPoints,
            this.state.min90thPercentileDelay
          );
      }
    );
  }

  openSelectModal() {
    this.setState({
      showSelectModal: true,
    });
  }
  displaySelectModal() {
    return (
      <Modal
        dialogClassName="custom-modal"
        show={this.state.showSelectModal}
        bsSize="large"
        onHide={() => {
          this.setState({ showSelectModal: false });
          this.formData = null;
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Health Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Distance (km)</span>
                  </label>
                  <div className="col-sm-7">
                    <input
                      type="number"
                      className="form-control"
                      id="distance"
                      min="1"
                      value={this.state.distance}
                      placeholder="Enter Distance (km)"
                      onChange={(value) => {
                        this.setState({ distance: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Freq %(0-10sec)</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="frequency"
                      min="0"
                      value={this.state.frequency}
                      placeholder="Enter Freq %(0-10sec)"
                      onChange={(value) => {
                        this.setState({ frequency: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Avg Delay</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="avgdelay"
                      min="1"
                      value={this.state.avgdelay}
                      placeholder="Enter Avg Delay(secs)"
                      onChange={(value) => {
                        this.setState({ avgdelay: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>GPS Point</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="gpspoint"
                      min="1"
                      placeholder="Enter GPS point"
                      value={this.state.gpspoint}
                      onChange={(value) => {
                        this.setState({ gpspoint: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Future Point</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="futurepoint"
                      min="1"
                      placeholder="Enter Future point"
                      value={this.state.futurePoints}
                      onChange={(value) => {
                        this.setState({ futurePoints: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Past Point</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="pastpoint"
                      min="1"
                      placeholder="Enter Past point"
                      value={this.state.pastPoints}
                      onChange={(value) => {
                        this.setState({ pastPoints: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-5 form-label"
                  >
                    <span>Delay 90 (%)</span>
                  </label>
                  <div className="col-sm-7 ">
                    <input
                      type="number"
                      className="form-control"
                      id="min90thPercentileDelay"
                      min="1"
                      placeholder="Enter Past point"
                      value={this.state.min90thPercentileDelay}
                      onChange={(value) => {
                        this.setState({ min90thPercentileDelay: value.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => {
              this.fetchAddUpdate();
            }}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  fetchAddUpdate() {
    this.fetchHealthReport(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      this.state.selectedProtocol,
      0,
      this.state.searchString,
      this.state.filterStatus,
      this.state.sortKey,
      this.state.sortOrder,
      this.state.distance,
      this.state.frequency,
      this.state.avgdelay,
      this.state.gpspoint,
      this.state.futurePoints,
      this.state.pastPoints,
      this.state.min90thPercentileDelay
    );
    this.setState({
      showSelectModal: false,
      filterStatus: null,
    });
  }

  handleRegionChange(regionId) {
    let startDate = parseFloat(
      this.state.selectedDate.startOf("day").format("x")
    );
    let endDate =
      parseFloat(this.state.selectedDate.endOf("day").format("x")) + 1;
    this.setState(
      {
        selectedRegion: regionId,
        filterStatus: this.state.filterStatus,
        startDate: startDate,
        endDate: endDate,
        selectedProtocol: null,
        selectedCity: null,
        selectedAgency: null,
        selectedOperator: null,
        selectedApplication: null,
        selectedDepot: null,
        agencies: [],
        operators: [],
        depots: [],
      },
      () => {
        this.fetchHealthReport(
          startDate,
          endDate,
          regionId,
          this.state.selectedProtocol,
          0,
          this.state.searchString,
          this.state.filterStatus,
          this.state.sortKey,
          this.state.sortOrder,
          this.state.distance,
          this.state.frequency,
          this.state.avgdelay,
          this.state.gpspoint,
          this.state.futurePoints,
          this.state.pastPoints,
          this.state.min90thPercentileDelay
        );
      }
    );
  }

  dateChange(date) {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
        filterStatus: null,
      },
      () => {
        this.fetchHealthReport(
          startDate,
          endDate,
          this.state.selectedRegion,
          this.state.selectedProtocol,
          0,
          this.state.searchString,
          this.state.filterStatus,
          this.state.sortKey,
          this.state.sortOrder,
          this.state.distance,
          this.state.frequency,
          this.state.avgdelay,
          this.state.gpspoint,
          this.state.futurePoints,
          this.state.pastPoints,
          this.state.min90thPercentileDelay
        );
      }
    );
  }

  /**
   * sort data for the giver key in given order
   * @param order
   * @param key
   */
  handleDataSorting(order, key) {
    this.setState({
      sortOrder: order,
      sortKey: key,
    });
    this.fetchHealthReport(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      this.state.selectedProtocol,
      0,
      this.state.searchString,
      this.state.filterStatus,
      key,
      order,
      this.state.distance,
      this.state.frequency,
      this.state.avgdelay,
      this.state.gpspoint,
      this.state.futurePoints,
      this.state.pastPoints,
      this.state.min90thPercentileDelay
    );
  }

  fetchHealthReport(
    startTime,
    endTime,
    regionId,
    selectedProtocol,
    pageNo,
    search,
    filterStatus,
    distance,
    sortKey,
    sortOrder,
    frequency,
    avgdelay,
    gpspoint,
    historypoint,
    futurepoint,
    min90thPercentileDelay
  ) {
    let masterType = {
      cityId: this.state.selectedCity,
      agencyId: this.state.selectedAgency,
      operatorId: this.state.selectedOperator,
      depotId: this.state.selectedDepot,
    };
    this.props.updateLoader(true);
    fetchHealthReport(
      startTime,
      endTime,
      regionId,
      selectedProtocol,
      pageNo,
      search,
      filterStatus,
      distance,
      sortKey,
      sortOrder,
      frequency,
      avgdelay,
      gpspoint,
      historypoint,
      futurepoint,
      masterType,
      min90thPercentileDelay
    )
      .then((data) => {
        this.setState({
          filteredData: data.content,
          totalPages: data.totalPages,
          pageNo: data.pageable.pageNumber,
          totalItems: data.totalElements,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
          filteredData: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
}
export default connect(null, defaultMapDispatchToProps)(HealthReport);
