import React from "react";
import { MessageType } from "../../../../../Constant";
import { Paginator, Search as SearchBar } from "../../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { AuthenticationService } from "../../../../../services/authorization";
import {
  fetchRSU,
  createCCO,
  fetchCcoStatus,
} from "../../services/reportService";
import { Modal, Button } from "react-bootstrap";
import * as moment from "moment";
import { RSUTemplateStrings } from "../../utils";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { connect } from "react-redux";
import {EpochtoEpochTZ} from "../../../../../shared/utils";

export class RSUreport extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    this.state = {
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
      filteredData: this.props.data,
      data: this.props.data,
      showSelectModal: false,
      showSRSelectModal: false,
      RSUdeviceId: null,
      dataCCO: null,
      loadingstatus: null,
      searchString: "",
      placeHolder: "Search By ICCID",
      sortOrder: "DESC",
    };
    this.fetchRSU = this.fetchRSU.bind(this);
    this.fetchCcoStatus = this.fetchCcoStatus.bind(this);
    this.createCCO = this.createCCO.bind(this);
    this.getList = this.getList.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  render() {
    return (
      <div className="device-page">
        <PageWrapper.Header
          heading="RSU Report "
          hideS={true}
          showButton={true}
          title="ICCID"
          onAdd={this.openSelectModal.bind(this, null)}
        >
          <div className="pull-right">
            <a
              onClick={() => {
                this.setState({ searchString: "" });
                this.fetchRSU(0, null, this.state.sortOrder);
              }}
            >
              <span className="span-icon-refresh refresh-icon" />
            </a>
          </div>
          {this.state.data && (
            <span className="cluster-ip">
              Device Count : {this.state.totalItems}
            </span>
          )}
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <TableWrapper>
            <TableWrapper.Header
              columns={RSUTemplateStrings}
              onSortToggle={this.onSortChange}
            />
            <tbody>
              {this.state.data &&
                this.state.data.map((data) => {
                  return (
                    <tr key={data.timestamp} className="gradeA odd" role="row">
                      <td
                        className={!data.srId ? "list-item-red" : "list-item"}
                      >
                        {data.deviceId}
                      </td>
                      <td className="list-item">{data.srId}</td>
                      <td className="list-item">
                        {moment(EpochtoEpochTZ(data.timestamp,localStorage.getItem('timeZone'))).format("DD MMM YYYY")}
                      </td>
                      <td>
                        <span className="protocol">
                          {data.createdBy ? data.createdBy : "NA"}
                        </span>
                        <div className="protocol-list">
                          {data.createdBy ? data.createdBy : "NA"}
                        </div>
                      </td>
                      <td className="action-column">
                        <span className="table-icons view-icon">
                          <a onClick={() => this.openSRSstatusModal(data.srId)}>
                            <i
                              className="fa fa-eye protocol"
                              aria-hidden="true"
                            />
                            <span className="protocol-list">view status</span>
                          </a>
                        </span>
                      </td>
                    </tr>
                  );
                })}

              {(!this.state.data ||
                (this.state.data && this.state.data.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.data && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
          {this.displaySelectModal()}
          {this.displaySRStatusModal()}
        </PageWrapper.Body>
      </div>
    );
  }

  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchRSU(pageNo, this.state.searchString, this.state.sortOrder);
    }
  }

  handleSearchChange(key) {
    this.setState(
      {
        searchString: key,
      },
      () => {
        this.fetchRSU(0, this.state.searchString, this.state.sortOrder);
      }
    );
  }

  openSelectModal() {
    this.setState({
      invalid: true,
      showSelectModal: true,
    });
  }
  onSortChange(sort, key) {
    this.setState({
      sortOrder: sort,
    });
    this.fetchRSU(0, this.state.searchString, sort);
  }
  openSRSstatusModal(srId) {
    this.fetchCcoStatus(srId);
    this.setState({
      showSRSelectModal: true,
    });
  }

  displaySelectModal() {
    return (
      <Modal
        dialogClassName="custom-modal"
        show={this.state.showSelectModal}
        bsSize="large"
        onHide={() => {
          this.setState({ showSelectModal: false, invalidJson: false });
          this.formData = null;
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter New ICCID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="col-sm-12">
                <div className="form-group">
                  <label
                    htmlFor="name7"
                    className="control-label col-sm-7 form-label"
                  ></label>
                  <div className="col-sm-9 ">
                    <input
                      type="number"
                      className="form-control"
                      id="RSUdeviceId"
                      placeholder="Enter ICCID"
                      onChange={(value) => {
                        this.setState({
                          RSUdeviceId: value.target.value,
                          invalid: false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => {
              this.fetchAddUpdate();
            }}
            disabled={this.state.invalid}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  displaySRStatusModal() {
    return (
      this.state.dataCCO &&
      this.state.loadingstatus && (
        <Modal
          show={this.state.showSRSelectModal}
          onHide={() => {
            this.setState({ showSRSelectModal: false });
            this.formData = null;
          }}
          dialogClassName="info-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>SR Status Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row device-info">
              <div className="col-sm-8">
                <div className="row">
                  <span className="info-label">SR Id</span>
                  <span className="info-value">
                    {this.state.dataCCO.srid ? this.state.dataCCO.srid : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">API Response Code</span>
                  <span className="info-value">
                    {this.state.dataCCO.apiResponseCode
                      ? this.state.dataCCO.apiResponseCode
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">API Response Msg</span>
                  <span className="info-value">
                    {this.state.dataCCO.apiResponseMsg
                      ? this.state.dataCCO.apiResponseMsg
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Requested Date</span>
                  <span className="info-value">
                    {this.state.dataCCO.requestedDate
                      ? this.state.dataCCO.requestedDate
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Card Count</span>
                  <span className="info-value">
                    {this.state.dataCCO.cardCount
                      ? this.state.dataCCO.cardCount
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">SR Status</span>
                  <span className="info-value">
                    {this.state.dataCCO.srStatus
                      ? this.state.dataCCO.srStatus
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Fail Reason</span>
                  <span className="info-value">
                    {this.state.dataCCO.failReason
                      ? this.state.dataCCO.failReason
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Action Requested</span>
                  <span className="info-value">
                    {this.state.dataCCO.actionRequested
                      ? this.state.dataCCO.actionRequested
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Closure Date</span>
                  <span className="info-value">
                    {this.state.dataCCO.closureDate
                      ? this.state.dataCCO.closureDate
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Response Code</span>
                  <span className="info-value">
                    {this.state.dataCCO.responseCode
                      ? this.state.dataCCO.responseCode
                      : "NA"}
                  </span>
                </div>

                <div className="row">
                  <span className="info-label">Response Msg</span>
                  <span className="info-value">
                    {this.state.dataCCO.msg ? this.state.dataCCO.msg : "NA"}
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )
    );
  }
  fetchAddUpdate() {
    this.createCCO(this.state.RSUdeviceId);
    this.setState({ showSelectModal: false });
  }

  createCCO(deviceId) {
    this.props.updateLoader(true);
    createCCO(deviceId)
      .then((data) => {
        this.setState({
          datacreateCCO: data,
        });
        this.props.updateLoader(false);
        this.fetchRSU(0, null, this.state.sortOrder);
        this.props.initiateNotification({
          type: MessageType.SUCCESS,
          message: data.apiResponseMsg || data.msg,
        });
      })
      .catch((err) => {
        this.props.updateLoader(false);
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
        this.props.initiateNotification({
          type: MessageType.ERROR,
          message: "Error in adding Device Id",
        });
      });
  }

  fetchRSU(pageNo, searchKey, sortOrder) {
    this.props.updateLoader(true);
    fetchRSU(pageNo, searchKey, sortOrder)
      .then((data) => {
        this.setState({
          data: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.setState({
          data: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        this.props.updateLoader(false);
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
  fetchCcoStatus(SRId) {
    this.setState({ loadingstatus: false });
    this.props.updateLoader(true);
    fetchCcoStatus(SRId)
      .then((data) => {
        this.setState({
          dataCCO: data,
        });
        this.props.updateLoader(false);
        this.setState({ loadingstatus: true });
      })
      .catch((err) => {
        this.props.updateLoader(false);
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
}

export default connect(null, defaultMapDispatchToProps)(RSUreport);