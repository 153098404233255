import {routes} from "./routes";
import DeviceList from "./../views/Dashboard/devices/DeviceList.js";
import ProtocolList from "./../views/Dashboard/protocol/protocolList.js";
import RegionList from "../views/Dashboard/region/containers/regionList.js";
import {VehicleWrapper} from "../views/Dashboard/vehicle/vehicleWrapper";
import DelayMatrix from "../views/Dashboard/delaymatrix/containers/delatMatrix.js";
import LiveMapWrapper from "../views/Dashboard/liveMap/liveMapWrapper";
import {ReportsWrapper} from "../views/Dashboard/reports/views/reportsWrapper";
import TripDistanceList from "../views/Dashboard/tripDistance/tripDistanceList";
import {MasterWrapper} from "../views/Dashboard/vehicleMaster/views/masterWrapper"
import CronTrigger  from "../views/Dashboard/cronTrigger/CronTrigger";
import { BSDWrapper } from "../views/Dashboard/BSD/BSDWrapper";
import { AlertsWrapper } from "../views/Dashboard/Alerts/alertsWrapper";

export const routeComponent = {
    [routes.HOME]: DelayMatrix,
    [routes.DEVICE]: DeviceList,
    [routes.VEHICLE]: VehicleWrapper,
    [routes.BSD]: BSDWrapper,
    [routes.MASTER]: MasterWrapper,
    [routes.VIEWMASTER]: MasterWrapper,
    [routes.LIVE_MAP]: LiveMapWrapper,
    [routes.REGION]: RegionList,
    [routes.PROTOCOL]: ProtocolList,
    [routes.REPORT]: ReportsWrapper,
    [routes.TRIP]: TripDistanceList,
    [routes.CRON]: CronTrigger,
    [routes.ALERTS]:AlertsWrapper
};


