import { routes } from "../../routing/routes";
import { ACCESS_RIGHTS_LIMIT, OPERATIONS } from "./accessRights";
import { getUserAuthorities } from "./Authorization";
import { Authorities } from "./index";

export class AuthenticationService {
    /**
     * getter for authorities
     * @returns {*}
     * @constructor
     */
    get Authorities() {
        return this.authorities;
    }

    /**
     * setter for authorities
     * @param authorities
     * @constructor
     */
    set Authorities(authorities) {
        this.authorities = authorities;
    }

    get IsRefreshing() {
        return this.isRefreshing;
    }
    set IsRefreshing(isRefreshing) {
        this.isRefreshing = isRefreshing;
    }


    /**
     * getter for viewRights
     * @returns {*}
     * @constructor
     */
    get viewRights() {
        return this.VIEW;
    }

    /**
     * setter for viewRights
     * @param viewRights
     * @constructor
     */
    set viewRights(viewRights) {
        this.VIEW = viewRights;

    }

    /**
     *getter for region to be filtered
     * @returns {Array}
     */
    get getRegionToFilter() {
        return this.regionsToFilters;
    }


    constructor(authorities) {
        this.Authorities = authorities;
        this.isRefreshing = false;
        this.isValidUser=true;
        this.subscribers = [];
        this.filterRegionsMap = new Map();
        if (this.Authorities) this.generateViewRightsForRoutes();
    }

    /**
     * returns single instance of service
     * @returns {AuthenticationService}
     */
    static getInstance() {
        if (!this.service) {
            this.service = new AuthenticationService(JSON.parse(getUserAuthorities()));
        }
        return this.service;
    }


    static destroyInstance() {
        this.service = null;
    }

    generateViewRightsForRoutes() {
        let VIEW = {};
        Object.keys(routes).forEach((route) => {
            VIEW[routes[route]] = this.getViewAccessForRoute(routes[route])
        });
        this.viewRights = VIEW;
    }

    /**
     * handles  view rights for different routes
     * @param route
     * @returns {boolean}
     */
    getViewAccessForRoute(route) {
        let isAccessible = true;
        if (ACCESS_RIGHTS_LIMIT[route] && ACCESS_RIGHTS_LIMIT[route][OPERATIONS.READ]) {
            isAccessible = false;
            for (var i = 0; i < ACCESS_RIGHTS_LIMIT[route][OPERATIONS.READ].length; i++) {
                if (this.Authorities[ACCESS_RIGHTS_LIMIT[route][OPERATIONS.READ][i]]) {
                    isAccessible = true;
                    break;
                }
            }
        }
        return isAccessible;
    }

    /**
     * checks whether need to filter regions from the all regions on the basis of view permissions and saves result in Map for further use
     * @param route
     * @param operation
     * @returns {boolean}
     */
    shouldFilterRegionFor(route, operation) {
        let mapKey = `${route}_${String(operation)}`;
        let noRegionsToFilters = true;
        this.regionsToFilters = [];
        if (this.filterRegionsMap.get(mapKey)) {
            if (this.filterRegionsMap.get(mapKey).length) {
                noRegionsToFilters = false;
                this.regionsToFilters = [...this.filterRegionsMap.get(mapKey)];
            }
        } else {
            let rolesWhoHasViewRightForRoute;
            if (ACCESS_RIGHTS_LIMIT[route]) {
                rolesWhoHasViewRightForRoute = ACCESS_RIGHTS_LIMIT[route][operation]
            }

            if (rolesWhoHasViewRightForRoute)
                Object.keys(this.Authorities).forEach((value) => {
                    noRegionsToFilters = noRegionsToFilters && rolesWhoHasViewRightForRoute.indexOf(value) != -1;
                    if (rolesWhoHasViewRightForRoute.indexOf(value) == -1) {
                        this.regionsToFilters = [...this.Authorities[value], ...this.regionsToFilters]
                    }
                });
            this.filterRegionsMap.set(mapKey, this.regionsToFilters);
        }
        return !noRegionsToFilters;

    }

    // getOperationPermissionForRouteAndRegion(route, regionId, operation) {
    //     let rolesWhoHasRight;
    //     let userHasRight = true;
    //     if (ACCESS_RIGHTS_LIMIT[route]) {
    //         rolesWhoHasRight = ACCESS_RIGHTS_LIMIT[route][operation]
    //     }
    //     if (rolesWhoHasRight)
    //         var userAuthorities = Object.keys(this.Authorities);
    //     for (var i = 0; i < userAuthorities.length; i++) {
    //         let userRole = userAuthorities[i];
    //         let userRoleHasRight = rolesWhoHasRight.indexOf(userRole) != -1;
    //         if (!userRoleHasRight) {
    //             if (typeof this.Authorities[userRole] == 'boolean') {
    //                 userHasRight = false;
    //                 break;
    //             }
    //             else {
    //                 if (regionId) {
    //                     userHasRight = (this.Authorities[userRole].indexOf(regionId) == -1);
    //                     if (!userHasRight) {
    //                         break;
    //                     }
    //                 }
    //                 else userHasRight = false;
    //             }
    //
    //
    //         } else {
    //             if (typeof this.Authorities[userRole] == 'boolean') {
    //                 userHasRight = true;
    //             }
    //             else {
    //                 if (regionId)
    //                     userHasRight = this.Authorities[userRole].indexOf(regionId) != -1;
    //                 else {
    //                     userHasRight = false;
    //                 }
    //             }
    //             if (userHasRight) {
    //                 break;
    //             }
    //         }
    //     }
    //
    //     return userHasRight;
    // }

    hasRightForOperationOnRouteInRegion(route, regionId, operation) {
        let rolesWhoHasRight;
        let userHasRight = true;
        if (ACCESS_RIGHTS_LIMIT[route]) {
            rolesWhoHasRight = ACCESS_RIGHTS_LIMIT[route][operation]
        }
        if (rolesWhoHasRight) {
            var userAuthorities = Object.keys(this.Authorities);
            for (var i = 0; i < userAuthorities.length; i++) {
                let userRole = userAuthorities[i];
                let userRoleHasRight = rolesWhoHasRight.indexOf(userRole) != -1;
                if (typeof this.Authorities[userRole] == 'boolean') {
                    userHasRight = userRoleHasRight;
                    if (!userHasRight) {
                        break;
                    }
                } else {
                    if (regionId) {
                        let regionExistsInUserRole = this.Authorities[userRole].indexOf(regionId.toLowerCase()) != -1;

                        /**
                         * implemented XOR operation with not, so final result will be
                         * T  T=  T
                         * T  F=  F
                         * F  T=  F
                         * F  F=  T
                         * @type {boolean}
                         */
                        userHasRight = !(userRoleHasRight ^ regionExistsInUserRole);
                        if (regionExistsInUserRole) {
                            break;
                        }
                    } else {
                        userHasRight = false;
                    }

                }
            }
        }
        return userHasRight;
    }

    shouldShowAll() {
        return this.Authorities && (this.Authorities[Authorities.Admin] || this.Authorities[Authorities.Owner])
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getSubscriber() {
        return this.subscribers;
    }

    setSubscriber(subscribers) {
        this.subscribers = subscribers
    }

    setValidUser(val){
        this.isValidUser=val;
    }
    checkValidUser(){
        return this.isValidUser
    }
}