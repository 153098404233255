const validateCamera = (formState)=> {
    const form = {};
    const requiredFields = ['cameraId', 'vehicleNo', 'cameraBrand', 'cameraType', 'position'];
    requiredFields.forEach(field => {
        if (!formState[field] || formState[field].length === 0) {
            form._error = "Required field missing";
            form.invalid = true;
            form[`${field}Error`] = true;
        }
    });
    if (formState.simCardNo && !formState.simCardNo.match("^[A-Za-z0-9]+$")) {
        form._error = "Invalid";
        form.invalid = true;
        form.simCardNoError=true;
    }
    if (formState.sdCard && !formState.sdCard.match("^[A-Za-z0-9]+$")) {
        form._error = "Invalid";
        form.invalid = true;
        form.tfCardError=true;
    }
    if(formState.cameraId && formState.cameraId.match("[^a-zA-Z0-9]")){
        form._error = "Special character and white space not allowed";
        form.invalid = true;
        form.cameraIdSpecialChar = true;
    }
    return form;
};
export  default validateCamera; 