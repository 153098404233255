import React, { Component } from "react";
import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import { MessageType } from "../../../Constant";
import PropTypes from "prop-types";
import './dropdown.css'

class DropdownWrapper extends Component {

    constructor(props) {
        super(props);
    }
    render() {

        return (
            <div className="dropDown-wrapper" >
               
                <DropdownButton id="dropdown-basic-button" title={this.props.title} className="dropdown-button" disabled={this.props.disabled}>
                    
                    {this.props.menue && this.props.menue.map((item, index) => (
                    <MenuItem key={index} eventKey={item.key} onSelect={this.props.onSelect}>{item.value}</MenuItem>
                    ))}
                </DropdownButton>
            </div>
        );
    }

}
DropdownWrapper.propTypes = {
    title: PropTypes.string,
    menue: PropTypes.array
};

export default DropdownWrapper;


