const validateCountry = (formState) => {
  let form = {};
  if (
    !formState.country ||
    !formState.callingCode ||
    !formState.currencySymbol ||
    !formState.placeValue ||
    !formState.currency ||
    !formState.countryCode ||
    !formState.currencyFactor ||
    !formState.phoneNumberPossibleLengths
  ) {
    form._error = "Required field missing";
    form.invalid = true;
  }
  if (formState.callingCode && formState.callingCode.match("^[a-zA-Z' ]*$")) {
    form._error = "Invalid Name";
    form.invalid = true;
    form.callingNameError = true;
  }

  return form;
};
export default validateCountry;
