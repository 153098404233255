import React from "react";
import classNames from "classnames";
import moment from "moment";
import {fetchTimeValue, transformedText, timeChange} from "../../../../shared/utils.js";
import {dateFormat, DeviceStatusFilterValues} from "../../../../Constant";
import DeviceConfigure from "../../deviceConfigurationModule/views/configuration/configureDevice";

export const FrequencyListItem = ({item,navigateToDetailFrequency,highLightString,region}) => (
        <tr onClick={(e)=> {
            console.log("item frequency",item)
            if (navigateToDetailFrequency) navigateToDetailFrequency(item.vehicleNo, item.deviceId,region);
        }} key={item.deviceId} className="gradeA odd" role="row">
            <td>{highLightString && <div
                dangerouslySetInnerHTML={transformedText(item.vehicleNo, highLightString)}/>}
                {!highLightString && item.vehicleNo}
            </td>
            <td className="sorting_1"><span className="protocol">
                    {highLightString && <div
                        dangerouslySetInnerHTML={transformedText(item.deviceId, highLightString)}/>}
                {!highLightString && item.deviceId}</span>
                <div
                    className="protocol-list"> {item.deviceId}</div>
            </td>
            <td className="action-column">
                {/*<DeviceConfigure deviceId={item.deviceId} setNotification={onNotification} number={item.simCard}/>*/}
                {/*<ConfigurationComponent deviceId={item.deviceId} setNotification={onNotification} number={item.simCard} />*/}
          
            </td>
        </tr>
    )
    ;
