export const CONFIG_CMDS = {
    RESTART: 'restart',
    SET_INTERVAL: 'interval',
    SET_TIMEZONE: 'timezone',
    TIMEZONE_DEFAULT: 'timezone/default',
    INTERVAL_DEFAULT: 'interval/default',
    APN_SETTING: 'apn',
    FRESH_CONFIG: '',
};
export const deviceIgnitionOffInterval = [1, 2, 5, 10, 20, 30, 60];
export const deviceIgnitionOnInterval = [10, 20, 30, 60];
export const timeZones = {IST: {key: 19800, value: 'IST'}};
export const defaultApn = "internet";
export const Apns = {
    IDEA: {key: "internet", value: 'internet(IDEA)'},
    'Airtel 2G & 3G': {key: "airtelgprs.com", value: 'airtelgprs.com(Airtel 2G & 3G)'},
    'Vodafone 2G & 3G': {key: "www", value: 'www(Vodafone 2G & 3G)'},
};
export const configurationTabs = [{key: CONFIG_CMDS.APN_SETTING, display: 'Port & APN Setting'}, {
    key: CONFIG_CMDS.SET_INTERVAL,
    display: 'Frequency'
}, {key: CONFIG_CMDS.SET_TIMEZONE, display: 'Time zone'}, {
    key: CONFIG_CMDS.RESTART,
    display: 'Restart'
}];