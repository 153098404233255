import { MessageType } from "../../Constant";
import { setTokenExpired } from "../actions/authActions";
import { setNotificationMessage } from "../actions/notificationActions";
import { matrixFetched } from "../actions/dMatrixActions";
import {
  fetchDelayMatrix,
  fetchMatrixCount,
} from "../../views/Dashboard/delaymatrix/service/index";
import { fetchDelayMatrix2 } from "../../views/Dashboard/delaymatrix2/service/index";
import {
  showLoading,
  hideLoading,
  resetLoading,
} from "./../../shared/widgets/bar-loader/src";
export function createDelayMatrixAction(timeSlab) {
  return (dispatch) => {
    dispatch(resetLoading("header"));
    dispatch(showLoading("header"));
    fetchDelayMatrix(timeSlab)
      .then((response) => {
        dispatch(matrixFetched(response));
        dispatch(hideLoading("header"));
        fetchMatrixCount(timeSlab).then((count) => {
          let combinedData = [];
          for (let i = 0; i < response.length; i++) {
            combinedData.push({
              ...response[i],
              ...count.find(
                (itmInner) => itmInner.region === response[i].regionId
              ),
            });
          }
          dispatch(matrixFetched(combinedData));
        });
      })
      .catch((err) => {
        dispatch(hideLoading("header"));
        if (err.status == 401 || err.message === "Invalid token") {
          dispatch(setTokenExpired(true));
        } else {
          if (err.status != 204)
            dispatch(
              setNotificationMessage({
                type: MessageType.ERROR,
                message: err.description
                  ? err.description
                  : "Error while fetching Matrix Data",
              })
            );
        }
      });
  };
}
export function createDelayMatrixAction2(timeSlab, status) {
  return (dispatch) => {
    dispatch(resetLoading("header"));
    dispatch(showLoading("header"));
    fetchDelayMatrix2(timeSlab, status)
      .then((response) => {
        dispatch(matrixFetched(response));
        dispatch(hideLoading("header"));
        fetchMatrixCount(timeSlab).then((count) => {
          let combinedData = [];
          for (let i = 0; i < response.length; i++) {
            combinedData.push({
              ...response[i],
              ...count.find(
                (itmInner) => itmInner.region === response[i].regionId
              ),
            });
          }
          dispatch(matrixFetched(combinedData));
        });
      })
      .catch((err) => {
        dispatch(hideLoading("header"));
        if (err.status == 401 || err.message === "Invalid token") {
          dispatch(setTokenExpired(true));
        } else {
          if (err.status != 204)
            dispatch(
              setNotificationMessage({
                type: MessageType.ERROR,
                message: err.description
                  ? err.description
                  : "Error while fetching Matrix Data",
              })
            );
        }
      });
  };
}
