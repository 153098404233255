class FormErrorState {
    constructor() {
        this.valid = false;
    }
}

export class vehicleErrorState extends FormErrorState {
    constructor() {
        super();
        this.vehicleNo = true;
        this.agencyName = true;
        this.depotName = true;
        this.vehicleType = true;
        this.seatingCapacity = true;
    }
}


export class VehicleState {
    constructor(vehicle) {
        this.regionId = vehicle ? vehicle.regionId : "";
        this.vehicleNo = vehicle ? vehicle.vehicleNo : "";
        this.vehicleCode = vehicle ? vehicle.vehicleCode : "";
        this.vehicleType = vehicle ? vehicle.vehicleType?{ label: vehicle.vehicleType, value: vehicle.vehicleType } :"": "";
        this.simCard = vehicle ? vehicle.simCard : "";
        this.deviceId = vehicle ? vehicle.deviceId : "";
        this.afcsAccountId = vehicle ? vehicle.afcsAccountId : "";
        this.cameras = vehicle ? vehicle.cameras : "";
        this.seatingCapacity = vehicle ? vehicle.seatingCapacity?vehicle.seatingCapacity:'' : "";
        this.standingCapacity = vehicle ? vehicle.standingCapacity?vehicle.standingCapacity:'' : "";
        this.make = vehicle ? vehicle.make : "";
        this.owner = vehicle ? vehicle.owner : "";
        this.model = vehicle ? vehicle.model : "";
        this.version = vehicle ? vehicle.version : "";
        this.secondarySim = vehicle ? vehicle.secondarySim : "";
        this.serviceType = vehicle ?vehicle.serviceType? { label: vehicle.serviceType, value: vehicle.serviceType } : '':'';
        this.operationType = vehicle ?vehicle.operationType? { label: vehicle.operationType, value: vehicle.operationType } :'': '';
        this.gstOnTicket = vehicle ?vehicle.gstOnTicket? { label: vehicle.gstOnTicket, value: vehicle.gstOnTicket } :"": "";        
        this.surchargeOnTicket = vehicle ?vehicle.surchargeOnTicket? { label: vehicle.surchargeOnTicket, value: vehicle.surchargeOnTicket } :"":"";
        this.collectionOnEtim = vehicle ?vehicle.collectionOnEtim? { label: vehicle.collectionOnEtim, value: vehicle.collectionOnEtim } :"":"";
        this.fuelPlan = vehicle ?vehicle.fuelPlan? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' };
        this.gpsRequiredFromEtim = vehicle ?vehicle.gpsRequiredFromEtim? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' };
        this.fwdToShuttl = vehicle ?vehicle.fwdToShuttl? { label: 'Yes', value: 'Yes' } :{ label: 'No', value: 'No' }: { label: 'No', value: 'No' };
        this.etimFrequency =  vehicle ? vehicle.etimFrequency ? vehicle.etimFrequency : 30 :30;
        this.protocolType = vehicle ? vehicle.protocolType ? { label: vehicle.protocolType, value: vehicle.protocolType } : '' : '';
        this.chasisNumber = vehicle ? vehicle.chasisNumber : "";
        this.engineNumber = vehicle ? vehicle.engineNumber : "";
        // this.applications = vehicle ? vehicle.applications ? vehicle.applications.map((app) => {
        //     return { label: app.name, value: app }
        // }) : '' : '';
    }

}