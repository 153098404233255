import {Authorities} from "./accessRights";
/**
 *parses token authorities from COA server token
 * @param authorities
 * @returns {{}}
 */
export function tokenParser(authorities) {
    let parsedAuthorities = {};
    if (authorities) {
        authorities[0].roles.map(role=> {
            console.log("role", role)
            if (role.role === Authorities.Admin || role.role === Authorities.Owner) {
                parsedAuthorities[role.role] = true;
            } else {
                parsedAuthorities[role.role] = role.roleInfo.map((resourceSet)=> {
                    return resourceSet.filter((resource)=>resource.name == 'Region')[0] ? resourceSet.filter((resource)=>resource.name == 'Region')[0].value.toLowerCase() : '';
                })
            }
        })
    }
    return parsedAuthorities;
}


/**
 * parsing gps server's token structure using this parser logic
 * accepts authorities object structured as array of strings, string contains information of role and regions i.e.{}
 * @param authorities
 * @returns {{}}
 */
function extractAuthorities(authorities) {
    let data = {};
    authorities.map((authority)=> {
        let splitAuthority = authority.split(':');
        if (splitAuthority.length == 1) {
            data[splitAuthority[0]] = true;
        } else if (splitAuthority.length > 1) {
            if (!data[splitAuthority[0]]) {
                data[splitAuthority[0]] = [];
            }
            data[splitAuthority[0]].push(splitAuthority[1]);
        }
    });
    return data;
}