import {actions} from "../../actions/action-list";
import initialState from "./initialState";

function Settings(state = initialState, action) {
    switch (action.type) {
        case actions.CHANGE_TIME_PARSE:
            return Object.assign({}, state, {
                timeParse: action.value
            });
        case actions.CHANGE_DEVICE_REGION:
            return Object.assign({}, state, {
                selectedRegion: action.value
            });
        case actions.CHANGE_TIME_INTERVAL:
            return Object.assign({}, state, {
                timeInterval: action.value
            });
        case actions.REGIONS_FETCHED:
            return Object.assign({}, state, {
                regions: action.value
            });
        case actions.SERVER_TIME:
            return Object.assign({}, state, {
                serverTimeOffset: action.value
            });
        default:
            return state;
    }
}
export default Settings;