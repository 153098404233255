import React from "react";
import {PageWrapper} from "../../../../../shared/layout";
import {FilterView, Search as SearchBar} from "../../../../../shared/widgets";
import {sortData} from "../../../../../shared/widgets/sortingView/sorting";
import "./report.css";
import {distanceFilterRange, gpsFilterRange} from "./../../utils.js";
import {ListView} from "./../list-view/reportListComponent";
import {reportsType} from "../../utils";
import {GraphViewComponent} from "./../graph-view/graphViewComponent";
import {LineGraphComponent} from "./../graph-view/lineGraphComponent";

export class MatrixBodyWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilter: distanceFilterRange.ALL,
            placeHolder: 'Search vehicle',
            data: this.props.data,
            filteredData: this.props.data
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.updateSearchedData = this.updateSearchedData.bind(this);
        this.handleDataSorting = this.handleDataSorting.bind(this);
    }

    componentDidMount() {
        this.updateFilterRange(this.props.type);
    }

    updateFilterRange(type) {
        const filterRange = type === reportsType.DISTANCE ? distanceFilterRange : type === reportsType.GPS_QUALITY ? gpsFilterRange : null;
        this.setState({filterRange: filterRange, selectedFilter: distanceFilterRange.ALL});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data != this.props.data) {
            this.setState({data: nextProps.data ,selectedFilter: distanceFilterRange.ALL}, ()=> {
                this.updateSearchedData(this.state.searchString);
            });
        }
        if (this.props.type != nextProps.type) {
            this.updateFilterRange(nextProps.type);
        }
    }

    render() {
        return <div><PageWrapper.Body>
            <div className="graph-wrapper">
                { this.props.type != reportsType.GPS_QUALITY_DETAIL ?
                    <GraphViewComponent type={this.props.type} data={this.props.graphData}/>
                    : <LineGraphComponent type={this.props.detailType} data={this.props.graphData}
                                          threshold={this.props.detailType == reportsType.DISTANCE ? 200 : 2000}/>}
            </div>
        </PageWrapper.Body>
            <div className="report-list">
                <PageWrapper.Body>
                    {!this.props.detailType && <PageWrapper.BodyHeader>
                        <span>Filter   </span>
                        <FilterView
                            selectedValue={this.state.selectedFilter.value}
                            title="Range"
                            menues={this.state.filterRange} onSelect={(value)=> {
                            this.setState({
                                selectedFilter: value,
                                searchString: ''
                            }, ()=> {
                                this.updateFilteredData()
                            })
                        }}/>

                        {!this.props.detailType && <div className="pull-right search-container">
                            <SearchBar placeHolder={this.state.placeHolder} onSearchChange={this.handleSearchChange}
                                       value={this.state.searchString}
                            />
                        </div>}
                        {this.state.filteredData &&
                        <span className="cluster-ip"> Device Count : {this.state.filteredData.length}</span>}
                    </PageWrapper.BodyHeader>}
                    <div className="list-wrapper">
                        <ListView type={this.props.type} loading={this.props.loading} detailType={this.props.detailType}
                                  content={this.state.filteredData}
                                  navigateTo={this.props.navigateTo}
                                  onSortChange={this.handleDataSorting}
                        />
                    </div>
                </PageWrapper.Body>
            </div>
        </div>
    }

    handleSearchChange(key) {
        this.setState({searchString: key, selectedFilter: distanceFilterRange.ALL});
        this.updateSearchedData(key);
    }

    updateSearchedData(key) {
        this.setState((prevState)=> {
            return {
                filteredData: (key ? (prevState.data.filter((item)=> {
                    return ((item.vehicleNo).toLowerCase().includes(key.toLowerCase()) || (item.deviceId).toLowerCase().includes(key.toLowerCase()));
                })) : prevState.data)
            }
        }, ()=> {
            if (this.state.sortOrder) {
                this.sortFilteredData(this.state.sortOrder, this.state.sortKey);
            }
        })
    }


    updateFilteredData() {
        let key;
        if (this.props.type == reportsType.DISTANCE) {
            key = 'distance';
        } else {
            key = 'totalPoint';
        }
        this.setState((prevState)=> {
            return {
                filteredData: this.state.selectedFilter.upperRange ? (prevState.data.filter((item)=> {
                    return (this.state.selectedFilter.lowerRange <= item[key]) && (item[key] <= this.state.selectedFilter.upperRange);
                })) : this.state.selectedFilter.upperRange == null && this.state.selectedFilter.lowerRange ? (prevState.data.filter((item)=> {
                    return (this.state.selectedFilter.lowerRange <= item[key]);
                })) : prevState.data
            }
        }, ()=> {
            if (this.state.sortOrder) {
                this.sortFilteredData(this.state.sortOrder, this.state.sortKey);
            }
        })
    }

    /**
     * sort data for the giver key in given order
     * @param order
     * @param key
     */
    handleDataSorting(order, key) {
        this.setState({
            sortOrder: order,
            sortKey: key
        });
        this.sortFilteredData(order, key);
    }

    sortFilteredData(order, key) {
        if (this.state.filteredData) {
            this.setState((previousState)=> {
                return {
                    filteredData: sortData(previousState.filteredData, key, order)
                }
            })

        }
    }
}