import React from "react";
import Select from "react-select";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/index";
import { connect } from "react-redux";
import { cityMetaActionCreator } from "../../../../../redux/action-creators/cityMetaActionCreator";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";

class CityToAgencyMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stayOpen: false,
            removeSelected: true,
            rtl: false,
            disabled: false,
            agencies: [],
            cities: [],
            depots: [],
            operators: [],
            city: '',
            agencyName: '',
            depotName: '',
            operator: '',
        };

        this.onCityChange = this.onCityChange.bind(this);
        this.onMasterChange = this.onMasterChange.bind(this);
        this.onAgencyChange = this.onAgencyChange.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchMasterData('city', null, 'cities');
        if (this.props.updateSelection) {
            let data = this.props.updateSelection
            this.setState({
                city: { label: data.cityMaster?data.cityMaster.name:null, value: data.cityMaster?data.cityMaster:null},
                agencyName: { label: data.agency?data.agency.name:null, value: data.agency?data.agency:null },
                operator: { label: data.operatorMaster?data.operatorMaster.name:null, value: data.operatorMaster?data.operatorMaster:null}
            }, () => {
                this.fireDataToParent();
                this.fetchMasterData('agency', { cityId: this.state.city.value?this.state.city.value.id:null }, 'agencies');
                this.fetchMasterData('operator', { cityId: this.state.city.value?this.state.city.value.id:null }, 'operators');
                this.fetchMasterData('depot', { cityId:this.state.city.value?this.state.city.value.id:null}, 'depots');
            })

            if (data.depot) {
                this.setState({
                    depotName: { label: this.props.updateSelection.depot.name, value: this.props.updateSelection.depot }
                }, () => this.fireDataToParent()
                )
            }
        }
    }


    /**
     * fires component's state to parent component
     */
    fireDataToParent() {
        this.props.onDataChange({
            city: this.state.city,
            depotName: this.state.depotName,
            agencyName: this.state.agencyName,
            operator: this.state.operator
        })

    }



    fetchMasterData(masterType, masterParentId, options) {
        fetchVehicleMaster(masterType, masterParentId, 0, 1000, null,'True').then((res => {
            if (this.mounted) {
                this.setState({
                    [options]: res.content.map(data => ({
                        label: data.name, value: data
                    }))
                });
            }

        })).catch(err => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else if (this.mounted) {
                this.setState({ citiesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching master data"
                });
            }
        }
        );
    }

    /**
     *on city change reset agencyOptions and depot
     *
     */
    onCityChange(value) {
        if (this.state.city != value) {
            this.setState({
                city: value,
                agencyName: '',
                agencies: [],
                depots: [],
                operators: [],
                depotName: '',
                operator: ''
            }, () => {
                this.fetchMasterData('agency', { cityId: this.state.city.value.id }, 'agencies');
                this.fetchMasterData('operator', { cityId: this.state.city.value.id }, 'operators');
                this.fetchMasterData('depot', { cityId: this.state.city.value.id }, 'depots');
                this.fireDataToParent();
            });

        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    /**
     *on agency change reset depot
     *
     */
    onAgencyChange(value) {
        if (this.state.agencyName != value) {

            this.setState({
                agencyName: value
            }, () => {
                // this.fetchMasterData('operator', { cityId: this.state.city.value.id, agencyId: this.state.agencyName.value.id }, 'operators');
                // this.fetchMasterData('depot', { cityId: this.state.city.value.id, agencyId: this.state.agencyName.value.id }, 'depots');
                this.fireDataToParent();
            });

        }
    }



    onMasterChange(type, value) {
        this.setState({
            [type]: value
        }, () => this.fireDataToParent());
    }


    render() {
        return <div>
            <div className="col-sm-12">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name7"
                            className="control-label col-sm-3 form-label"><span>City</span></label>
                        <div className="col-sm-9">
                            {!this.props.showInfo ?
                                <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    disabled={this.state.disabled}
                                    onChange={(value) => {
                                        this.onCityChange(value)
                                    }}
                                    options={this.state.cities}
                                    placeholder="Select City"
                                    clearable={false}
                                    rtl={this.state.rtl}
                                    value={this.state.city}
                                />
                                :
                                this.props.updateSelection.city
                            }
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name7"
                            className="control-label col-sm-3 form-label"><span>Agency</span></label>
                        <div className="col-sm-9">
                            {!this.props.showInfo ?
                                <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    disabled={!this.state.city}
                                    onChange={(value) => {
                                        this.onAgencyChange(value)
                                    }}
                                    options={this.state.agencies}
                                    placeholder="Select Agency"
                                    clearable={false}
                                    rtl={this.state.rtl}
                                    value={this.state.agencyName}
                                />
                                :
                                this.props.updateSelection.agencyName
                            }

                        </div>

                    </div>
                </div>
            </div>
            <div className="col-sm-12">

                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name7"
                            className="control-label col-sm-3 form-label"><span>Operator</span></label>
                        <div className="col-sm-9">
                            <Select
                                closeOnSelect={!this.state.stayOpen}
                                disabled={!this.state.city}
                                onChange={(value) => {
                                    this.onMasterChange('operator', value)
                                }}
                                options={this.state.operators}
                                placeholder="Select Operator"
                                clearable={false}
                                rtl={this.state.rtl}
                                value={this.state.operator}
                            />


                        </div>

                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="name7"
                            className="control-label col-sm-3 form-label"><span>Depot</span></label>
                        <div className="col-sm-9">
                            {!this.props.showInfo ?
                                <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    disabled={!this.state.city}
                                    onChange={(value) => {
                                        this.onMasterChange('depotName', value)
                                    }}
                                    options={this.state.depots}
                                    placeholder="Select Depot"
                                    rtl={this.state.rtl}
                                    clearable={false}
                                    value={this.state.depotName}
                                />
                                :
                                this.props.updateSelection.depotName
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    }
}


const mapDispatchToProps = (dispatch, state) => (
    {
        ...defaultMapDispatchToProps(dispatch, state),
        fetchCityMeta: value => {
            dispatch(cityMetaActionCreator(value))
        }
    }
);
export default connect(null, mapDispatchToProps)(CityToAgencyMapping);