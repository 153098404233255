import initialState from "./initialState";
import {actions} from "./action";
function PaginatorReducer(state = initialState, action) {
    let newState = state;
    let paginatedData;
    switch (action.type) {
        case actions.PAGINATOR.DATA_FETCHED:
            let totalPage = getTotalPagesCount(action.value, state.itemPerPage);
            paginatedData = getPaginatedData(action.value, state.itemPerPage);
            if (action.paginator) newState = {
                [action.paginator]: {
                    paginatedData: paginatedData,
                    totalPage: totalPage,
                    data: action.value,
                    content: paginatedData[0],
                    currentPage: 0
                },
            };
            else newState = {
                paginatedData: paginatedData,
                totalPage: totalPage,
                data: action.value,
                content: paginatedData[0],
                currentPage: 0
            };
            return Object.assign({}, state, newState);
        case actions.PAGINATOR.CHANGE_PAGE:
            if (action.paginator)
                newState = {
                    [action.paginator]: Object.assign({}, state[action.paginator], {
                        content: state[action.paginator].paginatedData[action.value],
                        currentPage: action.value
                    })

                };
            else newState = {
                content: state.paginatedData[action.value],
                currentPage: action.value
            };
            return Object.assign({}, state, newState);
        default:
            return state;
    }
}
/**
 * calculates total pages count on the basis of data and items per page
 * @param data
 * @param itemPerPage
 * @returns {number}
 */
function getTotalPagesCount(data, itemPerPage) {
    return data.length ? Math.ceil(data.length / itemPerPage) : 0;
}
/**
 * gets paginated data by dividing the entire data set
 * @param data
 * @param itemPerPage
 * @returns {{}}
 */
function getPaginatedData(data, itemPerPage) {
    let paginatedData = {};
    if (data.length) {
        var i = 0;
        paginatedData[0] = [];
        data.forEach((item, index)=> {
            if (paginatedData[i]) {
                paginatedData[i].push(item);
            }
            if ((index + 1) >= (i + 1) * itemPerPage) {
                i++;
                paginatedData[i] = [];
            }
        });
    }
    return paginatedData;
}

export default PaginatorReducer;