import React from "react";
import { PageWrapper } from "../../../../../shared/layout";
import {
  Search as SearchBar,
  DatePicker,
  FilterView,
  DropdownWrapper,
} from "../../../../../shared/widgets";
import * as moment from "moment";
import { ButtonToolbar, Modal } from "react-bootstrap";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { BsdAvailablityReportHeadersConfig } from "../../utils";
import { TableWrapper } from "../../../../../shared/layout";
import {
  fetchBsdAvailablityReportData,
  fetchProtocol,
  exportBsdAvailablityReport,
} from "../../services/reportService";
import { Paginator } from "../../../../../shared/widgets";
import { bsdAvailabilityReportStatus } from "../../../../../Constant";
import { CustomExport } from "../../views/customExport";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { connect } from "react-redux";


class BsdAvailablityReport extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    this.state = {
      showModal: false,
      backdrop: false,
      type: this.props.typeId,
      filteredData: [],
      pageNo: 0,
      pageSize: 0,
      currentPage: 0,
      totalPages: 0,
      numberOfItems: 0,
      totalItems: 0,
      exportOptions: [
        {
          key: "default",
          value: "YesterDay",
        },
        {
          key: "custom",
          value: "Select Range",
        },
      ],
      searchString: "",
      placeHolder: "Search",
      selectedProtocol: null,
      selectedStatus: null,
      protocolOptions: [],
      statusOptions: bsdAvailabilityReportStatus,
      startDate: "",
      endDate: "",
      selectedDate: m.subtract(1, "day"),
    };
    this.timeout = null;
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.getList = this.getList.bind(this);
  }
  exportBsdAvailablityReportData() {
    this.props.updateLoader(true);
    exportBsdAvailablityReport(
      this.state.startDate,
      this.state.endDate,
      this.state.pageNo,
      this.state.selectedProtocol,
      this.state.selectedStatus,
      this.state.searchString
    )
      .then((res) => {
        console.log(res);
        this.props.updateLoader(false);

      })
      .catch((err) => {
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
        }
        this.props.updateLoader(false);
      });
  }

  handleSearchChange(key) {
    clearInterval(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          searchString: key,
        },
        () => {
          this.fetchBsdAvailablityReport(
            this.state.startDate,
            this.state.endDate,
            0,
            this.state.selectedProtocol,
            this.state.selectedStatus,
            this.state.searchString
          );
        }
      );
    }, 500);
  }

  onFilterChange(value, type) {
    switch (type) {
      case "status":
        this.setState(
          {
            selectedStatus: value,
            pageNo: 0
          },
          () => {
            this.fetchBsdAvailablityReport(
              this.state.startDate,
              this.state.endDate,
              this.state.pageNo,
              this.state.selectedProtocol,
              this.state.selectedStatus,
              this.state.searchString
            );
          }
        );
        break;
      case "protocol":
        this.setState(
          {
            selectedProtocol: value,
            pageNo: 0
          },
          () => {
            this.fetchBsdAvailablityReport(
              this.state.startDate,
              this.state.endDate,
              this.state.pageNo,
              this.state.selectedProtocol,
              this.state.selectedStatus,
              this.state.searchString
            );
          }
        );
        break;
    }
  }
  dateChange(date) {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
      },
      () => {
        this.fetchBsdAvailablityReport(
          startDate,
          endDate,
          0,
          this.state.selectedProtocol,
          this.state.selectedStatus,
          this.state.searchString
        );
      }
    );
  }

  componentDidMount() {
    this.fetchProtocolOptions();
  }

  fetchProtocolOptions() {
    fetchProtocol()
      .then((data) => {
        data = data.map((d) => ({ name: d })).filter((d)=>d.name != 'UNKNOWN');
        this.setState(
          {
            protocolOptions: data,
          },
          () => {
            this.dateChange(this.state.selectedDate);
          }
        );
      })
      .catch((err) => {
        this.setState({
            filteredData: [],
            pageNo: 0,
            pageSize: 0,
            currentPage: 0,
            totalPages: 0,
            numberOfItems: 0,
            totalItems: 0,
            searchString:""
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }

  fetchBsdAvailablityReport(
    startTime,
    endTime,
    pageNo,
    selectedProtocol,
    selectedStatus,
    searchKey
  ) {
    this.props.updateLoader(true);
    fetchBsdAvailablityReportData(
      startTime,
      endTime,
      pageNo,
      selectedProtocol,
      selectedStatus,
      searchKey
    )
      .then((data) => {
        this.setState({
          filteredData: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
            filteredData: [],
            pageNo: 0,
            pageSize: 0,
            currentPage: 0,
            totalPages: 0,
            numberOfItems: 0,
            totalItems: 0,
            searchString:""
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState(
        {
          pageNo,
        },
        () => {
          this.fetchBsdAvailablityReport(
            this.state.startDate,
            this.state.endDate,
            pageNo,
            this.state.selectedProtocol,
            this.state.selectedStatus,
            this.state.searchString
          );
        }
      );
    }
  }
  open(data) {
    this.setState({
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal() {
    return (
      <Modal
        dialogClassName="custom-range-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Select Range" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CustomExport
              type={this.props.typeId}
              bsdAvailabilityState={this.state}
              onComplete={(e, msg) => {
                // this.props.initiateNotification({
                //     type: e,
                //     message: msg
                // });
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    return (
      <div>
        <div className="report-section">
          <PageWrapper.Header heading="Bsd Availablity Report" hideS={true}>
          {this.state.filteredData && (
            <span className="pl-3"> Device Count : {this.state.totalItems}</span>
          )}
            <div className="pull-right">
              <DropdownWrapper
                title="Export"
                menue={this.state.exportOptions}
                onSelect={(value, subValue) => {
                  switch (value) {
                    case "default": {
                      this.exportBsdAvailablityReportData();
                      break;
                    }
                    case "custom": {
                      this.open(this, null);
                      break;
                    }
                  }
                }}
              ></DropdownWrapper>
              {this.displayModal()}
            </div>
            <div className="pull-right search-container custom-search">
              <SearchBar
                placeHolder={this.state.placeHolder}
                onSearchChange={this.handleSearchChange}
                value={this.state.searchString}
              />
            </div>
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
                <DatePicker
                  date={this.state.selectedDate}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </ButtonToolbar>
            </div>
          </PageWrapper.Header>
          <PageWrapper.Body>
            <PageWrapper.BodyHeader>
              <div className="header-button-container ">
                <div className="form-group">
                  <div
                    className="dataTables_length wrapper-gps"
                    id="dataTables-example_length"
                  >
                    <ButtonToolbar>
                      <SelectFilter
                        value={this.state.selectedProtocol}
                        onChange={(value) => {
                          console.log("value", value);
                          if (value) {
                            this.onFilterChange(value.name, "protocol");
                          } else {
                            this.onFilterChange(null, "protocol");
                          }
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="Protocol"
                        options={this.state.protocolOptions}
                      />
                      <SelectFilter
                        value={this.state.selectedStatus}
                        onChange={(value) => {
                          console.log("value", value);
                          if (value) {
                            this.onFilterChange(value.name, "status");
                          } else {
                            this.onFilterChange(null, "status");
                          }
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="Status"
                        options={this.state.statusOptions}
                      />
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </PageWrapper.BodyHeader>
            <TableWrapper>
              <TableWrapper.Header
                columns={BsdAvailablityReportHeadersConfig}
                timeInterval={this.props.timeInterval}
                timeParse={this.props.timeParse}
                onSortToggle={this.handleDataSorting}
                type={"health"}
              />
              <tbody>
                {this.state.filteredData &&
                  this.state.filteredData.map((row) => {
                    return (
                      <tr key={row.id} className="cursor-pointer">
                        <td title={row.deviceId ? row.deviceId : "NA"}>
                          {row.deviceId ? row.deviceId : "NA"}
                        </td>
                        <td title={row.protocol ? row.protocol : "NA"}>
                          {row.protocol ? row.protocol : "NA"}
                        </td>
                        <td title={row.status ? row.status : "NA"}>
                          {row.status ? row.status : "NA"}
                        </td>
                      </tr>
                    );
                  })}

                {(!this.state.filteredData ||
                  (this.state.filteredData &&
                    this.state.filteredData.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </TableWrapper>
            {this.state.filteredData && (
              <Paginator
                pageNo={this.state.pageNo}
                totalPages={this.state.totalPages}
                getList={this.getList}
              />
            )}
            <br />
          </PageWrapper.Body>
        </div>
      </div>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(BsdAvailablityReport);
