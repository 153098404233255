import React, {Component} from "react";
import NotificationSystem from "react-notification-system";
import './notificationWrapper.css';

export class NotificationComponent extends Component {

    componentDidMount() {
        /**
         * gets reference of notification system into "this" context.
         */
        this._notificationSystem = this.refs.notificationSystem;
    }

    render() {
        return <div className="notificationStyle">
            <NotificationSystem ref="notificationSystem"/>
        </div>
    }

    /**
     * used to show notifications
     * @param type
     * @param msg
     * @private
     */
    _addNotification(type, msg) {
        this._notificationSystem.addNotification({
            message: msg,
            level: type,
            success: {
                color: 'green',
            },
            error: {
                color: 'rgb(228, 190, 190)'
            }
        });

    }

}