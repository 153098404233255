import {getAPIPromise} from "./APIutils";
import {BASE_URL, BASE_GPS_MATRIX_STAG_URL} from "../Constant";
import {GET_REGIONS} from "../Constant";

export function fetchDeviceMapping(regionId) {
    let url = `${BASE_URL}api/device/${regionId}/mapping`;
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function fetchRegions(pageNo, pageSize=10) {
    let url = `${BASE_URL}${GET_REGIONS}`;
    if (pageNo || pageNo == 0) {
        url = `${url}&pageNo=${pageNo}&pageSize=${pageSize}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function timeZoneRegions(){
    let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REGIONS}/regionWithTimeZone`;
    return getAPIPromise(url, {
        method: 'get'
    });
}