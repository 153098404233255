import { actions } from "../../actions/action-list";

function Timezones(state = {}, action) {
  let newState;
  if (action.type === actions.TIMEZONE.DATA_FETCHED) {
    newState = Object.assign({}, state, {
      timezones: action.value,
    });
    return newState;
  } else {
    return state;
  }
}
export default Timezones;
