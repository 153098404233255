import {MessageType} from "../../Constant";
import {setTokenExpired} from "../actions/authActions";
import {setNotificationMessage} from "../actions/notificationActions";
import {fetchRegions} from "../../services/regionService";
import {regionsFetched} from "../actions/regionActions";
export function createRegionListAction() {
    return (dispatch)=> {
        fetchRegions().then((response) => {
            dispatch(regionsFetched(response.items));
        }).catch((err) => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                dispatch(setTokenExpired(true))
            } else {
                if (err.status != 204) dispatch(setNotificationMessage({
                    type: MessageType.ERROR,
                    message: err.description ? err.description : "Error while fetching regions"
                }));
            }
            console.log(err);
        });
    }
}