import React from "react";
import { Button } from "react-bootstrap";
import validateCron from "../../validator/cronValidator";
import {
  SingleDateTimePicker,
  SelectFilter,
} from "../../../../../shared/widgets";
import { MessageType } from "../../../../../Constant";
import {
  createCronTrigger,
  getTimezones,
  timeZoneRegions,
  getEventTrigger,
} from "../../../../../services/cronTriggerService";
import Select from "react-select";
import { EpochTimezone } from "../../../../../shared/utils";

export class CronCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      date: new Date(),
      selectedTimeZone: "",
      selectedRegion: "",
      selectedEvent: "",
      timezoneOptions: [],
      regionOptions: [],
      eventTriggerOptions: [],
      selectAllRegions: false,
    };
    this.toDateChange = this.toDateChange.bind(this);
    this.fromDateChange = this.fromDateChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateFormErrorState();
  }

  componentWillMount() {
    this.fetchTimeZoneData();
    this.fetchTriggerEvent();
  }

  fetchTimeZoneData() {
    getTimezones()
      .then((response) => {
        let all_timezones = [];
        response.forEach((item) => {
          if (
            !all_timezones.some((timezone) => timezone.name === item.timeZone)
          ) {
            all_timezones.push({ name: item.timeZone });
          }
        });
        this.setState({ timezoneOptions: all_timezones });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred in timezone API"
          );
        }
      });
  }

  fetchRegionFromTimezone(timezone) {
    timeZoneRegions(timezone)
      .then((response) => {
        let all_regions = [];
        response.forEach((item) => {
          all_regions.push({ name: item.regionId });
        });
        this.setState({ regionOptions: all_regions });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred in region API"
          );
        }
      });
  }

  fetchTriggerEvent() {
    getEventTrigger()
      .then((response) => {
        let all_event = [];
        response.forEach((item) => {
          all_event.push({ name: item });
        });
        this.setState({ eventTriggerOptions: all_event });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred in timezone API"
          );
        }
      });
  }

  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Timezone</span>
              </label>
              <div className="col-sm-10">
                <SelectFilter
                  type="timezone"
                  value={this.state.selectedTimeZone}
                  onChange={(value) => {
                    this.handleChange("timezone", value);
                  }}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Timezone"
                  options={this.state.timezoneOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Region</span>
              </label>
              <div className="col-sm-10">
                <Select
                  type="region"
                  value={this.state.selectedRegion}
                  onChange={(value) => {
                    this.handleChange("region", value);
                  }}
                  disabled={!this.state.selectedTimeZone}
                  multi
                  valueKey="name"
                  labelKey="name"
                  placeholder="Region"
                  options={this.state.regionOptions}
                />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    disabled={!this.state.selectedTimeZone}
                    type="checkbox"
                    checked={this.state.selectAllRegions}
                    onChange={(value) => {
                      this.handleSelectAllChange(value);
                    }}
                  />
                  <span>Select All</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Event</span>
              </label>
              <div className="col-sm-10">
                <SelectFilter
                  type="event"
                  value={this.state.selectedEvent}
                  onChange={(value) => {
                    this.handleChange("event", value);
                  }}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Event"
                  options={this.state.eventTriggerOptions}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-sm-12">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span>Start Time</span>
                </label>
                <div className="col-sm-10 ">
                  <SingleDateTimePicker
                    id="startTime"
                    date={this.state.date}
                    onDateChange={this.fromDateChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span>End Time</span>
                </label>
                <div className="col-sm-10 ">
                  <SingleDateTimePicker
                    id="endTime"
                    date={this.state.date}
                    onDateChange={this.toDateChange}
                    minDate={this.state.startDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.setState({ loading: true });
                this.fetchAddUpdate();
              }}
              disabled={this.state.form.invalid || this.state.loading}
            >
              {this.state.loading ? "Saving..." : "Save changes"}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  /**
   * validates cron form data
   * @param target
   */
  updateFormErrorState() {
    let formState = validateCron(this.state);
    this.setState({
      form: formState,
    });
  }

  handleChange(key, value) {
    if (value) {
      switch (key) {
        case "timezone": {
          this.setState(
            {
              selectedTimeZone: value,
              selectedRegion: "",
              selectAllRegions: false,
            },
            () => {
              this.updateFormErrorState();
              this.fetchRegionFromTimezone(value.name);
            }
          );

          break;
        }
        case "region": {
          this.setState(
            {
              selectedRegion: value,
            },
            () => {
              this.updateFormErrorState();
              if (!this.state.selectedRegion.length) {
                this.setState({
                  selectAllRegions: false,
                });
              }
            }
          );
          break;
        }
        case "event": {
          this.setState(
            {
              selectedEvent: value,
            },
            () => {
              this.updateFormErrorState();
            }
          );
          break;
        }
      }
    }
  }

  handleSelectAllChange(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.handleChange("region", this.state.regionOptions);
    } else {
      this.handleChange("region", []);
    }
    this.setState(
      {
        selectAllRegions: isChecked,
      },
      () => {
        this.updateFormErrorState();
      }
    );
  }

  fromDateChange(date) {
    this.setState(
      {
        startTime: this.convertToEpoch(date),
        startDate: date,
      },
      () => {
        this.updateFormErrorState();
      }
    );
  }

  toDateChange(date) {
    this.setState({ endTime: this.convertToEpoch(date) }, () => {
      this.updateFormErrorState();
    });
  }

  convertToEpoch(date) {
    const epochTime = new Date(date).getTime();
    return EpochTimezone(epochTime, "Asia/Kolkata");
  }

  /**
   * Used to Add trip
   */
  fetchAddUpdate() {
    createCronTrigger(this.state)
      .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred "
          );
        }
      });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
}
