import React from "react";
import { Bar } from "react-chartjs-2";

export class FrequencyGraphViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
    this.datasetKeyProvider = this.datasetKeyProvider.bind(this);
  }
  
  formatDate(date) {
    var months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "agu",
      "sep",
      "oct",
      "nov",
      "dec",
    ];
    var day = date.getDate();
    var year = date.getFullYear();
    var month = months[date.getMonth()];
    return day + "-" + month + "-" + year;
  }

  datasetKeyProvider() {
    return btoa(Math.random()).substring(0, 12);
  }

  render() {
    const daywisedata = [];
    const timestamp = [];
    for (let i = 0; i < this.props.data.length; i++) {
      daywisedata[i] = [];
      for (let j = 0; j < this.props.data[i].length; j++) {
        if (j == 0) {
          let date = this.formatDate(new Date(this.props.data[i][j]));
          timestamp.push(date);
        } else {
          daywisedata[i].push(this.props.data[i][j]);
        }
      }
    }
    const data = {
      labels: ["0-5", "5-10", "10-20", "20-30", "30-60", "60-120", "120+"],
      datasets: [
        {
          label: timestamp[0],
          backgroundColor: "pink",
          borderColor: "pink",
          borderWidth: 1,
          data: daywisedata[0],
        },

        {
          label: timestamp[1],
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 1,
          data: daywisedata[1],
        },
        {
          label: timestamp[2],
          backgroundColor: "green",
          borderColor: "green",
          borderWidth: 1,
          data: daywisedata[2],
        },
        {
          label: timestamp[3],
          backgroundColor: "yellow",
          borderColor: "yellow",
          borderWidth: 1,
          data: daywisedata[3],
        },
        {
          label: timestamp[4],
          backgroundColor: "red",
          borderColor: "red",
          borderWidth: 1,
          data: daywisedata[4],
        },
        {
          label: timestamp[5],
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 1,
          data: daywisedata[5],
        },
        {
          label: timestamp[6],
          backgroundColor: "purple",
          borderColor: "purple",
          borderWidth: 1,
          data: daywisedata[6],
        },
      ],
    };
    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "SECONDS",
              fontStyle: "bold",
            },
          },
        ],
      },
    };
    return (
      <Bar
        data={data}
        width={null}
        height={70}
        options={options}
        datasetKeyProvider={this.datasetKeyProvider}
      />
    );
  }
}
