import React, { Component } from "react";
import PropTypes from "prop-types";
import { BASE_URL, MessageType } from "../../../Constant";
import FileSelectModal  from "../../widgets/fileSelectModal/fileSelectModal.js";
import Button from "../../widgets/button";
import { fetchWrapper, getValidToken } from "../../../services/APIutils";
import "./ImportButton.css";

class ImportButton extends Component {

    constructor(props) {
        super(props);
        let email = localStorage.getItem('userName');      
        this.state = {
            email: email,
            showSelectModal: false,
            uploading: false,
            regionId:props.regionId
        };
    }


    render() {
        if (this.props.regionId) {
            return (
                <>
                <Button onClick={() => {
                    this.openSelectModal()
                }} className="import-icon" buttonText={'Import'}>
                </Button>
                <FileSelectModal title={`Import ${this.props.importKey}s`}
                        supportedTypes={this.props.supportedTypes}
                        showSelectModal={this.state.showSelectModal}
                        onUpload={(data,selectedRegion) => {
                            this.setState({regionId:selectedRegion},()=>{
                                this.Upload(data)
                            })
                        }}
                        onHide={() => {
                            console.log("re after hide",this.props.regionId)
                            this.setState({ showSelectModal: false,
                                selectedRegion:this.props.regionId });                        }}
                        disableButton={this.state.uploading}
                        buttonText={this.state.uploading ? 'Uploading...' : 'Upload'}
                        selectedRegion={this.state.regionId}
                />
                </>
            )
        }
        else {
            return (
                <>
                <Button onClick={() => {
                    this.openSelectModal()
                }} className="import-icon" buttonText={'Import'}>
                </Button>
                <FileSelectModal title={`Import ${this.props.importKey}s`}
                        supportedTypes={this.props.supportedTypes}
                        showSelectModal={this.state.showSelectModal}
                        onUpload={(data,selectedRegion) => {
                            this.setState({regionId:selectedRegion},()=>{
                                this.Upload(data)
                            })
                        }}
                        onHide={() => {
                            this.setState({ showSelectModal: false,});                        
                        }}
                        hideRegion={this.props.importKey}
                        disableButton={this.state.uploading}
                        buttonText={this.state.uploading ? 'Uploading...' : 'Upload'}
                />
                </>
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("next",nextProps)
        if(this.props.regionId!=nextProps.regionId && nextProps.regionId){
            this.setState({
                regionId:nextProps.regionId
            })
        }
    }
    openSelectModal() {
        this.setState({
            showSelectModal: true
        })
    }



uploadVehicles(data) {
    let url;
    var timeZone=localStorage.getItem('timeZone');    
    url=`${BASE_URL}api/${this.props.importKey == 'device' ? 'region' : 'master/vehicle/v2'}/${this.state.regionId}/upload?email=${this.state.email}&timeZone=${timeZone}`
    return FileDownLoader(url, {
        method: 'post',
        body: data
    }).then((response) => {
        console.log("res", response)
        this.setState({ showSelectModal: false, uploading: false });
        this.props.onMessage(MessageType.SUCCESS, (response.description ? response.description : "Successfully done"));
    }).catch((err) => {
        this.setState({ showSelectModal: false, uploading: false });

        if(err.status===401 || err.message==='Invalid token'){
            this.props.onMessage(MessageType.ERROR, ("Invalid token"))
        }else{
        this.props.onMessage(MessageType.ERROR, (err.description ? err.description : "Error occurred "))
        }
    })
}

    uploadCamera(data) {
        let url = `${BASE_URL}api/master/camera/upload?email=${this.state.email}`
        return FileDownLoader(url, {
            method: 'post',
            body: data
        }).then((response) => {
            this.setState({ showSelectModal: false, uploading: false });
            this.props.onMessage(MessageType.SUCCESS, (response.description ? response.description : "Successfully done"));
        }).catch((err) => {
            this.setState({ showSelectModal: false, uploading: false }); if(err.status===401 || err.message==='Invalid token'){ this.props.onMessage(MessageType.ERROR, ("Invalid token")) }else{ this.props.onMessage(MessageType.ERROR, (err.description ? err.description : "Error occurred ")) }
        })
    }

uploadBSDData(data,key){
    let url;
    url=`${BASE_URL}bsd/${key == 'BSD'?'bsd-device':'stop-details'}/upload?email=${this.state.email}`
    if(key!=='BSD'){
        url = `${url}&city=${this.props.selectedCity}`;
    }
    return FileDownLoader(url, {
        method: 'post',
        body: data
    }).then((response) => {
        this.setState({ showSelectModal: false, uploading: false });
        this.props.onMessage(MessageType.SUCCESS, (response.description ? response.description : "Successfully done"));
    }).catch((err) => {
        this.setState({ showSelectModal: false, uploading: false });

        if(err.status===401 || err.message==='Invalid token'){
            this.props.onMessage(MessageType.ERROR, ("Invalid token"))
        }else{
        this.props.onMessage(MessageType.ERROR, (err.description ? err.description : "Error occurred "))
        }
    })
}


    Upload(data) {
        var myHeaders = new Headers();
        // myHeaders.append('Content-Type', 'multipart/form-data');
        this.setState({ uploading: true });
        if(this.props.importKey === 'camera'){
            this.uploadCamera(data);
        }else if (this.props.importKey === 'vehicle') {
            this.uploadVehicles(data)
        }
        else if(this.props.importKey == 'BSD' || this.props.importKey == 'Stop'){
            this.uploadBSDData(data,this.props.importKey)
        }
        else {
            fetchWrapper(`${BASE_URL}api/${this.props.importKey == 'device' ? 'region' : 'master/vehicle'}/${this.state.regionId}/upload`, {
                method: 'post',
                body: data
            }).then((response) => {
                this.setState({ showSelectModal: false, uploading: false });
                this.props.onMessage(MessageType.SUCCESS, response.message);
            }).catch((err) => {
                this.setState({ showSelectModal: false, uploading: false });
                this.props.onMessage(MessageType.ERROR, err.description || `Error while uploading files.`)
            })
        }
    }
}


function fetchFileBlob(url, options) {
    return fetch(url, options)
        .then(handleBlobResponse, handleNetworkError);
}
function handleBlobResponse(response) {
    if (response.ok) {
        if (response.status == 206) {
             /*Get disposition and content type from response header*/
             var disposition = response.headers.get('Content-Disposition');
             var type = response.headers.get('Content-Type');
 
             /*extract filename from disposition data*/
             var matches = disposition.split('=');
             var filename = (matches != null && matches[1] ? matches[1] : null);
 
             /*returns blob data with filename and type details*/
             return response.blob().then((blobData) => {
                 return ({ blob: blobData, filename: filename, type: type })
             }).catch((err) => {
                 return (err)
             });
            
        }
        else {
            return {
                status: 200, description: "Successfully imported"
            }

        }
    } else {
        return response.json().then(function (error) {
            error.status = response.status;
            throw error;
        });
    }
}

function handleNetworkError(error) {
    throw {
        msg: error.message
    };
}

function downloadFile(url, filename) {
    var link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function FileDownLoader(url, options, filename){
    return getValidToken().then((token) => {
        options.headers ? (options.headers.Authorization = 'Bearer ' + token) : (
            options.headers = {
                Authorization: 'Bearer ' + token
            });
    return new Promise((resolve, reject) => {
        fetchFileBlob(url, options).then((response) => {
            if (response.status == 200) {
                return response
            }
            /*creates blob for stream Data and type returned by server*/
            var blob = new Blob([response.blob], { type: response.type });
            response.url = window.URL.createObjectURL(blob);

            return response;

        }).then((response) => {
            if (response.status == 200) {
                resolve(response)
            }
            /**choose file name*/
            var typeMatch = response.type.split("/");
            var filename = response.filename || filename || `file.${typeMatch}`;

            /*download file*/
            downloadFile(response.url, filename);

            resolve({description:'Partial upload, inaccurate data'});
        }).catch(err => {
            reject(err)
        })
    })
    });
};

ImportButton.defaultProps = {
    supportedTypes: [],
    onMessage: () => {
    }
}
    ;
ImportButton.propTypes = {
    importKey: PropTypes.string.isRequired,
    regionId: PropTypes.string,
    onMessage: PropTypes.func,
    supportedTypes: PropTypes.array
};
export default ImportButton;