import React from "react";
import * as moment from "moment";
import { PageWrapper } from "../../../../../shared/layout";
import { connect } from "react-redux";
import { ButtonToolbar, Modal } from "react-bootstrap";

import { Search as SearchBar, DatePicker, DropdownWrapper, } from "../../../../../shared/widgets";
import { EtaAckMatrixHeadersConfig } from "../../utils";
import { TableWrapper } from "../../../../../shared/layout";
import { fetchEtaAckMatrixReportData, exportEtaAckMatrixReport } from "../../services/reportService";
import { Paginator } from "../../../../../shared/widgets";
import { CustomExport } from "../../views/customExport";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { RegionSelector } from '../../../../../shared/components'
import { routes } from "../../../../../routing/routes";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService"
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter"


class EtaAckMatrix extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    this.state = {
      showModal: false,
      backdrop: false,
      type: this.props.typeId,
      filteredData: [],
      pageNo: 0,
      pageSize: 0,
      currentPage: 0,
      totalPages: 0,
      numberOfItems: 0,
      totalItems: 0,
      exportOptions: [
        {
          key: "default",
          value: "YesterDay",
        },
        {
          key: "custom",
          value: "Select Range",
        },
      ],
      searchString: "",
      placeHolder: "Search",
      selectedCity: null,
      cities:[],
      startDate: "",
      endDate: "",
      selectedDate: m.subtract(1, "day"),
      sortKey:null,
      sortOrder:null
    };
    this.timeout = null;
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.getList = this.getList.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleDataSorting = this.handleDataSorting.bind(this)
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
          this.setState({ cities: res.content });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else  {
          this.setState({ cities: [] });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching cities",
          });
        }
      });
  }

  exportEtaAckMatrixReportData() {
    this.props.updateLoader(true);
    exportEtaAckMatrixReport(
        this.state.startDate,
        this.state.endDate,
        this.state.pageNo,
        this.state.selectedCity,
        this.state.searchString,
        this.state.sortKey,
        this.state.sortOrder
    )
      .then((res) => {
        console.log(res);
        this.props.updateLoader(false);

      })
      .catch((err) => {
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
        }
        this.props.updateLoader(false);
      });
  }

  handleSearchChange(key) {
    clearInterval(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          searchString: key,
        },
        () => {
          this.fetchEtaAckMatrixReport(
            this.state.startDate,
            this.state.endDate,
            this.state.pageNo,
            this.state.selectedCity,
            this.state.searchString,
            this.state.sortKey,
            this.state.sortOrder
          );
        }
      );
    }, 500);
  }

 
  dateChange(date) {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
      },
      () => {
        this.fetchEtaAckMatrixReport(
            this.state.startDate,
            this.state.endDate,
            0,
            this.state.selectedCity,
            this.state.searchString,
            this.state.sortKey,
            this.state.sortOrder
        );
      }
    );
  }

  componentDidMount() {
    this.fetchMasterData("city", null);
    this.dateChange(this.state.selectedDate);
  }


  fetchEtaAckMatrixReport(fromDate,endDate,pageNo,city,searchKey,sortKey,sortOrder) {
    this.props.updateLoader(true);
    fetchEtaAckMatrixReportData(fromDate,endDate,pageNo,city,searchKey,sortKey,sortOrder)
      .then((data) => {
        this.setState({
          filteredData: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
            filteredData: [],
            pageNo: 0,
            pageSize: 0,
            currentPage: 0,
            totalPages: 0,
            numberOfItems: 0,
            totalItems: 0,
            searchString:""
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState(
        {
          pageNo,
        },
        () => {
          this.fetchEtaAckMatrixReport(
            this.state.startDate,
            this.state.endDate,
            this.state.pageNo,
            this.state.selectedCity,
            this.state.searchString,
            this.state.sortKey,
            this.state.sortOrder
          );
        }
      );
    }
  }
  open(data) {
    this.setState({
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal() {
    return (
      <Modal
        dialogClassName="custom-range-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Select Range" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CustomExport
              type={this.props.typeId}
              etaAckMatrixState={this.state}
              onComplete={(e, msg) => {
                // this.props.initiateNotification({
                //     type: e,
                //     message: msg
                // });
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
    });
  }

  handleDataSorting(order, key) {
    this.setState(
        {
          sortOrder:order,
          sortKey:key,
        },
        () => {
          this.fetchEtaAckMatrixReport(
            this.state.startDate,
            this.state.endDate,
            this.state.pageNo,
            this.state.selectedCity,
            this.state.searchString,
            this.state.sortKey,
            this.state.sortOrder
          );
        }
      );
  }

  handleRegionChange(region) {
    this.setState({ selectedCity: region,pageNo:0 }, () => {
      this.fetchEtaAckMatrixReport(
        this.state.startDate,
        this.state.endDate,
        this.state.pageNo,
        this.state.selectedCity,
        this.state.searchString,
        this.state.sortKey,
        this.state.sortOrder
      );
    });
  }
  onFilterChange(value) {
    this.setState(
      {
        selectedCity: value && value.name ? value.name : "",
        pageNo: 0
      },
      () => {
        this.fetchEtaAckMatrixReport(
          this.state.startDate,
          this.state.endDate,
          this.state.pageNo,
          this.state.selectedCity,
          this.state.searchString,
          this.state.sortKey,
          this.state.sortOrder
        );
      });
  }

  render() {
    return (
      <div>
        <div className="report-section">
          <PageWrapper.Header heading="ETA Acknowledegment Report" hideS={true}>
          {this.state.filteredData && (
            <span className="pl-3"> Device Count : {this.state.totalItems}</span>
          )}
            <div className="pull-right">
              <DropdownWrapper
                title="Export"
                menue={this.state.exportOptions}
                onSelect={(value, subValue) => {
                  switch (value) {
                    case "default": {
                      this.exportEtaAckMatrixReportData();
                      break;
                    }
                    case "custom": {
                      this.open(this, null);
                      break;
                    }
                  }
                }}
              ></DropdownWrapper>
              {this.displayModal()}
            </div>
            <div className="pull-right search-container custom-search">
              <SearchBar
                placeHolder={this.state.placeHolder}
                onSearchChange={this.handleSearchChange}
                value={this.state.searchString}
              />
            </div>
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
                <DatePicker
                  date={this.state.selectedDate}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </ButtonToolbar>
            </div>
          </PageWrapper.Header>
          <PageWrapper.Body>
            <PageWrapper.BodyHeader>
              <div className="header-button-container ">
                <div className="form-group">
                  <div
                    className="dataTables_length wrapper-gps"
                    id="dataTables-example_length"
                  >
                    <ButtonToolbar>
                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        this.onFilterChange(value);
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />
                      {/* <RegionSelector
                        filterKey={routes.REPORT}
                        onRegionChange={this.handleRegionChange}
                      /> */}
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </PageWrapper.BodyHeader>
            <TableWrapper>
              <TableWrapper.Header
                columns={EtaAckMatrixHeadersConfig}
                timeInterval={this.props.timeInterval}
                timeParse={this.props.timeParse}
                onSortToggle={this.handleDataSorting}
                type={"health"}
              />
              <tbody>
                {this.state.filteredData &&
                  this.state.filteredData.map((row) => {
                    return (
                      <tr key={row.id} className="cursor-pointer">
                        <td title={row.deviceId ? row.deviceId : "NA"}>
                          {row.deviceId ? row.deviceId : "NA"}
                        </td>
                        <td title={row.totalNumberOfMsg ? row.totalNumberOfMsg : "NA"}>
                          {row.totalNumberOfMsg ? row.totalNumberOfMsg : "NA"}
                        </td>
                        <td title={row.totalNumberOfAck ? row.totalNumberOfAck : "NA"}>
                          {row.totalNumberOfAck ? row.totalNumberOfAck : "NA"}
                        </td>
                        <td title={row.averageTimeDifference ? row.averageTimeDifference : "NA"}>
                          {row.averageTimeDifference ? row.averageTimeDifference : "NA"}
                        </td>
                        <td title={row.city ? row.city : "NA"}>
                          {row.city ? row.city : "NA"}
                        </td>
                      </tr>
                    );
                  })}

                {(!this.state.filteredData ||
                  (this.state.filteredData &&
                    this.state.filteredData.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </TableWrapper>
            {this.state.filteredData && (
              <Paginator
                pageNo={this.state.pageNo}
                totalPages={this.state.totalPages}
                getList={this.getList}
              />
            )}
            <br />
          </PageWrapper.Body>
        </div>
      </div>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(EtaAckMatrix);
