import { tokenParser } from "./tokenParser";
import CryptoJs from 'crypto-js'
import config from "../../config";
import Cookies from 'universal-cookie';

export const setCookiesValue = (userId) => {
    const cookies = new Cookies();
    var current_date = new Date();
    var expiration_date = new Date();
    expiration_date.setDate(current_date.getDate()+7);
    var encryptedUserCookie=  encryptString(userId+'')
    cookies.set(config.cookieKeys.user,encryptedUserCookie,{domain:'.chalo.com',expires:expiration_date});
    var encryptedLoginCookie= encryptString('loggedIn')
    cookies.set(config.cookieKeys.loginState,encryptedLoginCookie,{domain:'.chalo.com',expires:expiration_date});
}


export function checkUserLoginState() {
    return localStorage.getItem('token');
}
export function getUserAuthorities() {
    return localStorage.getItem('authorities');
}

export function userAuthoritiesArray() {
    let authorities= JSON.parse(getUserAuthorities())
    return Object.keys(authorities) ;
}
/**
 * accepts access token and decode the values and updates the user data in local storage
 * @param token
 * @returns {Promise}
 */
export function updateUserLoginState(token,refresh_token) {

    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem('token', token);
            localStorage.setItem('refresh_token', refresh_token);

            if (token) {
                var decodedValue = parseJwt(token);
                console.log(decodedValue);
                setCookiesValue(decodedValue.userId)
                localStorage.setItem('userId', decodedValue.userId);
                localStorage.setItem('userName', decodedValue.user_name);
                let authorities = tokenParser(decodedValue.authorities);
                localStorage.setItem('authorities', JSON.stringify(authorities));
                resolve(authorities);
            }
            else {
                localStorage.setItem('userName', token);
                localStorage.setItem('authorities', token);
                resolve(true);
            }


        } catch (err) {
            reject(err);
        }
    })
}

/**
 * pases JWT token returns JSON object
 * @param token
 */
function parseJwt(token) {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    catch (err) {
        throw err;
    }
}

export function encryptString(stringToEncrypt) {
    try {
        var key = CryptoJs.enc.Utf8.parse('web_chalo_appkey');
        // var iv=CryptoJs.enc.Utf8.parse('7061737323313233');
        var encryptedString = CryptoJs.AES.encrypt(stringToEncrypt, key, {
            mode: CryptoJs.mode.ECB,
            padding: CryptoJs.pad.ZeroPadding
        });
        return encryptedString.toString()
    }
    catch (err) {
        throw err;
    }
}

export function decryptString(encryptedString) {
    try {
        var key = CryptoJs.enc.Utf8.parse('web_chalo_appkey');

        var decrypted = CryptoJs.AES.decrypt(encryptedString, key, {
            mode: CryptoJs.mode.ECB,
            padding: CryptoJs.pad.ZeroPadding
        });
        console.log('decrypted: ' + decrypted.toString(CryptoJs.enc.Utf8));
        return decrypted.toString(CryptoJs.enc.Utf8);
    }
    catch (err) {
        throw err;
    }
}

