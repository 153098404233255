import LoadingBarContainer, {ChaloLoadingBar} from './loading_bar'
import loadingBarMiddleware from './loading_bar_middleware'
import {
    hideLoading,
    loadingBarReducer,
    resetLoading,
    showLoading,
} from './loading_bar_ducks'
import ImmutableLoadingBar from './immutable'

export {
    hideLoading,
    ImmutableLoadingBar, ChaloLoadingBar,
    loadingBarMiddleware,
    loadingBarReducer,
    resetLoading,
    showLoading,
}
export default LoadingBarContainer
