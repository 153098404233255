import React from 'react';
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({ component: Component, role,pathname, ...rest }) => (
    
    <Route {...rest} render={(props) => (
        !(role.includes('Manager')) ||(role.includes('Manager') && (pathname.includes('/depot')|| pathname.includes('/operator') || pathname.includes('camera')) )
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)

export default GuardedRoute;