import {actions} from './action-list';
export function setTokenExpired(value) {
    return {
        type: actions.TOKEN_EXPIRE,
        value: value
    }
}
export function updateUserAuthorities(value) {
    return {
        type: actions.UPDATE_AUTHORITIES,
        value: value
    }
}

export function logoutUser(value) {
    return {
        type: actions.LOG_OUT,
        value: value
    }
}



