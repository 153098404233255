const validateVehicle = (formState)=> {
    let form = {};
    if (!formState.selectedRegion || !formState.vehicleNo || !formState.city || !formState.agencyName || !formState.depotName|| !formState.operator || !formState.seatingCapacity||!formState.vehicleType || !formState.gstOnTicket || !formState.collectionOnEtim || !formState.surchargeOnTicket || formState.cameraIdError || !formState.serviceCategory) {
        form._error = "Required field missing";
        form.invalid = true;
    }else{
        form.invalid = false;
    }
    return form;
};
export  default validateVehicle;