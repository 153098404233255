import Production from './production';
import Development from './development';
import Staging from './staging';
import Preprod from "./preprod";

const config = {
    production: Production,
    development: Development,
    staging: Staging,
    preprod: Preprod
};

let env = process.env.NODE_ENV || 'development';
if (process.env.REACT_APP_ENV) env = process.env.REACT_APP_ENV;

export default config[env]
