import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";

import { MessageType } from "../../../../../Constant";
import validateCamera from "../../validator/validateCamera";
import {
  upsertCameraMasterData,
  fetchCameraPositionData,
  fetchCameraBrands,
  fetchCameraTypes,
  fetchCameraServiceNames
} from "../../../../../services/vehicleMasterService";

export class CameraCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraId:"",
      userId:"",
      cameraBrand:"",
      cameraType:"",
      cameraPassword:"",
      simCardNo:"",
      sdCard:"",
      vehicleNo:"",
      position:"",
      form: {},
      stayOpen: false,
      removeSelected: true,
      rtl: false,
      positionOptions: [],
      isActiveOptions: [{label:'Active',value:true},{label:'InActive',value:false}],
      isActive:'',
      loading:false,
      sdCardOptions:[{label:'Yes',value:'Yes'},{label:'No',value:'No'}],
      cameraBrandOptions:[],
      cameraTypesOptions:[],
      isDataChangeInEditMode: true,
      serviceName:"",
      serviceNamesData:[]
    };
    this.fetchCameraBrands = this.fetchCameraBrands.bind(this);
    this.fetchCameraTypes = this.fetchCameraTypes.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateFormErrorState();
    this.fetchCameraPosition();
    this.fetchCameraBrands();
    this.fetchCameraTypes();
    this.fetchServiceNames();
    if (this.props.updateSelection) {
      let data = this.props.updateSelection;
      this.setState(
        {
          ...data,
          serviceName:data.serviceName,
          isActive : data.isActive ? true : false
        },
        () => {
          this.updateFormErrorState();
        }
      );
    }
  }

   /**
   * validates trip form data
   * @param target
   */
   updateFormErrorState() {
    let formState = validateCamera(this.state);
    let isDataChangeInEditMode = true;
    if(this.props.updateSelection){
      const requiredFields = [
        "cameraId",
        "cameraBrand",
        "cameraType",
        "vehicleNo",
        "position",
      ];
      const optionalFields = [
        "userId",
        "cameraPassword",
        "simCardNo",
        "sdCard",
        "isActive",
        "serviceName"
      ]
      let serverStr = '';
      let currentStr = '';
      requiredFields.forEach((field) => {
        serverStr += this.props.updateSelection[field];
        currentStr += this.state[field];
      });
      optionalFields.forEach((field)=>{
        if(this.props.updateSelection[field]){
          serverStr += this.props.updateSelection[field];
        }
        if(this.state[field]){
            currentStr += this.state[field];
        }
      });
      if (serverStr === currentStr) {
        isDataChangeInEditMode = false;
      }
    }
    this.setState( { form: formState,isDataChangeInEditMode } );
  }



  createCamera() {
    const { cameraId } = this.state;
    const fields = [
      "userId",
      "cameraBrand",
      "cameraType",
      "cameraPassword",
      "simCardNo",
      "sdCard",
      "vehicleNo",
      "position",
      "isActive",
      "serviceName"
    ];
    let data = { cameraId };

    fields.forEach((field) => {
      if (this.state[field]) data[field] = this.state[field];
    });
    upsertCameraMasterData("camera", data, this.props.updateSelection)
      .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
          this.setState({loading:false});
          this.props.initiateNotification({
            type: 'error',
            message: err.description ? err.description : "Error occurred "
          })
      });

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchCameraPosition() {
    fetchCameraPositionData()
      .then((positions) => {
        positions = positions.map((el) => ({ label: el, value: el }));
        this.setState({
          positionOptions: positions,
        });
      })
      .catch((err) => {
        this.handleError(err);
      });
  }
fetchServiceNames(){
  fetchCameraServiceNames()
  .then((serviceNames)=>{
    serviceNames=serviceNames.map((el)=>({label:el,value:el}))
    this.setState({
      serviceNamesData:serviceNames
    })
  })
  .catch((err)=>{
    this.handleError(err)
  })
}
  fetchCameraBrands(){
    fetchCameraBrands()
    .then((cameraBrands) => {
      cameraBrands = cameraBrands.map((el) => ({ label: el, value: el }));
      this.setState({
        cameraBrandOptions: cameraBrands,
      });
    })
    .catch((err) => {
       this.handleError(err);
    });
  }

  fetchCameraTypes(){
    fetchCameraTypes()
    .then((cameraTypesOptions) => {
      cameraTypesOptions = cameraTypesOptions.map((el) => ({ label: el, value: el }));
      this.setState({
        cameraTypesOptions: cameraTypesOptions,
      });
    })
    .catch((err) => {
     this.handleError(err);
    });
  }

  handleError = (err)=>{
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.onComplete(MessageType.ERROR, "Invalid token");
    } else if (this.mounted) {
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching cities",
      });
    }
  }

  getCameraId = ()=>{
    return(
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className=" col-sm-3 text-left pl-2 form-label"
              >
                <span>Camera Id</span>
              </label>
              <div className="col-sm-9 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="cameraId"
                  placeholder="Enter CameraId"
                  onChange={(value) => {
                    this.setState(
                      { cameraId: value.target.value },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.cameraId
                      : ""
                  }
                />
                {this.state.form.cameraIdSpecialChar ? (
                  <span className="text-danger">
                    Special character and white space not allowed
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
    )
  }

  getUserId = ()=>{
    return (
    <div className="col-sm-12">
    <div className="form-group">
      <label
        htmlFor="name7"
        className=" col-sm-3 text-left pl-2 form-label"
      >
        UserId
      </label>
      <div className="col-sm-9 ">
        <input
          type="text"
          autoComplete="off"
          className="form-control"
          id="userId"
          placeholder="Enter UserId"
          onChange={(value) => {
            this.setState({ userId: value.target.value.trim() }, () => {
              this.updateFormErrorState();
            });
          }}
          defaultValue={
            this.props.updateSelection
              ? this.props.updateSelection.userId
              : ""
          }
        />
      </div>
    </div>
  </div>
    )
  }

  getCameraBrand=()=>{
    return (<div className="col-sm-12">
    <div className="form-group">
      <label
        htmlFor="name7"
        className=" col-sm-3 text-start pl-2 form-label"
      >
       <span>Camera Brand</span>
      </label>
      <div className="col-sm-9 ">
        <Select
            closeOnSelect={!this.state.stayOpen}
            value={this.state.cameraBrand}
            onChange={(e) => {
              if(e){
                this.setState({ cameraBrand: e.value }, () =>
                  this.updateFormErrorState()
                );
              }else{
                this.setState({cameraBrand: ''},()=>{this.updateFormErrorState()})
              }
            }}
            options={this.state.cameraBrandOptions}
            placeholder="Select Camera Brand"
            rtl={this.state.rtl}
            removeSelected={this.state.removeSelected}
          />
      </div>
    </div>
  </div>)
  }

  getCameraType=()=>{
    return (
      <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className=" col-sm-3 text-start pl-2 form-label text-start"
              >
                <span>Camera Type</span>
              </label>
              <div className="col-sm-9 ">
                <Select
                    closeOnSelect={!this.state.stayOpen}
                    value={this.state.cameraType}
                    onChange={(e) => {
                      if(e){
                        this.setState({ cameraType: e.value }, () =>
                          this.updateFormErrorState()
                        );
                      }else{
                        this.setState({cameraType: ''},()=>{this.updateFormErrorState()})
                      }
                    }}
                    options={this.state.cameraTypesOptions}
                    placeholder="Select Camera Type"
                    rtl={this.state.rtl}
                    removeSelected={this.state.removeSelected}
                  />
              </div>
            </div>
          </div>
    )
  }

  getCameraPassword=()=>{
    return (
      <div className="col-sm-12">
      <div className="form-group">
        <label
          htmlFor="name7"
          className=" col-sm-3 text-start pl-2 form-label text-start"
        >
          Camera Password
        </label>
        <div className="col-sm-9 ">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            id="cameraPassword"
            placeholder="Enter Camera Password"
            onChange={(value) => {
              this.setState(
                { cameraPassword: value.target.value.trim() },
                () => {
                  this.updateFormErrorState();
                }
              );
            }}
            defaultValue={
              this.props.updateSelection
                ? this.props.updateSelection.cameraPassword
                : ""
            }
          />
        </div>
      </div>
    </div>
    )
  }

  getSimCardNo=()=>{
    return(
      <div className="col-sm-12">
      <div className="form-group">
        <label
          htmlFor="name7"
          className=" col-sm-3 text-start pl-2 form-label text-start"
        >
          Sim Card No
        </label>
        <div className="col-sm-9 ">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            id="simCardNo"
            placeholder="Enter Sim Card No"
            onChange={(value) => {
              this.setState(
                { simCardNo: value.target.value.trim() },
                () => {
                  this.updateFormErrorState();
                }
              );
            }}
            defaultValue={
              this.props.updateSelection
                ? this.props.updateSelection.simCardNo
                : ""
            }
          />
          {this.state.form.simCardNoError ? (
            <span className="text-danger">
              Only alphanumberi characters allowed
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
    )
  }

  getSdCard = ()=>{
    return (
      <div className="col-sm-12">
      <div className="form-group">
        <label
          htmlFor="name7"
          className=" col-sm-3 text-start pl-2 form-label text-start"
        >
          SD Card
        </label>
        <div className="col-sm-9 ">
          <Select
              closeOnSelect={!this.state.stayOpen}
              value={this.state.sdCard}
              onChange={(e) => {
                if(e){
                  this.setState({ sdCard: e.value }, () =>
                    this.updateFormErrorState()
                  );
                }else{
                  this.setState({sdCard: ''},()=>{this.updateFormErrorState()})
                }
              }}
              options={this.state.sdCardOptions}
              placeholder="Select SD Card"
              rtl={this.state.rtl}
              removeSelected={this.state.removeSelected}
            />
        </div>
      </div>
    </div>
    )
  }
  getServiceNames = () => {
    return (
      <div className="col-sm-12">
        <div className="form-group">
          <label htmlFor="name7"
            className=" col-sm-3 text-start pl-2 form-label">Service Name</label>
          <div className="col-sm-9">
            {
              <Select
                closeOnSelect={!this.state.stayOpen}
                disabled={this.state.disabled}
                onChange={(e) => {
                  if(e){
                    this.setState({ serviceName:e.value }, () =>
                      this.updateFormErrorState()
                    );
                  }else{
                    this.setState({serviceName: ''},()=>{this.updateFormErrorState()})
                  }
                }}
                options={this.state.serviceNamesData}
                placeholder="Select Camera Purpose"
                removeSelected={this.state.removeSelected}
                rtl={this.state.rtl}
                value={this.state.serviceName}
              />}
          </div>
        </div>
      </div>
    )

  }
  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          {this.getCameraId()}
          {this.getUserId()}
          {this.getCameraBrand()}
          {this.getCameraType()}
          {this.getCameraPassword()}
          {this.getSimCardNo()}
          {this.getSdCard()}
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className=" col-sm-3 text-start pl-2 form-label"
              >
                <span>Vechicle No</span>
              </label>
              <div className="col-sm-9 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="vehicleNo"
                  placeholder="Enter Vehicle Number"
                  onChange={(value) => {
                    this.setState(
                      { vehicleNo: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.vehicleNo
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className=" col-sm-3 text-start pl-2 form-label"
              >
                <span>Position</span>
              </label>
              <div className="col-sm-9 ">
                <Select
                  closeOnSelect={!this.state.stayOpen}
                  value={this.state.position}
                  onChange={(e) => {
                    if(e){
                      this.setState({ position: e.value }, () =>
                        this.updateFormErrorState()
                      );
                    }else{
                      this.setState({position: ''},()=>{this.updateFormErrorState()})
                    }
                  }}
                  options={this.state.positionOptions}
                  placeholder="Select Positions"
                  rtl={this.state.rtl}
                  removeSelected={this.state.removeSelected}
                />
              </div>
            </div>
          </div>
          {this.getServiceNames()}
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className=" col-sm-3 text-start pl-2"
              >
                {this.state.vehicleNo ? <span>Status</span> : ""}
                {!this.state.vehicleNo ? "Status" : ""}
              </label>
              <div className="col-sm-9 ">
                <Select
                  closeOnSelect={!this.state.stayOpen}
                  value={this.state.isActive}
                  onChange={(e) => {
                    if(e){
                      this.setState({ isActive: e.value }, () =>
                        this.updateFormErrorState()
                      );
                    }else{
                      this.setState({isActive: ''},()=>{this.updateFormErrorState()})
                    }
                  }}
                  options={this.state.isActiveOptions}
                  placeholder="Select Status"
                  rtl={this.state.rtl}
                  removeSelected={this.state.removeSelected}
                />
              </div>
            </div>
          </div>
          

          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.setState({ loading: true });
                this.createCamera();
              }}
              disabled={this.state.form.invalid || this.state.loading || !this.state.isDataChangeInEditMode}
            >
              {this.state.loading ? "Saving..." : "Save changes"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
