import { getAPIPromise } from "./APIutils";
import { BASE_GPS_MATRIX_STAG_URL, GET_TRIPS } from "../Constant";


export function fetchTripsForRegion(regionId, key, date, pageNo, pageSIze = 10) {
    let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_TRIPS}${regionId}?date=${date}&pageNo=${pageNo}&pageSize=${pageSIze}`;
    if (key) {
        url = `${url}&key=${key}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function createTrip(regionId, state) {
    let data = {};
    data.vehicleNo = state.vehicleNo;
    if(state.deviceId){data.deviceId = state.deviceId}
    if(state.tripId){ data.tripId = state.tripId}
    data.fromTimestamp = state.startTime;
    data.toTimestamp = state.endTime;
    let url = `${BASE_GPS_MATRIX_STAG_URL}api/v1/trips/distance/${regionId}`;
    return getAPIPromise(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([data])
    });
}