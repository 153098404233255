// import moment from "moment";
import moment from 'moment-timezone';
export function timeChange(m, offset) {

    moment.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: function (number) {
                return '' + (number < 10 ? '0' : '') + number + ' sec';
            },
            m: "01:00 mins",
            mm: function (number) {
                return (number < 10 ? '0' : '') + number + ' mins';
            },
            h: "an hour",
            hh: "%d hours",
            d: "a day",
            dd: "%d days",
            M: "a month",
            MM: "%d months",
            y: "a year",
            yy: "%d years"
        }
    });
    var m1 = moment(m);

    return m1.subtract(offset, 'ms').fromNow();
}
export function fetchTimeValue(value, timestamp1, timestamp2) {
    if (timestamp1 != null || timestamp2 != null) {
        const totalMilliseconds = timestamp1 + timestamp2;
        switch (value) {
            case 'hr':
                return (totalMilliseconds / 3600000).toFixed(2);
            case 'min':
                return (totalMilliseconds / 60000).toFixed(2);
            case 'sec':
                return (totalMilliseconds / 1000).toFixed(2);
            default:
                return '';
        }
    } else {
        return 'NA';
    }
}


export function transformedText(text, search) {
    if(text){
        var pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
        }).join('|');
        var regex = new RegExp(pattern, 'gi');
        return {__html: search ? text.replace(regex, (match) => `<span class="highlight">${match}</span>`) : text};
    }
}

export function UpperCaseAtFirst(lower) {
    if (lower)
        return lower.charAt(0).toUpperCase() + lower.substr(1);
    else
        return '';
}
export function regionExistIn(data, filterKey) {
    if (data) {
        return data.filter(dataItem=> {
            return dataItem == filterKey;
        }).length;
    } else {
        return 0;
    }
}

export function filterData(data, key, filterKey) {
    return data.filter((item)=> {
        return item[key].includes(filterKey)
    })
}
export function convertIntoKm(value) {
        return ((value/ 1000).toFixed(2)); 
}
//timezone filter from regions
export function fetchTimezonefromList(timezoneList,region){
    if(timezoneList){
        var found = timezoneList.find(e => e.regionId === region);
    }
    if(found){
        localStorage.setItem('timeZone', found.timeZone);
        return found;
    }
    else{
        localStorage.setItem('timeZone', "Asia/Kolkata");
        return {
            "regionId":region,
            "timeZone": "Asia/Kolkata"
        }
    }
}
//html timezone
export function EpochtoEpochTZ(date,timezone) {
    let timeZoneName;
    if(timezone){
        timeZoneName=timezone
    }
    else{
        timeZoneName= 'Asia/Kolkata'
    }
    return new Date(date).toLocaleString("en-US", {
      timeZone: timeZoneName,
    });
  }
//api timezone
export function EpochTimezone(date,timezone){
    let epochDate=parseInt(date);
    let timeZoneName;
    if(timezone){
        timeZoneName=timezone
    }
    else{
        timeZoneName= 'Asia/Kolkata'
    }
 return moment(
   moment(epochDate).parseZone().tz(timeZoneName, true).toISOString(true)
 ).valueOf();
}

export function getLocalStorageTz() {
    return localStorage.getItem("timeZone");
  }