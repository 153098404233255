import {BASE_URL, DEVICE_CONFIG} from "../../../../Constant";
import {getAPIPromise} from "../../../../services/APIutils";
import {CONFIG_CMDS} from "../utils";
export class ConfigurationService {
    fireConfigurationCommand(deviceId, simCard, event) {
        let url = `${BASE_URL}${DEVICE_CONFIG}`;
        if (event && event.endpoint != '') {
            url = `${url}/${event.endpoint}`;
        }
        deviceId && ( url = `${url}?deviceId=${deviceId}`);
        simCard && (url = `${url}&simCard=${simCard}`);
        url = this.getIntervalParams(url, event);

        return getAPIPromise(url, {
            method: 'post'
        });
    }

    /**
     * adds params to the event url
     * @param url
     * @param event
     * @returns {*}
     */
    getIntervalParams(url, event) {
        if (event && event.details)
            switch (event.endpoint) {
                case CONFIG_CMDS.SET_INTERVAL:
                    if (event.details.ignOffInterval) {
                        url = `${url}&ignOffInterval=${event.details.ignOffInterval}`;
                    }
                    if (event.details.ignOnInterval) {
                        url = `${url}&ignOnInterval=${event.details.ignOnInterval}`
                    }
                    break;
                case CONFIG_CMDS.SET_TIMEZONE:
                    if (event.details.timeZone) {
                        url = `${url}&offset=${event.details.timeZone.key}`
                    }
                    break;
                case CONFIG_CMDS.FRESH_CONFIG:
                    if (event.details.ignOffInterval) {
                        url = `${url}&ignOffInterval=${event.details.ignOffInterval}`;
                    }
                    if (event.details.ignOnInterval) {
                        url = `${url}&ignOnInterval=${event.details.ignOnInterval}`
                    }
                    if (event.details.timeZone) {
                        url = `${url}&offset=${event.details.timeZone.key}`
                    }
                    if (event.details.Apn) {
                        url = `${url}&apn=${event.details.Apn.key}`
                    }
                    if (event.details.regionId) {
                        url = `${url}&regionId=${event.details.regionId}`
                    }
                    if (event.details.protocolId) {
                        url = `${url}&protocolId=${event.details.protocolId}`
                    }
                    break;
                case CONFIG_CMDS.APN_SETTING:
                    if (event.details.Apn) {
                        url = `${url}&apn=${event.details.Apn.key}`
                    }
                    if (event.details.regionId) {
                        url = `${url}&regionId=${event.details.regionId}`
                    }
                    break;
            }
        return url;
    }
}