import {MessageType} from "../../Constant";
import {setTokenExpired} from "../actions/authActions";
import {setNotificationMessage} from "../actions/notificationActions";
import {protocolsFetched, protocolsLoading} from "../actions/protocolActions";
import {fetchProtocols} from "../../services/protocolService";
export function createProtocolListAction() {
    return (dispatch)=> {
        dispatch(protocolsLoading(true));
        fetchProtocols().then((response) => {
            dispatch(protocolsLoading(false));
            dispatch(protocolsFetched(response));
        }).catch((err) => {
            dispatch(protocolsLoading(false));
            if ((err.status === 401 || err.message==='Invalid token')) {
                dispatch(setTokenExpired(true))
            } else {
                if (err.status != 204) dispatch(setNotificationMessage({
                    type: MessageType.ERROR,
                    message: err.description ? err.description : "Error while fetching protocols"
                }));
            }
            console.log(err);
        });
    }
}