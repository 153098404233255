import {BASE_URL,BASE_GPS_MATRIX_STAG_URL} from "../../../../Constant";
import {GET_REGIONS_DELAY} from "../../../../Constant";
import {getAPIPromise} from "../../../../services/APIutils";

export function fetchDelayMatrix(timeSlab) {
    let URL = `${BASE_URL}${GET_REGIONS_DELAY}`;
    if (timeSlab) {
        URL = URL + `?timeSlab=${timeSlab}`
    }
    return getAPIPromise(URL, {
        method: 'get'
    });
}
export function fetchMatrixCount(timeslab){
    let URL=`${BASE_GPS_MATRIX_STAG_URL}api/reports/gpsQuality/count?timeSlab=${timeslab}`
    return getAPIPromise(URL, {
        method: 'get'
    });
}