import React from "react";
import { Button } from "react-bootstrap";
import { MessageType } from "../../../../../Constant";
import {
  createMasterData,
  fetchImgaes,
} from "../../../../../services/vehicleMasterService";
import validateCountry from "../../validator/countryValidator";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { countries } from "country-data";
import getSymbolFromCurrency from "currency-symbol-map";
import clm from "country-locale-map";
const SUPPORTED_TYPE = ["jpg", "jpeg", "png", "svg"];

export class CountryCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      countriesOptions: [],
      validCountry: true,
      validCallingCode: true,
      countryDetails: [],
      countryPreview: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.updateSelection) {
      let data = this.props.updateSelection;
      this.setState(
        {
          country: data.name,
          callingCode: data.callingCode,
          currencySymbol: data.currencySymbol,
          placeValue: data.placeValue,
          currency: data.currency,
          countryCode: data.countryCode,
          currencyFactor: data.currencyFactor,
          phoneNumberPossibleLengths: data.phoneNumberPossibleLengths,
          countryFlags: data.countryFlags,
        },
        () => {
          this.updateFormErrorState();
          this.getImageUrl();
        }
      );
    }
    this.updateFormErrorState();
  }

  componentWillMount() {
    const allCountries = countries.all.map((ele) => {
      return { id: ele.name, name: ele.name.toUpperCase() };
    });
    this.setState({
      countryDetails: countries.all,
      countriesOptions: allCountries,
    });
  }

  getImageUrl() {
    fetchImgaes(this.state.country, "country").then((res) => {
      if (res.status === 200) {
        res.blob().then((blob) => {
          this.setState({
            countryPreview: URL.createObjectURL(blob),
          });
        });
      }
    });
  }

  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Country</span>
              </label>
              <div className="col-sm-10">
                <SelectFilter
                  type="country"
                  value={this.state.country}
                  onChange={(value) => {
                    this.setState({ country: value.name }, () => {
                      this.handleAutoValues(this.state.country);
                      this.updateFormErrorState();
                    });
                  }}
                  valueKey="name"
                  labelKey="name"
                  placeholder="Country"
                  options={this.state.countriesOptions}
                  disabled={this.props.updateSelection ? true : false}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Country Code</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="countryCode"
                  placeholder="Enter country Code"
                  onChange={(value) => {
                    this.setState(
                      { countryCode: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.countryCode
                      : this.state.countryCode
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Currrency</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="currency"
                  placeholder="Enter Currency"
                  onChange={(value) => {
                    this.setState(
                      { currency: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.currency
                      : this.state.currency
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Calling Code</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="callingCode"
                  placeholder="Enter Calling Code"
                  onChange={(value) => {
                    this.setState(
                      { callingCode: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.callingCode
                      : this.state.callingCode
                  }
                />
                {!this.state.validCallingCode && (
                  <span className="error-text">Invalid Calling code</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Currrency Symbol</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  style={{ fontFamily: "DejaVu Sans" }}
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="currencySymbol"
                  placeholder="Enter Currency Symbol"
                  onChange={(value) => {
                    this.setState(
                      { currencySymbol: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.currencySymbol
                      : this.state.currencySymbol
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Place Value</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="placeValue"
                  placeholder="Enter Place Value"
                  onChange={(value) => {
                    this.setState(
                      { placeValue: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.placeValue
                      : this.state.placeValue
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>PhoneNo. Length</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="phoneNumberPossibleLengths"
                  placeholder="Enter Phone Number Possible Length"
                  onChange={(value) => {
                    this.setState(
                      { phoneNumberPossibleLengths: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.phoneNumberPossibleLengths
                      : ""
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                <span>Currrency Factor</span>
              </label>
              <div className="col-sm-10 ">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="currencyFactor"
                  placeholder="Enter Currency Factor"
                  onChange={(value) => {
                    this.setState(
                      { currencyFactor: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                    this.props.updateSelection
                      ? this.props.updateSelection.currencyFactor
                      : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className="control-label col-sm-2 form-label"
              >
                Country Flag
              </label>
              <div
                className="col-sm-10"
                onChange={(e) => {
                  if (e.target.files.length > 1) {
                    this.setState({
                      invalidJsonId: true,
                      errorMessageId: "please select max 1 file",
                    });
                    e.preventDefault();
                  } else if (
                    e.target.files.length &&
                    !this.isValidName(e.target.files[0].name)
                  ) {
                    this.setState({
                      invalidJsonId: true,
                      errorMessageId: `Sorry,${e.target.files[0].name} is invalid, allowed extension is ${SUPPORTED_TYPE}`,
                    });
                    e.preventDefault();
                  } else if (e.target.files.length) {
                    this.setState({ invalidJsonId: false });
                    this.onFileChange(e, "countryFlag");
                  }
                }}
              >
                <div>
                  <input type="file" accept={SUPPORTED_TYPE} multiple={false} />
                  {this.state.countryPreview && (
                    <a href={this.state.countryPreview}>Previous Upload</a>
                  )}
                </div>
                {this.state.invalidJsonId && (
                  <div className="message-box" style={{ color: "red" }}>
                    {this.state.errorMessageId}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.setState({ loading: true });
                this.createCityMaster();
              }}
              disabled={this.state.form.invalid || this.state.loading}
            >
              {this.state.loading ? "Saving..." : "Save changes"}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  /**
   * validates country form data
   * @param target
   */
  updateFormErrorState() {
    let formState = validateCountry(this.state);
    this.setState(
      {
        form: formState,
      },
      () => {
        this.state.form.countryNameError
          ? this.setState({
              validCountry: false,
            })
          : this.setState({
              validCountry: true,
            });
        this.state.form.callingNameError
          ? this.setState({
              validCallingCode: false,
            })
          : this.setState({
              validCallingCode: true,
            });
        this.state.form.callingNameError
          ? this.setState({
              validCallingCode: false,
            })
          : this.setState({
              validCallingCode: true,
            });
      }
    );
  }

  /**
   * Use to Create City Master Data
   */
  createCityMaster() {
    let data = {
      name: this.state.country.toUpperCase(),
      callingCode: this.state.callingCode,
      currencySymbol: this.state.currencySymbol,
      placeValue: this.state.placeValue ? this.state.placeValue.replace("_","-") : null,
      currency: this.state.currency,
      countryCode: this.state.countryCode,
      currencyFactor: this.state.currencyFactor,
      phoneNumberPossibleLengths: this.state.phoneNumberPossibleLengths,
      countryFlags: this.state.countryFlags,
    };
    if (this.props.updateSelection) {
      data["id"] = this.state.id;
    }
    this.formData = new FormData();
    this.formData.append("countryData", JSON.stringify(data));
    if (this.state.countryFlag && typeof this.state.countryFlag !== "string") {
      this.formData.append("countryFlag", this.state.countryFlag);
    }
    createMasterData("country", this.formData, this.props.updateSelection)
      .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred "
          );
        }
      });
  }

  handleAutoValues(value) {
    let temp = this.state.countryDetails.filter((ele) => {
      return ele.name.toUpperCase() == value;
    });
    this.setState({
      countryCode: temp[0].alpha3,
      callingCode: temp[0].countryCallingCodes[0],
      currency: temp[0].currencies[0],
      currencySymbol: getSymbolFromCurrency(temp[0].currencies[0]),
      placeValue: clm.getLocaleByAlpha2(temp[0].alpha2),
    });
  }

  isValidName(sFileName) {
    var _validFileExtensions = SUPPORTED_TYPE;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      return blnValid;
    }
  }
  onFileChange(evt, fieldName) {
    this.setState({
      invalidJsonId: false,
    });
    if (!this.browserSupportFileUpload()) {
      alert("The File APIs are not fully supported in this browser!");
    } else {
      var file = evt.target.files[0];
      this.setState(
        {
          [fieldName]: file,
        },
        () => {
          this.updateFormErrorState();
        }
      );
    }
  }

  browserSupportFileUpload() {
    var isCompatible = false;
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      isCompatible = true;
    }
    return isCompatible;
  }

  componentWillUnmount() {
    this.mounted = false;
  }
}
