import { StatusHeader } from "./views/Dashboard/devices/tableStatusHeader";
import { DelayHeader, OverAllDelayHeader } from "./views/Dashboard/delaymatrix/views/delayHeader";
import { DeviceDelayHeader } from "./views/Dashboard/devices/tableDelayHeader";
import Config from "./config";
import {sortOption} from "./shared/widgets/sortingView/sortingView";

/**
 * Get Final base url on the basis of build environment
 * @type {any}
 */
export const BASE_URL = Config.API.BASE_URL;
export const META_BASE_URL = Config.API.META_CALL_BASE_URL;
export const BASE_GPS_MATRIX_STAG_URL = Config.API.GPS_MATRIX_STAG_URL;
export const GET_PROTOCOLS = 'api/protocol/';
export const GET_REGIONS = 'api/region';
export const GET_REPORTS = 'api/reports';
export const GET_REPORTS_DISTANCE = 'api';
export const GET_DEVICES = 'api/device';
export const GET_VEHICLES_LIST = 'api/master/vehicle/list';
export const ADD_PROTOCOL_URL = 'api/protocol/';
export const ADD_REGION_URL = 'api/region/';
export const UPDATE_REGION_URL = 'api/region/';
export const EXPORT_REGIONS_DEVICE = 'api/device';
export const EXPORT_REGIONS_VEHICLES = 'api/master/vehicle';
export const GET_REGIONS_DELAY = 'api/region/avg_delay';
export const GET_REGIONS_DELAY2 = 'api/region/v1/avg_delay';
export const LOGIN_TOKEN = 'api/v1/google/token?webRequest=true';
export const LOGIN_AUTHENTICATION = 'api/v1/google/secure_authenticated/new';
export const LOGOUT_USER = 'api/v1/google/logout/new';
export const AUTH_TOKEN = 'oauth/token';
export const USERS = 'api/users/';
export const GET_DEVICE_DETAIL = 'api/device/get/';
export const SEARCH_VEHICLES = 'api/vehicle/search';
export const GET_VEHICLES = 'api/master/vehicle/';
export const GET_TRIPS = 'api/v1/trips/distance/';
export const GET_CRONS= 'api/cron/trigger'
export const GET_SERVER_TIME = 'api/healthcheck/currenttimestamp/';
export const DEVICE_CONFIG = 'api/device/config';
export const META_CALL = 'configuration/v1/cityInfo';
export const EXPORT_DISTANCE = 'api/distance';
export const EXPORT_GPS_QUALITY = 'api/reports/gpsQuality';
export const EXPORT_RESTORE_DATA = 'api/restore/data';
export const GET_CITIES_MASTER = 'api/master/city';
export const GET_COUNTRIES_MASTER = 'api/master/country';
export const GET_AGENCY_MASTER = 'api/master/agency';
export const GET_OPERATOR_MASTER = 'api/master/operator';
export const GET_DEPOT_MASTER = 'api/master/depot';
export const GET_CAMERA_MASTER = 'api/master/camera';
export const GET_VEHICLE_APPLICATIONS = 'api/vehicle/applications';
export const GET_VEHICLE_SERVICE_TYPE = 'api/vehicle/service_type';
export const GET_VEHICLE_OPERATION_TYPE = 'api/vehicle/operation_type';
export const GET_VEHICLE_TYPE = 'api/vehicle/vehicle_type';
export const GET_ID_TYPE = 'api/master/operator/idType';
export const GET_PROTOCOL_TYPE = 'api/master/vehicle/protocolType';
export const GET_VEHICLE_LOG = 'api/audit/vehicle';
export const GET_LANGUAGE = 'api/master/languages/filter';
export const UPDATE_VEHICLE_STATUS = 'api/master/vehicle/status';
export const APPROVE_ID = 'api/master/operator/approveId';
export const APPROVE_PAN = 'api/master/operator/approvePan';
export const APPROVE_BANK = 'api/master/operator/approveBank';
export const DOWNLOAD = 'api/master/operator/download';
export const timestamp = 60000;
export const refreshTimestamp = 120000;
export const clusterIP = '3.6.109.15';
export const testRegion = 'test';
export const dateFormat = "DD/MM/YYYY, h:mm:ss A";
export const timeAddToConvert = 19800000;
export const defaultLatitude = 19.0760;
export const defaultLongitude = 72.8777;
export const timeIntervals = {
    last1Min: { key: 'last1Min', value: 'Last 01 Min', time: 60000 },
    last5Min: { key: 'last5Min', value: 'Last 05 Min', time: 300000 },
    last15Min: { key: 'last15Min', value: 'Last 15 Min', time: 900000 },
    last30Min: { key: 'last30Min', value: 'Last 30 Min', time: 1800000 },
    last1Hour: { key: 'last1Hour', value: 'Last 1 Hour', time: 3600000 },
    last4Hour: { key: 'last4Hour', value: 'Last 4 Hour', time: 14400000 },
    last12Hour: { key: 'last12Hour', value: 'Last 12 Hour', time: 43200000 },
    last24Hour: { key: 'last24Hour', value: 'Last 24 Hour', time: 86400000 }
};
export const timeParsing = {
    sec: { key: 'sec', value: 'sec' },
    min: { key: 'min', value: ' min' },
    hr: { key: 'hr', value: 'hr' },
};
export const templateStrings = {
    // operator: 'Operator',
    operatorMaster: 'Operator',
    brand_name: 'Chalo',
    toggle_navigation: 'Toggle navigation',
    region: 'Region',
    delay: 'Delay',
    overAllDelay: 'Overall Delay',
    vehicleNo: 'Vehicle No',
    vehicleCode:'Vehicle Code',
    // depotName: 'Depot Name',
    depot: 'Depot',
    // agencyName: 'Agency Name',
    agency: 'Agency',
    make: 'Make',
    // city: 'City',
    cityMaster: 'City',
    owner: 'Owner',
    version: 'Version',
    model: 'Model',
    simCard: 'Sim 1',
    secondarySim: 'Sim 2',
    deviceId: 'Device Id',
    seatingCapacity: 'Seating Capacity ',
    standingCapacity: 'Standing Capacity ',
    vehicleType: 'Vehicle Type ',
    serviceType: 'Service Type ',
    operationType: 'Operation Type ',
    registrationDate: 'Registration Date',
    gstOnTicket: 'GST On Ticket',
    surchargeOnTicket: 'Surcharge On Ticket',
    collectionOnEtim: 'Collection On Etim',
    afcsAccountId: 'Afcs Account Id',
    // cameras: 'Camera Id',
    applications: 'Applications',
    regionId: 'Region ',
    fuelPlan: "Fuel Plan",
    gpsRequiredFromEtim:'GPS From Etim',
    etimFrequency:'Etim Frequency',
    fwdToShuttl:'Forward to Shuttl',
    protocolType: "Protocol Type",
    chasisNumber: "Chasis Number",
    engineNumber: "Engine Number",
    status: "Status",
    deviceStop: "Stop",
    deviceStatus: "Status",
    deviceHealth:"Health",
    protocol:"Protocol",
    networkStrength:"Network",
    city: "City",
    lastPacketArrivedAt:"Last Packet Arrived At",
    createdAt: "Created At",
    updatedAt : "Updated At",
    routeIds : "Route Ids",
    stopName: "Stop Name",
    regions: {
        region: 'Region',
        receiver_port: 'Receiver Port',
        end_point: 'Chalo Endpoint',
        remote_host: 'Remote Host',
        remote_port: 'Remote Port',
        parsing_Enabled:'Is Parsing',
        protocols: 'Protocols',
        tags: 'Tags',
        actions: 'Actions'
    },
    protocols: {
        heading: 'Supported Protocol',
        columns: { protocol: 'Protocol' }
    },
    devices: {
        device_id: 'DeviceId',
        vehicle_no: 'Vehicle No.',
        status: {
            component: StatusHeader
        },
        delay: {
            component: DeviceDelayHeader
        },
        last_seen: 'Last Seen Packet',
        actions: 'Actions'
    },
    frequency: {
        vehicle_no: 'Vehicle No.',
        device_id: 'DeviceId',
    },
    home: {
        heading: 'Delay Matrice',
        avg_parsing_time: 'Parsing',
        avg_response_time: 'Response Time',
        columns: {
            region: 'Region',
            health_status: 'Health Status',
            delay: {
                component: DelayHeader
            },
            overlay_delay: {
                component: OverAllDelayHeader
            },
            device_count: 'Device Count',
            matrix_count: 'Matrix Count',
        }

    },
    vehicles: {
        vehicle_no: 'Vehicle',
        vehicleCode:'Vehicle Code',
        device_id: 'DeviceId',
        cityMaster: 'City',
        agency: 'Agency',
        operatorMaster: 'Operator',
        depot: 'Depot',
        vehicleType: 'Vehicle Type',
        // regionId: 'Region',
        status: 'Status',
        actions: 'Actions'
    },
    BSD: {
        device_id: 'DeviceId',
        stopId: 'StopId',
        stopName: 'Stop Name',
        deviceStatus: 'Status',
        // protocol: 'Protocol',
        city: 'City',
        signal: 'Signal',
        lastArrivedAt: 'Last Arrived At',
        health: 'Health',
        actions: 'Actions'
    },
    stop:{
        stopId: 'StopId',
        stopName: 'Stop Name',
        nativeLanguage: 'Native Lang',
        nativeName: 'Native Name',
        latitude: 'Latitude',
        longitude: 'Longitude',
        actions: 'Actions'
    },
    users: {
        heading: 'User',
        user: 'User',
        columns: {
            username: 'User Name',
            email: 'Email',
            RegionRole: 'Region: Role',
            action: 'Actions'
        }
    },
    actions: 'Actions',
    roles: {
        role: 'Role',
        region: 'Region'
    },
    trips: {
        vehicle_no: 'Vehicle No.',
        device_id: 'DeviceId',
        tripId: 'TripId',
        from: 'Start Time',
        to: 'End Time',
        status: 'Status',
        distance: 'Distance(Km)'
    },
    cronTrigger:{
        event:'Event',
        triggerStatus: 'Status',
        regionId: 'Region',
        timeZone:'Timezone',
        startTime:'Start Time',
        endTime:'End Time',
        updatedBy:'Triggered By',
        createdAt: {
            display: 'Triggered At',
            sortable: true,
            defaultSort: sortOption.ASC
        },
    },
    cronTriggerStatus:{
        ALL: {
            key: "All",
            value: "All"
        },
        GREEN: {
            key: "Green",
            value: "Success"
        },
        RED: {
            key: "Red",
            value: "Failure"
        }
    },
    vehicleLogs: {
        action: 'Action',
        oldValue: 'Previous Value',
        value: 'Updated Value',
        modifiedDate: 'Date',
        modifiedBy: 'Modified By'
    },
    BSDLogs:{
        action: 'Action',
        oldValue: 'Previous Value',
        newValue: 'Updated Value',
        modifiedDate: 'Date',
        modifiedBy: 'Modified By'
    },
    vehicleDownload:{
        fileName:'File Name',
        updatedBy:'Created By',
        createdDate:'Created Date',
        updatedDate:'Updated Date',
        fileStatus:'File Status',
        action:'Download'
    },
    BSDImportDownload:{
        fileName:'File Name',
        uploadedBy:'Uploaded By',
        uploadStartTime:'Created Date',
        uploadEndTime:'Updated Date',
        fileStatus:'File Status',
        action:'Download'
    },
    serviceCategory:"Service Category",
    serviceProvider:"Service Provider",
    tag:"Tag",
    cameraLogs: {
        action: 'Action',
        oldValue: 'Previous Value',
        newValue: 'Updated Value',
        modifiedDate: 'Date',
        modifiedBy: 'Modified By'
    },
    panicAlertReport:{
        "deviceId": "Device Id",
        "vehicleNo": "Vehicle No.",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "timestamp": "Packet Timestamp",
        "lastPacketArriveAt": "Packet Arrived At",
        "action":"Action"

    },
    fuelType:'Fuel Type'
};
export const vehicleTypes = [{ label: 'Bus', value: 'Bus' },
{ label: 'Ferry', value: 'Ferry' }, { label: 'Metro', value: 'Metro' },];

export const InfoCoulumns = {
    device: {
        deviceId: "Device Id",
        vehicleNo: "Vehicle No."
    }
};
export const UserFormRoles = {
    admin: 'admin',
    others: 'others'
};
export const DeviceStatusFilterValues = {
    ALL: {
        key: "All",
        value: "All"
    }, RED: {
        key: "Red",
        value: "InActive"
    }, GREEN: {
        key: "Green",
        value: "Active"
    }, YELLOW: {
        key: "Yellow",
        value: "Warning"
    }
};
export const HealthStatusFilterValues = [
  { name: "Healthy" },
  { name: "Unhealthy" },
];
export const EnableParsingFilter = {
    ALL: {
        key: "All",
        value: "All"
    },
    TRUE: {
        key: "true",
        value: "True"
    },
    FALSE: {
        key: "false",
        value: "False"
    }
};
export const SMSStatusFilter = {
    ALL: {
        key: "All",
        value: "All"
    },
    SUCCESS: {
        key: "success",
        value: "Success"
    },
    FAILURE: {
        key: "failure",
        value: "Failure"
    }
};
export const SMSServiceFilter = {
    ALL: {
        key: "All",
        value: "All"
    },
    TEXTLOCAL: {
        key: "TEXTLOCAL",
        value: "TEXTLOCAL"
    },
    AWS: {
        key: "AWS",
        value: "AWS"
    }
};
export const MatrixStatusFilterValues = {
    ALL: {
        key: "All",
        value: "All"
    }, RED: {
        key: "Red",
        value: "InActive"
    }, GREEN: {
        key: "Green",
        value: "Active"
    }, YELLOW: {
        key: "Yellow",
        value: "Warning"
    }
};

export const WarningFilterStatus = {
    All: {
        key: 'All',
        value: 'All '
    },
    Lat: {
        key: 'Lat',
        value: 'has zero lat lng '
    },
    Past: {
        key: 'Past',
        value: 'has past date '
    },
    Future: {
        key: 'Future',
        value: 'has future date '
    }
};
export const DeviceSortValues = {
    DELAY: {
        key: "delay",
        value: "Delay"
    }, ARRIVE_TIME: {
        key: "arriveTime",
        value: "Arrive Time"
    }, PARSING_TIME: {
        key: "parsingTime",
        value: "Parsing Time"
    }, POSTING_TIME: {
        key: "postingTime",
        value: "Posting Time"
    }
};
export const MessageType = {
    ERROR: 'error',
    SUCCESS: 'success'
};
export const packetStatus = {
    VALID: 'Valid',
    INVALID: 'Invalid',
    HISTORY_VALID: 'HistoryValid',
    HISTORY_INVALID: 'HistoryInvalid',
    FIRST_PACKET: 'FirstPacket'
};
export const updateTemplateStrings = () => {
    if (Config.operatorBasedVehicle) {
        templateStrings.operator = 'Operator';
        templateStrings.vehicles.operator = 'Operator';
    } else {
        delete templateStrings.operator;
        delete templateStrings.vehicles.operator;
    }
};
export const messages = {
    user_info_error: 'Failed to get user from server',
    auth_token_error: "Failed to get auth token from server.",
    google_code_error: "Failed to get code from google services"

}

export const validationRegex = {
    gstValidation: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    alphanumeric: /^[a-zA-Z0-9]+$/,
    number: /^[0-9]+$/,
    ifscValidation: /^[A-Z]{4}0[A-Z0-9]{6}$/
}

export const idLengthLimit = {
    Aadhar: '12',
    DriverLicense: '16',
    VoterId: '10'
}

export const exportDataTypeOptions = [
    { label: 'Raw', value: 'Raw' },
    { label: 'Raw-vehicle', value: 'Vehicle' },
    { label: 'Processed', value: 'Processed' }];

export const bsdAvailabilityReportStatus = [
    {name:"ACTIVE"},
    {name:"INACTIVE"}
]
export const GET_CAMERA_LOG = 'api/audit/camera';

export const DEVICE_COMMAND_STATUS = [
    {
        key: "FAILED",
        value: "Failed"
    },
    {
        key: "SENT",
        value: "Sent"
    },
    {
        key: "PENDING",
        value: "Pending"
    }
]

export const DEVICE_COMMAND_RESPONSE_STATUS = [
    {
        key: "FAILED",
        value: "Failed"
    },
    {
        key: "RECEIVED",
        value: "Received"
    },
    {
        key: "PENDING",
        value: "Pending"
    }
]
