const ValidateCreateDevice = (formState)=> {
    const form = {};
    const requiredFields = ['selectedRegion', 'deviceId', 'command'];
    requiredFields.forEach(field => {
        if (!formState[field] || formState[field].length === 0) {
            form._error = "Required field missing";
            form.invalid = true;
            form[`${field}Error`] = true;
        }else if(field ==='deviceId'  && formState[field] && formState[field].match("[^a-zA-Z0-9]")){
            form._error = "Special character and white space not allowed";
            form.invalid = true;
            form[`${field}SpecialChar`] = true;
        }
    });
    return form;
};
export  default ValidateCreateDevice; 