import React from "react";
import { Button } from "react-bootstrap";
import validateStops from "../../validator/StopValidator";
import {
  createOrUpdateStop,
  fetchStopNativeLanguage,
} from "../../../../../services/BSDService";
import { MessageType } from "../../../../../Constant";
import Select from "react-select";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";

export class CreateStopDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      stopLanguages: [],
      stopId:
        this.props.updateSelection && this.props.updateSelection.stopId
          ? this.props.updateSelection.stopId
          : "",
      stopName:
        this.props.updateSelection && this.props.updateSelection.stopName
          ? this.props.updateSelection.stopName
          : "",
      stopNativeName:
        this.props.updateSelection && this.props.updateSelection.stopNativeName
          ? this.props.updateSelection.stopNativeName
          : "",
      city:
        this.props.updateSelection && this.props.updateSelection.city
          ? this.props.updateSelection.city
          : "",
      stopNativeLanguage:
        this.props.updateSelection &&
        this.props.updateSelection.stopNativeLanguage
          ? this.props.updateSelection.stopNativeLanguage
          : "",
      latitude:
        this.props.updateSelection && this.props.updateSelection.latitude
          ? this.props.updateSelection.latitude
          : "",
      longitude:
        this.props.updateSelection && this.props.updateSelection.longitude
          ? this.props.updateSelection.longitude
          : "",
      form: {},
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchMasterData("city", null, "cities");
    this.fetchStopNativeLanguage();
    this.updateFormErrorState();
    if (this.props.updateSelection) {
      let data = this.props.updateSelection;
      this.setState({
        city: {
          label: data.city ? data.city.toUpperCase() : "",
          value: data.city ? data.city.toUpperCase() : "",
        },
      });
    }
  }
  
  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  <span>Stop Id</span>
                </label>
                <div className="col-sm-9 ">
                  {this.props.showInfo || this.props.updateSelection ? (
                    <span>{this.props.updateSelection.stopId}</span>
                  ) : (
                    <span>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        id="stopId"
                        placeholder="Enter stopId"
                        onChange={(value) => {
                          this.setState(
                            { stopId: value.target.value.trim() },
                            () => {
                              this.updateFormErrorState();
                            }
                          );
                        }}
                        defaultValue={
                          this.props.updateSelection
                            ? this.props.updateSelection.stopId
                            : ""
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Stop Name
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      id="stopName"
                      placeholder="Enter Stop Name"
                      onChange={(value) => {
                        this.setState({ stopName: value.target.value.trim() });
                      }}
                      defaultValue={
                        this.props.updateSelection
                          ? this.props.updateSelection.stopName
                          : ""
                      }
                    />
                  ) : (
                    <span>{this.props.updateSelection.stopName}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  <span>City</span>
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <Select
                      closeOnSelect={!this.state.stayOpen}
                      disabled={this.state.disabled}
                      onChange={(value) => {
                        this.onCityChange(value);
                      }}
                      options={this.state.cities}
                      placeholder="Select City"
                      clearable={false}
                      value={this.state.city}
                    />
                  ) : (
                    this.props.updateSelection.city
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Native Language
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <Select
                      closeOnSelect={!this.state.stayOpen}
                      disabled={this.state.disabled}
                      onChange={(value) => {
                        this.onLanguageChange(value);
                      }}
                      options={this.state.stopLanguages}
                      placeholder="Select Native Language"
                      clearable={false}
                      value={this.state.stopNativeLanguage}
                    />
                  ) : (
                    this.props.updateSelection.stopNativeLanguage
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Native Name
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      id="stopNativeName"
                      placeholder="Enter Stop Native Name"
                      onChange={(value) => {
                        this.setState({
                          stopNativeName: value.target.value.trim(),
                        });
                      }}
                      disabled={!this.state.stopNativeLanguage}
                      defaultValue={
                        this.props.updateSelection
                          ? this.props.updateSelection.stopNativeName
                          : ""
                      }
                    />
                  ) : (
                    <span>{this.props.updateSelection.stopNativeName}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Latitude
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      id="latitude"
                      placeholder="Enter Stop latitude"
                      onChange={(value) => {
                        this.setState({
                          latitude: value.target.value.trim(),
                        });
                      }}
                      defaultValue={
                        this.props.updateSelection
                          ? this.props.updateSelection.latitude
                          : ""
                      }
                    />
                  ) : (
                    <span>{this.props.updateSelection.latitude}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Longitude
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      id="longitude"
                      placeholder="Enter Stop longitude"
                      onChange={(value) => {
                        this.setState({
                          longitude: value.target.value.trim(),
                        });
                      }}
                      defaultValue={
                        this.props.updateSelection
                          ? this.props.updateSelection.longitude
                          : ""
                      }
                    />
                  ) : (
                    <span>{this.props.updateSelection.longitude}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            {!this.props.showInfo && (
              <Button
                bsStyle="primary"
                onClick={() => {
                  this.setState({ loading: true });
                  this.fetchAddUpdate();
                }}
                disabled={this.state.form.invalid || this.state.loading}
              >
                {this.state.loading ? "Saving..." : "Save changes"}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }

  /**
   * Used to Add/Update Vehicle
   */
  fetchAddUpdate() {
    createOrUpdateStop(this.props.updateSelection, this.state, this.props.email)
      .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred "
          );
        }
      });
  }

  fetchMasterData(masterType, masterParentId, options) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000, null, "True")
      .then((res) => {
        if (this.mounted) {
          this.setState({
            [options]: res.content.map((data) => ({
              label: data.name,
              value: data,
            })),
          });
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.setState({ citiesOptions: [] });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching master data",
          });
        }
      });
  }

  fetchStopNativeLanguage() {
    fetchStopNativeLanguage()
      .then((res) => {
        this.setState({
          stopLanguages: res.map((data) => ({
            label: data,
            value: data,
          })),
        });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.setState({ citiesOptions: [] });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching master data",
          });
        }
      });
  }

  fetchMasterData(masterType, masterParentId, options) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000, null, "True")
      .then((res) => {
        if (this.mounted) {
          this.setState({
            [options]: res.content.map((data) => ({
              label: data.name,
              value: data,
            })),
          });
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.setState({ citiesOptions: [] });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching master data",
          });
        }
      });
  }

  /**
   *on city change reset agencyOptions and depot
   *
   */
  onCityChange(value) {
    if (value) {
      this.setState(
        {
          city: { label: value.label, value: value.label },
        },
        () => {
          this.updateFormErrorState();
        }
      );
    }
  }

  onLanguageChange(value) {
    if (value) {
      this.setState(
        {
          stopNativeLanguage: value.value,
        },
        () => {
          this.updateFormErrorState();
        }
      );
    }
  }

  /**
   * validates device from data
   * @param target
   */
  updateFormErrorState() {
    let formState = validateStops(this.state);
    this.setState({
      form: formState,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }
}

export default CreateStopDetailView;
