import React, {Component} from "react";
import {Modal, Button} from "react-bootstrap";
import {MessageType} from "../../../Constant";
import '../../components/confirmationModal/confirmationModal.css'


class ModalWrapper extends Component {

    render() {
        const {showModal, title, message, messageCallBack, toggler, bodyRender, titleRender, errorBtnText, successBtnText, disableSuccess, wrapperClassName, ...rest} =this.props;
        const backdrop = 'static';
        return (
            <div className={`modal-wrapper ${wrapperClassName}`}>
                {toggler && toggler()}
                <Modal show={showModal} onHide={()=>messageCallBack(null, null)} {...rest} >
                    <div className={titleRender ? 'title-view' : ''}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div>{title}</div>
                            </Modal.Title>
                            {titleRender ? titleRender() : null}
                        </Modal.Header></div>
                    <Modal.Body>
                        <div> {bodyRender ? bodyRender() : message}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="confirmation-modal">
                            {errorBtnText && <Button className="error-button" onClick={()=> {
                                messageCallBack(MessageType.ERROR, null)
                            }}>{errorBtnText || 'No'}
                            </Button>}
                            {successBtnText &&
                            <Button className="success-button" bsStyle="primary" disabled={disableSuccess}
                                    onClick={()=> {
                                        messageCallBack(MessageType.SUCCESS, null)
                                    }}>{successBtnText || 'Yes'}
                            </Button>}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}


export default ModalWrapper;


