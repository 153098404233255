import {actions} from './action-list';
export function matrixFetched(value) {
    return {
        type: actions.DELAY_MATRIX.DATA_FETCHED,
        value: value
    }
}
export function changePage(value) {
    return {
        type: actions.DELAY_MATRIX.CHANGE_PAGE,
        value: value
    }
}
export function changeSort(value) {
    return {
        type: actions.DELAY_MATRIX.CHANGE_SORT,
        value: value
    }
}
export function applySearch(value) {
    return {
        type: actions.DELAY_MATRIX.SEARCH,
        value: value
    }
}
