import initialState from  "./initialState";
import {actions} from "../../actions/action-list";
import {sortData} from "../../../shared/widgets/sortingView/sorting";
import {filterData} from "../../../shared/utils";
function DelayMatrix(state = initialState, action) {
    let newState = state;
    switch (action.type) {
        case actions.DELAY_MATRIX.DATA_FETCHED:
            let sortedData = action.value;
            if (state.sort) {
                sortedData = sortData(action.value, state.sort.key, state.sort.order);
            }
            newState = {
                data: action.value,
                displayData: sortedData,
                search: ''
            };
            if (state.sort) {
                newState.sortedData = sortedData
            }
            return Object.assign({}, state, newState);
        case actions.DELAY_MATRIX.CHANGE_SORT:
            if (state.data) {
                /**
                 * update sort on filtred data
                 */
                let data;
                if (state.search) {
                    data = [...state.filteredData]
                } else {
                    data = [...state.data]
                }
                let sortedData = sortData(data, action.value.key, action.value.order);
                let futureState = {
                    displayData: [...sortedData],
                    sort: action.value
                };
                /**
                 * update complete sorted data
                 */
                if (!state.search) {
                    futureState.sortedData = sortedData
                } else {
                    futureState.sortedData = sortData(state.data, action.value.key, action.value.order);
                }
                newState = Object.assign({}, state, futureState)
            } else {
                newState = Object.assign({}, state, {
                    sort: action.value
                })
            }
            return newState;
        case actions.DELAY_MATRIX.SEARCH:
            if (state.data) {
                let data;
                if (state.search && action.value.startsWith(state.search)) {
                    data =[...state.filteredData]
                } else if (state.sortedData) {
                    data = [...state.sortedData]
                } else {
                    data = [...state.data];
                }
                let filteredData = filterData(data, 'regionId', action.value);
                newState = Object.assign({}, state, {
                    filteredData: filteredData,
                    displayData: filteredData,
                    search: action.value,
                })
            } else {
                newState = Object.assign({}, state, {
                    search: action.value
                })
            }
            return newState;
        default:
            return state;
    }
}

export default DelayMatrix;