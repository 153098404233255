import React, {Component} from "react";
import {FilterView} from "../../../shared/widgets";
import {changeTimeParse, changeTimeInterval} from "../../../redux/actions/settingActions";
import {connect} from "react-redux";
import {timeIntervals, timeParsing} from "../../../Constant";

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderBUtton: false
        }
    }

    render() {
        const {hideTimeParse}=this.props;
        return (<div>
                {!hideTimeParse && <div className="row setting-item">
                    <div className="col-sm-6 "> Display time in :</div>
                    <div className="col-sm-6">
                        <FilterView
                            selectedValue={this.props.timeParse}
                            title="Time Parse"
                            menues={timeParsing} onSelect={(value)=> {
                            this.setTimeAs("timeParse", value.key)
                        }}/>
                    </div>
                </div>}
                <div className="row setting-item">
                    <div className="col-sm-6"> Fetch stats for :</div>
                    <div className="col-sm-6">
                        <FilterView
                            selectedValue={this.props.timeInterval}
                            title="Time Parse"
                            menues={timeIntervals} onSelect={(value)=> {
                            this.setTimeAs("timeInterval", value.key)
                        }}/>
                    </div>
                </div>
            </div>
        );
    }

    setTimeAs(key, value) {
        if (key == 'timeParse') {
            this.props.timeParseChange(value);
        } else if (key == 'timeInterval') {
            this.props.timeIntervalChange(value);
        }

    }

}

function mapStateToProps(state) {
    return {
        timeParse: state.Settings.timeParse,
        timeInterval: state.Settings.timeInterval
    };

}
const mapDispatchToProps = dispatch => {
    return {
        timeParseChange: value => {
            dispatch(changeTimeParse(value))
        },
        timeIntervalChange: value=> {
            dispatch(changeTimeInterval(value))
        }
    }
};
export  default  connect(mapStateToProps, mapDispatchToProps)(SettingsPage);