import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "./styles/main.css";
import store from "./redux/createStore";

ReactDOM.render(<Provider store={store}><BrowserRouter>
    <App/>
</BrowserRouter></Provider>, document.getElementById('root'));
registerServiceWorker();
