import React from "react";
import {templateStrings} from "../../../../Constant";
export const DelayHeader = (rest)=> {
    return (
        <div><span
            className="protocol">{templateStrings.delay}({rest.timeParse})&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
                className="protocol-list">({templateStrings.home.avg_parsing_time}+{templateStrings.home.avg_response_time}) </span>
        </div>
    )
};
export const OverAllDelayHeader = (rest)=> {
    return (
        <div><span>{templateStrings.overAllDelay}({rest.timeParse})</span>
        </div>
    )
};