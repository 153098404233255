import {MessageType} from "../../Constant";
import {setTokenExpired} from "../actions/authActions";
import {setNotificationMessage} from "../actions/notificationActions";
import {timeZoneRegions} from "../../services/regionService";
import {timezonesFetched} from "../actions/timezoneActions";
export function createTimezoneListAction() {
    return (dispatch)=> {
        timeZoneRegions().then((response) => {
            dispatch(timezonesFetched(response));
        }).catch((err) => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                dispatch(setTokenExpired(true))
            } else {
                if (err.status != 204) dispatch(setNotificationMessage({
                    type: MessageType.ERROR,
                    message: err.description ? err.description : "Error while fetching timezones"
                }));
            }
            console.log(err);
        });
    }
}