import React from "react";
import {NavLink} from "react-router-dom";
import {BetaVersion} from "./../../widgets";
import PropTypes from "prop-types";
import { userAuthoritiesArray} from "../../../services/authorization/Authorization"


export class MultilevelLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parentActive: false,
            isSubMenuOpen:false
        };
        this.checkIfParentActive = this.checkIfParentActive.bind(this);
    }

    componentDidMount() {
        this.checkIfParentActive(this.props.location.pathname);
    }

    /**
     * checks if parent link is active
     * @param url
     */
    checkIfParentActive(url) {
        this.setState({parentActive: url && url.includes(this.props.root.path)});
    }

    componentWillReceiveProps(nextProps) {
        this.checkIfParentActive(nextProps.location.pathname);
    }

    checkRole(role,child){
        if(child.path == "smsStatus" && !(role.toString() == 'Manager' || role.toString() == 'Admin') ){
            return false;
        }else if(child.path == "deviceConfig" && role.toString() != 'Admin'){
            return false;
        }
        else{
            return true;
        }
    }

    render() {
        const {root, childs, isBeta}=this.props;
        return <li>
           <div className="sub-menu-container"   onMouseLeave={()=>{ this.setState({ isSubMenuOpen:false})}}>
               <div className={this.state.parentActive ? "icon-container active-link " : "icon-container"}>
                   <span className={`sidebar-icon ${root.icon}`}/></div>
               <span>{root.title} {isBeta && <BetaVersion/>}</span>
            {  !this.state.isSubMenuOpen && <span className="navlink-arrow"  onClick={() => this.setState({ isSubMenuOpen:true})}>
               <i className="fa fa-angle-down" aria-hidden="true" />
               </span>}
              { this.state.isSubMenuOpen && <span className="navlink-arrow"  onClick={() => this.setState({ isSubMenuOpen:false})}>
               <i className="fa fa-angle-up" aria-hidden="true" />
               </span>}
               { this.state.isSubMenuOpen && <div className="sub-menu">
                   {childs.map(child=> {
                        let authority = userAuthoritiesArray();
                        if(this.checkRole(authority,child)){
                       return <NavLink key={child.path} onClick={this.props.toggleSideBarState}
                                       to={`${root.path}/${child.path}`}
                                       activeClassName="active-link">
                            <span>{child.title}</span>
                       </NavLink>
                   }
                   })}
               </div>}
           </div>

        </li>
    }

}
MultilevelLink.defaultProps = {
    isBeta: false
};
MultilevelLink.propTypes = {
    root: PropTypes.object.isRequired,
    childs: PropTypes.array.isRequired,
    isBeta: PropTypes.bool,
    toggleSideBarState: PropTypes.func,
    location: PropTypes.object

};