import { actions } from "../../actions/action-list";
const initialState = {
  selectedCity: "",
  selectedDepot:''
};
function CityReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHANGE_CITY:
      return { ...state, selectedCity: action.value };
    case actions.CHANGE_DEPOT:
        return { ...state, selectedDepot: action.value };
    default:
      return state;
  }
}
export default CityReducer;
