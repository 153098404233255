import React from "react";
import {GraphListItem} from "../list-item/reportListItem";
import {TableWrapper} from "../../../../../shared/layout";
import {TableLoader} from "../../../../../shared/widgets";
import {
    viewListfrequencyByRegionTemplateStrings
} from "../../utils";
export const ViewListFrequency = (props)=> {

    const templateStrings = viewListfrequencyByRegionTemplateStrings;
    var i=0;
    let color=['class-pink','class-blue','class-green','class-yellow','class-red','class-orange','class-purple']
    return (
        <TableWrapper id="report-list">
            <TableWrapper.Header columns={templateStrings} onSortToggle={props.onSortChange} type={'deviceFrequency'}/>
            {props.loading && <TableLoader loading={props.loading}
                                           colSpan={Object.keys(templateStrings).length}/>}
            {!props.loading && <tbody>
            {props.content && props.content.map((data, index) => {
                return <GraphListItem key={index} item={data} columns={templateStrings} color={color[i++]}
                                 keyId="vehicleNo" keyId2="deviceId"/>;
            })}
            {(!props.content || (props.content && props.content.length == 0)) &&
            <tr className="gradeA odd noDataStyle" role="row">
                <td className="sorting_1"
                    colSpan="6">{!props.content ? "No Data Found" : "GPS Matrix service is not enabled!"}</td>
            </tr>
            }
            </tbody>}
        </TableWrapper>)
};