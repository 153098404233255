import React from 'react';
import {SelectValue} from "./form-views";
import {deviceIgnitionOffInterval, deviceIgnitionOnInterval} from "../utils";

export class SelectIgnitionInterval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ignOnInterval: 10,
            ignOffInterval: 1
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    render() {
        return <div>
            <SelectValue useData={true} title="Ignition Off" keyValue="ignOffInterval" data={deviceIgnitionOffInterval}
                         selectedValue={this.state.ignOffInterval} onSelect={this.handleSelect} unit="Min"/>
            <SelectValue useData={true} title="Ignition On " keyValue="ignOnInterval" data={deviceIgnitionOnInterval}
                         selectedValue={this.state.ignOnInterval} onSelect={this.handleSelect} unit="Sec"/>
        </div>
    }

    /**
     * handles value change events
     * @param key
     * @param value
     */
    handleSelect(key, value) {
        let state = {};
        state[key] = value;
        this.setState(state, ()=> {
            this.props.onValueChange(this.state);
        })
    }
}

