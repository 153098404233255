import React from 'react';
import {ResponsiveLine} from '@nivo/line';
import {lineGraphData} from "../../utils";
import {markerLineColor} from "../../utils";

export class LineGraphComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            axisLeftTickValues: [],
        };
    }

    componentDidMount() {
        this.addMarker(this.props.data, this.props.threshold);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.data != nextProps.data) {
            this.addMarker(nextProps.data, this.props.threshold);

        }
        if (this.props.threshold != nextProps.threshold) {
            this.addMarker(this.props.data, nextProps.threshold);
        }
    }

    /**
     * addMarker to graph only when you have graph data atleast for single line
     * @param data
     */
    addMarker(data, threshold) {
        if (data && data[0] && data[0].data && data[0].data.length) {
            let axisBottomTickValues;
            if (data[0].data.length < 10) {
                axisBottomTickValues = data[0].data.length;
            }
            let maxValue = this.getMaxValue(data, threshold);
            let totalGrids = 4;
            let interval = Math.round(maxValue / totalGrids);
            let a = [0, interval, 2 * interval, 3 * interval];
            this.setState({
                marker: [{
                    axis: 'y',
                    value: threshold,
                    lineStyle: {stroke: markerLineColor, strokeWidth: 2, strokeDasharray: '5,5'}
                }],
                yScale: {type: 'linear', stacked: false, max: maxValue},
                axisLeftTickValues: a,
                axisBottomTickValues: axisBottomTickValues
            })
        }
    }


    /**
     * gets max value of data including threshold
     * @param data
     * @param threshold
     * @returns {number}
     */
    getMaxValue(data, threshold) {
        let maxValue;
        if (data[0].data.length == 1) {
            maxValue = data[0].data[0].y
        }
        else {
            maxValue = data[0].data.reduce((a = 0, b)=> {
                let p;
                if (typeof a == 'object') {
                    p = a.y
                } else {
                    p = a;
                }
                return Math.max(p, b.y);
            });
        }
        let final = Math.max(threshold, maxValue);
        return final;
    }


    render() {
        return <ResponsiveLine
            data={this.props.data}
            margin={{
                top: 20,
                right: 20,
                bottom: 50,
                left: 40
            }}
            minY="auto"
            maxY="auto"
            animate
            xScale={{type: 'time', format: '%Y-%m-%d', precision: 'day'}}
            yScale={this.state.yScale || {type: 'linear', stacked: false}}
            axisBottom={{
                format: '%b %d',
                "tickSize": 0,
                "tickPadding": 15,
                "tickValues": this.state.axisBottomTickValues
            }}
            axisLeft={{
                "tickSize": 0,
                "tickPadding": 15,
                "tickValues": this.state.axisLeftTickValues
            }}
            curve="monotoneX"
            enableDotLabel={false}
            enableGridX={false}
            colorBy={(e)=> {
                return e.color
            }}
            markers={this.state.marker}
            gridYValues={this.state.axisLeftTickValues}
        />
    }
}
