import React from "react";
import { Button } from "react-bootstrap";
import validateBSDDevice from "../../validator/BSDDeviceValidator";
import { createOrUpdateDevice } from "../../../../../services/BSDService";
import { MessageType } from "../../../../../Constant";

export class CreateBSDDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId:
        this.props.updateSelection && this.props.updateSelection.deviceId
          ? this.props.updateSelection.deviceId
          : "",
      stopId:
        this.props.updateSelection &&
        this.props.updateSelection.deviceStop &&
        this.props.updateSelection.deviceStop.stopId
          ? this.props.updateSelection.deviceStop.stopId
          : "",
      routeId:
        this.props.updateSelection &&
        this.props.updateSelection.routeIds
          ? this.FilterRouteIds(this.props.updateSelection.routeIds)
          : "",
      form: {},
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateFormErrorState();
  }

  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  <span>Device Id</span>
                </label>
                <div className="col-sm-9 ">
                  {this.props.showInfo || this.props.updateSelection ? (
                    <span>{this.props.updateSelection.deviceId}</span>
                  ) : (
                    <span>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        id="deviceId"
                        placeholder="Enter Vehicle No"
                        onChange={(value) => {
                          this.setState(
                            { deviceId: value.target.value.trim() },
                            () => {
                              this.updateFormErrorState();
                            }
                          );
                        }}
                        defaultValue={
                          this.props.updateSelection
                            ? this.props.updateSelection.deviceId
                            : ""
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Stop Id
                </label>
                <div className="col-sm-9">
                  {!this.props.showInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      id="stopId"
                      placeholder="Enter Stop Id"
                      onChange={(value) => {
                        this.setState({ stopId: value.target.value.trim() });
                      }}
                      defaultValue={
                        this.props.updateSelection &&
                        this.props.updateSelection.deviceStop &&
                        this.props.updateSelection.deviceStop.stopId
                          ? this.props.updateSelection.deviceStop.stopId
                          : ""
                      }
                    />
                  ) : (
                    <span>{this.props.updateSelection.deviceStop.stopId}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-3 form-label"
                >
                  Route Ids
                </label>
                <div className="col-sm-9">
                  <div>
                    {!this.props.showInfo ? (
                      <input
                        type="text"
                        className="form-control"
                        id="routeId"
                        placeholder="Enter Route Ids"
                        onChange={(value) => {
                          this.setState({ routeIds: value.target.value.trim() });
                        }}
                        defaultValue={
                          this.props.updateSelection &&
                          this.props.updateSelection.routeIds
                            ? this.FilterRouteIds(
                                this.props.updateSelection.routeIds
                              )
                            : ""
                        }
                      />
                    ) : (
                      <span>
                        {this.props.updateSelection &&
                        this.props.updateSelection.routeIds
                          ? this.FilterRouteIds(
                              this.props.updateSelection.routeIds
                            )
                          : ""}
                      </span>
                    )}
                    <span className="info-text">
                      Multiple RouteIds should be comma separated.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            {!this.props.showInfo && (
              <Button
                bsStyle="primary"
                onClick={() => {
                  this.setState({ loading: true });
                  this.fetchAddUpdate();
                }}
                disabled={this.state.form.invalid || this.state.loading}
              >
                {this.state.loading ? "Saving..." : "Save changes"}
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }

  /**
   * Used to Add/Update Vehicle
   */
  fetchAddUpdate() {
    createOrUpdateDevice(
      this.props.updateSelection,
      this.state,
      this.props.email
    )
      .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.onComplete(MessageType.ERROR, "Invalid token");
        } else if (this.mounted) {
          this.props.onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred "
          );
        }
      });
  }

  /**
   * validates device from data
   * @param target
   */
  updateFormErrorState() {
    let formState = validateBSDDevice(this.state);
    this.setState({
      form: formState,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  FilterRouteIds(val) {
    return val.toString();
  }
}

export default CreateBSDDevice;
