/**
 * Main container
 * imported in App.js
 */
import React, { Component } from "react";
import Button from "./../../widgets/button";
import "./pageHeader.css";
class PageHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    return (
      <div className="page-header">
        <h3>
          {props.heading}
          {!props.hideS && <span>s</span>}
        </h3>
        {this.props.showButton && (
          <Button
            onClick={this.props.onAdd}
            className="add-icon"
            buttonText={`
                                New ${
                                  this.props.title
                                    ? this.props.title
                                    : this.props.heading
                                }`}
          />
        )}
        {this.props.children != 0 && this.props.children}
        <div className="clear-float"></div>
      </div>
    );
  }
}

export default PageHeader;
