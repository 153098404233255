import React from "react";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../redux/map-to-props";
import { PageWrapper, TableWrapper } from "../../../shared/layout";
import { templateStrings, MessageType, dateFormat } from "../../../Constant";
import { Modal, ButtonToolbar } from "react-bootstrap";
import {
  fetchCronTrigger,
  getEventTrigger,
} from "../../../services/cronTriggerService";
import { CronCreate } from "./views/create/createView";
import { AuthenticationService } from "../../../services/authorization";
import {
  Paginator,
  Search as SearchBar,
  FilterView,
} from "../../../shared/widgets";
import uuid from "uuid";
import validateCron from "./validator/cronValidator";
import { EpochtoEpochTZ } from "../../../shared/utils";
import { SelectFilter } from "../../../shared/widgets/selectFilter/selectFilter";
import * as moment from "moment";
import { fetchRegions } from "../../../services/regionService";

class CronTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    this.state = {
      showHeaderButton: true,
      pageNo: 0,
      totalPages: 0,
      selectedEvent: "",
      eventTriggerOptions: [],
      regionOptions: [],
      backdrop: false,
      placeHolder: "Search",
      searchString: "",
      sortOrder: "DESC",
      sortKey: "createdAt",
      filterStatus: templateStrings.cronTriggerStatus.ALL,
      region: "",
    };
    this.getList = this.getList.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleDataSorting = this.handleDataSorting.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchTriggerEvent();
    this.fetchRegions();
  }

  fetchRegions() {
    fetchRegions()
      .then((res) => {
        if (res && res.items) {
          const orderedNewOptions = res.items.sort((a, b) => {
            return a.regionId > b.regionId
              ? 1
              : b.regionId > a.regionId
              ? -1
              : 0;
          });
          let all_regions = [];
          orderedNewOptions.forEach((item) => {
            all_regions.push({ name: item.regionId });
          });
          this.setState({
            regionOptions: all_regions,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
        this.props.initiateNotification({
          type: "error",
          message: err.description
            ? err.description
            : "Error while fetching regions",
        });
      });
  }

  fetchTriggerEvent() {
    getEventTrigger()
      .then((response) => {
        let all_event = [];
        response.forEach((item) => {
          all_event.push({ name: item });
        });
        this.setState(
          {
            eventTriggerOptions: all_event,
            selectedEvent: "Point_trigger",
          },
          () => {
            this.fetchCronDetails(
              this.state.selectedEvent,
              this.state.searchString,
              this.state.sortKey,
              this.state.sortOrder,
              this.state.filterStatus.value,
              this.state.region,
              0
            );
          }
        );
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.props.updateLoader(false);
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching events",
          });
        }
      });
  }

  render() {
    return (
      <div>
        <PageWrapper.Header
          heading="Cron Trigger"
          onAdd={this.open.bind(this, null)}
          showButton={this.state.showHeaderButton}
          hideS={true}
        >
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container">
              <div className="form-group">
                <p className="filters-label">Filter By Event:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <SelectFilter
                      value={this.state.selectedEvent}
                      onChange={(value) => {
                        this.handleEventChange(value);
                      }}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Event"
                      options={this.state.eventTriggerOptions}
                    />
                    Status:
                    <FilterView
                      selectedValue={this.state.filterStatus.value}
                      title="Status Filter"
                      menues={templateStrings.cronTriggerStatus}
                      onSelect={(value) => {
                        this.handleStatusFilter(value);
                      }}
                    />
                    Region:
                    <SelectFilter
                      value={this.state.region}
                      onChange={(value) => {
                        this.handleRegionChange(value);
                      }}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Region"
                      options={this.state.regionOptions}
                      clearable={true}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={templateStrings.cronTrigger}
              onSortToggle={this.handleDataSorting}
              type={"cronTrigger"}
            />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.map((data) => {
                    return this.displayList(data);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          <Paginator
            pageNo={this.state.pageNo}
            totalPages={this.state.totalPages}
            getList={this.getList}
          />
          {this.displayModal()}
        </PageWrapper.Body>
      </div>
    );
  }

  handleSearchChange(key) {
    this.setState(
      {
        searchString: key,
      },
      () => {
        this.fetchCronDetails(
          this.state.selectedEvent,
          this.state.searchString,
          this.state.sortKey,
          this.state.sortOrder,
          this.state.filterStatus.value,
          this.state.region,
          this.state.pageNo
        );
      }
    );
  }

  /**
   * sort data for the giver key in given order
   * @param order
   * @param key
   */
  handleDataSorting(order, key) {
    this.setState(
      {
        sortOrder: order,
        sortKey: key,
      },
      () => {
        this.fetchCronDetails(
          this.state.selectedEvent,
          this.state.searchString,
          this.state.sortKey,
          this.state.sortOrder,
          this.state.filterStatus.value,
          this.state.region,
          this.state.pageNo
        );
      }
    );
  }

  handleStatusFilter(filterStatus) {
    this.setState({ filterStatus: filterStatus }, () => {
      this.fetchCronDetails(
        this.state.selectedEvent,
        this.state.searchString,
        this.state.sortKey,
        this.state.sortOrder,
        this.state.filterStatus.value,
        this.state.region,
        this.state.pageNo
      );
    });
  }

  fetchCronDetails(event, searchKey, sortKey, sortOrder, filterStatus,region, pageNo) {
    this.props.updateLoader(true);
    fetchCronTrigger(event, searchKey, sortKey, sortOrder, filterStatus,region, pageNo)
      .then((res) => {
        if (this.mounted)
          this.setState({
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
          });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.props.updateLoader(false);
          this.setState({ content: [], totalPages: 0, pageNo: 0 });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching details",
          });
        }
      });
  }

  handleEventChange(event) {
    this.setState(
      { selectedEvent: event && event.name ? event.name : "" },
      () => {
        this.fetchCronDetails(
          this.state.selectedEvent,
          "",
          this.state.sortKey,
          this.state.sortOrder,
          this.state.filterStatus.value,
          this.state.region,
          this.state.pageNo
        );
      }
    );
  }

  handleRegionChange(region) {
    this.setState(
      { region: region && region.name ? region.name : "" },
      () => {
        this.fetchCronDetails(
          this.state.selectedEvent,
          "",
          this.state.sortKey,
          this.state.sortOrder,
          this.state.filterStatus.value,
          this.state.region,
          this.state.pageNo
        );
      }
    );
  }
  /**
   * Call when Add/Update modal Open.
   */
  open(data) {
    this.setState({
      updateSelection: data,
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal() {
    return (
      <Modal
        show={this.state.showModal || this.state.showInfo}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Add New Cron" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CronCreate
              onComplete={(e, msg) => {
                if (e == MessageType.SUCCESS) {
                  this.fetchCronDetails(
                    this.state.selectedEvent,
                    "",
                    this.state.sortKey,
                    this.state.sortOrder,
                    this.state.filterStatus.value,
                    this.state.region,
                    0
                  );
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                } else if (e === MessageType.ERROR && msg === "Invalid token") {
                  this.props.setTokenExpired(true);
                } else {
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                }

                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * validates trip form data
   * @param target
   */
  updateFormErrorState() {
    let formState = validateCron(this.state);
    this.setState({
      form: formState,
    });
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
      showInfo: false,
      updateSelection: null,
      value: [],
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * API call to get devices.
   * @param pageNo current page number
   */
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchCronDetails(
        this.state.selectedEvent,
        this.state.searchString,
        this.state.sortKey,
        this.state.sortOrder,
        this.state.filterStatus.value,
        this.state.region,
        pageNo
      );
    }
  }

  removeGMT(date) {
    var len = date.length;
    return date.slice(12, len).toUpperCase();
  }

  /**
   * Used to show device in row in List view
   * @param item  Object of item to display
   */
  displayList(item) {
    return (
      <tr key={uuid.v4()}>
        <td width={70}>
          <span className="protocol">{item.event ? item.event : "NA"}</span>
          <div className="protocol-list">{item.event ? item.event : "NA"}</div>
        </td>
        <td width={50}>
          <span className={item.triggerStatus ? "green-class" : "red-class"}>
            <a>
              <i className="fa fa-circle" aria-hidden="true"></i>
            </a>
          </span>
        </td>
        <td width={50}>
          <span className="protocol">
            {item.regionId ? item.regionId : "NA"}
          </span>
          <div className="protocol-list">
            {item.regionId ? item.regionId : "NA"}
          </div>
        </td>
        <td width={50}>
          <span className="protocol">
            {item.timeZone ? item.timeZone : "NA"}
          </span>
          <div className="protocol-list">
            {item.timeZone ? item.timeZone : "NA"}
          </div>
        </td>
        <td>
          {moment(
            new Date(
              EpochtoEpochTZ(
                parseInt(item.startTime),
                localStorage.getItem("timeZone")
              )
            )
          ).format(dateFormat)}
        </td>
        <td>
          {moment(
            new Date(
              EpochtoEpochTZ(
                parseInt(item.endTime),
                localStorage.getItem("timeZone")
              )
            )
          ).format(dateFormat)}
        </td>
        <td>
          <span className="protocol">
            {item.updatedBy ? item.updatedBy : "NA"}
          </span>
          <div className="protocol-list">
            {item.updatedBy ? item.updatedBy : "NA"}
          </div>
        </td>
        <td>
          {moment(
            new Date(
              EpochtoEpochTZ(
                parseInt(item.createdAt),
                localStorage.getItem("timeZone")
              )
            )
          ).format(dateFormat)}
        </td>
      </tr>
    );
  }
}
function mapStateToProps(state) {
  return {
    timezoneList: state.Timezones.timezones,
  };
}
export default connect(mapStateToProps, defaultMapDispatchToProps)(CronTrigger);
