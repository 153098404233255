export const routes = {
    HOME: '/',
    ALERTS:'/alerts',
    DEVICE: '/devices',
    VEHICLE: '/vehicles',
    BSD: '/BSD',
    LIVE_MAP: '/map',
    REPORT: '/reports',
    MASTER:'/vehicleMaster',
    REGION: '/region',
    VIEWMASTER:'/vehicleMasterView',
    PROTOCOL: '/protocol',
    CRON:'/cronTrigger',
    //undo comment to show Trips section
    // TRIP: '/trips'
};

export const developerRoutes = {
    SCHEDULER: 'schedulers',
    POST_ANALYSIS: 'postingAnalysis'
};

export const masterRoutes = {
    CITY: 'city',
    AGENCY: 'agency',
    OPERATOR:'operator',
    DEPOT:'depot'
};

export const extraRoutes = {
    LOGIN: '/login'
};




