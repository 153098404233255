const ENV = "development";
const operatorBasedVehicle = true;
const API = {
  BASE_URL: process.env.REACT_APP_DEV_API_BASE_URL,
  COA_BASE_URL: process.env.REACT_APP_DEV_COA_BASE_URL,
  GPS_MATRIX_STAG_URL: process.env.REACT_APP_DEV_GPS_MATRIX_STAG_URL,
  META_CALL_BASE_URL: process.env.REACT_APP_DEV_META_CALL_BASE_URL,
};
const google_properties = {
  auth:{
    baseUrl: process.env.REACT_APP_DEV_COA_BASE_URL,
    client_id: process.env.REACT_APP_DEV_AUTH_CLIENTID,
  },
  google:{
    clientId: process.env.REACT_APP_DEV_CLIENT_ID,
    clientSecret: process.env.REACT_APP_DEV_CLIENT_SECRET,
    redirectUri: process.env.REACT_APP_DEV_REDIRECT_URI,
  },
  map: {
    api_key: process.env.REACT_APP_DEV_GOOGLE_MAP_KEY,
  },
};
const cookieKeys = {
  user: process.env.REACT_APP_DEV_COOKIE_USER,
  loginState: process.env.REACT_APP_DEV_COOKIE_LOGIN_STATE,
};
export default {
  API,
  ENV,
  google_properties,
  operatorBasedVehicle,
  cookieKeys,
};
