import SettingsInitialState from "./settingsReducer/initialState";
import UserInitialState from "./userReducer/initialState";
import AuthInitialState from "./authReducer/initialState";
import NotificationInitialState from "./notificationReducer/initialState";
import SideBarToggleInitialState from "./sideToggleReducer/initialState";
import UserFormInitialState from "./userFormReducer/initialState";
import AlertInitialState from "./alertReducer/initialState";

const initialState = {
    Settings: SettingsInitialState,
    User: UserInitialState,
    Auth: AuthInitialState,
    Notification: NotificationInitialState,
    SideBarToggle: SideBarToggleInitialState,
    form: {},
    UserForm: UserFormInitialState,
    Alert:AlertInitialState
};
export default initialState;