import React from "react";
import './loaderView.css';

export const TableLoader = (props)=> {
    return (
        <tbody>
        <tr>
            <th colSpan={props.colSpan ? props.colSpan : 1}>
                <Loader loading={props.loading}/>
            </th>
        </tr>
        </tbody>
    )
};
export const Loader = (props)=> {
    if (props.loading)
        return (
            <div className="loader-container">
                <div className="md-preloader">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="50" width="50"
                         viewBox="0 0 50 50">
                        <circle cx="25" cy="25" r="21" strokeWidth="6"/>
                    </svg>
                </div>
            </div>
        )
    else return null;
};