const validateDepot = (formState)=> {
    let form = {};
    if (!formState.depot) {
        form._error = "Required field missing";
        form.invalid = true;
    }
    if (formState.depot && !formState.depot.match("^[a-zA-Z][a-zA-Z0-9' ]*$")) {
        form._error = "Invalid";
        form.invalid = true;
        form.depotNameError=true;
    }
    return form;
};
export  default validateDepot;