import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import uuid from "uuid";
import { MessageType } from "../../../../../Constant";
import { getRegionsForPanicReport } from "../../../../../services/vehicleMasterService";
import {
  createDeviceConfig,
  fetchCommandTypes,
  previewOfCommand,
} from "../../services/reportService";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import ValidateCreateDevice from "./ValidateCreateDevice";
import "./index.css";

export class CreateDeviceConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      removeSelected: true,
      rtl: false,
      regionOptions: [],
      selectedRegion: null,
      deviceId: "",
      command: "",
      commandType:"",
      commandTypeOptions:[],
      loading: false,
      isPreviewClick: false,
      tableData: [],
      isPreviewLoading: false,
      isPreviewButtonPresed: false,
    };
  }

  componentDidMount() {
    this.updateFormErrorState();
    this.fetchRegions();
    this.fetchCommandType()
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
fetchCommandType(){
  fetchCommandTypes()
  .then((res)=>{
    if(res && res.length){
      res=res.map((el)=>({label:el,value:el}))
      this.setState({
        commandTypeOptions:res
      })
    }
  })
}
  handleError = (err) => {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.initiateNotification({
        type: MessageType.ERROR,
        message: "Invalid token",
      });
    } else if (this.mounted) {
      this.props.initiateNotification({
        type: MessageType.ERROR,
        message:
          err.description || "Error occurred in while getting data for regions",
      });
    }
  };

  fetchRegions = () => {
    getRegionsForPanicReport()
      .then((res) => {
        if (res && res.items) {
          const orderedNewOptions = res.items.sort((a, b) => {
            if (a.regionId > b.regionId) {
              return 1;
            } else if (b.regionId > a.regionId) {
              return -1;
            } else {
              return 0;
            }
          });

          this.setState({
            regionOptions: orderedNewOptions.map((data) => {
              return {
                label: this.capitalize(data.regionId),
                value: data.regionId,
              };
            }),
            selectedRegion: {
              label: this.capitalize(this.props.selectedRegion),
              value: this.props.selectedRegion,
            }
          });
        } else {
          this.setState({ regionOptions: [] });
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  createDeviceConfiguration = () => {
    createDeviceConfig(this.state)
      .then(() => {
        this.setState({ loading: false });
        this.props.onComplete();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.initiateNotification({
          type: "error",
          message: err.description || "Error occured whil adding notification",
        });
      });
  };

  updateFormErrorState() {
    let formState = ValidateCreateDevice(this.state);
    this.setState({
      form: formState,
      isPreviewClick: false,
      isPreviewButtonPresed: false,
    });
  }

  fetchPreviewOfDeviceConfiguration = () => {
    const {commandType,commandTypeOptions,...rest}=this.state
    const data={...rest}
    previewOfCommand(data)
      .then((res) => {
        this.setState({ tableData: res, isPreviewLoading: false });
      })
      .catch((err) => {
        this.handleError(err);
        this.setState({ isPreviewLoading: false });
      });
  };

  render() {
    return (
      <>
        <div className="row">
          <form className="form-horizontal">
            <div className="col-sm-12 pr-3">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span className="region-label">Region</span>
                </label>
                <div className="col-sm-10">
                  <SelectFilter
                    value={this.state.selectedRegion}
                    onChange={(value) =>
                      this.setState({ selectedRegion: value }, () => {
                        this.updateFormErrorState();
                      })
                    }
                    type="city"
                    valueKey="value"
                    labelKey="label"
                    clearable={true}
                    placeholder="Select Region"
                    options={this.state.regionOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 pr-3">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span className="device-label">Device Id</span>
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ deviceId: e.target.value }, () => {
                        this.updateFormErrorState();
                      })
                    }
                    value={this.state.deviceId}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 pr-3">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span className="command-label">Cmd Type</span>
                </label>
                <div className="col-sm-10">
                <Select
                  value={this.state.commandType}
                  onChange={(e) => {
                    if(e){
                      this.setState({ commandType: e.value }, () =>
                        this.updateFormErrorState()
                      );
                    }else{
                      this.setState({commandType:''},()=>{this.updateFormErrorState()})
                    }
                  }}
                  options={this.state.commandTypeOptions}
                  placeholder="Select command Type"
                />
                </div>
              </div>
            </div>
            <div className="col-sm-12 pr-3">
              <div className="form-group">
                <label
                  htmlFor="name7"
                  className="control-label col-sm-2 form-label"
                >
                  <span>Command</span>
                </label>
                <div className="col-sm-10">
                  <div className="command-create-container">
                    <span>$command_id,device_id,password,</span>
                    <textarea
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ command: e.target.value }, () => {
                          this.updateFormErrorState();
                        })
                      }
                      value={this.state.command}
                      height="100px"
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.isPreviewLoading && (
              <div className="preview-loading">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            )}

            {this.state.isPreviewClick && this.state.tableData.length > 0 && (
              <div className="command-preview">Command Preview</div>
            )}
            <div className="scroll-container-1">
              {this.state.isPreviewClick && this.state.tableData.length > 0 && (
                <table className="editable-table-1">
                  <thead>
                    <tr>
                      <th>Command</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.tableData.map((row) => {
                      return (
                        <tr key={uuid.v4()}>
                          <td>{row.command}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="col-sm-12 form-view">
              <Button onClick={() => this.props.onClose()}>Close</Button>
              <Button
                className={!this.state.isPreviewClick?"custom-preview-button":""}
                bsStyle="primary"
                onClick={() => {
                  this.setState({ loading: true }, () => {
                    this.createDeviceConfiguration();
                  });
                }}
                disabled={!this.state.isPreviewClick}
              >
                {this.state.loading ? "Saving..." : "Save changes"}
              </Button>
              <Button
                className={(this.state.form.invalid || this.state.isPreviewButtonPresed)?"custom-preview-button":""}
                bsStyle="primary"
                onClick={() => {
                  this.setState(
                    {
                      isPreviewClick: true,
                      isPreviewLoading: true,
                      isPreviewButtonPresed: true,
                    },
                    () => {
                      this.fetchPreviewOfDeviceConfiguration();
                    }
                  );
                }}
                disabled={
                  this.state.form.invalid || this.state.isPreviewButtonPresed
                }
              >
                Preview
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }
}
