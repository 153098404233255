/**
 * Main container
 * imported in App.js
 */
import React, {Component,} from "react";
import  PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Switch, Redirect} from "react-router-dom";

import {Notification as NotificationComponent, BarLoader} from "./shared/widgets";
import {PageWrapper} from "./shared/layout";
import SideBar from "./views/Sidebar";
import {toggleSideBar} from "./redux/actions/sideToggleActions";
import {updateUserAuthorities} from "./redux/actions/authActions";
import {AuthenticationService, checkUserLoginState} from "./services/authorization";
import { onLogoutUser} from './services/login/loginService'
import {routes, extraRoutes} from "./routing/routes";
import {routeComponent} from "./routing/route-componentMapping";

class AuthenticatedWrapper extends Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        this.state = {
            toggleShow: this.props.toggleValue,
            redirectToLogin: false
        };
        this.toggleNavigation = this.toggleNavigation.bind(this);
        this.SideBarShow =  this.SideBarShow.bind(this);
        this.SideBarDontShow = this.SideBarDontShow.bind(this)
    }

    componentWillMount() {
        let isUserLoggedIn = checkUserLoginState();
        if (!isUserLoggedIn || isUserLoggedIn == "null") {
            AuthenticationService.destroyInstance();
            this.setState({redirectToLogin: true});
        } else {
            this.setState({
                viewPermission: this.authService.viewRights
            });
            this.props.updateUserAuthorities(this.authService.Authorities);
        }
    }

    componentDidMount() {
        /**
         * Used to get reference of notificationSystem into "this".
         */
        this._notificationSystem = this.refs.notificationSystem;
    }

    componentWillReceiveProps(nextProps) {
        const {notificationMessage, toggleValue}=nextProps;
        if (notificationMessage != this.props.notificationMessage) {
            this._notificationSystem._addNotification(notificationMessage.type, notificationMessage.message)
        }
        // if (toggleValue != this.state.toggleShow) {
        //     this.setState({
        //         toggleShow: toggleValue
        //     })
        // }
        if (this.props.isTokenExpired != nextProps.isTokenExpired) {
            // localStorage.clear()
            onLogoutUser()
            AuthenticationService.destroyInstance();
        }
    }

    /**
     * Sidebar Navigation
     */
    toggleNavigation() {
        this.props.toggleSideBarState(this.state.toggleShow);
        this.setState({toggleShow:!this.state.toggleShow})
    }

    SideBarShow() {
        this.setState({toggleShow:false})
        this.props.toggleSideBarState(this.state.toggleShow);
    }

    SideBarDontShow() {
        this.setState({toggleShow:true})
        this.props.toggleSideBarState(this.state.toggleShow);
    }

    moveToPanicReport = ()=>{
        this.props.history.push({
            pathname: `/alerts/panic-alerts`
        })
    }

    render() {
        const {redirectToLogin, viewPermission}=this.state;
        const {isTokenExpired, location}=this.props;
        if (redirectToLogin || isTokenExpired) {
            return <Redirect
                to={{
                    pathname: extraRoutes.LOGIN,
                    state: {from: this.props.location}
                }}
            />
        }
        return <div className={this.state.toggleShow ? 'nav-toggle' : '' }>
            <BarLoader />
            <PageWrapper>
                <SideBar toggleShow={this.state.toggleShow} SideBarShow={this.SideBarShow} SideBarDontShow={this.SideBarDontShow} toggle={this.toggleNavigation} location={location} moveToPanicReport={this.moveToPanicReport}/>
                <Switch>
                    {Object.keys(routes).map((key)=> {
                        if (viewPermission[routes[key]])
                            return <Route key={routes[key]} exact={routes[key] === routes.HOME} path={routes[key]}
                                          component={routeComponent[routes[key]]}/>;
                        else return null
                    })}
                </Switch>
            </PageWrapper>
            <NotificationComponent ref="notificationSystem"/>
        </div>
    }
}

AuthenticatedWrapper.propTypes = {
    isTokenExpired: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    notificationMessage: PropTypes.object.isRequired,
    toggleValue: PropTypes.bool.isRequired,
    toggleSideBarState: PropTypes.func.isRequired,
    updateUserAuthorities:PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isTokenExpired: state.Auth.isTokenExpired,
        notificationMessage: state.Notification.message,
        toggleValue: state.SideBarToggle.togglerValue
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateUserAuthorities: value => {
            dispatch(updateUserAuthorities(value))
        },
        toggleSideBarState: (value)=> {
            dispatch(toggleSideBar(value))
        }
    }
};
export  default  connect(mapStateToProps, mapDispatchToProps)(AuthenticatedWrapper);
