import React from "react";
import { Button } from "react-bootstrap";
import { MessageType } from "../../../../../Constant";
import { createMasterData,fetchCountryData } from "../../../../../services/vehicleMasterService";
import validateCity from "../../validator/cityValidator"
import {SelectFilter} from '../../../../../shared/widgets/selectFilter/selectFilter';
import countryTimezone from 'country-timezone';

export class CityCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            validCity: true,
            countriesOptions:[],
            selectedCountry: this.props.country,
            timezoneOptions:[],
            selectedTimeZone:'',
            timeOffsetMs:''
        };
    }

    componentWillMount() {
        this.fetchCountryData();
        this.fetchTimeZoneData();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.updateSelection) {
            let data = this.props.updateSelection
            this.setState({
                selectedCountry: data.country ? {'name':data.country,'id':data.countryObj.id} : '',
                id: data.id ? data.id : '',
                city:data.name?data.name:'',
                selectedTimeZone:data.timeZone ? {'name':data.timeZone}: '',
                timeOffsetMs:data.timeOffsetMs ? data.timeOffsetMs :'NA'
            }, () => {
                this.updateFormErrorState()
            })
        }
        this.updateFormErrorState()
    }

    fetchCountryData() {
        fetchCountryData().then((res => {
            let all_countries = [];
            res['items'].forEach((item) => {
                all_countries.push({id:item.id, name: item.name});
            });
            if (this.mounted) {
                this.setState({ countriesOptions: all_countries }, () => {
                });
            }
        })).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ countriesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching countries"
                });
            }        });
    }


    fetchTimeZoneData() {
        const timezonesWithCountryName = countryTimezone.getTimezones(this.state.selectedCountry.name);
        let all_timezones = [];
        timezonesWithCountryName.forEach((item) => {
            all_timezones.push({ name: item });
        });
        this.setState({ timezoneOptions: all_timezones });
    }

    render() {
        return (
            <div className="row">
                <form className="form-horizontal">
                <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>Country</span></label>
                            <div className="col-sm-10">
                            <SelectFilter
                            type='country'
                            value={this.state.selectedCountry}
                            onChange={(value) => {
                            this.onCountryChange(value)
                            }}
                            valueKey="name"
                            labelKey="name"
                            placeholder="Country"
                            options={this.state.countriesOptions}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>City</span></label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="city"
                                    placeholder="Enter City"
                                    readOnly={this.props.updateSelection}
                                    onChange={(value) => {
                                        this.setState({ city: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.name : ""}
                                />
                                {!this.state.validCity && <span className="error-text">Invalid city name</span>}

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>TimeZone</span></label>
                            <div className="col-sm-10">
                            <SelectFilter
                            type='timezone'
                            value={this.state.selectedTimeZone}
                            onChange={(value) => {
                            this.onTimeZoneChange(value)
                            }}
                            valueKey="name"
                            labelKey="name"
                            placeholder="Timezone"
                            options={this.state.timezoneOptions}
                            />
                            </div>
                        </div>
                    </div>
                    { this.props.updateSelection && <div className="col-sm-12" >
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Time Offset</label>
                            <div className="col-sm-10">
                                {this.state.timeOffsetMs} ms
                            </div>
                        </div>
                    </div>}
                 


                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.createCityMaster()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}
                        >{this.state.loading ? 'Saving...' : 'Save changes'}</Button>
                    </div>
                </form>
            </div>)
    }

    /**
     * validates trip form data
     * @param target
     */
    updateFormErrorState() {
        let formState = validateCity(this.state);
        this.setState({
            form: formState
        }, () => {
            this.state.form.cityNameError ? this.setState({
                validCity: false
            }) : this.setState({
                validCity: true
            });
        });
    }

     /**
     *on country change
     *
     */
     onCountryChange(value) {
        this.setState({
            selectedCountry: value,
            selectedTimeZone: null
        }, () => {
            this.updateFormErrorState()
            this.fetchTimeZoneData()
        });
    }

         /**
     *on timezone change
     *
     */
     onTimeZoneChange(value) {
        this.setState({
            selectedTimeZone: value
        }, () => {
            this.updateFormErrorState()
        });
    }

    /**
     * Use to Create City Master Data
     */
    createCityMaster() {
        let data = { 
            name: this.state.city.toUpperCase(),
            country:this.state.selectedCountry.name,
            timeZone:this.state.selectedTimeZone ? this.state.selectedTimeZone.name :'',
            countryObj:{
                id:this.state.selectedCountry.id
            }        }
        if (this.props.updateSelection) {
            data['id'] = this.state.id
        }
        createMasterData('city',data,this.props.updateSelection).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));

            }
        });
    }


    componentWillUnmount() {
        this.mounted = false;
    }
}