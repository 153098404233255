const validateAgency = (formState)=> {
    let form = {};
    if ( !formState.agency || formState.selectedCity && formState.selectedCity.length===0) {
        form._error = "Required field missing";
        form.invalid = true;
    }
    if (formState.agency && !formState.agency.match("^[a-zA-Z][a-zA-Z0-9'\\- ]*$")) {
        form._error = "Invalid";
        form.invalid = true;
        form.agencyNameError=true;
    }
    return form;
};
export  default validateAgency; 