import React from "react";
import { templateStrings } from "../../../../../Constant";
import * as moment from "moment";

const conditionCheck = (props) => {
  if(props.label == 'cameras')return false;
  if (props.value != null && props.label != "applications") {
    if (typeof props.value == "string" && props.value.length === 0) {
      return false;
    } else {
      return true;
    }
  } else if (props.label == "applications" && props.value.length > 0) {
    return true;
  } else {
    return false;
  }
};
const FilterCameras=(val)=>{
  var cameraIds = val.map(function(item) {
      return item['cameraId'];
  });
  return cameraIds.toString();
}

export const VehicleInfoItem = (props) => (
    <div className="row" >
        { (conditionCheck(props)) &&
            <div> <span className="info-label"> {templateStrings[props.label]} :</span>
                <span className="info-value">{props.label == 'registrationDate' ? moment(props.value).format('DD MMM YYYY') :
                    props.label == 'applications' ?
                        props.value.map((app, index) => {
                            return (<span key={index}>{app.name}{(props.value.length > index + 1 ? ',' : '')}</span>)
                        })
                        : props.label == 'status' ? (props.value ? 'Active' : 'InActive') 
                        : props.label == 'fuelPlan' ? (props.value ? 'Yes' : 'No') 
                        : props.label == 'gpsRequiredFromEtim' ? (props.value ? 'Yes' : 'No') 
                        : props.label == 'fwdToShuttl' ? (props.value ? 'Yes' : 'No') 
                        : props.label == 'tag' ? (props.value.tag ? props.value.tag : "")
                        : props.label == 'cameras' ? (props.value && props.value.length ? FilterCameras(props.value) : 'NA') 
                        : props.value.name ? props.value.name : props.value}</span></div>
        }
    </div>
);
