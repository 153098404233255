import {actions} from './action-list';
export function changeTimeParse(value) {
    return {
        type: actions.CHANGE_TIME_PARSE,
        value: value
    }
}
export function changeTimeInterval(value) {
    return {
        type: actions.CHANGE_TIME_INTERVAL,
        value: value
    }
}
export function changeDeviceRegion(value) {
    return {
        type: actions.CHANGE_DEVICE_REGION,
        value: value
    }
}
export function regionsFetched(value) {
    return {
        type: actions.REGIONS_FETCHED,
        value: value
    }
}
export function setServerTime(value) {
    return {
        type: actions.SERVER_TIME,
        value: value
    }
}


