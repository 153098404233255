import React from "react";
import * as moment from "moment";
import { ButtonToolbar } from "react-bootstrap";
import {
  Search as SearchBar,
  DatePicker,
  DropdownWrapper,
} from "../../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { RegionSelector } from "../../../../../shared/components";
import { AuthenticationService } from "../../../../../services/authorization";
import { routes } from "../../../../../routing/routes";
import { fetchHaltReport,exportHaltReportData } from "../../services/reportService";
import { haltTemplateStrings } from "../../utils";
import { Paginator } from "../../../../../shared/widgets";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { connect } from "react-redux";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { dateFormat } from "../../../../../Constant";
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import uuid from "uuid";

class HaltReport extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    let m = moment(new Date());
    this.state = {
      content: [],
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
      endDate: "",
      startDate: "",
      searchString: "",
      placeHolder: "Search Devices",
      filteredData: [],
      exportOptions: [
        {
          key: "default",
          value: "Export Report",
        },
      ],
      selectedDate: m.subtract(1, "day"),
    };
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.filterByProtocol = this.filterByProtocol.bind(this);
    this.getList = this.getList.bind(this);
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching data",
      });
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchMasterData("city", null);
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          switch (masterType) {
            case "city": {
              this.setState({ cities: res.content });
              break;
            }
            case "agency": {
              this.setState({ agencies: res.content });
              break;
            }
            case "operator": {
              this.setState({ operators: res.content });
              break;
            }
            case "depot": {
              this.setState({ depots: res.content });
              break;
            }
          }
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  onFilterChange(value, masterType) {
    switch (masterType) {
      case "city": {
        this.setState(
          {
            selectedCity: value && value.name ? value.name : null,
            cityId: value && value.id ? value.id : null,
            agencies: [],
            selectedAgency: null,
            agencyId: null,
            depotId: null,
            selectedDepot: null,
            depots: [],
            operatorId: null,
            operators: [],
            selectedOperator: null,
            searchString: "",
          },
          () => {
            this.fetchHaltReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              0,
              this.state.searchString,
              this.state.selectedProtocol
            );
            if (this.state.cityId) {
              this.fetchMasterData("agency", { cityId: this.state.cityId });
              this.fetchMasterData("operator", { cityId: this.state.cityId });
              this.fetchMasterData("depot", { cityId: this.state.cityId });
            }
          }
        );
        break;
      }
      case "agency": {
        this.setState(
          {
            selectedAgency: value && value.name ? value.name : null,
            agencyId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHaltReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              0,
              this.state.searchString,
              this.state.selectedProtocol
            );
          }
        );
        break;
      }
      case "operator": {
        this.setState(
          {
            selectedOperator: value && value.name ? value.name : null,
            operatorId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHaltReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              0,
              this.state.searchString,
              this.state.selectedProtocol
            );
          }
        );
        break;
      }
      case "depot": {
        this.setState(
          {
            selectedDepot: value && value.name ? value.name : null,
            depotId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchHaltReport(
              this.state.startDate,
              this.state.endDate,
              this.state.selectedRegion,
              0,
              this.state.searchString,
              this.state.selectedProtocol
            );
          }
        );
        break;
      }
    }
  }

  render() {
    return (
      <div className="device-page">
        <PageWrapper.Header heading="Halt Report" hideS={true}>
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
          <div className="pull-right">
            <DropdownWrapper
              title="Export"
              menue={this.state.exportOptions}
              onSelect={(value) => {
                switch (value) {
                  case "default": {
                    this.exportReportData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div>
          {this.state.filteredData && (
            <span className="cluster-ip">
              Device Count :{" "}
              {this.state.totalItems ? this.state.totalItems : "0"}
            </span>
          )}
          <div
            className="dataTables_length wrapper-gps"
            id="dataTables-example_length"
          >
            <ButtonToolbar>
              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.dateChange}
                withoutDefaultBorder={false}
              />
            </ButtonToolbar>
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container ">
              <div className="form-group">
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <RegionSelector
                    filterKey={routes.REPORT}
                    onRegionChange={this.handleRegionChange}
                    showProtocols={true}
                    onProtocolChange={this.filterByProtocol}
                    selectedProtocol={this.state.selectedProtocol}
                  />
                  <br />
                  <ButtonToolbar>
                    <p className="filters-label">Filter By:</p>
                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        this.onFilterChange(value, "city");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />
                    <SelectFilter
                      value={this.state.selectedAgency}
                      onChange={(value) => {
                        this.onFilterChange(value, "agency");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Agency"
                      options={this.state.agencies}
                      disabled={!this.state.selectedCity}
                    />
                    <SelectFilter
                      value={this.state.selectedOperator}
                      onChange={(value) => {
                        this.onFilterChange(value, "operator");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Operator"
                      options={this.state.operators}
                      disabled={!this.state.selectedCity}
                    />
                    <SelectFilter
                      value={this.state.selectedDepot}
                      onChange={(value) => {
                        this.onFilterChange(value, "depot");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Depot"
                      options={this.state.depots}
                      disabled={!this.state.selectedCity}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={haltTemplateStrings}
              type={"health"}
            />
            <tbody>
              {this.state.filteredData &&
                this.state.filteredData.map((item) => {
                  return (
                    <tr key={uuid.v4()} className="gradeA odd" role="row">
                      <td className="list-item">{item.deviceId}</td>
                      <td className="list-item">{item.vehicleNo}</td>
                      <td className="list-item">{item.latitude}</td>
                      <td className="list-item">{item.longitude}</td>
                      <td className="list-item">
                        {this.removeGMT(
                          moment(
                            new Date(
                              EpochtoEpochTZ(
                                parseInt(item.startHaltTimestamp),
                                localStorage.getItem("timeZone")
                              )
                            )
                          ).format(dateFormat)
                        )}
                      </td>
                      <td className="list-item">
                        {this.removeGMT(
                          moment(
                            new Date(parseInt(item.endHaltTimestamp))
                          ).format(dateFormat)
                        )}
                      </td>
                    </tr>
                  );
                })}
              {(!this.state.filteredData ||
                (this.state.filteredData &&
                  this.state.filteredData.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.filteredData && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchHaltReport(
        this.state.startDate,
        this.state.endDate,
        this.state.selectedRegion,
        pageNo,
        this.state.searchString,
        this.state.selectedProtocol
      );
    }
  }

  removeGMT(date) {
    var len = date.length;
    return date.slice(12, len).toUpperCase();
  }

  filterByProtocol(value) {
    let selectedProtocol = value;
    if (selectedProtocol == "reset") {
      selectedProtocol = null;
    }
    this.setState(
      {
        selectedProtocol: selectedProtocol,
      },
      () => {
        if (value)
          this.fetchHaltReport(
            this.state.startDate,
            this.state.endDate,
            this.state.selectedRegion,
            0,
            this.state.searchString,
            this.state.selectedProtocol
          );
      }
    );
  }

  handleSearchChange(key) {
    this.setState({
      searchString: key,
    });
    this.fetchHaltReport(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      0,
      key,
      this.state.selectedProtocol
    );
  }

  handleRegionChange(regionId) {
    let startDate = parseFloat(
      this.state.selectedDate.startOf("day").format("x")
    );
    let endDate =
      parseFloat(this.state.selectedDate.endOf("day").format("x")) + 1;
    this.setState(
      {
        selectedRegion: regionId,
        startDate: startDate,
        endDate: endDate,
        selectedProtocol: null,
        selectedCity:null,
        selectedAgency:null,
        selectedOperator:null,
        selectedDepot:null
      },
      () => {
        this.fetchHaltReport(
          startDate,
          endDate,
          regionId,
          0,
          this.state.searchString,
          this.state.selectedProtocol
        );
      }
    );
  }

  dateChange(date) {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
      },
      () => {
        this.fetchHaltReport(
          startDate,
          endDate,
          this.state.selectedRegion,
          0,
          this.state.searchString,
          this.state.selectedProtocol
        );
      }
    );
  }

  exportReportData() {
    let masterType = {
      cityId: this.state.selectedCity,
      agencyId: this.state.selectedAgency,
      operatorId: this.state.selectedOperator,
      depotId: this.state.selectedDepot,
    };
    exportHaltReportData(
      this.state.startDate,
      this.state.endDate,
      this.state.selectedRegion,
      0,
      this.state.searchString,
      this.state.selectedProtocol,
      masterType
    )
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.props.initiateNotification({
          type: "error",
          message: err.description ? err.description : "No Content Found!",
        });
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
        }
      });
  }

  fetchHaltReport(startTime, endTime, regionId, pageNo, search, protocol) {
    this.props.updateLoader(true);
    let masterType = {
      cityId: this.state.selectedCity,
      agencyId: this.state.selectedAgency,
      operatorId: this.state.selectedOperator,
      depotId: this.state.selectedDepot,
    };
    fetchHaltReport(
      startTime,
      endTime,
      regionId,
      pageNo,
      search,
      protocol,
      masterType
    )
      .then((data) => {
        this.setState({
          filteredData: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
          filteredData: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
}
export default connect(null, defaultMapDispatchToProps)(HaltReport);
