import React from "react";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { templateStrings, MessageType , dateFormat} from "../../../../../Constant";
import { ButtonToolbar } from "react-bootstrap";
import { RegionSelector, ImportButton } from "../../../../../shared/components";
import { routes } from "../../../../../routing/routes";
import {
  fetchVehicleDownloads,
  downloadTest,
} from "../../../../../services/vehicleService";
import * as moment from "moment";
import { Paginator } from "../../../../../shared/widgets";
import {
  AuthenticationService,
  OPERATIONS,
} from "../../../../../services/authorization";
import { EpochtoEpochTZ } from "../../../../../shared/utils";

class DownloadFileList extends React.Component {
  constructor(props) {
    super(props);
    let authorities = localStorage.getItem('authorities');      
    this.authService = AuthenticationService.getInstance();
    this.state = {
      authorities: authorities,
      content: [],
      pageNo: 0,
      totalPages: 0,
      totalItems: 0,
    };
    this.supportedTypes = [".xlsx"];
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.getList = this.getList.bind(this);
  }

  componentDidMount() {
    let auth=JSON.parse(this.state.authorities)
    this.mounted = true;
    if(Object.keys(auth)[0]=='Manager'){
      this.updateRightsForRegion(auth['Manager'][0]);
    }
    else{
      this.updateRightsForRegion(this.state.selectedRegion);
    }
  }

  updateStateForRegion(regionId) {
    this.updateRightsForRegion(regionId);
  }

  updateRightsForRegion(region) {
    this.setState({
      canWrite: this.authService.hasRightForOperationOnRouteInRegion(
        routes.VEHICLE,
        region,
        OPERATIONS.WRITE
      ),
    });
  }

  render() {
    const templatestrings = templateStrings.vehicleDownload;
    return (
      <div>
        <PageWrapper.Header heading="Vehicle File Import" hideS={false}>
        <div className="pull-right">
            <a
              onClick={() => {
                this.fetchVehicleDownloads(this.state.selectedRegion, 0);
              }}
            >
              <span className="span-icon-refresh refresh-icon" />
            </a>
          </div>
          {this.state.canWrite && this.state.selectedRegion && (
            <ImportButton
              importKey="vehicle"
              regionId={this.state.selectedRegion}
              supportedTypes={this.supportedTypes}
              onMessage={(e, msg) => {
                if (e == MessageType.SUCCESS) {
                  this.props.initiateNotification({ type: e, message: msg });
                  this.fetchVehicleDownloads(this.state.selectedRegion, 0);
                } else if (e == MessageType.ERROR && msg === "Invalid token") {
                  this.props.setTokenExpired(true);
                } else {
                  this.props.initiateNotification({ type: e, message: msg });
                }
              }}
            />
          )}
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container">
              <div className="form-group">
                <p className="filters-label">Filter By:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <RegionSelector
                      filterKey={routes.VEHICLE}
                      onRegionChange={this.handleRegionChange}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header columns={templatestrings} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.length > 0 &&
                  this.state.content.map((data) => {
                    return this.displayList(data, templatestrings);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length === 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          {this.state.content && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  displayList(item, templateStrings) {
    return (
      <tr key={item.id} className="gradeA odd" role="row">
        <td className="list-item">{item.fileName}</td>

        <td className="list-item">
          <span className="protocol">
            {item.updatedBy ? item.updatedBy : "NA"}
          </span>
          <div className="protocol-list">
            {item.updatedBy ? item.updatedBy : "NA"}
          </div>
        </td>
        <td className="list-item">
          {item.createdDate
            ? moment(
              new Date(
                EpochtoEpochTZ(
                  parseInt(item.createdDate),
                  localStorage.getItem("timeZone")
                )
              )
            ).format(dateFormat)
            : "NA"}
        </td>
        <td className="list-item">
        {item.updatedDate
            ? moment(
              new Date(
                EpochtoEpochTZ(
                  parseInt(item.updatedDate),
                  localStorage.getItem("timeZone")
                )
              )
            ).format(dateFormat)
            : "NA"}
        </td>
        <td
          className={
            item.fileStatus === "Processed"
              ? "list-item-green"
              : item.fileStatus === "Partially Processed"
              ? "list-item-red"
              : item.fileStatus === "InProgress"
              ? "list-item-yellow"
              : "list-item"
          }
        >
          {item.fileStatus}
        </td>
        <td className="list-item">
          {item.fileStatus === "Partially Processed" && (
            <span className="table-icons edit-icon">
              <a
                onClick={() => {
                  this.fileDownloadIndividual(item);
                }}
              >
                <span className="download-icon" />
                <span className="protocol-list">Download File</span>
              </a>
            </span>
          )}
        </td>
      </tr>
    );
  }

  fileDownloadIndividual(data) {
    let importId = data.id;
    let fileName = data.fileName;
    downloadTest(importId, fileName)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        this.props.initiateNotification({
          type: "error",
          message: err.description
            ? err.description
            : "Error while downloading file",
        });
        console.log("err", err);
      });
  }

  handleRegionChange(region) {
    this.setState({ selectedRegion: region });
    this.fetchVehicleDownloads(region, 0);
    this.updateStateForRegion(region);
  }

  fetchVehicleDownloads(region, pageNo) {
    if (this.mounted) this.props.updateLoader(true);
    fetchVehicleDownloads(region, pageNo)
      .then((res) => {
        if (this.mounted) {
          this.setState({
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
            totalItems: res.totalItems,
          });
        }
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.setState({
          content: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        this.errorHandler(err);
        console.log("Err", err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.props.updateLoader(false);
      this.setState({ content: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching vehicle log data",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchVehicleDownloads(this.state.selectedRegion, pageNo);
    }
  }
}

export default connect(null, defaultMapDispatchToProps)(DownloadFileList);
