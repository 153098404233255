import React from "react";
import * as moment from "moment";

import {
    masterType, cityMasterTemplateStrings, agencyMasterTemplateStrings, depotMasterTemplateStrings, operatorViewTemplateStrings, countryMasterTemplateStrings,cameraHeadingsInView,serviceCategoryViewTemplateStrings,ViewLanguageMasterTemplateStrings
} from '../../utils';
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import CityView from './CityView'


export const MasterInfoItem = (props) => {
  const templateStrings = {
    [masterType.city]: cityMasterTemplateStrings,
    [masterType.agency]: agencyMasterTemplateStrings,
    [masterType.operator]: operatorViewTemplateStrings,
    [masterType.depot]: depotMasterTemplateStrings,
    [masterType.country]: countryMasterTemplateStrings,
    [masterType.camera]: cameraHeadingsInView,
    [masterType.serviceCategory]: serviceCategoryViewTemplateStrings,
    [masterType.language]:ViewLanguageMasterTemplateStrings
  };

  const renderCityNames = () => {
    const { data:info,onClose,initiateNotification,activeTab,fetchServiceReportOnUpsertOfAgency,onComplete } = props.cityViewProps;
    const { value:tableData } = props;
    return <CityView data={{tableData,info,onClose,initiateNotification,activeTab,fetchServiceReportOnUpsertOfAgency,onComplete}} />
  };

  const getCityName=(value)=>{
    if(Array.isArray(value) && value.length){
      return value.map((city, index) => (
        <span key={index}>
          {city.name}
          {index < value.length - 1 && ','}
        </span>
      ));
    }
    return value.name ? value.name : value;
  }

  const getLanguageName=(value)=>{
    if(value.length){
      return value.map((language, index) => (
        <span key={index}>
          {language.language}
          {index < value.length - 1 && ','}
        </span>
      ));
    }
    return value.name ? value.language : value;
  }
  const renderValue = () => {
    const { label, value } = props;

    switch (label) {
      case 'city':
        return getCityName(value);
      case 'status':
      case 'isActive':
        return value ? 'Active' : 'Inactive';
      case 'currencySymbol':
        return <span style={{ fontFamily: 'DejaVu Sans' }}>{value}</span>;
      case 'operatorVerified':
        return value ? 'Yes' : 'No';
      case 'profilePercent':
        return value.toString() + '%';
      case 'createAt':
      case 'updatedAt':
      case 'createdAt':
        return value ? moment(new Date(EpochtoEpochTZ(parseInt(value), localStorage.getItem('timeZone')))).format('DD/MM/YYYY') : 'NA';
      case 'languages':
       return getLanguageName(value);
      default:
        return value.name ? value.name : value;
    }
  };

  const shouldRenderValue = () => {
    const { value, label } = props;

    return (
      (value && templateStrings[props.masterType][label]) ||
      label === 'status' ||
      label === 'isActive' ||
      label === 'operatorVerified' ||
      label === 'profilePercent'
    );
  };

  return (
    <div className="row" style={{ display: 'flex!important' }}>
      {shouldRenderValue() && (
        <div>
          {!['agencyCategoryDto','agencyProviderDto'].includes(props.label) && (
            <span className="info-label">{templateStrings[props.masterType][props.label]} :</span>
          )}
          {!['agencyCategoryDto','agencyProviderDto'].includes(props.label) && <span className="info-value">{renderValue()}</span>}
          {['agencyCategoryDto','agencyProviderDto'].includes(props.label) && renderCityNames()}
        </div>
      )}
    </div>
  );
};

