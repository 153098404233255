import React from "react";
import {ListItem} from "../list-item/reportListItem";
import {TableWrapper} from "../../../../../shared/layout";
import {TableLoader} from "../../../../../shared/widgets";
import {
    reportsType,
    distanceDetailTemplateStrings,
    gpsQualityDetailTemplateStrings,
    distanceTemplateStrings,
    gpsQualityTemplateStrings
} from "../../utils";
export const ListView = (props)=> {
    console.log("props content",props.content)

    const templateStrings = props.type === reportsType.DISTANCE ?
        distanceTemplateStrings : props.type === reportsType.GPS_QUALITY ?
        gpsQualityTemplateStrings : props.detailType && props.detailType === reportsType.DISTANCE ? distanceDetailTemplateStrings : props.detailType && props.detailType === reportsType.GPS_QUALITY ? gpsQualityDetailTemplateStrings : {};

    return (
        <TableWrapper id="report-list">
            <TableWrapper.Header columns={templateStrings} onSortToggle={props.onSortChange}/>
            {props.loading && <TableLoader loading={props.loading}
                                           colSpan={Object.keys(templateStrings).length}/>}
            {!props.loading && <tbody>
            {props.content && props.content.map((data, index) => {
                return <ListItem key={index} item={data} columns={templateStrings} navigateTo={props.navigateTo}
                                 keyId="vehicleNo" keyId2="deviceId"/>;
            })}
            {(!props.content || (props.content && props.content.length == 0)) &&
            <tr className="gradeA odd noDataStyle" role="row">
              <td className="sorting_1" colSpan="6">
                No Data Found!
              </td>
            </tr>
            }
            </tbody>}
        </TableWrapper>)
};