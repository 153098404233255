import { routes } from "./routes";
export const routeIcon = {
  [routes.HOME]: { icon: "home-icon", title: "Home" },
  [routes.ALERTS]: { 
    icon: "alerts-icon", 
    title: "Alerts",
    children: [
      { path: "panic-alerts", title: "Panic Alerts" }
    ]
  },
  [routes.DEVICE]: { icon: "device-icon", title: "Devices" },
  [routes.VEHICLE]: {
    icon: "vehicle-icon",
    title: "Vehicles",
    children: [
      { path: "detail", title: "Vehicle Detail" },
      { path: "file/Import", title: "File Import" },
    ],
  },
  [routes.BSD]: {
    icon: "bsd-icon",
    title: "BSD",
    children: [
      { path: "detail", title: "BSD Detail" },
      { path: "stop", title: "Stop Detail" },
      {
        path: "file/Import",
        title: "File Import",
      },
    ],
  },
  [routes.REPORT]: {
    icon: "report-icon",
    title: "Reports",
    children: [
      { path: "gpsQuality", title: "GPS Quality" },
      {
        path: "distance",
        title: "Distance",
      },
      { path: "archiveData", title: "Export Data" },
      { path: "deviceFrequency", title: "Device Frequency" },
      { path: "dateFrequency", title: "Date Frequency" },
      { path: "health", title: "Health Report" },
      { path: "RSU", title: "RSU Config" },
      { path: "depot", title: "Vehicle IN/OUT" },
      { path: "haltStatus", title: "Halt Report" },
      { path: "bsdAvailabilityReport", title:"BSD Availability Report"},
      { path: "etaAckMatrix", title:"ETA Ack. Report"},
      { path: "vehicleInOutEvent", title:"Vehicle In/Out Events"},
      { path: "deviceConfig", title:"Device Configuration"},
    ],
  },
  [routes.MASTER]: {
    icon: "master-icon",
    title: "Master",
    children: [
      { path: "city", title: "City" },
      { path: "country", title: "Country" },
      { path: "depot", title: "Depot" },
      { path: "agency", title: "Agency" },
      { path: "operator", title: "Operator" },
      { path: "serviceCategory", title: "Vehicle Services"},
      { path: "camera", title: "Camera Master"},
      { path: "language", title: "Languages"},
    ],
  },
  [routes.VIEWMASTER]: {
    icon: "master-icon",
    title: "Master",
    children: [
      { path: "depot", title: "Depot" },
      { path: "operator", title: "Operator" },
      { path: "camera", title: "Camera Master"}
    ],
  },
  [routes.LIVE_MAP]: { icon: "map-icon", title: "Live Map" },
  [routes.REGION]: { icon: "region-icon", title: "Regions" },
  [routes.PROTOCOL]: { icon: "protocol-icon", title: "Protocols" },
  [routes.TRIP]: { icon: "trip-icon", title: "Trips" },
  [routes.CRON]: { icon: "cron-icon", title: "Cron Trigger (β)", isBeta: true },
};
