const validateCron = (formState) => {
  let form = {};
  if (
    !formState.startTime ||
    !formState.endTime ||
    !formState.selectedRegion.length ||
    !formState.selectedTimeZone.name ||
    !formState.selectedEvent
  ) {
    form._error = "Required field missing";
    form.invalid = true;
  }
  return form;
};
export default validateCron;
