import React from "react";
import './tableWrapper.css';
import {SortingView} from './../../widgets/sortingView/sortingView';

class TableWrapper extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {children}=this.props;
        return <div className=" table-style table-responsive">
            <table id={this.props.id} className="table table-hover dataTable" role="grid">
            {children}
            </table>
        </div>
    }
}

const TableHeader = ({columns, ...rest}) => {
    return (
        <thead className="normal_table_head">
        <tr role="row">
            {Object.keys(columns).map((columnKey)=> {
                return <th key={columnKey} className="sorting" tabIndex="0"
                            rowSpan="1" colSpan="1" title={columns[columnKey].title?columns[columnKey].title:""}
                >{columns[columnKey].component ? columns[columnKey].component(rest) : columns[columnKey].display?columns[columnKey].display:columns[columnKey]}
                        { columns[columnKey].sortable&&rest.onSortToggle&& <SortingView onToggle={(value)=>rest.onSortToggle(value,columnKey)} defaultSort={columns[columnKey].defaultSort} type={rest.type}/>}
                </th>
            })}
        </tr>
        </thead>)
};
TableWrapper.Header=TableHeader;

export default TableWrapper