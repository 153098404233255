import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";
import {Loader} from "../../../shared/widgets";
import {dateFormat} from "../../../Constant";
import "./healthCheckComponent.css";

export default class HealthCheckComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSelectModal: false,
            content: [],
            isGreen: this.isStatusGreen(props)
        };
        this.isStatusGreen = this.isStatusGreen.bind(this);
    }

    /**
     * updated the current state for status for change in region props
     * */
    componentWillReceiveProps(nextProps) {
        this.setState({isGreen: this.isStatusGreen(nextProps)})
    }

    componentDidMount() {
        this.mounted = true;
    }

    /**
     *return  green status either true or false
     */
    isStatusGreen(props) {
        let isGreen = true;
        if (props.region.healthStatus && props.region.healthStatus.length) {
            props.region.healthStatus.forEach((health)=> {
                isGreen = isGreen && (health.status == 'success');
            });
        } else {
            isGreen = false;
        }
        return isGreen;
    }


    render() {
        return (<span className={classNames({
                'red-class': !this.state.isGreen,
                'green-class': this.state.isGreen,
                'table-icons': true,
                'edit-icon': true
            })}>
                        <a onClick={()=> {
                            this.openSelectModal()
                        }}>
                            <i className="fa fa-circle protocol" aria-hidden="true">
                            </i>
                            <span className="protocol-list">view status</span>
                        </a>
                {this.displaySelectModal()}
                    </span>
        );
    }

    /*
     * open the modal and hit fetch healthcheck API
     *
     */
    openSelectModal() {
        if (this.props.region.healthStatus) {
            this.setState({content: this.props.region.healthStatus})
        }
        this.setState({
            showSelectModal: true
        })
    }

    displaySelectModal() {
        return (
            <Modal show={this.state.showSelectModal} bsSize="large" dialogClassName="health-check"
                   onHide={() => {
                       this.setState({showSelectModal: false, invalidJson: false});
                       this.formData = null;
                   }}>
                <Modal.Header closeButton>
                    <Modal.Title>HealthCheck for {this.props.region.regionId}
                    </Modal.Title>
                    <span className="error-count">
                        <span className="header-data">Error Count:&nbsp;&nbsp;<span
                            className="value">{this.props.region.errorCount}</span></span>
                    <span
                        className="header-data">Error Percentage:&nbsp;&nbsp;<span className="value">{this.props.region.errorPercentage}</span></span>
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Loader loading={this.state.loading}/>
                    {!this.state.loading && <div>
                        <div className="dataTable_wrapper">
                            <div id="dataTables-example_wrapper"
                                 className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                <div className="row">
                                    <div className="col-sm-12 healthcheck-table-style table-responsive">
                                        <table className="table table-hover dataTable no-footer"
                                               id="dataTables-example" role="grid"
                                               aria-describedby="dataTables-example_info">
                                            <thead className="normal_table_head">
                                            <tr role="row">
                                                <th className="sorting" tabIndex="0"
                                                    aria-controls="dataTables-example" rowSpan="1"
                                                    colSpan="1">Protocol
                                                </th>
                                                <th className="sorting" tabIndex="0"
                                                    aria-controls="dataTables-example" rowSpan="1"
                                                    colSpan="1"
                                                >Last Attempted At
                                                </th>
                                                <th className="sorting" tabIndex="0"
                                                    aria-controls="dataTables-example" rowSpan="1"
                                                    colSpan="1"
                                                >Last SuccessFul Attempt At
                                                </th>
                                                <th className="sorting" tabIndex="0"
                                                    aria-controls="dataTables-example" rowSpan="1"
                                                    colSpan="1"
                                                >Status
                                                </th>
                                            </tr >
                                            </thead >
                                            < tbody >
                                            {this.state.content && this.state.content.map((data, index) => {
                                                return this.displayList(data, index);
                                            })
                                            }

                                            {
                                                this.state.content && this.state.content.length == 0 &&
                                                <tr className="gradeA odd noDataStyle" role="row">
                                                    <td className="sorting_1" colSpan="6">No Data
                                                        Found !
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Modal.Body>
            </Modal >
        )
    }


    /**
     * Used to show protocol list in row in List view
     * @param item Object of item to display in list view
     * @param index index of displayed item
     */
    displayList(item, index) {
        return (
            <tr key={item.protocol + index } className="gradeA odd" role="row">
                <td className="list-item">{item.protocol}</td>
                <td>{parseInt(item.lastAttemptedTimestamp) ? moment(new Date(parseInt(item.lastAttemptedTimestamp))).format(dateFormat) : 'NA'}</td>
                <td>{parseInt(item.lastSuccessfullAttempt) ? moment(new Date(parseInt(item.lastSuccessfullAttempt))).format(dateFormat) : 'NA'}</td>
                <td className="list-item">{item.status}</td>
            </tr>
        );
    }


    componentWillUnmount() {
        this.mounted = false;
    }
}