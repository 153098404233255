import { getAPIPromise, getValidToken } from "./APIutils";
import FileDownLoader from "./fileDownloader";
import { BASE_URL } from "../Constant";

export function fetchBSDData(
  city,
  timeSlab,
  status,
  searchKey,
  pageNo = 0,
  pageSize = 10
) {
  let url = `${BASE_URL}bsd/bsd-device/all?pageNo=${pageNo}&pageSize=${pageSize}`;

  if (timeSlab) {
    url = `${url}&timeSlab=${timeSlab}`;
  }
  if (city) {
    url = `${url}&city=${city}`;
  }
  if (searchKey) {
    url = `${url}&searchKey=${searchKey}`;
  }
  if (status && status != "All") {
    url = `${url}&status=${status.toUpperCase()}`;
  }
  return getAPIPromise(url, {
    method: "get",
  });
}

export function fetchStopData(city, searchKey, pageNo = 0, pageSize = 10) {
  let url = `${BASE_URL}bsd/stop-details/all?pageNo=${pageNo}&pageSize=${pageSize}`;

  if (city) {
    url = `${url}&city=${city}`;
  }
  if (searchKey) {
    url = `${url}&searchKey=${searchKey}`;
  }
  return getAPIPromise(url, {
    method: "get",
  });
}

export function downLoadBSDData(city, timeSlab, status, exportKey) {
  let url;
  url = `${BASE_URL}bsd/bsd-device/export`;
  if (exportKey == "format") {
    url = `${url}/format`;
  } else {
    url = `${url}?timeSlab=${timeSlab}`;
    if (city) {
      url = `${url}&city=${city}`;
    }
    if (status && status != "All") {
      url = `${url}&status=${status.toUpperCase()}`;
    }
  }

  return getValidToken().then((token) => {
    return FileDownLoader.download(url, {
      methods: "GET",
      headers: { Authorization: "Bearer " + token },
    });
  });
}

export function downLoadStopData(city, exportKey) {
  let url;
  url = `${BASE_URL}bsd/stop-details/export`;
  if (exportKey == "format") {
    url = `${url}/format`;
  } else {
    url = `${url}`;
    if (city) {
      url = `${url}?city=${city}`;
    }
  }
  return getValidToken().then((token) => {
    return FileDownLoader.download(url, {
      methods: "GET",
      headers: { Authorization: "Bearer " + token },
    });
  });
}

export function fetchBSDLogs(deviceId) {
  let url = `${BASE_URL}bsd/audit/bsd-device?deviceId=${deviceId}`;

  return getAPIPromise(url, {
    method: "get",
  });
}

export function fetchStopLogs(stopId) {
  let url = `${BASE_URL}bsd/audit/stop-details?stopId=${stopId}`;

  return getAPIPromise(url, {
    method: "get",
  });
}

export function createOrUpdateDevice(updatedData, state, email) {
  let data = updatedData ? { ...updatedData } : {};
  data.deviceId = state.deviceId;
  data.stopId = state.stopId ? state.stopId : null;
  data.routeIds = state.routeIds ? routeIdFilter(state.routeIds) : [];

  let url;
  url = `${BASE_URL}bsd/bsd-device?email=${email}`;
  return getAPIPromise(url, {
    method: updatedData ? "PUT" : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

function routeIdFilter(val){
  if (val) {
      const routeArray = val.split(",");
      var trimmedRouteArray;
      trimmedRouteArray = routeArray.map((val) => {
        return (val.trim())
      })
      trimmedRouteArray = trimmedRouteArray.filter((str) => { return (/\S/.test(str)) })
      // let final= trimmedRouteArray.map(item=>{
      //     return {routeId:item}
      // });
      return trimmedRouteArray;
    } 
    else
     {  return [];
  }
}

export function createOrUpdateStop(updatedData, state, email) {
  let data = {};
  data.stopId = state.stopId ? state.stopId : null;
  data.stopName = state.stopName ? state.stopName : null;
  data.stopNativeLanguage = state.stopNativeLanguage
    ? state.stopNativeLanguage
    : null;
  data.stopNativeName = state.stopNativeName ? state.stopNativeName : null;
  data.city = state.city ? state.city.value : null;
  data.latitude = state.latitude ? state.latitude : null;
  data.longitude = state.longitude ? state.longitude : null;

  let url;
  url = `${BASE_URL}bsd/stop-details?email=${email}`;
  return getAPIPromise(url, {
    method: updatedData ? "PUT" : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function fetchStopNativeLanguage() {
  let url = `${BASE_URL}bsd/stop-details/list/native-language`;

  return getAPIPromise(url, {
    method: "get",
  });
}

export function fetchBSDImportData(city,searchKey, pageNo, activeTab, pageSize = 10) {
  let url = `${BASE_URL}bsd/import-details/${activeTab=='BSD'?'bsd-device':'stop-details'}?pageNo=${pageNo}&pageSize=${pageSize}`;

  if (searchKey) {
    url = `${url}&searchKey=${searchKey}`;
  }
  if(activeTab!=='BSD'){
    url = `${url}&city=${city}`;
  }
  return getAPIPromise(url, {
    method: "get",
  });
}


export function downloadBSDById(importId,activeTab) {
  let url = `${BASE_URL}bsd/${activeTab=='BSD'?'bsd-device':'stop-details'}/download?importId=${importId}`;

  return getValidToken().then((token) => {
    return FileDownLoader.download(url, {
      methods: "GET",
      headers: { Authorization: "Bearer " + token },
    });
  });
}
