import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import moment from "moment";

import {MapWrapperComponent} from "../../map/mapWrapper";
import {fetchDeviceDetail} from "../../../../services/deviceService";
import {DeviceInfoItem} from "./deviceInfoItem";
import {fetchTimeValue,EpochtoEpochTZ} from "../../../../shared/utils.js";
import {dateFormat, defaultLatitude, defaultLongitude} from "../../../../Constant";

export class DeviceDetailModal extends Component {

    constructor(props) {
        super(props);
        let protocol = window.location.protocol;
        let imgUrl = `${protocol}//gps.chalo.com/assets/images`;
        this.state = {
            data: this.props.data,
            center: {
                lat: parseFloat(this.props.data.latitude),
                lng: parseFloat(this.props.data.longitude)
            },
            icon: {
                url: `${imgUrl}/device.png`,
                scaledSize: {
                    width: 40,
                    height: 57
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data
        });
        if (nextProps.data.latitude != this.props.data.longitude) {
            if (parseFloat(nextProps.data.latitude)) {
                this.setState({
                    center: {
                        lat: parseFloat(nextProps.data.latitude),
                        lng: parseFloat(nextProps.data.longitude)
                    }
                });
            } else {
                // this.props.messageCallback(MessageType.ERROR, "Device's location can't be located");
                this.setState({
                    center: {
                        lat: defaultLatitude,
                        lng: defaultLongitude
                    }
                })
            }
        }

    }

    componentDidMount() {
        this.fetchDeviceDetail();
    }


    render() {
        const backdrop = 'static';
        const {data, center}=this.state;
        return (
            <Modal show={this.props.showModal} onHide={()=>this.props.messageCallback(null, null)} backdrop={backdrop}
                   dialogClassName="info-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Device Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row device-info">
                        <div className="col-sm-5">
                            <DeviceInfoItem label="Device Id" value={data.deviceId}/>
                            <DeviceInfoItem label="Vehicle No." value={data.vehicleNo}/>
                            <DeviceInfoItem label="Protocol" value={data.protocol}/>
                            <DeviceInfoItem label="Latitude" value={data.latitude}/>
                            <DeviceInfoItem label="Longitude" value={data.longitude}/>
                            <DeviceInfoItem label="Time in Packet"
                                            value={parseInt(data.lastPacketTimestamp) ? moment(new Date(EpochtoEpochTZ(parseInt(data.lastPacketTimestamp),localStorage.getItem('timeZone')))).format(dateFormat) : 'NA'}/>
                            <DeviceInfoItem label="Last Arrived At"
                                            value={parseInt(data.lastPacketArriveAt) ? moment(new Date(EpochtoEpochTZ(parseInt(data.lastPacketArriveAt),localStorage.getItem('timeZone')))).format(dateFormat) : 'NA'}/>
                            <DeviceInfoItem label="Last Posted At"
                                            value={parseInt(data.lastPacketPostedAt) ? moment(new Date(EpochtoEpochTZ(parseInt(data.lastPacketPostedAt),localStorage.getItem('timeZone')))).format(dateFormat) : 'NA'}/>
                            <DeviceInfoItem label="Last Parsing Time"
                                            value={`${fetchTimeValue(this.props.timeParse, parseInt(data.lastPacketParsingTime), null)} ${this.props.timeParse}`}/>
                            <DeviceInfoItem label="Last Posting Time"
                                            value={`${fetchTimeValue(this.props.timeParse, parseInt(data.lastPacketPostingTime), null)} ${this.props.timeParse}`}/>
                            <DeviceInfoItem label="Delay"
                                            value={`${fetchTimeValue(this.props.timeParse, parseInt(data.delay), null)} ${this.props.timeParse}`}/>
                            <DeviceInfoItem label="Packet Status" value={data.packetStatus}/>
                            <DeviceInfoItem label="GPS Signal" value={data.signal}/>
                        </div>
                        <div className="col-sm-7 map-wrapper">
                            <div className="row device-detail-map">
                                <MapWrapperComponent data={data} center={center} icon={this.state.icon}/>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        );
    }

    fetchDeviceDetail() {
        this.intervalId = setInterval(()=> {
            if (this.props.showModal)
                fetchDeviceDetail(this.props.data.deviceId, this.props.regionId).then((response)=> {
                    this.setState({data: response});
                }).catch(function (err) {
                    console.log(err);
                });
        }, 10000);

    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }
}

