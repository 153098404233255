import {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} from "react-google-maps";
import {withHandlers, compose, withStateHandlers} from "recompose";
import React from "react";
import {InfoWindowView} from './infoWindowView';

export const MapComponent = compose(
    withStateHandlers(() => ({
        isOpen: true,
    }), {
        onToggleOpen: ({isOpen}) => () => ({
            isOpen: !isOpen
        }),
    }),
    withScriptjs,
    withGoogleMap
    )((props) => {
        const {address}=props;
        const {latitude, longitude, lastPacketArriveAt}=props.data;
        const markerLocation = {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
        };
        const showMarker = !(!props.isMarkerShown || !parseFloat(latitude) || !parseFloat(latitude));
        return <GoogleMap
            defaultZoom={14}
            center={props.center}
        >
            { showMarker &&
            < Marker
                position={markerLocation}
                onClick={props.onToggleOpen}
                icon={props.icon}> {props.isOpen &&
            <InfoWindow onCloseClick={props.onToggleOpen}>
                <InfoWindowView lastPacketArriveAt={lastPacketArriveAt} address={address} />
            </InfoWindow>}</Marker>}
        </GoogleMap>
    });




