import { fetchWrapperWithoutToken, fetchWrapperTextResponse } from "../APIutils";
import { AUTH_TOKEN, LOGIN_TOKEN, LOGIN_AUTHENTICATION, LOGOUT_USER } from "../../Constant";
import { googleProperties } from "./properties";
import Config from "./../../config";
import Cookies from 'universal-cookie';
import { encryptString } from "../../services/authorization";
import {AuthenticationService} from "../../services/authorization/service"
export function fetchAccessToken(userName, password) {
    var details = {
        username: userName,
        password: password,
        grant_type: 'password'
    };
    return fetchToken(details)

}

export function fetchToken(details) {
    var formBody = getFormBodyFromJson(details);
    let auth_basic_header = btoa(`${googleProperties.google.clientId}:${googleProperties.google.clientSecret}`);

    return fetchWrapperWithoutToken(`${Config.API.COA_BASE_URL}/${AUTH_TOKEN}`, {
        method: 'post', headers: {
            'Authorization': `Basic ${auth_basic_header}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        }, body: formBody
    });
}

export function fetchLoginTokenFromServer(authCode) {
    return fetchWrapperWithoutToken(`${Config.API.COA_BASE_URL}/${LOGIN_TOKEN}`, {
        method: 'post', headers: {
            'auth-code': authCode,
            'Content-Type': 'application/x-www-form-urlencoded'
        }, body: getFormBodyFromJson({ redirect_uri: googleProperties.google.redirectUri })
    });
}

export function fetchLoggedInUser(userId) {
    var encodedValue = encodeURIComponent(userId)
    let url = `${Config.API.COA_BASE_URL}/${LOGIN_AUTHENTICATION}?userId=${encodedValue}`

    return new Promise((resolve, reject) => {
        fetchWrapperWithoutToken(url, { method: 'get' })
            .then((res => {
                resolve(res)
            })).catch(err => {
                reject(err)
            })
    });
}

function getFormBodyFromJson(details) {
    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody;
}
export function logoutAuthenticateUser(userId) {
    let url = `${Config.API.COA_BASE_URL}/${LOGOUT_USER}`
    if (userId) {
        url = `${url}?userId=${userId}`
    }
    return fetchWrapperTextResponse(url, {
        method: 'post'
    });
}


export function onLogoutUser() {
    const cookies = new Cookies();
    const authInstance = AuthenticationService.getInstance()
    const userId = localStorage.getItem('userId')
    var current_date = new Date();
    var expiration_date = new Date();
    expiration_date.setDate(current_date.getDate() + 7);
    var encryptedLoginCookie = encryptString('loggedOut')
    if (authInstance.checkValidUser()) {
        // cookies.remove(Config.cookieKeys.user, { path: "/" })
        // cookies.set(Config.cookieKeys.loginState, encryptedLoginCookie, {  expires: expiration_date });

        cookies.remove(Config.cookieKeys.user, { path: "/", domain: ".chalo.com" })
        cookies.set(Config.cookieKeys.loginState, encryptedLoginCookie, { domain: '.chalo.com', expires: expiration_date });
        logoutAuthenticateUser(userId).then((val) => {
            console.log("logout successful");
        }).catch(e => {
            console.log(e);
        });
    }
    localStorage.clear()

   
}

