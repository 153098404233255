/**
 * Main container
 * imported in App.js
 */
import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
// import SignInWrapper from "../views/login/signin/signIn-wrapper";
import AuthenticatedWrapper from "../AuthenticatedWrapper";
// import {google} from "../services/login/properties";
import LoginComponent from "../views/login/signin/LoginComponent";

class Main extends Component {
    // constructor(props) {
    //     super(props);
    //     this.getSignIn = this.getSignIn.bind(this);
    // }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/login' component={LoginComponent}/>
                    <Route path='/' component={AuthenticatedWrapper}/>
                </Switch>
            </BrowserRouter>
        );
    }

    // getSignIn() {
    //     return <SignInWrapper asyncScriptOnLoad={onLoad}/>
    // }
}


// function onLoad() {
//     window.gapi && window.gapi.load('auth2', function () {
//         window.auth2 = window.gapi.auth2.init({
//             client_id: google.auth.client_id,
//             redirect_uri: google.auth.redirect_uri
//         });
//     });
// }


export default Main;
