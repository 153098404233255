import {getAPIPromise} from "./APIutils";
import {BASE_URL, GET_PROTOCOLS} from "../Constant";
export function fetchProtocols() {
    let url = `${BASE_URL}${GET_PROTOCOLS}`;
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function createProtocol(data, type) {
    let url = `${BASE_URL}${GET_PROTOCOLS}`;
    return getAPIPromise(url, {
        method: type, headers: {
            'Accept': 'application/json',
            'content-type': 'application/json'
        },
        body: JSON.stringify(data)
    })
}
