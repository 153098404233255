import {getAPIPromise, fetchWrapperWithoutToken,fetchWrapper} from "./APIutils";
import {BASE_URL, GET_DEVICE_DETAIL, GET_SERVER_TIME, GET_DEVICES, DeviceStatusFilterValues} from "../Constant";
import {WarningFilterStatus} from "../Constant";

export function fetchDeviceDetail(deviceId, regionId) {
    let url = `${BASE_URL}${GET_DEVICE_DETAIL}${deviceId}`;
    if (regionId) {
        url = `${url}?regionId=${regionId}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function fetchDevices(key, regionId, pageNo, sortBy, protocol, timeSlab, status, filter,depot) {
    let url = `${BASE_URL}${GET_DEVICES}?regionId=${regionId}&pageNo=${pageNo}&timeSlab=${timeSlab}`;
    if (key) url = `${url}&key=${key}`;
    if (protocol) url = `${url}&protocol=${protocol}`;
    if (sortBy) url = `${url}&sortBy=${sortBy}`;
    if (depot) url = `${url}&depotId=${depot}`;
    if (status && status != DeviceStatusFilterValues.ALL.key) url = `${url}&status=${status}`;
    if (filter && filter.key && filter.key != WarningFilterStatus.All.key) url = `${url}&faultStatus=${filter.key}`;
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function deleteDevices(deviceId, regionId) {
    let url = `${BASE_URL}${GET_DEVICES}/${deviceId}?regionId=${regionId}`;
    return getAPIPromise(url, {
        method: 'delete'
    });
}

export function fetchCurrentServerTime() {
    let url = `${BASE_URL}${GET_SERVER_TIME}`;
    return new Promise((resolve, reject)=> {
        fetchWrapper(url, {method: 'get'})
            .then((res=> {
                resolve(res)
            })).catch(err=> {
            reject(err)
        })
    });
}