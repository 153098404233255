import { getAPIPromise, getValidToken } from "./APIutils";
import { BASE_URL, GET_CAMERA_LOG, GET_VEHICLES ,GET_VEHICLE_LOG, UPDATE_VEHICLE_STATUS} from "../Constant";
import { SEARCH_VEHICLES } from "../Constant";
import FileDownLoader from "./fileDownloader";
import { EXPORT_REGIONS_DEVICE } from "../Constant";
import { EXPORT_REGIONS_VEHICLES } from "../Constant";
import { DeviceStatusFilterValues } from "../Constant";
import { WarningFilterStatus } from "../Constant";

export function fetchVehicles(key) {
    let url = `${BASE_URL}${SEARCH_VEHICLES}?pageNo=0&pageSize=10`;
    if (key) {
        url = `${url}&key=${key}`
    }
    return getAPIPromise(url, {
        method: 'get'
    });
}
export function cameraIdFilter(val){
    if (val) {
        const cameraArray = val.split(",");
        var trimmedCameraArray;
        trimmedCameraArray = cameraArray.map((val) => {
          return (val.trim())
        })
        trimmedCameraArray = trimmedCameraArray.filter((str) => { return (/\S/.test(str)) })
        let final= trimmedCameraArray.map(item=>{
            return {cameraId:item}
        });
        return final;
      } 
      else
       {  return [];
    }
}
export function fetchVehiclesForRegion(regionId, key, masterType, pageNo, pageSIze = 10) {
    let url = `${BASE_URL}${GET_VEHICLES}${regionId}?pageNo=${pageNo}&pageSize=${pageSIze}`;
    if (key) {
        url = `${url}&key=${key}`
    }
    if (masterType) {
        if (masterType.cityId) { url = `${url}&cityId=${masterType.cityId}` }
        if (masterType.agencyId) { url = `${url}&agencyId=${masterType.agencyId}` }
        if (masterType.operatorId) { url = `${url}&operatorId=${masterType.operatorId}` }
        if (masterType.applicationId) { url = `${url}&applicationId=${masterType.applicationId}` }
        if (masterType.depotId) { url = `${url}&depotId=${masterType.depotId}` }

    }
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function createOrUpdateVehicle(regionId, updatedData, state, email) {
    let data = updatedData ? { ...updatedData } : {};
    data.regionId = regionId ? regionId.toLowerCase() : '';
    data.vehicleNo = state.vehicleNo;
    data.vehicleCode =state.vehicleCode ? state.vehicleCode :null;
    data.deviceId = state.deviceId ? state.deviceId : null;
    data.agency = { id: state.agencyName.value.id };
    data.depot = state.depotName ? { id: state.depotName.value.id } : null;
    data.seatingCapacity = state.seatingCapacity ? state.seatingCapacity : null;
    data.standingCapacity = state.standingCapacity ? state.standingCapacity : null;
    data.make = state.make ? state.make : null;
    data.owner = state.owner ? state.owner : null;
    data.version = state.version ? state.version : null;
    data.model = state.model ? state.model : null;
    data.cityMaster = state.city ? { id: state.city.value.id } : null;
    data.operatorMaster = { id: state.operator.value.id };
    data.vehicleType = state.vehicleType ? state.vehicleType.value : null;
    data.operationType = state.operationType ? state.operationType.value : null;
    data.serviceType = state.serviceType ? state.serviceType.value : null;
    data.gstOnTicket  = state.gstOnTicket  ? state.gstOnTicket.value : null;
    data.surchargeOnTicket = state.surchargeOnTicket ? state.surchargeOnTicket.value : null;
    data.collectionOnEtim = state.collectionOnEtim ? state.collectionOnEtim.value : null;
    data.simCard = state.simCard ? state.simCard : null;
    data.secondarySim = state.secondarySim ? state.secondarySim : null;
    data.registrationDate = state.selectedDate;
    data.afcsAccountId = state.afcsAccountId ? state.afcsAccountId : null;
    data.fuelPlan = state.fuelPlan ? state.fuelPlan.value=='Yes' : false;
    data.gpsRequiredFromEtim = state.gpsRequiredFromEtim ? state.gpsRequiredFromEtim.value == 'Yes' : false;
    data.fwdToShuttl =  state.fwdToShuttl ? state.fwdToShuttl.value == 'Yes' : false;
    data.etimFrequency = state.etimFrequency ? state.etimFrequency  : 30;
    data.engineNumber = state.engineNumber ? state.engineNumber : null;
    data.chasisNumber = state.chasisNumber ? state.chasisNumber : null;
    data.protocolType = state.protocolType ? state.protocolType.value : null;
    data.applications = state.applications ? state.applications.map((data) => {
        return data.value.id
    }) : null;
    data.serviceCategory = state.serviceCategory ? state.serviceCategory.id : null;
    data.serviceProvider = state.serviceProvider ? state.serviceProvider.id: null;
    data['tag'] = state.tag ? {id:state.tag.id}:null;
    data['fuelType'] = state.fuelType ? state.fuelType.value : null;
    let url;
    url = `${BASE_URL}api/master/vehicle/v2?regionId=${regionId}`;
 
    return getAPIPromise(url, {
        method: updatedData ? 'PUT' : 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });
}

export function downLoadVehicleData(exportKey, regionId, timeParse, protocol, sortBy, status, filter, city, agency, operator,depot) {
    var timeZone=localStorage.getItem('timeZone')
    let url = (exportKey == "device") ? EXPORT_REGIONS_DEVICE : EXPORT_REGIONS_VEHICLES;
    if (exportKey == "format") {
        url = `${BASE_URL}${url}/export/format?timeZone=${timeZone}`;
    } else {
        url = `${BASE_URL}${url}/${regionId}/export?timeZone=${timeZone}`;
        if (timeParse) url = `${url}&timeSlab=${timeParse}`;
        if (protocol) url = `${url}&protocol=${protocol}`;
        if (sortBy) url = `${url}&sortBy=${sortBy}`;
        if (status && status != DeviceStatusFilterValues.ALL.key) url = `${url}&status=${status}`;
        if (filter && filter.key && filter.key != WarningFilterStatus.All.key) url = `${url}&faultStatus=${filter.key}`;
        if (city) url = `${url}&cityId=${city}`;
        if (agency) url = `${url}&agencyId=${agency}`;
        if (operator) url = `${url}&operatorId=${operator}`;
        if (depot) url = `${url}&depotId=${depot}`;

    }


    return getValidToken().then((token) => {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: { Authorization: 'Bearer ' + token }
        });
    })

  
}

export function fetchVehicleLogs(vehicle) {
    let url = `${BASE_URL}${GET_VEHICLE_LOG}?vehicleNo=${vehicle}`;
    
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function fetchVehicleDownloads(region,pageNo) {
    var timeZone=localStorage.getItem('timeZone')
    let url = `${BASE_URL}api/master/vehicle/filesDetails/${region}?pageNo=${pageNo}&pageSize=10&timeZone=${timeZone}`;
    
    return getAPIPromise(url, {
        method: 'get'
    });
}

export function downloadTest(importId,fileName) {
    var timeZone=localStorage.getItem('timeZone')
    let url = `${BASE_URL}api/master/vehicle/downloadFile/v2?importId=${importId}&fileName=${fileName}&timeZone=${timeZone}`;
    
    return getValidToken().then((token) => {
        return FileDownLoader.download(url, {
            methods: 'GET',
            headers: { Authorization: 'Bearer ' + token }
        });
    })
}

export function updateVehicleStatus(vehicle, status) {
    let url = `${BASE_URL}${UPDATE_VEHICLE_STATUS}?vehicleNo=${vehicle}&status=${status}`;
    
    return getAPIPromise(url, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function fetchCameraLogs(cameraId) {
    let url = `${BASE_URL}${GET_CAMERA_LOG}/${cameraId}`;
    
    return getAPIPromise(url, {
        method: 'get'
    });
}