import React, {Component} from "react";
import PropTypes from "prop-types";
import {downLoadVehicleData} from "../../../services/vehicleService";
import './ExportButton.css'
import {reportsType} from "../../../views/Dashboard/reports/utils";
import Button from "./../../widgets/button";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../redux/map-to-props";


class ExportButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.selectedRegion) {
            return (
                <Button onClick={()=> {
                    this.exportAllDevice()
                }} className="export-icon" buttonText="Export">
                </Button>)
        }
        else {
            return null
        }
    }


    exportAllDevice() {
        if (this.props.data) {
            var csvContent = "";
            csvContent = `vehicle No,DeviceId,` + (this.props.type == reportsType.DISTANCE ? 'Distance' : 'GPS count') + '\n';
            if (this.props.data) {
                this.props.data.map((object)=> {
                    csvContent += `${object.vehicleNo},${object.deviceId},${object.distance || object.totalPoint}\n`;
                });
            }
            var csvData = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
            var csvURL = window.URL.createObjectURL(csvData);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', (this.props.type == reportsType.DISTANCE ? 'Distance' : 'GPS count') + ( new Date()).getTime() + '.csv');
            tempLink.click();
        } else
            downLoadVehicleData(this.props.exportKey, this.props.selectedRegion, this.props.timeSlab, this.props.selectedProtocol, this.props.selectedSort, this.props.selectedStatus, this.props.selectedFilter,
                this.props.selectedCity,this.props.selectedAgency,this.props.selectedOperator,this.props.selectedDepot).then((res)=> {
                console.log(res)
            }).catch((err)=> {
                console.log(err);
                if (err.status == 401 || err.message==='Invalid token') {
                    this.props.setTokenExpired(true);
                }
            });


    }


}

ExportButton.propTypes = {
    data: PropTypes.array,
    exportKey: PropTypes.string,
    selectedRegion: PropTypes.string,
    timeSlab: PropTypes.string,
    type: PropTypes.string,
    selectedProtocol: PropTypes.string,
    selectedSort: PropTypes.string,
    selectedStatus: PropTypes.string,
    selectedFilter: PropTypes.string,
    messageCallBack: PropTypes.func
};


export default connect(null, defaultMapDispatchToProps)(ExportButton);