import {actions} from '../../actions/action-list';
import  initialState from "./initialState";

function Notification(state = initialState, action) {
    switch (action.type) {
        case actions.NOTIFICATION:
            return Object.assign({}, state, {
                message: action.value
            });
        case actions.NOTIFICATION_REGION:
            return Object.assign({}, state, {
                notificationRegion:action.value
            })
        default:
            return state;
    }
}
export default Notification;