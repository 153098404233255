const validateRegion=(target,currentState)=>{
    let  formState=currentState;
    if (target.id == 'deploymentPort') {
        if (!target.value || parseInt(target.value) < 1025) {
            formState.valid = false;
            formState.deploymentPort = true;
        } else {
            formState.valid = !(formState.regionId || formState.endpoint || formState.protocols || formState.remotePort);
            formState.deploymentPort = false;
        }
    } else if (target.id == 'regionId') {
        if (!target.value.trim()) {
            formState.valid = false;
            formState.regionId = true;
        } else {
            formState.valid = !(formState.deploymentPort || formState.endpoint || formState.protocols || formState.remotePort);
            formState.regionId = false;
        }
    } else if (target.id == 'endpoint') {
        if (!target.value.trim()) {
            formState.valid = false;
            formState.endpoint = true;
        } else {
            formState.valid = !(formState.deploymentPort || formState.regionId || formState.protocols || formState.remotePort);
            formState.endpoint = false;
        }
    } else if (target.id == 'remoteport') {
        if (target.value && parseInt(target.value) < 1025) {
            formState.valid = false;
            formState.remotePort = true;
        } else {
            formState.valid = !(formState.regionId || formState.endpoint || formState.protocols || formState.deploymentPort);
            formState.remotePort = false;
        }
    } else if (target.id == 'protocols') {
        if (target.value.length == 0) {
            formState.valid = false;
            formState.protocols = true;
        } else {
            formState.valid = !(formState.deploymentPort || formState.regionId || formState.endpoint || formState.remotePort);
            formState.protocols = false;
        }
    } else if (target.id == 'edit') {
        formState = {
            valid: target.value.regionId && target.value.endpoint && (target.value.deploymentPort && target.value.deploymentPort >= 1025) && target.value.protocols,
            regionId: !target.value.regionId,
            endpoint: !target.value.endpoint,
            deploymentPort: !((target.value.deploymentPort && target.value.deploymentPort >= 1025) || !target.value.deploymentPort),
            remotePort: !(!target.value.remotePort || target.value.remotePort >= 1025),
            protocols: (target.value.protocols.length < 0),
            parsing_Enabled: (target.value.parsing_Enabled ? target.value.parsing_Enabled: false)

        };
    } else if (target.id == 'create') {
        formState = {
            valid: false,
            regionId: true,
            endpoint: true,
            deploymentPort: false,
            protocols: true,
            remotePort: false
        };
    }
    return formState;
}
export default validateRegion;