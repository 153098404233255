import React from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import { ButtonToolbar, Modal } from "react-bootstrap";
import { PageWrapper } from "../../../../shared/layout";
import {
  DatePicker,
  DropdownWrapper,
  Paginator,
} from "../../../../shared/widgets";
import { ExportButton, RegionSelector } from "../../../../shared/components";
import { MatrixBodyWrapper } from "../views/matrix-body/matrixBodyWrapper";
import {
  fetchGpsQuality,
  RestoreData,
  downloadFile,
  fetchVehiclesList,
  downLoadDistanceData
} from "../services/reportService";
import { routes } from "../../../../routing/routes";
import { reportsType, createGraphData } from "../utils";
import { defaultMapDispatchToProps } from "../../../../redux/map-to-props/";
import "./container.css";
import { CustomExport } from "../views/customExport";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { fetchVehicles } from "../../../../services/vehicleService";
import { exportDataTypeOptions, MessageType } from "../../../../Constant";
import DelayMatrix2 from "../../delaymatrix2/containers/delatMatrix";
import FrequencyDeviceList from "../views/device-frequency/deviceFrequencyReport";
import HealthReport from "../views/health-report/healthReport";
import DayFrequencyBodyWrapper from "../views/date-frequency/dateFrequencyReport"
import RSUreport from "../views/RSU-report/RSUreport";
import DeportReport from "../views/depot-report/depotReport";
import HaltReport from "../views/halt-report/haltReport";
import BsdAvailablityReport from "../views/bsd-availability-report/bsdAvailabilityReport"
import EtaAckMatrix from "../views/eta-acknow-matrix/etaAckMatrix"
import VehicleInOutEvent from '../views/Vehicle-in-out-event/VehicleInOutEvent';
import DeviceConfiguration from "../views/device-configuration/DeviceConfiguration";
class GPSQualityContainer extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    let email = localStorage.getItem("userName");
    this.state = {
      email: email,
      graphData: [],
      type: this.props.match.params.typeId,
      date: m.subtract(1, "day"),
      focused: null,
      pageNo: 0,
      totalPages: 0,
      exportOptions: [
        {
          key: "default",
          value: "Yesterday ",
        },
        {
          key: "custom",
          value: "Select Range",
        },
      ],
      backdrop: false,
      dataTypeOptions: exportDataTypeOptions,
      vehicles: [],
    };
    this.navigateToDetail = this.navigateToDetail.bind(this);
    this.navigateToDetailFrequency = this.navigateToDetailFrequency.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.fetchGPSQuality = this.fetchGPSQuality.bind(this);
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.typeId != this.state.type) {
      this.setState(
        {
          type: nextProps.match.params.typeId,
          dataType: null,
        },
        () => {
          this.handleDateChange(
            this.state.selectedRegion,
            this.state.selectedDate || this.state.date
          );
        }
      );
    }
  }
  
  getComponents = () => {
    const { type } = this.state;
    const { history, location } = this.props;
  
    switch (type) {
      case 'smsStatus':
        history.push({ pathname: '/' });
        break;
      case 'haltStatus':
        return <HaltReport history={history} pathname={location.pathname} />;
      case 'bsdAvailabilityReport':
        return <BsdAvailablityReport typeId={type} />;
      case 'vehicleInOutEvent':
        return <VehicleInOutEvent typeId={type} />;
      case 'deviceConfig':
        return <DeviceConfiguration typeId={type} history={history} />;
      default:
        return <EtaAckMatrix typeId={type} />;
    }
  }

  exportYesterdayDistance = ()=> {
    const startDate = moment(new Date()).subtract(1, 'day');
    const endDate = moment(new Date()).subtract(1, 'day');
    downLoadDistanceData(this.state.type, this.state.selectedRegion, startDate, endDate)
    .then(() => {
       this.props.initiateNotification({ type: "success", message: "Exported Successfully!" });
      })
    .catch((err) => {
        if ((err.status === 401 || err.message==='Invalid token')) {
            console.log('unauthorized', err.description)
           this.props.initiateNotification({ type: "error", message: "Authorization failed" });
        }else{
           this.props.initiateNotification({ type: "error", message: err.message ? err.message : "Error occured while exporting data" });
        }
      });
  }

  render() {
    return (
      <div className="report-section">
        {this.state.type == "distance" || this.state.type == "gpsQuality" ? (
          <PageWrapper.Header
            heading={
              this.state.type == reportsType.DISTANCE
                ? "Distance"
                : "GPS Quality"
            }
            hideS={true}
          >
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
                <RegionSelector
                  filterKey={routes.REPORT}
                  onRegionChange={this.handleRegionChange}
                />
                <DatePicker
                  date={this.state.date}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </ButtonToolbar>
            </div>
            <DropdownWrapper
              title="Export"
              menue={this.state.exportOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    if(this.state.type === "distance") {
                      this.exportYesterdayDistance();            
                    }else{
                      this.exportData();
                    }
                    break;
                  }
                  case "custom": {
                    this.open(this, null);
                    break;
                  }
                }
              }}
            ></DropdownWrapper>

            {this.displayModal()}

            {/* <ExportButton
                    selectedRegion={this.state.selectedRegion} data={this.state.data} type={this.state.type}/> */}
          </PageWrapper.Header>
        ) : this.state.type == "archiveData" ? (
          <PageWrapper.Header
            heading="Export Data"
            hideS={true}
          ></PageWrapper.Header>
        ) : null}

        {this.state.type == "distance" || this.state.type == "gpsQuality" ? (
          <MatrixBodyWrapper
            data={this.state.data}
            type={this.state.type}
            graphData={this.state.graphData}
            navigateTo={this.navigateToDetail}
          />
        ) : this.state.type == "archiveData" ? (
          <div className="archive-Wrapper">
            <div className="row">
              <div className="col-sm-2 labelText"> Data Type</div>
              <div className="col-sm-4">
                <Select
                  closeOnSelect={!this.state.stayOpen}
                  onChange={(value) => {
                    this.setState({ dataType: value });
                  }}
                  clearable={false}
                  options={this.state.dataTypeOptions}
                  placeholder="Select"
                  rtl={this.state.rtl}
                  value={this.state.dataType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2 labelText"> Select Date</div>
              <div className="col-sm-4">
                {" "}
                <DatePicker
                  date={this.state.date}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </div>
            </div>
            {this.state.dataType &&
              ["Raw", "Vehicle"].includes(this.state.dataType.value) && (
                <div className="row">
                  <div className="col-sm-2 labelText"> Select Region</div>
                  <div className="col-sm-4">
                    <RegionSelector
                      filterKey={routes.REPORT}
                      onRegionChange={this.handleRegionChange}
                    />
                  </div>
                </div>
              )}
            {this.state.dataType && this.state.dataType.value == "Vehicle" && (
              <div className="row">
                <div className="col-sm-2 labelText"> Select Vehicle</div>
                <div className="col-sm-4">
                  <Select
                    closeOnSelect={!this.state.stayOpen}
                    onChange={(value) => {
                      this.setState({ vehicleNo: value });
                    }}
                    clearable={false}
                    options={this.state.vehicles}
                    placeholder="Select"
                    rtl={this.state.rtl}
                    value={this.state.vehicleNo}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-sm-2"> </div>
              <div className="col-sm-4">
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    this.archiveData();
                  }}
                  disabled={
                    !this.state.dataType ||
                    (this.state.dataType.value == "Vehicle" &&
                      !this.state.vehicleNo)
                  }
                >
                  Export
                </Button>
              </div>
            </div>
            {this.emailValid(this.state.email) && (
              <div>
                <DelayMatrix2 />
              </div>
            )}
          </div>
        ) : this.state.type == "deviceFrequency" ? (
          <FrequencyDeviceList
            type={this.state.type}
            graphData={this.state.graphData}
            navigateToDetailFrequency={this.navigateToDetailFrequency}
          />
        ) : this.state.type == "dateFrequency" ? (
          <div>
            <DayFrequencyBodyWrapper
              type={this.state.type}
            />
          </div>
        ) : this.state.type == "health" ? (
          <div>
            <HealthReport/>
          </div>
        ) : this.state.type == "RSU" ? (
          <div>
            <RSUreport/>
          </div>
        ) : this.state.type == "depot" ? (
          <DeportReport
            history={this.props.history}
            pathname={this.props.location.pathname}
          />
        ) : this.getComponents()
        }
      </div>
    );
  }

  emailValid(email) {
    let array = email.split("@");
    console.log("email", array[1]);
    if (array[1] == "unthinkable.co" || array[1] === "daffodilsw.com") {
      return true;
    } else {
      return false;
    }
  }

  navigateToDetail(vehicleId, deviceId) {
    this.props.history.push(`${this.props.location.pathname}/${vehicleId}/`, {
      regionId: this.state.selectedRegion,
      deviceId: deviceId,
    });
  }

  navigateToDetailFrequency(vehicleId, deviceId, region) {
    this.props.history.push(`${this.props.location.pathname}/${vehicleId}/`, {
      regionId: region,
      deviceId: deviceId,
    });
  }

  archiveData() {
    let formattedDate = this.state.date.startOf("day").format("x");
    RestoreData(
      this.state.dataType.value,
      formattedDate,
      this.state.selectedRegion,
      this.state.vehicleNo ? this.state.vehicleNo.value : null,
      this.props.timezoneList.find((tz) => tz.regionId === this.state.selectedRegion)
    )
      .then((data) => {
        if (data) {
          downloadFile(data);
        }
        this.setState({
          dataType: null,
          vehicleNo: null,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          dataType: null,
          vehicleNo: null,
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else {
          if (err.status == 204) {
            this.props.initiateNotification({
              type: MessageType.ERROR,
              message: err.description || "No Data Found!",
            });
          }
        }
      });
  }

  handleRegionChange(region) {
    this.setState({ selectedRegion: region }, () => {
      if (this.state.dataType && this.state.dataType.value === "Vehicle") {
        this.fetchVehiclesList(this.state.selectedRegion);
      } else {
        this.handleDateChange(
          this.state.selectedRegion,
          this.state.selectedDate || this.state.date
        );
      }
    });
  }

  fetchVehiclesList(regionId) {
    fetchVehiclesList(regionId)
      .then((data) => {
        let tempArr = [];
        tempArr = data.map((val) => {
          return { label: val, value: val };
        });
        this.setState({
          vehicles: tempArr,
        });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }

  fetchGPSQuality(regionId, timestamp) {
    this.props.updateLoader(true);
    fetchGpsQuality(regionId, this.state.type, timestamp)
      .then((data) => {
        this.props.updateLoader(false);
        this.prepareGraphData(data.items);
        this.setState({
          data: data.items,
        });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
        if (this.mounted) {
          this.props.updateLoader(false);
          this.setState({
            data: [],
          });
        }
        this.prepareGraphData([]);
      });
  }

  prepareGraphData(items) {
    createGraphData(
      items,
      this.state.type,
      this.state.type == reportsType.DISTANCE ? "distance" : "totalPoint"
    )
      .then((data) => {
        this.setState({
          graphData: data,
        });
      })
      .catch((err) => {});
  }

  dateChange(date) {
    this.setState({ selectedDate: date, date: date });
    this.handleDateChange(this.state.selectedRegion, date);
  }

  handleDateChange(regionId, date) {
    let formattedDate = date.startOf("day").format("x");
    if (this.state.type == "distance" || this.state.type == "gpsQuality") {
      this.fetchGPSQuality(regionId, parseInt(formattedDate));
    }
  }

  exportData() {
    var csvContent = "";
    csvContent =
      `vehicle No,DeviceId,` +
      (this.state.type == reportsType.DISTANCE ? "ODM Distance," : "") +
      (this.state.type == reportsType.DISTANCE ? "Distance," : "GPS count,") +
      "Date" +
      "\n";
    if (this.state.data) {
      this.state.data.map((object) => {
        if (this.state.type == reportsType.DISTANCE) {
          csvContent += `${object.vehicleNo},${object.deviceId},${
            object.odmDistance ? object.odmDistance : 0
          },${object.distance || object.totalPoint || 0},${moment(
            object.fromTimestamp
          ).format("DD-MM-YYYY")}\n`;
        } else {
          csvContent += `${object.vehicleNo},${object.deviceId},${
            object.distance || object.totalPoint || 0
          },${moment(object.fromTimestamp).format("DD-MM-YYYY")}\n`;
        }
      });
    }
    var csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      (this.state.type == reportsType.DISTANCE ? "Distance" : "GPS count") +
        new Date().getTime() +
        ".csv"
    );
    tempLink.click();
  }

  /**
   * Call when Add/Update modal Open.
   */
  open(data) {
    this.setState({
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal() {
    return (
      <Modal
        dialogClassName="custom-range-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Select Range" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CustomExport
              selectedRegion={this.state.selectedRegion}
              type={this.state.type}
              onComplete={(e, msg) => {
                // this.props.initiateNotification({
                //     type: e,
                //     message: msg
                // });
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
}

function mapStateToProps(state) {
  return {
      timezoneList: state.Timezones.timezones
  };
}

export default connect(mapStateToProps, defaultMapDispatchToProps)(GPSQualityContainer);
