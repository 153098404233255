import React from "react";
import "./tabsComponent.css";

export const Tabs = ({tabs, activeTab, onTabSelection})=> {
    return <ul className="tab-list ">
        {tabs.map((tab)=> {
            return <li key={tab.key}
                       className={activeTab == tab.key ? "tab-item active" : "tab-item"} onClick={()=> {
                onTabSelection(tab.key)
            }}>{tab.display}</li>
        })}
    </ul>
};

