import React from "react";
import Select from "react-select";
import {connect} from "react-redux";
import {MapWrapperComponent} from "../map/mapWrapper";
import {PageWrapper} from "../../../shared/layout";
import {fetchVehicles} from "../../../services/vehicleService";
import {fetchDeviceDetail} from "../../../services/deviceService";
import {MessageType, defaultLatitude, defaultLongitude} from "../../../Constant";
import "./liveMapWrapper.css";
import {defaultMapDispatchToProps} from "../../../redux/map-to-props/";
class LiveMapWrapper extends React.Component {
    constructor(props) {
        super(props);
        let protocol = window.location.protocol;
        let imgUrl = `${protocol}//gps.chalo.com/assets/images`;
        this.state = {
            data: {},
            center: {lat: defaultLatitude, lng: defaultLongitude},
            vehicles: [],
            icon: {
                url: `${imgUrl}/live_bus2.png`,
                scaledSize: {
                    width: 50,
                    height: 50
                }
            }
        };
        this.fetchVehicles = this.fetchVehicles.bind(this);
        this.fetchDeviceDetail = this.fetchDeviceDetail.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onMapTouch = this.onMapTouch.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        const mapWrapper = document.getElementsByClassName('map-wrapper');
        // mapWrapper.addEventListener("touchstart", this.onMapTouch, false);
    }

    onMapTouch(event) {
        this.focusOutReactSelectInput();
    };

    onChange(value) {
        this.focusOutReactSelectInput();
        if (!value) {
            this.stopPolling();
            this.setState({
                data: {},
                value: value
            });
        } else {
            this.setState({
                value: value,
            }, ()=> {
                this.stopPolling();
                if (value)
                    this.initiateDeviceDetailPolling(value.deviceId, value.regionId);
            });
        }

    }

    render() {
        return (
            <div className="wrapper">
                <PageWrapper.Header heading="Live Map"
                                    hideS={true}/>
                <PageWrapper.Body>
                    <PageWrapper.BodyHeader>
                        <div >
                            <p className="select-lable">Search Vehicle:</p>
                            <div className="select-input">
                                <Select.Async value={this.state.value} onChange={this.onChange}
                                              valueKey="deviceId" labelKey="vehicleNo"
                                              loadOptions={this.fetchVehicles}
                                              backspaceRemoves={this.state.backspaceRemoves}/>

                            </div>
                        </div>
                    </PageWrapper.BodyHeader>
                    <div className="map-wrapper" onTouchStart={this.onMapTouch}>
                        <MapWrapperComponent data={this.state.data} height={`500px`}
                                             center={this.state.center}
                                             icon={this.state.icon}
                                             defaultCurrentLocation={true}
                                             onMessage={(e)=> {
                                                 this.props.initiateNotification(e)
                                             }}
                        />
                    </div>
                </PageWrapper.Body>

            </div>)
    }

    focusOutReactSelectInput() {
        let conatiner = document.getElementsByClassName('Select-input');
        conatiner[0].firstChild.blur();
    }

    fetchVehicles(key) {
        if (!key) {
            return Promise.resolve({options: []});
        }
        if (this.mounted)
            this.setState({
                loading: true
            });
        return fetchVehicles(key).then((response)=> {
            return {
                options: response.items
            }
        }).catch((err)=> {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else {
                this.props.initiateNotification({
                    type: MessageType.ERROR,
                    message: err.description || 'Error occurred while fetching vehicles'
                });
                this.setState({
                    loading: false
                });
            }
        })
    }

    initiateDeviceDetailPolling(deviceId, regionId) {
        this.firstTimeRender = true;
        this.fetchDeviceDetail(deviceId, regionId);
        this.intervalID = setInterval(()=> {
            if (!document.hidden)
                this.fetchDeviceDetail(deviceId, regionId)
        }, 10000);
    }

    stopPolling() {
        if (this.intervalID) {
            clearInterval(this.intervalID)
        }
    }

    fetchDeviceDetail(deviceId, regionId) {
        if (this.mounted) this.setState({
            loading: true
        });
        fetchDeviceDetail(deviceId, regionId).then((response)=> {
            if (this.mounted) {
                this.setState({
                    data: response
                });
                if (this.firstTimeRender) {
                    this.firstTimeRender = false;
                    this.setState({
                        center: {
                            lat: parseFloat(response.latitude),
                            lng: parseFloat(response.longitude)
                        }
                    })
                }
            }
        }).catch((err)=> {
            console.log(err);
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else {
                this.props.initiateNotification({
                    type: MessageType.ERROR,
                    message: err.description || 'Error occurred while fetching details'
                });
                if (this.mounted) this.setState({
                    loading: false
                });
            }

        })
    }

    componentWillUnmount() {
        this.mounted = false;
        this.stopPolling();
    }
}


export default connect(null, defaultMapDispatchToProps)(LiveMapWrapper);