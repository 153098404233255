import React from "react";
import {isNumber} from "./form-views/validators";
import {SelectValue, InputField} from "./form-views";
import {CONFIG_CMDS, timeZones, Apns} from "../utils";
import {SelectIgnitionInterval} from "./selectTimeInterval";
import RegionSelector from "./../../../../shared/components/RegionSelector";

export class DeviceConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'Apn': Apns.IDEA,
            'timeZone': timeZones.IST,
            'protocolId': 'itriangle_ts101'
        };
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    render() {
        const {flag}=this.props;
        return <div>
            {flag == CONFIG_CMDS.FRESH_CONFIG && <InputField
                isRequired={true}
                title="Device Id"
                onChange={(value, error)=> {
                    this.handleValueChange('deviceId', value, error);
                }}
            />}
            <InputField isRequired={true}
                        validator={isNumber}
                        readOnly={this.props.number}
                        defaultValue={this.props.number}
                        title="Phone Number"
                        onChange={(value, error)=> {
                            this.handleValueChange('phoneNumber', value, error);
                        }}
            />
            {flag == CONFIG_CMDS.FRESH_CONFIG &&
            <SelectValue
                title="Protocol"
                keyValue="protocolId"
                selectedValue={this.state.protocolId}
                useData={true}
                data={this.props.protocols}
                dataKey="protocolId"
                onSelect={this.handleValueChange}
            />}
            {(flag == CONFIG_CMDS.SET_INTERVAL || flag == CONFIG_CMDS.FRESH_CONFIG) &&
            <SelectIgnitionInterval
                hour={false}
                onValueChange={(details)=> {
                    this.setState(details, this.fireData);
                }}/>}
            {(flag == CONFIG_CMDS.APN_SETTING || flag == CONFIG_CMDS.FRESH_CONFIG) &&
            <div>
                <div className="row setting-item">
                    <div className="col-sm-3 label ">Region Port</div>
                    <div className="col-sm-9">
                        <RegionSelector onRegionChange={(value)=> {
                            this.handleValueChange('regionId', value);
                        }}/></div>
                </div>
                <SelectValue
                    title="Apn"
                    keyValue="Apn"
                    menues={Apns}
                    selectedValue={this.state.Apn.value}
                    onSelect={this.handleValueChange}
                />
            </div>}
            {(flag == CONFIG_CMDS.SET_TIMEZONE || flag == CONFIG_CMDS.FRESH_CONFIG) &&
            <SelectValue
                title="Time zone"
                keyValue="timeZone"
                menues={timeZones}
                selectedValue={this.state.timeZone.value}
                onSelect={this.handleValueChange}
            />}
            {flag == CONFIG_CMDS.RESTART &&
            <div>
                This action is gonna restart the device!</div>}
        </div>

    }

    /**
     * handles value change events
     * @param key
     * @param value
     */
    handleValueChange(key, value, error) {
        let state = {};
        state[`${key}Error`] = error;
        state[key] = value;
        this.setState(state, this.fireData);
    }

    fireData() {
        let formError = Object.keys(this.state).every(key=> {
            if (key.includes('Error')) {
                return !this.state[key]
            } else {
                return true
            }
        });
        this.props.onDataUpdate(this.state, !formError);
    }
}