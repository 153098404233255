const validateStops = (formState) => {
  let form = {};
  if (!formState.stopId || !formState.city) {
    form._error = "Required field missing";
    form.invalid = true;
  } else {
    form.invalid = false;
  }
  return form;
};
export default validateStops;
