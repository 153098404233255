import React from "react";
import { connect } from "react-redux";
import { templateStrings, dateFormat } from "../../../../../Constant";
import * as moment from "moment";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { fetchCameraLogs } from "../../../../../services/vehicleService";
import { EpochtoEpochTZ } from "../../../../../shared/utils";

class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraId: this.props.match.params.cameraId,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchCameraLogs();
  }

  render() {
    const templatestrings = templateStrings.cameraLogs;
    return (
      <div>
        <PageWrapper.Header
          heading={this.state.cameraId ? this.state.cameraId : "Logs"}
          hideS={true}
        ></PageWrapper.Header>
        <PageWrapper.Body>
          <TableWrapper>
            <TableWrapper.Header columns={templatestrings} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.length > 0 &&
                  this.state.content.map((data) => {
                    return this.displayList(data, templatestrings);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length === 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
        </PageWrapper.Body>
      </div>
    );
  }

  fetchCameraLogs() {
    if (this.mounted) this.props.updateLoader(true);
    fetchCameraLogs(this.state.cameraId)
      .then((res) => {
        if (this.mounted) {
          this.setState({
            content: res,
          });
          this.props.updateLoader(false);
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.props.updateLoader(false);
      this.setState({ content: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching vehicle log data",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Used to show  data in row in List view
   * @param item  Object of item to display
   */
  displayList(item, tableHeaderMappings) {
    const getOtherAuditColumn = (column)=>{
      return item[column] ? item[column] : "";
    }
    return (
      <tr key={item.id}>
        {Object.keys(tableHeaderMappings).map((column, index) => {
          return (
            <td
              className="master-table"
              data-toggle="tooltip"
              title={
                column !== "action" && column !== "modifiedDate"
                  ? item[column]
                  : ""
              }
              key={index}
            >
              {column == "modifiedDate"
                ? moment(
                    new Date(
                      EpochtoEpochTZ(
                        parseInt(item.modifiedDate),
                        localStorage.getItem("timeZone")
                      )
                    )
                  ).format(dateFormat)
                : getOtherAuditColumn(column)}
            </td>
          );
        })}
      </tr>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(Audit);
