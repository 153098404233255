import {MessageType} from "../../Constant";
import {setTokenExpired} from "../actions/authActions";
import {setNotificationMessage} from "../actions/notificationActions";
import {fetchCityMetaData} from "../../services/metaService";
import {fetchedCityMeta} from "../actions/cityMetaActions";
export function cityMetaActionCreator(city) {
    return (dispatch)=> {
        fetchCityMetaData(city).then((response) => {
            dispatch(fetchedCityMeta(city, response));
        }).catch((err) => {
            if ((err.status === 401 || err.message==='Invalid token')) {
                dispatch(setTokenExpired(true))
            } else {
                if (err.status != 204) {
                    dispatch(fetchedCityMeta(city, {}));
                    dispatch(setNotificationMessage({
                        type: MessageType.ERROR,
                        message: err.description ? err.description : "Error while fetching city meta data"
                    }));
                }
            }
            console.log(err);
        });
    }
}