import {resetLoading, hideLoading, showLoading} from "../../shared/widgets";
import {setTokenExpired} from "../actions/authActions";
import {setNotificationMessage,setNofificationRegion} from "../actions/notificationActions";
/**
 * this is the default mapping of required dispatches to props.
 * @param dispatch
 * @param state
 * @returns {{updateLoader: (function(*=)), setTokenExpired: (function(*=)), initiateNotification: (function(*=))}}
 */
export const defaultMapDispatchToProps = (dispatch, state)=> {
    return {
        updateLoader: value => {
            if (value) {
                dispatch(resetLoading('header'));
                dispatch(showLoading('header'));
            }
            else {
                dispatch(hideLoading('header'))
            }
        },
        setTokenExpired: value=> {
            dispatch(setTokenExpired(value))
        },
        initiateNotification: value=> {
            dispatch(setNotificationMessage(value))
        },
        updateNotificationRegion: value => {
            dispatch(setNofificationRegion(value))
        }
    };
};

