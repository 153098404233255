import React from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import SettingsPage from "../../../views/Dashboard/settings/settingsPage";
import "./changeTimeButton.css";

export default class ChangeTimeButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSelectModal: false
        };

    }


    componentDidMount() {
        this.mounted = true;
    }

    render() {
        return (<span>
            <a onClick={() => {
                this.openSelectModal();
            }}>
                 <span
                     className="span-icon settings-icon"/>
            </a>
                {this.displaySelectModal()}
        </span>
        );
    }

    /*
     * open the modal and hit fetch healthcheck API
     *
     */
    openSelectModal() {
        this.setState({
            showSelectModal: true
        });
    }

    displaySelectModal() {
        const {hideTimeParse}=this.props;
        return (

            <Modal dialogClassName="custom-modal" show={this.state.showSelectModal} bsSize="large"
                   onHide={() => {
                       this.setState({showSelectModal: false, invalidJson: false});
                       this.formData = null;
                   }}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Time Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SettingsPage hideTimeParse={hideTimeParse}/>
                </Modal.Body>
            </Modal >
        )

    }

    componentWillUnmount() {
        this.mounted = false;
    }
}
ChangeTimeButton.propTypes = {
    hideTimeParse: PropTypes.bool
};
