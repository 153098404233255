export function getUserCurrentLocation() {
    return new Promise((resolve, reject)=> {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                resolve(pos);
            }, function (err) {
                reject(err);
            });
        }
        else {
            reject('Navigator geolocation object undefined')
        }
    })
}
export function getGeoCoder() {
    return new Promise((resolve, reject)=> {
        if (window.google) {
            resolve(new window.google.maps.Geocoder());
        } else {
            reject('error');
        }
    })

}