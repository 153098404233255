import React from "react";
import {connect} from "react-redux";
import * as moment from "moment";
import {PageWrapper} from "../../../../shared/layout";
import {RangePicker,RangePickerforFrequency,DatePicker} from "../../../../shared/widgets";
import {MatrixBodyWrapper} from "../views/matrix-body/matrixBodyWrapper";
import {fetchVehicleDetail,fetchFrequency} from "../services/reportService";
import {reportsType, createLineGraphData, createFrequencyGraphData} from "../utils";
import "./container.css";
import {defaultMapDispatchToProps} from "../../../../redux/map-to-props/index";
import { ButtonToolbar } from "react-bootstrap";
import { FrequencyGraphViewComponent } from "../views/graph-view/frequencyGraphViewComponent";
import { SelectFilter } from '../../../../shared/widgets/selectFilter/selectFilter';
import { ViewListFrequency } from "../../reports/views/list-view/viewListFrequencyComponent";
import DepotListItem from "../../reports/views/depot-report/depotListItem";
class DetailViewContainer extends React.Component {
    constructor(props) {
        super(props);
        let m = moment(new Date());
        let m1 = moment(new Date());
        let m2 = moment(new Date());
        let mf1 = moment(new Date());
        let mf2 = moment(new Date());
        this.state = {
            id: this.props.match.params.vehicleId,
            type: this.props.match.params.typeId,
            regionId: this.props.location.state.regionId,
            deviceId: this.props.location.state.deviceId,
            endDate: m1.subtract(1, 'day'),
            startDate: m2.subtract(7, 'days'),
            graphData: [{id: 'graph', data: [], color: '#ff0000'}],
            frequencyData: [],
            freqEndDate: mf1.subtract(1, 'day'),
            freqStartDate: mf2.subtract(7, 'days'),
            selectedDate: m.subtract(1,'day'),
            selectedRegion:this.props.location.state.regionId

        };
        this.handleDatesChange = this.handleDatesChange.bind(this);
        // this.dateChange = this.dateChange.bind(this);
        this.handleFrequencyDatesChange = this.handleFrequencyDatesChange.bind(this);
    }

    componentDidMount() {
        if(this.state.type!='depot'){
        if(this.state.type!="deviceFrequency"){
            this.handleDatesChange({startDate: this.state.startDate, endDate: this.state.endDate});
        }
        else{
            // this.handleFrequencyDatesChange(this.state.selectedDate);
            this.handleFrequencyDatesChange({startDate: this.state.freqStartDate, endDate: this.state.freqEndDate});
        }
    }
    }
    render() {
       return this.state.type!="deviceFrequency" && this.state.type!='depot'?
       <div className="detail-view">
        <PageWrapper.Header
            heading={this.state.id}
            hideS={true}
        ><RangePicker startDate={this.state.startDate} endDate={this.state.endDate} monthsToDisplay={2}
                      onDatesChange={this.handleDatesChange}/></PageWrapper.Header>
        <MatrixBodyWrapper type={reportsType.GPS_QUALITY_DETAIL} data={this.state.data} detailType={this.state.type}
                           graphData={this.state.graphData}/>
        </div>:
        this.state.type=='deviceFrequency' ?
        <div>
        <PageWrapper.Header
            heading={this.state.id}
            hideS={true}>
          <div className="dataTables_length wrapper-gps"
                    id="dataTables-example_length">
                    <ButtonToolbar>
                        <SelectFilter placeholder={this.state.regionId} disabled/>
                        {/* <DatePicker date={this.state.selectedDate} onDateChange={this.dateChange} withoutDefaultBorder={false} /> */}
                        <RangePickerforFrequency startDate={this.state.freqStartDate} endDate={this.state.freqEndDate} minimumNights={6} monthsToDisplay={2} onDatesChange={this.handleFrequencyDatesChange}/>
                    </ButtonToolbar>
            </div> 
        </PageWrapper.Header>
        <PageWrapper.Body>
            <div>
                <FrequencyGraphViewComponent data={this.state.frequencyData}/>
            </div>
        </PageWrapper.Body>
        <PageWrapper.Body>
            <div className="report-list">
            <ViewListFrequency
                type={this.props.type}
                loading={this.props.loading}
                detailType={this.props.detailType}
                content={this.state.data}
              />            
            </div>
        </PageWrapper.Body>
        </div>
        :
        <div>
            <div className="pull-left" onClick={() => {
                this.back();
            }}><i className="fa fa-arrow-left" aria-hidden="true"></i></div>
            <PageWrapper.Header
            heading={this.state.id}
            hideS={true}>
        </PageWrapper.Header>
        <PageWrapper.Body>
            <div className="report-list">
            <DepotListItem
                type={this.props.type}
                loading={this.props.loading}
                content={this.props.location}
              />            
            </div>
        </PageWrapper.Body>        
        </div>
    }

    handleDatesChange(dates) {
        if (dates.startDate && dates.endDate) {
            let startDate = parseFloat(dates.startDate.startOf('day').format('x'));
            let endDate = parseFloat(dates.endDate.endOf('day').format('x'));
            this.fetchVehicleDetail(startDate, endDate)
        }
    }
    back(){
        window.history.back();
    }
    // dateChange(date) {
    //     console.log("dste is changes",date,typeof(date))
    //     this.setState({selectedDate:date});
    //     this.handleFrequencyDatesChange(date);
    // }
    // handleFrequencyDatesChange(dates) {
    //         let startDate = parseInt(dates.startOf('day').format('x'));
    //         let endDate = parseInt(dates.endOf('day').format('x'))+1;
    //         this.fetchFrequency(startDate,endDate,this.state.selectedRegion);
    // }
    handleFrequencyDatesChange(dates) {
            let startDate = parseFloat(dates.startDate.startOf('day').format('x'));
            let eMoment = moment(dates.startDate);
            let endDateMoment = eMoment.add(6,'days')
            let endDate = parseFloat(endDateMoment.endOf('day').format('x'))+1;
            this.fetchFrequency(startDate,endDate,this.state.selectedRegion);
    }

    fetchFrequency(startDate,endDate,regionId){
     this.props.updateLoader(true);
        fetchFrequency(this.state.deviceId,startDate,endDate,regionId).then((data) => {
            console.log("Data-->",data)
            this.props.updateLoader(false);
            this.prepareGraphData(data);
            this.setState({
                data: data
            })
        }).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.setTokenExpired(true);
            }
            if (this.mounted) {
                this.props.updateLoader(false);
                this.setState({
                    data: []
                });
            }
            this.prepareGraphData([]);
        })
    }

    prepareGraphData(items) {
        createFrequencyGraphData(items).then(data => {
            this.setState({
                frequencyData: data
            })
        }).catch(err => {
        });
    }

    fetchVehicleDetail(startTime, endTime) {
        this.props.updateLoader(true);
        fetchVehicleDetail(this.state.regionId, this.state.type, startTime, endTime, this.state.deviceId).then((data)=> {
            this.setState({
                data: data
            });
            this.props.updateLoader(false);
            createLineGraphData(data, this.state.type).then((graphData)=> {
                this.setState({graphData: graphData})
            })
        }).catch((err)=> {
            this.props.updateLoader(false);
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            }
        })
    }
}

export default connect(null, defaultMapDispatchToProps)(DetailViewContainer);

