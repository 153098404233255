import * as moment from "moment";
import {DistanceHeader} from "./views/distanceHeader";
import {sortOption} from "../../../shared/widgets/sortingView/sortingView";

export const gpsQualityScaleInterval = 500;
export const gpsQualityScaleLimit = 3000;
export const distanceScaleInterval = 25;
export const distanceScaleLimit = 200;
export const markerLineColor = '#b0413e';
export var distanceFilterRange = {
    ALL: {
        key: "All",
        value: "All"
    }
};
export var gpsFilterRange = {
    ALL: {
        key: "All",
        value: "All"
    },
};

export const frequencyByRegionTemplateStrings = {
    vehicleNo: 'Vehicle No.',
    deviceId: 'Device Id',
    zeroToFive: {
        display: '0-5 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    fiveToTen: {
        display: '5-10 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    tenToTwenty: {
        display: '10-20 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    twentyToThirty: {
        display: '20-30 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    thirtyToSixty: {
        display: '30-60 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    sixtyToOneTwenty: {
        display: '60-120 sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    greaterThanOneTwenty: {
        display: '120+ sec',
        sortable: true,
        defaultSort: sortOption.ASC
    },


};

export const RSUTemplateStrings={
    deviceId: 'ICCID',
    srId: 'SR Id',
    timestamp:{
        display:'Created Date',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    createdBy: 'Created By',
    actions: 'Actions'
};

export const SMSStatusTemplateStrings={
    status: 'Status',
    number: 'Number',
    message: 'Message Sent',
    responseMessage:'Response Message',
    requestArrivedAt:{
        display:'Request Time',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    responseArrivedAt:{
        display:'Response Time',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    delay:{
        display:'Delay(sec)',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    serviceProvider:'Provider',
};

export const viewListfrequencyByRegionTemplateStrings = {
    vehicleNo: 'Vehicle No.',
    color: 'Day',
    fromTimestamp: 'Date',
    zeroToFive: {
        display: '0-5 sec',
    },
    fiveToTen: {
        display: '5-10 sec',
    },
    tenToTwenty: {
        display: '10-20 sec',
    },
    twentyToThirty: {
        display: '20-30 sec',
    },
    thirtyToSixty: {
        display: '30-60 sec',
    },
    sixtyToOneTwenty: {
        display: '60-120 sec',
    },
    greaterThanOneTwenty: {
        display: '120+ sec',
    },
};

export const gpsQualityTemplateStrings = {
    vehicleNo: 'Vehicle',
    deviceId: 'Device Id',
    totalPoint: {
        display: 'GPS Count',
        sortable: true,
        defaultSort: sortOption.ASC
    }

};
export const health ={
    vehicle_no: 'Vehicle',
    device_id: 'Device Id',
    health: 'Status',
    distance: {
        display: 'Distance(Km)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    percentage: {
        display: 'Freq %(0-10sec)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    avgDelay: {
        display: 'Avg Delay(sec)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    delayPacketCount: {
        display: 'Delay Pkt Count(%)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    points: {
        display: 'GPS Point',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    futureDatePoints: {
        display: 'Future Point(%)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    pastDatePoints: {
        display: 'Past Point(%)',
        sortable: true,
        defaultSort: sortOption.ASC
    },
    delay90thPercentile:{
        display: 'Delay 90 (%)',
        sortable: true,
        defaultSort: sortOption.ASC
    }
};

export const haltTemplateStrings ={
    device_id: 'Device Id',
    vehicleNo: 'Vehicle',
    latitude:'Latitude',
    longitude:'Longitude',
    startHaltTime:'Start Halt Time',
    endHaltTime:'End Halt Time',
};

export const gpsQualityDetailTemplateStrings = {
    totalPoint: 'GPS Count',
    totalFuturedatePoint: 'Future Date Points',
    totalPastdatePoint: 'Past Date Points',
    totalUniquePoint: 'Unique Points',
    totalZerolatlongPoint: 'Zero lat lng Points',
    fromTimestamp: {
        display: 'Date',
        sortable: true,
        defaultSort: sortOption.ASC
    }
};


export const distanceTemplateStrings = {
    vehicleNo: 'Vehicle',
    deviceId: 'Device Id',
    odmDistance:'ODM Distance(Km)',
    distance: {
        component: DistanceHeader,
        display: 'GPS Count',
        sortable: true,
        defaultSort: sortOption.ASC
    },
};

export const distanceDetailTemplateStrings = {
    distance: {
        component: DistanceHeader
    },
    fromTimestamp: {
        display: 'Date',
        sortable: true,
        defaultSort: sortOption.ASC
    }
};

export const reportsType = {
    DISTANCE: 'distance',
    GPS_QUALITY: 'gpsQuality',
    GPS_QUALITY_DETAIL: ''
};

export const DepotReport={
    cityName:'City',
    depotName:'Depot Name',
    inVehicles: {
        display:'In Vehicle Count',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    outVehicles: {
        display:'Out Vehicle Count',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    unknownVehicles: {
        display:'Unknown Vehicle Count',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    // inVehicles: 'In Vehicle Count',
    // outVehicles:'Out Vehicle Count',
    // unknownVehicles:'Unknown Vehicle Count'
}

export const DepotVehiclesDetail={
    vehicleNo: 'Vehicle',
    device_id:'DeviceId',
    agency:'Agency',
    operator:'Operator',
    timestamp:'Event Time',
}

export const BsdAvailablityReportHeadersConfig = {
    deviceId:'DeviceId',
    protocol:'Protocol',
    status:'Status'
}


export const EtaAckMatrixHeadersConfig = {
    deviceId:{
        display:"DeviceId",
        // sortable:true,
        // defaultSort: sortOption.DESC
    },
    totalNumberOfMsg:{
        display:"Total Message Sent",
        sortable:true,
        defaultSort: sortOption.DESC
    },
    totalNumberOfAck: {
        display:'Total Acknowledgement Received',
        sortable: true,
        defaultSort: sortOption.DESC
    },
    averageTimeDifference:{
        title: '(msg_sent_time - ack_rcvd_time)/total_ack_rcvd',
        display:"Average Time Difference",
        sortable:true,
        defaultSort: sortOption.DESC
    },
    city:{
        display:"City",
        // sortable:true,
        // defaultSort: sortOption.DESC
    }  
}

export function createLineGraphData(data, type) {
    return new Promise((resolve, reject)=> {
        try {
            let graphData = new Array();
            let dataKey = type === reportsType.DISTANCE ? 'distance' : 'totalPoint';
            graphData.push({id: type, data: [], color: '#309bf4'});
            data.sort((a, b)=> {
                return a['fromTimestamp'] - b['fromTimestamp'];
            });
            data.forEach((item)=> {
                let obj = {};
                obj['x'] = moment(item.fromTimestamp).format('YYYY-MM-DD');
                obj['y'] = item[dataKey];
                obj['fromTimestamp'] = item.fromTimestamp;
                graphData[0].data.push(obj);
            });
            resolve(graphData);
        }
        catch (err) {
            console.log("err", err);
            reject('error while extracting graph data');
        }
    })
}

function updateRange(type, scale, lowerRange, upperRange) {
    if (type === reportsType.DISTANCE) {
        distanceFilterRange[scale] = {
            key: scale,
            value: upperRange ? `${lowerRange} to ${upperRange}` : `greater than ${lowerRange} `,
            lowerRange: lowerRange,
            upperRange: upperRange
        }
    } else {
        gpsFilterRange[scale] = {
            key: scale,
            value: upperRange ? `${lowerRange} to ${upperRange}` : `greater than ${lowerRange} `,
            lowerRange: lowerRange,
            upperRange: upperRange
        }
    }
}
export function createGraphData(data, type, key) {
    return new Promise((resolve, reject)=> {

        try {
            let totalBuckets, interval, limit;
            if (type === reportsType.DISTANCE) {
                interval = distanceScaleInterval;
                limit = distanceScaleLimit;
            } else {
                interval = gpsQualityScaleInterval;
                limit = gpsQualityScaleLimit;
            }
            totalBuckets = (limit / interval) + 1;
            let buckets = new Array();
            for (var i = 0; i < totalBuckets; i++) {
                let lowerScale = i * interval;
                let upperScale = (i + 1) * interval;
                if (lowerScale == limit) {
                    buckets.push({scale: `${lowerScale}+`, count: 0, countColor: '#309bf4'});
                    updateRange(type, `${lowerScale}-${upperScale}`, lowerScale, null);
                } else {
                    buckets.push({scale: `${lowerScale}-${upperScale}`, count: 0, countColor: '#309bf4'});
                    updateRange(type, `${lowerScale}-${upperScale}`, lowerScale, upperScale);
                }
            }
            data.forEach((item)=> {
                let index;
                if (item[key] > limit) {
                    index = totalBuckets - 1;
                } else {
                    index = Math.floor(item[key] / interval);
                }
                buckets[index].count++;
            });
            resolve(buckets);
        }
        catch (err) {
            reject('error while extracting graph data');
        }
    })
}

export function createFrequencyGraphData(data){
    return new Promise((resolve, reject)=> {
        try {
            var days=[]
            for(var i=0;i<data.length;i++){
                days[i]=[];
            }
            for(var i=0;i<data.length;i++){
                days[i].push(data[i].fromTimestamp)
                days[i].push(data[i].zeroToFive)
                days[i].push(data[i].fiveToTen)
                days[i].push(data[i].tenToTwenty)
                days[i].push(data[i].twentyToThirty)
                days[i].push(data[i].thirtyToSixty)
                days[i].push(data[i].sixtyToOneTwenty)
                days[i].push(data[i].greaterThanOneTwenty)
            }
            resolve(days);
        }
        catch (err) {
            reject('error while extracting graph data');
        }
    })
}

export const VehicleInOutEventsHeaderConfig = {
    // id: "Vehicle Id",
    vehicleNo: "Vehicle No",
    deviceId: "Device Id",
    city: "City",
    agency: "Agency",
    depot: "Depot",
    operator: "Operator",
    // regionId: "Region Id",
    timestamp: "Time In Packet",
    // fromTimestamp: "From Timestamp",
    // toTimestamp: "To Timestamp",
    status: "Status"
}


export const DeviceConfigurationConfig={
    "deviceId": "Device Id",
    "command": "Command",
    "commandSentAt": "Cmd Sent At",
    "commandStatus": "Cmd Status",
    "commandResponseStatus": "Cmd Response Status",
    "commandResponse": "Cmd Response",
    "action":"Action"
};