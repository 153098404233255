import RegionSelector from "./RegionSelector";
import withRegions from "../../../views/Dashboard/region/HOC";
import {changeDeviceRegion} from "../../../redux/actions/settingActions";

const mapStateToProps = (state)=> {
    return {
        selectedRegion: state.Settings.selectedRegion,
    };
};
const mapDispatchToProps = (dispatch)=> {
    return {
        regionSelectChange: value => {
            dispatch(changeDeviceRegion(value))
        }
    };
};
export  default  withRegions(RegionSelector, mapStateToProps, mapDispatchToProps);