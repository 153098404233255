import React from "react";
import {MasterInfoItem} from "./masterInfoItem";
import { masterType } from "../../utils";
import { CreateLanguageWithCity } from "../createView/createLanguageWithCity";
export const VehicleMasterView = (props)=>{
    const propsForCreateLanguageWithCity = {
        initiateNotification:props.initiateNotification,
        onComplete:props.onComplete,
        onClose:props.onClose,
        editMode:true,
        serverRow:props.data,
        synLanguageTableAfterIndividualLangRemove:props.synLanguageTableAfterIndividualLangRemove
    }
    return (
    <div className="row vehicle-info">
        <div className="col-sm-12">
            {Object.keys(props.data).map(columnKey=> {
                return <MasterInfoItem key={columnKey} label={columnKey} value={props.data[columnKey]} masterType={props.masterType}
                data={props.data} onClose={props.onClose} initiateNotification={props.initiateNotification}
                cityViewProps={props}
                />
            })}
        </div>
        <div className="col-sm-12">
            {props.masterType == masterType.language && 
            <CreateLanguageWithCity {...propsForCreateLanguageWithCity} />
            }
        </div>
    </div>
)}