import React,{useEffect, useState} from "react";
import {VehicleInfoItem} from "./vehicleInfoItem";
import { fetchBusClassification,fetchServiceProviderWithCity } from "../../../../../services/vehicleMasterService";

export const VehicleDetailView = (props)=>{
    const [data,setData] = useState(props.data);
    useEffect(()=>{
        const fetchData = async () => {
            const { cityMaster, serviceCategory, serviceProvider, agency} = props.data;
            const agencyName = agency.name;
    
            const [busClassificationResult, serviceProviderResult] = await Promise.allSettled([
                fetchBusClassification({ agencyName,cityId: cityMaster.id } ),
                fetchServiceProviderWithCity({ agencyName,cityId: cityMaster.id })
            ]);
    
            let updateData = { ...data };

            function extractData(info,colCompare,fieldCompare) {
                if (info.status !== 'fulfilled' || !info.value || !info.value.length) {
                    return null;
                }
                return info.value.find(i => i[colCompare] == fieldCompare);
            }

            const serverServiceCategory = extractData(busClassificationResult,'serviceId', serviceCategory);
            if (serverServiceCategory) {
                updateData.serviceCategory = serverServiceCategory.serviceName;
            }

            const serverServiceProvider = extractData(serviceProviderResult,'serviceProviderId', serviceProvider);
            if (serverServiceProvider) {
                updateData.serviceProvider = serverServiceProvider.serviceProviderName;
            }

            setData(updateData);
        };
    
        fetchData();
    },[])
   return(
    <div className="row vehicle-info">
        <div className="col-sm-12">
            {Object.keys(data).map(columnKey=> {
                return <VehicleInfoItem key={columnKey} label={columnKey} value={data[columnKey]}/>
            })}
        </div>
    </div>
   )
};