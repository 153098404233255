import React, { useState } from 'react';
import './regionList.css'
const ChipInput = ({chips,setChips}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = inputValue.trim();
      
      if (value && !chips.includes(value)) {
        setChips([...chips, value]);
        setInputValue('');
      }
    }
  };

  const handleDeleteChip = (index) => {
    setChips(chips.filter((_, i) => i !== index));
  };

  return (
    <div>
      <div className='custom-chip-container'>
        {chips.map((chip, index) => (
          <div key={index} className='p-2'>
            {chip}
           <i className="fa fa-times" aria-hidden="true" onClick={() => handleDeleteChip(index)}></i>
          </div>
        ))}
        <input
          className='custom-chip'
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Type and press Enter"
        />
      </div>
    </div>
  );
};

export default ChipInput;