import React from "react";
import {connect} from "react-redux";
import {dataFetched} from "./redux/action-creator";
import {changePage} from "./redux/action-creator";
/**
 * A higher order component for frontend side pagination, manages pagination for provided data
 * Keeps data in reducer state and passes total page count and current page information for particular paginator
 * @param WrappedComponent
 * @returns {{}}
 */
function withPaginator(WrappedComponent) {
    return class extends React.Component {
        componentDidMount() {
            this.props.dataFetched(this.props.data)
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.data != nextProps.data) {
                this.props.dataFetched(nextProps.data)
            }
        }

        render() {
            return <WrappedComponent {...this.props}/>
        }
    }
}
/**
 * maps state values of page no and totalPages for the provided paginator
 * @param paginator
 * @param state
 * @returns {{pageNo: *, totalPages: *}}
 */
const mapStateToProps = (paginator, state)=> {
    return {
        pageNo: state.paginator[paginator] ? state.paginator[paginator].currentPage : 0,
        totalPages: state.paginator[paginator] ? state.paginator[paginator].totalPage : 0
    }
};

const mapDispatchToProps = (paginator, dispatch)=>({
    dataFetched: (value)=> {
        dispatch(dataFetched(paginator, value));
    }, changePage: (value)=> {
        dispatch(changePage(paginator, value));
    }
});

export default (WrappedComponent, infoData)=> {
    let updatedMapStateToProps = (state)=> Object.assign({}, mapStateToProps(infoData.paginator, state));
    let updatedMapDispatcherToProps = (state)=>Object.assign({}, mapDispatchToProps(infoData.paginator, state));
    return connect(updatedMapStateToProps, updatedMapDispatcherToProps)(withPaginator(WrappedComponent))
};



