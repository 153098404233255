import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal, Button} from "react-bootstrap";
import {MessageType} from "../../../Constant";
import "./confirmationModal.css";


class ConfirmationModal extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {showModal, title, message, messageCallBack, backdrop} =this.props;
        return (
            <Modal show={showModal} onHide={()=>messageCallBack(null, null)} backdrop={backdrop}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> {message}</div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="confirmation-modal">
                        <Button onClick={()=> {
                            messageCallBack(MessageType.ERROR, null)
                        }}>No
                        </Button>
                        <Button onClick={()=> {
                            messageCallBack(MessageType.SUCCESS, null)
                        }}>Yes
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
ConfirmationModal.defaultProps = {
    backdrop: 'static',
    message: 'Message',
    title: 'Modal',
    showModal:false
};
ConfirmationModal.propTypes = {
    showModal: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    backdrop: PropTypes.string,
    messageCallBack: PropTypes.func
};

export default ConfirmationModal;
