import {actions} from '../../actions/action-list';
import initialState from "./initialState";

function UserForm(state = initialState, action) {
    switch (action.type) {
        case actions.LOAD:
            return Object.assign({}, state, {
                user: action.value
            });
        default:
            return state;
    }
}
export default UserForm;
