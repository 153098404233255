import React from "react";
import { DeviceInfoItem } from "./DeviceInfoItem";

export const DeviceDetailView = (props) => (
    <div className="row vehicle-info">
      <div className="col-sm-12">
        {props.data && Object.keys(props.data).map((columnKey,i) => {
          return (
            <div key={i}>
            {columnKey && <DeviceInfoItem
              key={columnKey}
              label={columnKey}
              value={props.data[columnKey]}
            />}
            {columnKey == 'deviceStop' && <DeviceInfoItem
              key={'stopName'}
              label={'stopName'}
              value={props.data[columnKey]}
            />}
            </div>
          );
        })}
      </div>
    </div>
  );