/**
 * Main container
 * imported in App.js
 */
import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {templateStrings, MessageType} from "../../../Constant";
import {TableWrapper, PageWrapper} from "../../../shared/layout";
import {TableLoader} from "../../../shared/widgets";
import {setTokenExpired} from "../../../redux/actions/authActions";
import {setNotificationMessage} from "../../../redux/actions/notificationActions";
import {createProtocol} from "../../../services/protocolService";
import {AuthenticationService, OPERATIONS} from "../../../services/authorization";
import {routes} from "../../../routing/routes";
import {createProtocolListAction} from "../../../redux/action-creators/protocolActionCreator";

class ProtocolList extends Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        this.state = {
            content: [],
            showModal: false,
            showHeaderButton: false,
            backdrop: 'static',
            loading: false,
            protocolForm: {
                error: {
                    protocolId: true
                }
            },
            canWrite: this.authService.hasRightForOperationOnRouteInRegion(routes.PROTOCOL, null, OPERATIONS.WRITE),
        }
    }

    componentDidMount() {
        if (!this.props.content) {
            this.props.createFetchAction();
        }
    }

    render() {
        const showHeaderButton = this.state.showHeaderButton && this.state.canWrite;
        return (
            <div >
                <PageWrapper.Header
                    heading={templateStrings.protocols.heading} onAdd={this.open.bind(this)}
                    showButton={showHeaderButton}
                />

                <PageWrapper.Body>
                    <TableWrapper>
                        <TableWrapper.Header columns={templateStrings.protocols.columns}/>
                        {this.props.loading ?
                            <TableLoader loading={this.props.loading}
                                         colSpan={Object.keys(templateStrings.protocols.columns).length}/> :
                            <tbody>
                            {!this.props.content ? <tr className="gradeA odd noDataStyle" role="row">
                                <td className="sorting_1" colSpan="6">Error while loading data !</td>
                            </tr> : this.props.content.length == 0 ?
                                <tr className="gradeA odd noDataStyle" role="row">
                                    <td className="sorting_1" colSpan="6">No Data Found !</td>
                                </tr> : this.props.content.map((data) => {
                                return this.displayList(data);
                            })}
                            </tbody>
                        }
                    </TableWrapper>
                </PageWrapper.Body>
                {this.displayModal()}
            </div>
        );
    }

    /**
     * Call when Add/Update modal Open.
     */
    open() {
        this.setState({showModal: true});
    }


    /**
     * Used to show protocol list in row in List view
     * @param  item
     */
    displayList(item) {
        return (
            <tr key={item.protocolId} className="gradeA odd" role="row">
                <td className="list-item">{item.protocolId}</td>
            </tr>
        );
    }

    /**
     * Used to show Modal for "Add new device" or "Update device"
     */
    displayModal() {
        return (
            <Modal show={this.state.showModal} onHide={this.close.bind(this)} backdrop={this.state.backdrop}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Protocol</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <form className="form-horizontal" onSubmit={(e)=> {
                            e.preventDefault();
                        }}
                        >
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7" className="control-label col-sm-2 form-label"><span>Protocol Id</span></label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" id="name7"
                                               placeholder="Enter Protocol Id" onChange={(value) => {
                                            if (value.target.value.trim()) {
                                                this.setState({
                                                    protocolForm: {
                                                        error: {protocolId: false}
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    protocolForm: {
                                                        error: {protocolId: true}
                                                    }
                                                })
                                            }
                                            this.setState({protocolId: value.target.value.trim()})
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close.bind(this)}>Close</Button>
                    <Button bsStyle="primary" disabled={this.state.protocolForm.error.protocolId}
                            onClick={(data) => {
                                this.fetchAddUpdate()
                            }}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    /**
     * Call when Add/Update modal Close.
     */
    close() {
        this.setState({
            showModal: false,
            protocolForm: {
                error: {protocolId: true}
            }
        });
    }

    /**
     * Used to Add/Update device
     */
    fetchAddUpdate(data1) {
        let data = {
            "protocolId": this.state.protocolId ? this.state.protocolId.toLowerCase() : '',
        };
        if (data.protocolId) {
            createProtocol(data, 'POST').then((response) => {
                if (this.mounted) {
                    this.setState({
                        protocolId: "", showModal: false
                    });
                    this.props.createFetchAction();
                    this.props.initiateNotification({type: MessageType.SUCCESS, message: "successfully done."});
                }
            }).catch((err) => {
                if ((err.status === 401 || err.message==='Invalid token')) {
                    this.props.setTokenExpired(true);
                } else if (this.mounted)
                    this.props.initiateNotification({
                        type: MessageType.ERROR,
                        message: err.description || "Error Occurred"
                    });
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

}


const mapStateToProps = (state)=> {
    return {
        userAuthorities: state.Auth.authorities,
        content: state.Protocols.data,
        loading: state.Protocols.loading
    };
};
const mapDispatchToProps = dispatch => (
{
    setTokenExpired: value=> {
        dispatch(setTokenExpired(value))
    },
    initiateNotification: value=> {
        dispatch(setNotificationMessage(value))
    },
    createFetchAction: ()=> {
        dispatch(createProtocolListAction())
    }
}
);
export default connect(mapStateToProps, mapDispatchToProps)(ProtocolList);
