import {actions} from "../../actions/action-list";
function Protocols(state = {}, action) {
    switch (action.type) {
        case actions.PROTOCOLS.DATA_FETCHED:
            return Object.assign({}, state, {
                data: action.value
            });
        case actions.PROTOCOLS.LOADING:
            return Object.assign({}, state, {
                loading: action.value
            });
        default:
            return state;
    }
}
export default Protocols;