import { getAPIPromise } from "./APIutils";
import { BASE_GPS_MATRIX_STAG_URL, GET_CRONS, GET_REGIONS } from "../Constant";
import {EpochTimezone} from "./../shared/utils";

export function fetchCronTrigger( event,searchKey,sortKey,sortOrder,filterStatus,region, pageNo, pageSIze = 10) {
  let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_CRONS}/events?pageNo=${pageNo}&pageSize=${pageSIze}`;
  if (event) {
    url += `&eventName=${event}`;
  }
  if(searchKey){
    url += `&searchKey=${searchKey}`;  
  }
  if(sortKey && sortOrder){
    url += `&sortKey=${sortKey}&sortOrder=${sortOrder}`;  
  }
  if(filterStatus !=='All'){
    url += `&triggerStatus=${filterStatus == 'Success' ? true:false}`;  
  }
  if(region){
    url += `&region=${region} ` 
  }
  return getAPIPromise(url, {
    method: "get",
  });
}

export function getEventTrigger() {
  let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_CRONS}`;
  return getAPIPromise(url, {
    method: "get",
  });
}

export function getTimezones(){
  let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REGIONS}/regionWithTimeZone`;
  return getAPIPromise(url, {
      method: 'get'
  });
}

export function timeZoneRegions(timezone) {
  let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_REGIONS}/regionWithTimeZone?timeZone=${timezone}`;
  return getAPIPromise(url, {
    method: "get",
  });
}

export function createCronTrigger(state) {
  var username = localStorage.getItem("userName");
  const region_list = filterRegion(state.selectedRegion);
  const dayStartTime = setStartTime(state.startTime);
  let data = {
    data: {
      timezone: state.selectedTimeZone.name,
      dayStartTime: EpochTimezone(dayStartTime,state.selectedTimeZone.name),
      event: state.selectedEvent.name.toUpperCase(),
      startTime: EpochTimezone(state.startTime,state.selectedTimeZone.name),
      endTime: EpochTimezone(state.endTime,state.selectedTimeZone.name),
      region: region_list,
    },
    updatedBy: username,
  };
  let url = `${BASE_GPS_MATRIX_STAG_URL}${GET_CRONS}`;
  return getAPIPromise(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export function filterRegion(regions) {
  const region_list = regions.map((city) => city.name);
  return region_list;
}

export function setStartTime(time) {
  const date = new Date(time);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
}
