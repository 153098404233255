import React from "react";
import { Button } from "react-bootstrap";
import { MessageType } from "../../../../../Constant";
import validateAgency from "../../validator/agencyValidator";
import Select from "react-select";
import { fetchVehicleMaster, createMasterData } from "../../../../../services/vehicleMasterService";


export class AgencyCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            stayOpen: false,
            removeSelected: true,
            rtl: false,
            citiesOptions: [],
            selectedCity: [],
            validAgency:true
        };
        this.onCityChange = this.onCityChange.bind(this);
    }

    componentWillMount() {
        this.fetchMasterData();

    }

    componentDidMount() {
        this.mounted = true;
        this.updateFormErrorState();
        if (this.props.updateSelection) {
            let data = this.props.updateSelection
            this.setState({
                agency: data.name ? data.name : '',
                id: data.id ? data.id : ''
            }, () => {
                this.updateFormErrorState()
            })
        }
    }



    fetchMasterData() {
        fetchVehicleMaster('city', null, 0, 1000).then((res => {
            if (this.mounted) this.setState({
                citiesOptions: res.content.map(data => ({
                    label: data.name,
                    value: data
                })),
            }, () => {
                if (this.props.updateSelection) {
                    let cities = this.props.updateSelection.city;
                    if (cities.length > 0) {
                        this.setState({
                            selectedCity: this.state.citiesOptions ? this.state.citiesOptions.filter(city => {
                                return cities.some(b => city.label === b.name)
                            }) : []
                        }, () => {
                            this.updateFormErrorState()
                        })
                    }
                }
            })


        })).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ citiesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching cities"
                });
            }
        }
        );
    }




    /**
     *on city change
     *
     */
    onCityChange(value) {
        this.setState({
            selectedCity: value
        }, () => {
            this.updateFormErrorState()
        });
    }

    render() {
        return (
            <div className="row">
                <form className="form-horizontal">


                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>Agency</span></label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="agency"
                                    placeholder="Enter Agency"
                                    onChange={(value) => {
                                        this.setState({ agency: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                    readOnly={this.props.updateSelection}
                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.name : ""}

                                />
                                {!this.state.validAgency && <span className="error-text">Invalid agency name</span>}

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>City</span></label>
                            <div className="col-sm-10">
                                <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    multi
                                    value={this.state.selectedCity}
                                    onChange={this.onCityChange}
                                    options={this.state.citiesOptions}
                                    placeholder="Select City"
                                    rtl={this.state.rtl}
                                    removeSelected={this.state.removeSelected}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.createAgencyMaster()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}
                        >{this.state.loading ? 'Saving...' : 'Save changes'}</Button>
                    </div>
                </form>
            </div>)
    }

    /**
     * validates trip form data
     * @param target
     */
    updateFormErrorState() {
        let formState = validateAgency(this.state);
        this.setState({
            form: formState
        }, () => {
            this.state.form.agencyNameError ? this.setState({
                validAgency: false
            }) : this.setState({
                validAgency: true
            });
        })
    }


    /**
     * Used to Create Agency
     */
    createAgencyMaster() {

        let selectedCityArray = this.state.selectedCity.map((city) => {
            return city.value
        })
        let data = { name: this.state.agency.toUpperCase(), city: selectedCityArray }
        if (this.props.updateSelection) {
            data['id'] = this.state.id
        }
        createMasterData('agency', data, this.props.updateSelection).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}