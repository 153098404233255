import React from "react";
import PropTypes from "prop-types";
import {Button, ModalWrapper, Tabs, BetaVersion} from "./../../../../../shared/widgets";
import {MessageType} from "../../../../../Constant";
import {CONFIG_CMDS,configurationTabs} from "../../utils";
import {ConfigurationService} from "../../service/configurationService";
import {DeviceConfiguration} from "./../deviceConfiguration";
import "./configurationComponent.css";

/**
 * component handles modal views for device configuration options on the basis of flag value
 * for fresh config set flag value to CONFIG_CMDS.FRESH_CONFIG, else it will render tab view with
 * default view as CONFIG_CMDS.SET_INTERVAL
 */
class DeviceConfigure extends React.Component {
    constructor(props) {
        super(props);
        this.configurationService = new ConfigurationService();
        this.fireEvent = this.fireEvent.bind(this);
        this.state = {
            showModal: false,
            configDetails: {}
        };
        this.getToggler = this.getToggler.bind(this);
    }

    componentDidMount() {
        if (!this.props.protocols) {
            this.props.fetchProtocols();
        }
    }

    render() {
        return <ModalWrapper
            toggler={this.getToggler}
            titleRender={ this.props.flag == CONFIG_CMDS.FRESH_CONFIG ? null : ()=> {
                return <Tabs activeTab={this.state.flag}
                             tabs={configurationTabs} onTabSelection={(key)=> {
                    this.setState({flag: key})
                }}/>
            }}
            successBtnText="Done"
            dialogClassName="configuration-modal"
            wrapperClassName={`configuration-modal-wrapper ${this.props.flag == CONFIG_CMDS.FRESH_CONFIG ? 'pull-right' : ''}`}
            disableSuccess={this.state.formError}
            title={ this.props.flag == CONFIG_CMDS.FRESH_CONFIG ? "Configure device" : null} bodyRender={()=> {
            return <DeviceConfiguration
                protocols={this.props.protocols}
                flag={this.props.flag || this.state.flag} {...this.props}
                onDataUpdate={(data, error)=> {
                    if (!error) {
                        this.setState({
                            configDetails: data,
                            formError: false
                        })
                    } else {
                        this.setState({formError: true})
                    }
                }}/>
        }}
            showModal={this.state.showModal}
            messageCallBack={(type)=> {
                if (type == MessageType.SUCCESS) {
                    if (this.state.flag == CONFIG_CMDS.SET_INTERVAL) {
                        this.fireEvent({
                            endpoint: this.state.flag,
                            details: this.state.configDetails
                        })
                    } else {
                        this.fireEvent({endpoint: this.state.flag, details: this.state.configDetails})
                    }

                } else {
                    this.hideModal();
                }

            }}/>
    }

    fireEvent(event) {
        this.configurationService.fireConfigurationCommand(this.props.deviceId || this.props.deviceId || this.state.configDetails.deviceId, this.state.configDetails.phoneNumber, event).then(response=> {
            this.props.setNotification({type: MessageType.SUCCESS, message: response.description || 'Success'});
            this.hideModal();
        }).catch(err=> {
            this.props.setNotification({type: MessageType.ERROR, message: err.description || 'error occured'});
            this.hideModal();
        })
    }

    hideModal() {
        this.setState({
            showModal: false
        })
    }

    showModal(flag) {
        this.setState({
            flag: flag,
            showModal: true,
            configDetails: {}
        })
    }

    getToggler() {
        if (this.props.flag == CONFIG_CMDS.FRESH_CONFIG)
            return <Button
                onClick={()=> {
                    this.showModal(CONFIG_CMDS.FRESH_CONFIG)
                }}
                className="add-icon"
                buttonText="Configure Device"/>;
        else
            return <span className="table-icons">
                    <img className="reconfigure-icon" src="./../../../../../assets/images/reconfigure.png"
                         onClick={()=> {
                             this.showModal(CONFIG_CMDS.SET_INTERVAL);
                         }}/><BetaVersion/></span >

    }
}
DeviceConfigure.propTypes = {
    flag: PropTypes.string.isRequired,
    setNotification: PropTypes.func.isRequired,
    deviceId: PropTypes.string.isRequired,
    protocols: PropTypes.array.isRequired,
    fetchProtocols: PropTypes.func.isRequired

};
export default  DeviceConfigure;


