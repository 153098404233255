import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import {
    BASE_URL,
    ADD_REGION_URL,
    UPDATE_REGION_URL,
    templateStrings,
    clusterIP,
    testRegion,
    EnableParsingFilter
} from "../../../../Constant";
import Select from "react-select";
import {PageWrapper, TableWrapper} from "../../../../shared/layout";
import {TableLoader, Search as SearchBar, frontEndPaginator,DropdownWrapper,FilterView} from "../../../../shared/widgets";
import {RegionListItem} from "../views/regionItem";
import {fetchWrapper} from "../../../../services/APIutils";
import {fetchProtocols} from "../../../../services/protocolService";
import validateRegion from "../regionValidators";
import withRegions from "../HOC";
import {searchRegions} from "../../../../redux/actions/regionActions";
import "./regionList.css";
import {AuthenticationService, OPERATIONS} from "../../../../services/authorization";
import {routes} from "../../../../routing/routes";
import { ButtonToolbar } from "react-bootstrap";
import ChipInput from './CustomChip';

const paginatorInfo = {paginator: 'Regions'};
const FrontPaginator = frontEndPaginator(paginatorInfo);

class RegionList extends Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        this.state = {
            loading: this.props.content ? false : true,
            filteredData: this.props.content,
            showModal: false,
            showInfo: false,
            exportOptions: [
                {
                  key: "default",
                  value: "Export Region Report",
                },
              ],
            showSelectModal: false,
            stayOpen: false,
            removeSelected: true,
            rtl: false,
            protocols: [],
            parsingOptions: [{ label: 'False', value: false },{ label: 'True', value: true }],
            showHeaderBUtton: true,
            filterStatus: EnableParsingFilter.ALL,
            backdrop: 'static',
            pageNo: 0,
            totalPages: 0,
            form: {
                valid: false,
                regionId: true,
                endpoint: true,
                deploymentPort: false,
                protocols: true,
                parsing_Enabled:false,
            },
            tags:[],
            mode:'create',
            canWrite: this.authService.hasRightForOperationOnRouteInRegion(routes.REGION, null, OPERATIONS.WRITE),
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.fetchRegions = this.fetchRegions.bind(this);
        this.fetchProtocols = this.fetchProtocols.bind(this);
        this.open = this.open.bind(this);
        this.setTags = this.setTags.bind(this);
    }

    setTags(newTags){
        this.setState({ tags: newTags });
    };

    componentWillMount() {
        /**
         * API call for protocols list
         */
        this.fetchProtocols();
    }

    componentDidMount() {
        /**
         * gets reference of notification system into "this" context.
         */
        this.mounted = true;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.content != nextProps.content && nextProps.content) {
            this.setState({loading: false,filteredData:nextProps.content});
        }
    }

    fetchProtocols() {
        fetchProtocols().then((response) => {
            if (this.mounted) {
                this.setState({
                    protocols: response.map((protocol)=> {
                        return {
                            label: protocol.protocolId, value: protocol.protocolId
                        }
                    })
                });
            }
        }).catch((err)=> {
            if ((err.status === 401 || err.message==='Invalid token')) {
                this.props.setTokenExpired(true);
            } else if (this.mounted)
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description || "Error while fetching protocols"
                });
            console.log(err);
        });
    }

    render() {
        const showHeaderBUtton = this.state.showHeaderBUtton && this.state.canWrite;
        return (
            <div className="regions">
                <PageWrapper.Header
                    heading="Region"
                    onAdd={()=>this.open(null)}
                    showButton={showHeaderBUtton}
                ><span
                    className="cluster-ip">Cluster IP: {clusterIP}</span>
                     <div className="pull-right">
            <DropdownWrapper
              title="Export"
              menue={this.state.exportOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    this.exportReportData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div>
                    <div className="pull-right search-container">
                        <SearchBar placeHolder={this.state.placeHolder} onSearchChange={this.handleSearchChange}
                                   value={this.props.searchString}
                        />
                    </div>
                </PageWrapper.Header>
                <PageWrapper.Body>
                <PageWrapper.BodyHeader>
            <div className="header-button-container ">
              <div className="form-group">
                <p className="filters-label">Filter By Parsing:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <FilterView
                      selectedValue={this.state.filterStatus.value}
                      title="Status Filter"
                      menues={EnableParsingFilter}
                      onSelect={(value) => {
                        this.handleFilter(value)
                      }}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
                    <TableWrapper>
                        <TableWrapper.Header columns={templateStrings.regions}/>
                        {this.state.loading && <TableLoader loading={this.state.loading}
                                                            colSpan={Object.keys(templateStrings.regions).length}/>}
                        {!this.state.loading && <tbody>
                        {this.props.paginatedData && this.props.paginatedData.filter(region=> {
                            return region.regionId != testRegion;
                        }).map((data) => {
                            return <RegionListItem item={data} key={data.regionId}
                                                   showEdit={this.state.canWrite}
                                                   onMessage={(e, msg)=> {
                                                       this.props.initiateNotification({
                                                           type: e,
                                                           message: msg
                                                       });
                                                   }}
                                                   onOpenClick={()=> {
                                                       this.open(data);
                                                   }}/>;
                        })}

                        {this.props.content && this.props.content.length == 0 &&
                        <tr className="gradeA odd noDataStyle" role="row">
                            <td className="sorting_1" colSpan="6">No Data Found !</td>
                        </tr>
                        }
                        </tbody>}
                        {!this.state.loading && this.props.paginatedData && !(!this.props.paginatedData.filter(region=> {
                            return region.regionId == testRegion;
                        }).length ) && <thead className="normal_table_head">

                        <tr role="row">
                            <th className="test-header" tabIndex="0" colSpan="7">Test
                                Region
                            </th>
                        </tr>
                        </thead>}
                        {!this.state.loading && <tbody>
                        {this.props.paginatedData && this.props.paginatedData.filter(region=> {
                            return region.regionId == testRegion;
                        }).map((data) => {
                            return <RegionListItem item={data} key={data.regionId}
                                                   showEdit={this.state.canWrite}
                                                   onMessage={(e, msg)=> {
                                                       this.props.initiateNotification({
                                                           type: e,
                                                           message: msg
                                                       });
                                                   }}
                                                   onOpenClick={()=>this.open(data)}/>;
                        })}
                        </tbody>}
                    </TableWrapper>
                    {this.state.filteredData && <FrontPaginator data={this.state.filteredData}/>}
                </PageWrapper.Body>
                {this.displayModal()}
            </div>
        );
    }


    /**
     * Call when Add/Update modal Open.
     */
    open(item) {
        item = JSON.parse(JSON.stringify(item));
        if (item) {
            this.setState({mode:"edit"},()=>{
            })
            this.updateFormErrorState({id: 'edit', value: item})
        } else {
            this.setState({mode:"create"},()=>{
            })
            this.updateFormErrorState({id: 'create'})
        }
        let tags = [];
        if(item && item.tags && item.tags.length){
            tags = item.tags.map((tag)=>tag.tag);
        }
        this.setState({
            showModal: true, updateSelection: item, value: item ? item.protocols.map(protocol=> {
                return {
                    label: protocol.protocolId, value: protocol.protocolId
                }
            }) : [],
            regionId: item ? item.regionId : "",
            endpoint: item ? item.endpoint : "",
            parsing_Enabled:item?item.parsing_Enabled:false,
            deploymentPort: item ? item.deploymentPort : "1025",
            remoteHost: item ? item.remoteHost : "",
            remotePort: item ? item.remotePort : "",
            tags:tags
        });
    }

    handleFilter(value){
        var map = new Map();
        var tempTrue = [];
        var tempFalse = [];
        var tempAll = [];
        map.set("True", tempTrue);
        map.set("False", tempFalse);
        map.set("All", tempAll);
        if (this.props.content) {
            for (var i = 0; i < this.props.content.length; i++) {
              if (map.has("All")) {
                var arr = map.get("All");
                arr.push(this.props.content[i]);
                map.set("All", arr);
              } else {
                var arr = [];
                arr.push(this.props.content[i]);
                map.set("All", arr);
              }
              if (this.props.content[i].parsing_Enabled) {
                if (map.has("True")) {
                  var arr = map.get("True");
                  arr.push(this.props.content[i]);
                map.set("True", arr);
              } else {
                var arr = [];
                arr.push(this.props.content[i]);
                map.set("True", arr);
              }
            } else {
              if (map.has("False")) {
                var arr = map.get("False");
                arr.push(this.props.content[i]);
                map.set("False", arr);
              } else {
                var arr = [];
                arr.push(this.props.content[i]);
                map.set("False", arr);
              }
            }
          }
          this.setState({ map: map },()=>{
            this.setState({ filterStatus: value }, () => {
                      this.setState({
                        filteredData: this.state.map.get(value.value),
                    },()=>{
                        console.log("fileterd dt",this.state.filteredData)

                    });
            });
          });
        }
    
    }

    /**
     * API call by fetch to get protocol lists
     */
    fetchRegions() {
        this.setState({loading: true});
        this.props.fetchRegions();
    }

    exportReportData(){
        var csvContent = "";
        csvContent = `Region,Receiver Port,Chalo Endpoint,Remote Host,Remote Port,Is Parsing,Protocols,Tags`+ '\n';
        if (this.props.content) {
            this.props.content.map((object) => {
                let protocol=object.protocols.map((item)=>{
                    return item.protocolId;
                });
                const tags = object.tags.map((tag)=>tag.tag).join(" | ");
                protocol=protocol.toString().replaceAll(","," | ");
                csvContent += `${object.regionId},${object.deploymentPort},${object.endpoint},${object.remoteHost?object.remoteHost:'NA'},${object.remotePort?object.remotePort:'NA'},${object.parsing_Enabled},${protocol},${tags}\n`;
            });
        }
        var csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        var csvURL = window.URL.createObjectURL(csvData);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', ('Region Report') + (new Date()).getTime() + '.csv');
        tempLink.click();
    }

    show(item) {
        this.setState({showInfo: true, updateSelection: item});
    }

    /**
     * Used to show Modal for "Add new device" or "Update device"
     */
    displayModal() {
        return (
            <Modal show={this.state.showModal || this.state.showInfo} onHide={this.close.bind(this)}
                   backdrop={this.state.backdrop}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.showModal ? this.state.updateSelection ? 'Update Region' : 'Add new Region' : this.state.showInfo ? 'Region Info' : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <form className="form-horizontal">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                           className="control-label col-sm-2 form-label"><span>Region Id</span></label>
                                    <div className="col-sm-10 ">
                                        {this.state.updateSelection ?
                                            <span>{this.state.updateSelection.regionId}</span> :
                                            <input type="text" className="form-control" id="regionId"
                                                   placeholder="Enter Region Id (This is deployment location.)"
                                                   onChange={(value) => {
                                                       this.updateFormErrorState(value.target);
                                                       this.setState({regionId: value.target.value.trim()})
                                                   }}
                                            />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                           className="control-label col-sm-2 form-label"><span>End Point</span></label>
                                    <div className="col-sm-10 ">
                                        {this.state.showModal ?
                                            <input type="text" className="form-control" id="endpoint"
                                                   placeholder="Enter End point"
                                                   onChange={(value) => {
                                                       this.updateFormErrorState(value.target);
                                                       this.setState({endpoint: value.target.value.trim()})
                                                   }}
                                                   defaultValue={this.state.updateSelection ? this.state.updateSelection.endpoint : "" }/>
                                            : this.state.showInfo ?
                                            <span>{this.state.updateSelection.endpoint}</span> : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7" className="control-label col-sm-2 form-label"><span>Deployment Port</span></label>
                                    <div className="col-sm-10">
                                        {this.state.showModal ?
                                            <div>
                                                <input type="text" className="form-control" id="deploymentPort"
                                                       placeholder="Enter Deployment Port"
                                                       readOnly={this.state.updateSelection}
                                                       value={this.state.deploymentPort}
                                                       onChange={(value) => {
                                                           let target = value.target;
                                                           if (!value.target.value || parseInt(value.target.value)) {
                                                               this.setState({deploymentPort: parseInt(value.target.value) ? parseInt(value.target.value) : ''}, ()=> {
                                                                   this.updateFormErrorState(target);
                                                               })
                                                           }
                                                       }}
                                                />
                                                { this.state.form.deploymentPort &&
                                                <p style={{color: 'red'}}> deployment port can't be less than 1025</p>}
                                            </div>
                                            : this.state.showInfo ?
                                            <span>{this.state.updateSelection.deploymentPort}</span> : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7" className="control-label col-sm-2">Remote Host</label>
                                    <div className="col-sm-10">
                                        {this.state.showModal ?
                                            <input type="text" className="form-control" id="name7"
                                                   placeholder="Enter Remote Host"
                                                   onChange={(value) => {
                                                       this.setState({remoteHost: value.target.value.trim()})
                                                   }}
                                                   defaultValue={this.state.updateSelection ? this.state.updateSelection.remoteHost : "" }
                                            />
                                            : this.state.showInfo ?
                                            <span>{this.state.updateSelection.remoteHost}</span> : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7" className="control-label col-sm-2">Remote Port</label>
                                    <div className="col-sm-10">
                                        {this.state.showModal ?
                                            <div><input type="text" className="form-control" id="remoteport"
                                                        value={this.state.remotePort}
                                                        placeholder="Enter Remote Port" onChange={(value) => {
                                                let target = value.target;
                                                if (!value.target.value || parseInt(value.target.value)) {
                                                    this.setState({remotePort: parseInt(value.target.value) ? parseInt(value.target.value) : ''}, ()=> {
                                                        this.updateFormErrorState(target);
                                                    })
                                                }

                                            }}
                                            />
                                                { this.state.form.remotePort &&
                                                <p style={{color: 'red'}}> Remote port can't be less than 1025</p>}
                                            </div>
                                            : this.state.showInfo ?
                                            <span>{this.state.updateSelection.remotePort}{this.state.showInfo}</span> : ''
                                        }


                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7" className="control-label col-sm-2">Is Parsing</label>
                                    <div className="col-sm-10">
                                        {this.state.updateSelection ?
                                            <span>{this.state.updateSelection.parsing_Enabled?'True':'False'}</span> :
                                            <Select
                                            closeOnSelect={!this.state.stayOpen}
                                            onChange={(value) => {
                                                this.handleSelectChangeParsing(value)

                                            }}                                            
                                            options={this.state.parsingOptions}
                                            placeholder="Select"
                                            rtl={this.state.rtl}
                                            value={this.state.form.parsing_Enabled}
                                        />
                                        }


                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                           className="control-label col-sm-2 form-label"><span>Protocols</span></label>
                                    <div className="col-sm-10">
                                        {this.state.showModal ?
                                            <Select
                                                closeOnSelect={!this.state.stayOpen}
                                                disabled={this.state.disabled}
                                                multi
                                                onChange={this.handleSelectChange}
                                                options={this.state.protocols}
                                                placeholder="Select Protocol(s)"
                                                removeSelected={this.state.removeSelected}
                                                rtl={this.state.rtl}
                                                value={this.state.value}
                                            />
                                            : this.state.showInfo ?
                                            this.state.updateSelection.protocols.map((protocol)=> {
                                                return <span className="list-item"
                                                             key={protocol.protocolId}>{protocol.protocolId} ,</span>
                                            }) : ''
                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                           className="control-label col-sm-2 form-label">Tags</label>
                                    <div className="col-sm-10">
                                        {this.state.showModal ?
                                          <ChipInput chips={this.state.tags} setChips={this.setTags} />   : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close.bind(this)}>Close</Button>
                    {this.state.showModal && <Button bsStyle="primary" onClick={() => {
                        this.fetchAddUpdate()
                    }} disabled={!this.state.form.valid}>Save changes</Button>}
                </Modal.Footer>
            </Modal>
        );
    }

    /*
     * updates region from's validations
     */
    updateFormErrorState(target) {
        let formState = validateRegion(target, this.state.form);
        this.setState({
            form: formState
        });
    }

    /**
     * Call when Add/Update modal Close.
     */
    close() {
        this.setState({
            showModal: false,
            showInfo: false,
            updateSelection: null,
            value: [],
            tags:[]
        });

    }

    handleSelectChange(value) {
        this.updateFormErrorState({id: 'protocols', value: value});
        this.setState({value: value});
    }
  
    handleSelectChangeParsing(value) {
        var formState = this.state.form
        formState['parsing_Enabled'] = value.value
        this.setState({form: formState})
    }
    handleSearchChange(value) {
        this.setState({filterStatus:EnableParsingFilter.ALL})
        this.props.search(value);
    }

    /**
     * Used to Add/Update device
     */
    fetchAddUpdate() {
        let data = this.state.updateSelection ? this.state.updateSelection : {};
        data.regionId = this.state.regionId ? this.state.regionId.toLowerCase() : '';
        data.endpoint = this.state.endpoint;
        data.isParsingEnabled = this.state.form.parsing_Enabled;
        data.deploymentPort = this.state.deploymentPort;
        data.remoteHost = this.state.remoteHost;
        data.remotePort = this.state.remotePort;
        if(this.state.tags.length){
            data.tags = this.state.tags.map((chip)=>({tag:chip}));
        }else{
            data.tags = [];
        }
        data.protocols = this.state.value.map(value=> {
            return {protocolId: value.value.trim()};
        });
        if (data.regionId && data.deploymentPort && data.endpoint) {
            fetchWrapper(BASE_URL + (this.state.updateSelection ? UPDATE_REGION_URL : ADD_REGION_URL), {
                method: (this.state.updateSelection ? 'PUT' : 'POST'),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (!(response.status == 'NOT_ACCEPTABLE' || response.status == 'CONFLICT' || response.status == 'FAILED_DEPENDENCY' || response.status == 500)) {
                    this.props.initiateNotification({
                        type: 'success',
                        message: "successfully done.In order to make services available you need to restart"
                    });
                    if (this.mounted) {
                        this.setState({
                            regionId: "",
                            endpoint: "",
                            parsing_Enabled:false,
                            deploymentPort: "",
                            remoteHost: "",
                            remotePort: "",
                            value: [],
                            showModal: false,
                            updateSelection: null
                        });
                    }
                    this.props.search('');
                    //todo need to refresh store's regions data
                    this.fetchRegions();
                    this.setState({filterStatus:EnableParsingFilter.ALL})
                } else {
                    if (this.mounted)
                        this.props.initiateNotification({type: 'error', message: response.description});
                }
            }).catch((err)=> {
                if ((err.status === 401 || err.message==='Invalid token')) {
                    this.props.setTokenExpired(true);
                } else if (this.mounted)
                    this.props.initiateNotification({type: 'error', message: err.description || "Error Occurred"});
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

}

const mapStateToProps = state=> ({
    userAuthorities: state.Auth.authorities,
    content: state.Regions.displayData,
    searchString: state.Regions.search,
    paginatedData: state.paginator[paginatorInfo.paginator] ? state.paginator[paginatorInfo.paginator].content : []
});


const mapDispatchToProps = dispatch=> ({
    search: (value)=> {
        dispatch(searchRegions(value));
    }
});

export  default  withRegions(RegionList, mapStateToProps, mapDispatchToProps);

