import {actions} from "./action-list";
export function protocolsFetched(value) {
    return {
        type: actions.PROTOCOLS.DATA_FETCHED,
        value: value
    }
}
export function protocolsLoading(value) {
    return {
        type: actions.PROTOCOLS.LOADING,
        value: value
    }
}