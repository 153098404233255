import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {masterRoutes} from '../../../../routing/routes';
import MasterList from './listView/masterList'
import GuardedRoute from '../guradedRoute';
import {userAuthoritiesArray} from '../../../../services/authorization';
import Audit from './auditView/Audit';

export class MasterWrapper extends React.Component {
    render() {
        const {match}=this.props;
        return <Switch>
             <Route exact path={`${match.url}/camera/details/:cameraId`}  component={Audit}/>
             <GuardedRoute path={`${match.url}/:masterType`} component={MasterList} role={userAuthoritiesArray()}  pathname={this.props.location.pathname}/>
            <Redirect from="/" to={`${match.url}/${masterRoutes.DEPOT}`}/>
        </Switch>

    }
}