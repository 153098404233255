import React from "react";
import { Button } from "react-bootstrap";
import { MessageType } from "../../../../../Constant";
import validateDepot from "../../validator/depotValidator";
import Select from "react-select";
import { fetchVehicleMaster, createMasterData } from "../../../../../services/vehicleMasterService";

export class DepotCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            stayOpen: false,
            removeSelected: true,
            rtl: false,
            disabled: false,
            citiesOptions: [],
            selectedCity: null,
            validDepot: true
        };
        this.onCityChange = this.onCityChange.bind(this);

    }

    componentDidMount() {
        this.mounted = true;
        this.updateFormErrorState();
        this.fetchMasterData('city', null);
        if (this.props.updateSelection) {
            let data = this.props.updateSelection
            this.setState({
                depot: data.name ? data.name : '',
                radius: data.radius ? data.radius : '',
                latitude: data.latitude ? data.latitude : '',
                longitude: data.longitude ? data.longitude : '',
                id: data.id ? data.id : ''
            }, () => {
                this.updateFormErrorState()
            })
        }
    }

    fetchMasterData(masterType, masterParentId) {
        fetchVehicleMaster(masterType, masterParentId, 0, 1000).then((res => {
            if (this.mounted) {
                switch (masterType) {
                    case 'city': {
                        this.setState({
                            citiesOptions: res.content.map(data => ({
                                label: data.name,
                                value: data
                            })),
                        }, () => {
                            if (this.props.updateSelection) {
                                let city = this.props.updateSelection.city;

                                this.setState({
                                    selectedCity: this.state.citiesOptions ? this.state.citiesOptions.find(val => {
                                        return val.label === city.name
                                    }) : []
                                }, () => {
                                    this.updateFormErrorState()
                                })

                            }
                        })
                    }; break;

                }
            }

        })).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ citiesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching cities"
                });
            }
        }
        );
    }



    /**
     *on city change reset depot
     *
     */
    onCityChange(value) {
        this.setState({
            selectedCity: value
        }, () => {
            this.updateFormErrorState()
        });

    }


    render() {
        return (
            <div className="row">
                <form className="form-horizontal">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>City</span></label>
                            <div className="col-sm-10">
                                {!this.props.updateSelection && <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    clearable={false}
                                    onChange={(value) => {
                                        this.onCityChange(value)
                                    }}
                                    options={this.state.citiesOptions}
                                    placeholder="Select City"
                                    rtl={this.state.rtl}
                                    value={this.state.selectedCity}
                                />}
                                {this.props.updateSelection && <span className="detail">{this.props.updateSelection.city.name}</span>}

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label"><span>Depot</span></label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="depot"
                                    placeholder="Enter Depot"

                                    disabled={!this.state.selectedCity}
                                    onChange={(value) => {
                                        this.setState({ depot: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                    readOnly={this.props.updateSelection}

                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.name : ""}

                                />
                                {!this.state.validDepot && <span className="error-text">Invalid depot name</span>}

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Radius</label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="radius"
                                    placeholder="Enter radius in meters"

                                    onChange={(value) => {
                                        this.setState({ radius: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                    onKeyPress={(value) => {
                                        this.preventAlphaCharacter(value, value.key);
                                    }}
                                    onPaste={(value) => {
                                        this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                    }}
                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.radius : ""}

                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Latitude</label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="latitude"
                                    placeholder="Enter latitude"

                                    onChange={(value) => {
                                        this.setState({ latitude: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                    onKeyPress={(value) => {
                                        this.preventAlphaCharacter(value, value.key);
                                    }}
                                    onPaste={(value) => {
                                        this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                    }}
                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.latitude : ""}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor="name7"
                                className="control-label col-sm-2 form-label">Longitude</label>
                            <div className="col-sm-10 ">
                                <input type="text" autoComplete="off" className="form-control" id="longitude"
                                    placeholder="Enter longitude"

                                    onChange={(value) => {
                                        this.setState({ longitude: value.target.value.trim() }, () => {
                                            this.updateFormErrorState()
                                        })
                                    }}
                                     onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                    defaultValue={this.props.updateSelection ? this.props.updateSelection.longitude : ""}

                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.createDepotMaster()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}
                        >{this.state.loading ? 'Saving...' : 'Save changes'}</Button>
                    </div>
                </form>
            </div>)
    }

    /**
     * validates Depot form data
     * @param target
     */
    updateFormErrorState() {
        let formState = validateDepot(this.state);
        this.setState({
            form: formState
        }, () => {
            this.state.form.depotNameError ? this.setState({
                validDepot: false
            }) : this.setState({
                validDepot: true
            });
        });
    }


    /**
     * Used to Create Depot Master
     */
    createDepotMaster() {
        let data = {
            name: this.state.depot.toUpperCase(), city: { id: this.state.selectedCity.value.id },
            latitude: this.state.latitude ? this.state.latitude : '',
            longitude: this.state.longitude ? this.state.longitude : '',
            radius: this.state.radius ? this.state.radius : ''
        }

        if (this.props.updateSelection) {
            data['id'] = this.state.id
        }
        createMasterData('depot', data, this.props.updateSelection).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
        });
    }

    preventAlphaCharacter(event, value) {
        const re = /^-?\d*\.?\d*$/;
        if (!re.test(value)) {
            event.preventDefault();
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }
}