import {actions} from './action-list';
export function fetchedCityMeta(city, value) {
    return {
        type: actions.FETCHED_CITY_META,
        value: value,
        city: city
    }
}




