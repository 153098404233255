import React from "react";
import classNames from "classnames";

export const HealthListItem = ({ item }) => (
  <tr key={item.deviceId} className="gradeA odd cursor-pointer" role="row">
    <td width="10%" className="list-item" title={item.vehicleId}>{item.vehicleId}</td>
    <td width="13%" title={item.deviceId ? item.deviceId : 'NA'}>{item.deviceId ? item.deviceId : 'NA'}
      {/* <span className="protocol">{item.deviceId ? item.deviceId : 'NA'}</span>
      <div className="protocol-list">{item.deviceId ? item.deviceId : 'NA'}</div> */}
    </td>
    <td width="5%">
      <span
        className={classNames({
          "red-class": item.health == false,
          "green-class": item.health == true,
        })}
      >
        <a>
          <i className="fa fa-circle" aria-hidden="true"></i>
        </a>
      </span>
    </td>
    <td className={item.distance_status?"list-item-red":"list-item"} title={item.distance}>{item.distance}</td>
    <td className={item.frequency_percentage_status?"list-item-red":"list-item"} title={item.percentage}>{item.percentage}</td>
    <td className={item.avg_delay_status?"list-item-red":"list-item"} title={item.avgDelay}>{item.avgDelay}</td>
    <td className="list-item" title={item.delayPacketCount}>{item.delayPacketCount}</td>
    <td className={item.point_status?"list-item-red":"list-item"} title={item.points}>{item.points}</td>
    <td className={item.futureDatePointsStatus?"list-item-red":"list-item"} title={item.futureDatePoints?item.futureDatePoints:'0'}>{item.futureDatePoints?item.futureDatePoints:'0'}</td>
    <td className={item.pastDatePointsStatus?"list-item-red":"list-item"} title={item.pastDatePoints?item.pastDatePoints:'0'}>{item.pastDatePoints?item.pastDatePoints:'0'}</td>
    <td className={item.delay90thPercentileStatus?"list-item-red":"list-item"} title={item.delay90thPercentile?item.delay90thPercentile:'0'}>{item.delay90thPercentile?item.delay90thPercentile:'0'}</td>
  </tr>
);
