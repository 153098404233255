import {actions} from "./action";
export function dataFetched(paginator, value) {
    return {
        type: actions.PAGINATOR.DATA_FETCHED,
        value: value,
        paginator: paginator
    }
}
export function changePage(paginator, value) {
    return {
        type: actions.PAGINATOR.CHANGE_PAGE,
        value: value,
        paginator: paginator
    }
}