import React from "react";
import PropTypes from "prop-types";
import {FilterView} from "../../../../../shared/widgets";

export const SelectValue = ({title, keyValue, dataKey, onSelect, ...rest})=> {
    return <div className="row setting-item">
        <div className="col-sm-3 label ">{title} </div>
        <div className="col-sm-9">
            <FilterView
                {...rest}
                title={title}
                dataKey={dataKey}
                onSelect={(value)=> {
                    if (dataKey && !rest.useData) {
                        onSelect(keyValue, value[dataKey]);
                    } else {
                        onSelect(keyValue, value);
                    }
                }}/>
        </div>
    </div>
};
SelectValue.propTypes = {
    title: PropTypes.string.isRequired,
    keyValue: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};

export const InputValue = ({title, onChange, ValidateValue})=> {
    return <div className="row setting-item">
        <div className="col-sm-3 label ">{title}</div>
        <div className="col-sm-9">
            <input className="input-item" onChange={(event)=> {
                if (ValidateValue && ValidateValue(event.target.value))onChange(event.target.value)
            }}/>
        </div>
    </div>
};
InputValue.propTypes = {
    title: PropTypes.string.isRequired,
    ValidateValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export class InputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: this.props.defaultValue || ''};
        this.updateErrorStateAndFireData = this.updateErrorStateAndFireData.bind(this);
    }

    componentDidMount() {
        this.updateErrorStateAndFireData({target: {value: this.state.value}});
    }

    render() {
        const {title, onChange, validator, minLength, maxLength, defaultValue, isRequired, ...rest}=this.props;
        return <div className="row setting-item">
            <div className="col-sm-3 form-label label ">{isRequired ? <span>{title}</span> : title}</div>
            <div className="col-sm-9">
                <input className="input-item" value={this.state.value} {...rest}
                       onChange={this.updateErrorStateAndFireData}/>
            </div>
        </div>
    }

    updateErrorStateAndFireData(event) {
        let finalValue;
        if (event.target.value == '') {
            finalValue = event.target.value;
            this.setState({value: finalValue}, ()=> {
                this.props.onChange(this.state.value, this.props.isRequired);
            })

        } else if (this.props.validator) {
            if (this.props.validator(event.target.value)) {
                finalValue = this.props.validator(event.target.value);
                this.setState({value: finalValue}, ()=> {
                    this.props.onChange(this.state.value, false);
                })
            } else {
                this.props.onChange(null, true);
            }
        } else {
            finalValue = event.target.value;
            this.setState({value: finalValue}, ()=> {
                this.props.onChange(finalValue, false);
            });
        }
    }

    componentWillUnmount() {
        this.props.onChange('', false);
    }


}
InputField.propTypes = {
    title: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    minLength: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    validator: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired
};