import React from "react";
import { PageWrapper } from "../../../../../shared/layout";
import {
  Search as SearchBar,
  Paginator,
  DatePicker,
  DropdownWrapper,
} from "../../../../../shared/widgets";
import { RegionSelector } from "../../../../../shared/components";
import { ListViewFrequency } from "./reportListFrequencyComponent";
import { fetchFrequencyByRegion, downLoadFrequencyDistanceDataYesterday} from "../../services/reportService";
import { ButtonToolbar, Modal } from "react-bootstrap";
import { CustomExport } from "../customExport";
import { routes } from "../../../../../routing/routes";
import * as moment from "moment";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import { connect } from "react-redux";
class DayFrequencyBodyWrapper extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    // let m1 = moment(new Date());
    this.state = {
      pageNo: 0,
      totalPages: 0,
      exportOptions: [
        {
          key: "default",
          value: "Current Date",
        },
        {
          key: "custom",
          value: "Select Range",
        },
      ],
      placeHolder: "Search vehicle",
      totalItems: this.props.totalItems,
      date: m.subtract(1, "day"),
      // yesterdayDate: m1.subtract(1, "day"),
      sortOrder: "ASC",
      sortKey: "greaterThanOneTwenty",
      searchString:null
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.updateSearchedData = this.updateSearchedData.bind(this);
    this.handleDataSorting = this.handleDataSorting.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.fetchFrequencyByRegion = this.fetchFrequencyByRegion.bind(this);
    this.getList = this.getList.bind(this);
  }

  render() {
    return (
      <div>
        <div className="report-section">
          <PageWrapper.Header heading="Date-wise Frequency " hideS={true}>
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
                <RegionSelector
                  filterKey={routes.REPORT}
                  onRegionChange={this.handleRegionChange}
                />
                <DatePicker
                  date={this.state.date}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </ButtonToolbar>
            </div>
            <DropdownWrapper
              title="Export"
              menue={this.state.exportOptions}
              onSelect={(value) => {
                switch (value) {
                  case "default": {
                    this.exportFrequencyData();
                    break;
                  }
                  case "custom": {
                    this.open(this, null);
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
            {this.displayFrequencyModal()}
          </PageWrapper.Header>
          <PageWrapper.Body>
            {!this.props.detailType && (
              <PageWrapper.BodyHeader>
                {!this.props.detailType && (
                  <div className="pull-right search-container">
                    <SearchBar
                      placeHolder={this.state.placeHolder}
                      onSearchChange={this.handleSearchChange}
                      value={this.state.searchString}
                    />
                  </div>
                )}
                {this.state.data && (
                  <span> Device Count : {this.state.totalItems}</span>
                )}
              </PageWrapper.BodyHeader>
            )}
            <div className="list-wrapper">
              <ListViewFrequency
                type={this.props.type}
                loading={this.props.loading}
                detailType={this.props.detailType}
                content={this.state.data}
                onSortChange={this.handleDataSorting}
              />
            </div>
            {this.state.data && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
          </PageWrapper.Body>
        </div>
      </div>
    );
  }

  handleSearchChange(key) {
    this.setState({
      searchString: key,
    });
    this.updateSearchedData(key);
  }

  updateSearchedData(key) {
    this.setState(
      (prevState) => {
        return {
          data: key
            ? prevState.data.filter((item) => {
                return item.vehicleNo.toLowerCase().includes(key.toLowerCase());
              })
            : prevState.data,
        };
      },
      () => {
        if (this.state.sortOrder) {
          this.sortFilteredData(this.state.sortOrder, this.state.sortKey,key);
        }
      }
    );
  }
  fetchFrequencyByRegion(regionId, timestamp, pageNo, key, searchKey, order) {
    this.props.updateLoader(true);
    fetchFrequencyByRegion(regionId, timestamp, pageNo, key,searchKey, order)
      .then((data) => {
        this.props.updateLoader(false);
        this.setState({
          data: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
        if (this.mounted) {
          this.props.updateLoader(false);
          this.setState({
            data: [],
          });
        }
      });
  }
  getList(pageNo) {
    let formattedDate = this.state.date.startOf("day").format("x");
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchFrequencyByRegion(
        this.state.selectedRegion,
        parseInt(formattedDate),
        pageNo,
        this.state.sortKey,
        this.state.searchString,
        this.state.sortOrder
      );
    }
  }
  /**
   * sort data for the giver key in given order
   * @param order
   * @param key
   */
  handleDataSorting(order, key) {
    console.log("calling handlesorsorting", order, "key", key);
    this.setState({
      sortOrder: order,
      sortKey: key,
    });
    this.sortFilteredData(order, key);
  }

  handleRegionChange(region) {
    this.setState({ selectedRegion: region }, () => {
      if (this.state.dataType && this.state.dataType.value === "Vehicle") {
        this.fetchVehiclesList(this.state.selectedRegion);
      } else {
        this.handleDateChange(
          this.state.selectedRegion,
          this.state.selectedDate || this.state.date
        );
      }
    });
  }

  dateChange(date) {
    this.setState({ date: date });
    this.handleDateChange(this.state.selectedRegion, date);
  }

  handleDateChange(regionId, date) {
    let formattedDate = date.startOf("day").format("x");
    this.fetchFrequencyByRegion(
      regionId,
      parseInt(formattedDate),
      0,
      this.state.sortKey,
      null,
      this.state.sortOrder
    );
  }
  sortFilteredData(order, key) {
    let formattedDate = this.state.date.startOf("day").format("x");
    this.fetchFrequencyByRegion(
      this.state.selectedRegion,
      parseInt(formattedDate),
      0,
      key,
      this.state.searchString,
      order
    );
  }
  exportFrequencyData() {
    downLoadFrequencyDistanceDataYesterday(
      this.state.selectedRegion,
      this.state.date,
      this.state.sortOrder, 
      this.state.sortKey
    )
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
        }
      });
  }

  /**
   * Call when Add/Update modal Open.
   */
  open(data) {
    this.setState({
      showModal: true,
    });
  }

  displayFrequencyModal() {
    return (
      <Modal
        dialogClassName="custom-range-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Select Range" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CustomExport
              selectedRegion={this.state.selectedRegion}
              onComplete={(e, msg) => {
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
    });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
}
export default connect(null, defaultMapDispatchToProps)(DayFrequencyBodyWrapper);