function fetchFileBlob(url, options) {
    return fetch(url, options)
        .then(handleBlobResponse, handleNetworkError);
}
function handleBlobResponse(response) {
    if (response.ok) {
        if (response.status == 204) {
            return {
                status: 204, description: "No Content Found"
            }
        }
        else {
            /*Get disposition and content type from response header*/
            var disposition = response.headers.get('Content-Disposition');
            var type = response.headers.get('Content-Type');

            /*extract filename from disposition data*/
            var matches = disposition.split('=');
            var filename = (matches != null && matches[1] ? matches[1] : null);

            /*returns blob data with filename and type details*/
            return response.blob().then((blobData)=> {
                return ({blob: blobData, filename: filename, type: type})
            }).catch((err)=> {
                return (err)
            });

        }
    } else {
        return response.json().then(function (error) {
            error.status = response.status;
            throw error;
        });
    }
}
function handleNetworkError(error) {
    throw {
        msg: error.message
    };
}

function downloadFile(url, filename) {
    var link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


const FileDownLoader = {};
FileDownLoader.download = (url, options, filename)=> {
    return new Promise((resolve, reject)=> {
        fetchFileBlob(url, options).then((response)=> {
            /*creates blob for stream Data and type returned by server*/
            var blob = new Blob([response.blob], {type: response.type});
            response.url = window.URL.createObjectURL(blob);

            return response;

        }).then((response)=> {
            /**choose file name*/
            var typeMatch = response.type.split("/");
            var filename = response.filename || filename || `file.${typeMatch}`;
            var replaced_filename = filename.replace(/"/g, '');
            /*download file*/
            downloadFile(response.url, replaced_filename);

            resolve('success');
        }).catch(err=> {
            reject(err)
        })
    });
};

export default  FileDownLoader;