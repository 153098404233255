import {BASE_URL} from "../../../../Constant";
import {GET_REGIONS_DELAY2,MatrixStatusFilterValues} from "../../../../Constant";
import {getAPIPromise} from "../../../../services/APIutils";

export function fetchDelayMatrix2(timeSlab,status) {
    let URL = `${BASE_URL}${GET_REGIONS_DELAY2}`;
    if (timeSlab) {
        URL = URL + `?timeSlab=${timeSlab}`
    }
    if (status && status != MatrixStatusFilterValues.ALL.key){
        URL = URL + `&status=${status}`
    }
    return getAPIPromise(URL, {
        method: 'get'
    });
}