import React from "react";
import { connect } from "react-redux";
import { Modal, ButtonToolbar } from "react-bootstrap";
import * as moment from "moment";

import { templateStrings } from "../../../../Constant.js";
import { MapWrapperComponent } from "../../map/mapWrapper";
import { defaultMapDispatchToProps } from "../../../../redux/map-to-props";
import { Paginator, DatePicker } from "../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../shared/layout";
import { getPanicReportDetail } from "../../../../services/vehicleMasterService.js";
import "./index.css";

class PanicAlertDetail extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date(this.props.location.state[0].fromTime));
    this.state = {
      pageNo: 0,
      type: this.props.match.params.vehicleId,
      totalPages: 0,
      selectedDate: m.subtract(0, "day"),
      showModal: false,
      selectedAlert: null,
      startDate: this.props.location.state[0].fromTime,
      endDate: this.props.location.state[0].toTime,
      panicReportState:this.props.location.state[0]
    };
  }

  /**
   * Called when the component is mounted.
   * Fetches the city names list.
   */
  componentDidMount() {
    this.fetchPanicAlertDetail();
  }

  fetchPanicAlertDetail = () => {
    this.props.updateLoader(true);
    getPanicReportDetail(this.state)
      .then((res) => {
        this.props.updateLoader(false);
        this.setState({
          content: res.items,
          totalPages: res.totalPages,
          pageNo: res.currentPage,
          totalItems: res.totalItems,
        });
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
          content: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        this.errorHandler(err);
      });
  };

  errorHandler = (err) => {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching Panic detail report",
      });
    }
  };

  /**
   * API call to get devices.
   * @param pageNo current page number
   */
  getList = (pageNo) => {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState({ pageNo }, () => {
        this.fetchPanicAlertDetail();
      });
    }
  };

  /**
   * Used to show device in row in List view
   * @param item  Object of item to display
   */
  displayList = (item) => {
    const getTableCelContent = (column) => {
      switch (column) {
        case "timestamp":
          return moment(item[column]).format("DD/MM/YYYY hh:mm:ss A");
        case "lastPacketArriveAt":
          return moment(item[column]).format("hh:mm:ss A");
        default:
          return item[column];
      }
    };
    function generateUniqueString() {
      const array = new Uint8Array(16);
      crypto.getRandomValues(array);
      return Array.from(array, (value) =>
        ("0" + value.toString(16)).slice(-2)
      ).join("");
    }
    return (
      <tr key={generateUniqueString()}>
        {Object.keys(templateStrings.panicAlertReport).map((column) => {
          return (
            <td key={generateUniqueString()} title={getTableCelContent(column)}>
              {column !== "action" && getTableCelContent(column)}
              {column === "action" && (
                <div
                  onClick={() => {
                    this.setState({ showModal: true, selectedAlert: item });
                  }}
                >
                  <i className="fa fa-eye protocol" aria-hidden="true" />
                </div>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  dateChange = (date) => {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
        pageNo: 0,
      },
      () => {
        this.fetchPanicAlertDetail();
      }
    );
  };

  back = () => {
    this.props.history.push({
      pathname: `/alerts/panic-alerts`,
      state: [this.state.panicReportState]
    });
  }

  /**
   * Renders the component and returns the JSX for the Panic Alert Detail page.
   *
   * @return {JSX.Element} The JSX for the Panic Alert Detail page.
   */
  render() {
    return (
      <div>
        <div className="page-header">
        <span
          onClick={() => {
            this.back();
          }}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </span>
        <span className="panic-alert-detail-header-text">Panic Alert Detail </span>
          {this.state.content && (
            <span className="count pl-5"> Count : {this.state.totalItems}</span>
          )}
          <div
            className="dataTables_length wrapper-gps"
            id="dataTables-example_length"
          >
            <ButtonToolbar>
              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.dateChange}
                withoutDefaultBorder={false}
                todaySelectable={true}
              />
            </ButtonToolbar>
          </div>
          <div className="pull-right search-container">
            <span
              className="span-icon refresh-icon"
              onClick={this.fetchPanicAlertDetail}
            ></span>
          </div>
          </div>
        <PageWrapper.Body>
          <TableWrapper>
            <TableWrapper.Header columns={templateStrings.panicAlertReport} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.map((data) => {
                    return this.displayList(data);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          <Paginator
            pageNo={this.state.pageNo}
            totalPages={this.state.totalPages}
            getList={this.getList}
          />
        </PageWrapper.Body>
        {this.displayModal()}
      </div>
    );
  }

  close = () => {
    this.setState({
      showModal: false,
      selectedAlert: null,
    });
  };

  displayModal = () => {
    if (this.state.showModal) {
      const data = this.state.selectedAlert;
      const center = {
        lat: data.latitude,
        lng: data.longitude,
      };
      const imgUrl = `${window.location.protocol}//gps.chalo.com/assets/images`;
      const icon = {
        url: `${imgUrl}/device.png`,
        scaledSize: {
          width: 40,
          height: 57,
        },
      };

      return (
        <Modal
          show={this.state.showModal}
          onHide={this.close.bind(this)}
          backdrop={false}
          dialogClassName="info-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Panic Alert Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="detail-container-1">
              <div className="col-sm-5">
                {Object.keys(templateStrings.panicAlertReport).map((key) => {
                  return (
                    <div className="row" key={key}>
                      {key != "action" && (
                        <>
                          <span className="info-value">
                            {" "}
                            {templateStrings.panicAlertReport[key]} :{" "}
                          </span>
                          {key === "timestamp" && (
                            <span className="info-label">
                              {" "}
                              &nbsp;&nbsp;{" "}
                              {moment(this.state.selectedAlert[key]).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )}
                            </span>
                          )}
                          {key === "lastPacketArriveAt" && (
                            <span className="info-label">
                              {" "}
                              &nbsp;&nbsp;{" "}
                              {moment(this.state.selectedAlert[key]).format(
                                "hh:mm:ss A"
                              )}
                            </span>
                          )}
                          {!["timestamp", "lastPacketArriveAt"].includes(
                            key
                          ) && (
                            <span className="info-label">
                              {" "}
                              &nbsp;&nbsp; {this.state.selectedAlert[key]}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="col-sm-7 map-wrapper">
                <div className="row device-detail-map">
                  <MapWrapperComponent
                    data={data}
                    center={center}
                    icon={icon}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  };
}

export default connect(null, defaultMapDispatchToProps)(PanicAlertDetail);
