/**
 * Main container
 * imported in App.js
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableWrapper, PageWrapper } from "../../../../shared/layout";
import { ChangeTimeButton } from "../../../../shared/components";
import {
  Search as SearchBar,
  frontEndPaginator,
} from "../../../../shared/widgets";
import {
  refreshTimestamp,
  testRegion,
  timeIntervals,
} from "../../../../Constant";
import { fetchTimeValue } from "../../../../shared/utils.js";
import HealthCheckComponent from "../../healthstatus/healthCheckComponent";
import { setNotificationMessage } from "../../../../redux/actions/notificationActions";
import { createDelayMatrixAction } from "../../../../redux/action-creators/dMatrixActionCreator";
import {
  changePage,
  changeSort,
  applySearch,
} from "../../../../redux/actions/dMatrixActions";
import { homeTemplateStrings } from "../utils";

const paginatorInfo = { paginator: "Matrix" };
const FrontPaginator = frontEndPaginator(paginatorInfo);
class DelayMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledRefresh: false,
      timerId: null,
      content: [],
      showModal: false,
      showHeaderButton: false,
      backdrop: "static",
      timeIn: "sec",
      showMessageinHeader: true,
    };
    this.onSortChange = this.onSortChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.refreshAtInterval = this.refreshAtInterval.bind(this);
  }

  componentWillMount() {
    /**
     * API call for protocols list
     */
    this.mounted = true;
    this.initiateDevicesPolling(this.props.timeInterval);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.timeInterval != nextProps.timeInterval) {
      this.initiateDevicesPolling(nextProps.timeInterval);
    }
  }

  render() {
    return (
      <div>
        <PageWrapper.Header
          heading={homeTemplateStrings.heading}
          onAdd={this.open.bind(this)}
          showButton={this.state.showHeaderButton}
          showMessage={this.state.showMessageinHeader}
        >
          <div className="pull-right">
            <a onClick={this.refreshAtInterval}>
              <span
                className={`span-icon refresh-icon ${
                  this.state.disabledRefresh ? "disabled" : ""
                }`}
              />
            </a>
          </div>
          <div className="pull-right">
            <ChangeTimeButton />
          </div>
          <div className="pull-right search-container">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.props.searchString}
            />
          </div>
          <span className="cluster-ip">
            Details for {timeIntervals[this.props.timeInterval].value}
          </span>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <TableWrapper>
            <TableWrapper.Header
              columns={homeTemplateStrings.columns}
              timeParse={this.props.timeParse}
              onSortToggle={this.onSortChange}
            />
            <tbody>
              {this.props.paginatedData &&
                this.props.paginatedData
                  .filter((region) => {
                    return region.regionId != testRegion;
                  })
                  .map((data) => {
                    return this.displayList(data);
                  })}
              {(!this.props.content ||
                (this.props.content && this.props.content.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
            {this.props.paginatedData &&
              !!this.props.paginatedData.filter((region) => {
                return region.regionId == testRegion;
              }).length && (
                <thead className="normal_table_head">
                  <tr role="row">
                    <th className="test-header" tabIndex="0" colSpan="7">
                      Test Region
                    </th>
                  </tr>
                </thead>
              )}
            <tbody>
              {this.props.paginatedData &&
                this.props.paginatedData
                  .filter((region) => {
                    return region.regionId == testRegion;
                  })
                  .map((data) => {
                    return this.displayList(data);
                  })}
            </tbody>
          </TableWrapper>
          {this.props.content && <FrontPaginator data={this.props.content} />}
        </PageWrapper.Body>
      </div>
    );
  }

  /**
   * Call when Add/Update modal Open.
   */
  open() {
    this.setState({ showModal: true });
  }

  /**
   * API call by fetch to get protocol lists
   */
  fetchRegionsDelayMatrix(timeInterval) {
    this.props.fetchMatrix(timeInterval);
  }

  /**
   * Used to show protocol list in row in List view
   * @param item Object of item to display in list view
   */
  displayList(item) {
    return (
      <tr key={item.regionId} className="gradeA odd" role="row">
        <td className="list-item">{item.regionId}</td>
        <td>
          <HealthCheckComponent
            region={item}
            onMessage={(e, msg) => {
              this.props.initiateNotification({ type: e, message: msg });
            }}
          />
        </td>
        <td>
          <span className="protocol">
            {fetchTimeValue(
              this.props.timeParse,
              item.avgParsingTime,
              item.avgPostingTime
            )}
          </span>
          <span className="protocol-list">
            ({fetchTimeValue(this.props.timeParse, item.avgParsingTime, null)}+
            {fetchTimeValue(this.props.timeParse, item.avgPostingTime, null)})
          </span>
        </td>
        <td>{fetchTimeValue(this.props.timeParse, item.avgDelay, null)}</td>
        <td>{item.deviceCount}</td>
        <td>
          {item.packetCount
            ? (item.packetCount / 1000).toFixed(1) + "K"
            : "N/A"}
        </td>
      </tr>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  refreshAtInterval() {
    const { timerId } = this.state;
    if (timerId) {
      clearTimeout(timerId);
    }
    if (!this.state.disabledRefresh) {
      this.initiateDevicesPolling(this.props.timeInterval);
    }
    this.setState({
      disabledRefresh: true,
    });
    this.setState({
      timerId: setTimeout(() => {
        this.initiateDevicesPolling(this.props.timeInterval);
        this.setState({
          disabledRefresh: false,
        });
      }, 30000),
    });
  }

  debounce(callback, delay = 1000) {
    var time;
    return (...args) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }

  initiateDevicesPolling(timeInterval) {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this.fetchRegionsDelayMatrix(timeInterval);
    let intervalId = setInterval(() => {
      if (!document.hidden) this.fetchRegionsDelayMatrix(timeInterval);
    }, refreshTimestamp);
    this.setState({
      intervalId: intervalId,
    });
  }

  /**
   * handle sort change event
   * @param sort
   * @param key
   */
  onSortChange(sort, key) {
    this.props.changeSort({ key: key, order: sort });
  }

  handleSearchChange(str) {
    this.props.applySearch(str);
  }
}

function mapStateToProps(state) {
  return {
    timeParse: state.Settings.timeParse,
    timeInterval: state.Settings.timeInterval,
    content: state.DelayMatrix.displayData,
    paginatedData: state.paginator[paginatorInfo.paginator]
      ? state.paginator[paginatorInfo.paginator].content
      : [],
    searchString: state.DelayMatrix.search,
  };
}

const mapDispatchToProps = (dispatch) => ({
  initiateNotification: (value) => {
    dispatch(setNotificationMessage(value));
  },
  fetchMatrix: (value) => {
    dispatch(createDelayMatrixAction(value));
  },
  changePage: (value) => {
    dispatch(changePage(value));
  },
  changeSort: (value) => {
    dispatch(changeSort(value));
  },
  applySearch: (value) => {
    dispatch(applySearch(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DelayMatrix);
