import React from "react";
import PropTypes from "prop-types";
import {SelectFilter} from "../../widgets/selectFilter/selectFilter";
import {FilterView} from "../../widgets/staticDropdown/staticDropDown";
import {regionExistIn} from "../../utils";
import "./RegionSelector.css";
import {AuthenticationService} from "../../../services/authorization";
import {OPERATIONS} from "../../../services/authorization/accessRights";
import { fetchTimezonefromList } from "../../../shared/utils";
import { connect } from "react-redux";


class RegionSelector extends React.Component {
    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        this.state = {
            showFilteredData: this.shouldShowFilteredData(),
            tobeFilteredRegions: this.getToBefiltredRegions()
        };
    }

    shouldShowFilteredData() {
        return this.authService.shouldFilterRegionFor(this.props.filterKey, OPERATIONS.READ);
    }

    getToBefiltredRegions() {
        return this.authService.getRegionToFilter;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.regions != nextProps.regions) {
            if (!this.state.showFilteredData) {
                this.selectRegion(nextProps.regions);
            }
            else
                this.updateFilteredRegions(nextProps.regions);
        }
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.selectedRegion) {
            if (this.state.showFilteredData)
                this.updateFilteredRegions(this.props.regions);
            else {
                if (!this.shouldChooseAll() && this.props.selectedRegion == 'all') {
                    this.selectRegion(this.props.regions);
                } else {
                    this.props.onRegionChange(this.props.selectedRegion);
                    fetchTimezonefromList(this.props.timezoneList,this.props.selectedRegion)
                    this.updateProtocolFilterData(this.props.regions, this.props.selectedRegion);

                }
            }
        } else {
            if (this.props.regions) {
                if (this.state.showFilteredData)
                    this.updateFilteredRegions(this.props.regions);
                else {

                    this.selectRegion(this.props.regions);
                }

            }

        }

    }

    selectRegion(regions) {
        let region = this.getValidRegion(regions[0].regionId);
        this.props.onRegionChange(region);
        fetchTimezonefromList(this.props.timezoneList,region)
        this.props.regionSelectChange(region);
        this.updateProtocolFilterData(regions, region);
    }

    getValidRegion(region) {
        if (this.shouldChooseAll()) {
            region = 'all';
        }
        return region;
    }

    /**
     * check if need to select all as default region
     * @returns {*}
     */
    shouldChooseAll() {
        const {showAll}=this.props;
        return showAll && this.authService.shouldShowAll();
    }

    updateFilteredRegions(regions) {
        this.setState({
            filteredRegions: regions.filter(region=> {
                let returnValue = regionExistIn(this.state.tobeFilteredRegions, region.regionId);
                return !returnValue;
            })
        }, ()=> {
            let regionExists = !this.props.selectedRegion || (regionExistIn(this.state.tobeFilteredRegions, this.props.selectedRegion));
            if (!regionExists && (this.shouldChooseAll() || this.props.selectedRegion != 'all')) {
                this.props.onRegionChange(this.props.selectedRegion);
                fetchTimezonefromList(this.props.timezoneList,this.props.selectedRegion)
                this.updateProtocolFilterData(regions, this.props.selectedRegion);
            } else {
                this.props.regionSelectChange(this.state.filteredRegions[0].regionId);
                this.props.onRegionChange(this.state.filteredRegions[0].regionId);
                fetchTimezonefromList(this.props.timezoneList,this.state.filteredRegions[0].regionId)
                this.updateProtocolFilterData(regions, this.state.filteredRegions[0].regionId);

            }
        })
    }

    render() {
        const {onRegionChange, showProtocols, onProtocolChange, ...rest}=this.props;
        return <div className="region-selector-wrapper">
            <SelectFilter value={this.props.selectedRegion}
                          onChange={(value) => {
                              if(value){
                                onRegionChange(value.regionId);
                                fetchTimezonefromList(this.props.timezoneList,value.regionId)
                                this.updateProtocolFilterData(this.props.regions, value.regionId);
                                this.props.regionSelectChange(value.regionId)
                              }
                          }}
                          valueKey="regionId"
                          labelKey="regionId"
                          placeholder="Region..."
                          options={this.state.showFilteredData ? this.state.filteredRegions : this.props.regions}
                          showAll={this.shouldChooseAll()}
                          {...rest}
            />
            {showProtocols && <FilterView
                selectedValue={this.props.selectedProtocol}
                title="Protocol"
                useData={true}
                data={this.state.protocols}
                dataKey="protocolId"
                showReset={true}
                onSelect={onProtocolChange}/>}
        </div>
    }

    /**
     * update protocols filter data in view on region change
     * @param regions
     * @param regionId
     */
    updateProtocolFilterData(regions, regionId) {
        if (this.props.showProtocols && this.props.onProtocolChange) {
            this.setState({
                protocols: ((regions.filter(region => {
                    return region.regionId === regionId
                }))[0]).protocols,
            });
            this.props.onProtocolChange(null);
        }
    }

}

function mapStateToProps(state) {
    return {
        timezoneList: state.Timezones.timezones
    };
}

RegionSelector.propTypes = {
    filterKey: PropTypes.string,
    showAll: PropTypes.bool,
    selectedRegion: PropTypes.string,
    selectedProtocol: PropTypes.string,
    regions: PropTypes.array,
    showProtocols: PropTypes.bool,
    onRegionChange: PropTypes.func,
    regionSelectChange: PropTypes.func,
    onProtocolChange: PropTypes.func,
};
export default connect(mapStateToProps, null)(RegionSelector);