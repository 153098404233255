import React, {Component} from "react";
import {Button, Pager, FormGroup, FormControl, InputGroup} from "react-bootstrap";
import './paginator.css';

/**
 * Paginator component which will have total page,current page  and pageChnage event handler as props and renders view
 */
export default class Paginator extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pageNo != this.props.pageNo) {
            this.textInput.value = nextProps.pageNo + 1;
        }
    }

    render() {
        if (this.props.totalPages > 1)
            return (  <div className=" cusPagination">
                    <Pager>
                        {this.props.pageNo > 0 ?
                            <Pager.Item previous href="#"
                                        onClick={() => this.prevPage()}>Previous
                            </Pager.Item>
                            :
                            <Pager.Item previous href="#" disabled
                                        onClick={() => this.prevPage()}>Previous
                            </Pager.Item>
                        }
                        {/* Paginate via input */}
                        {this.props.totalPages && this.props.totalPages > 0 &&
                        <div className="pagination-wrap">
                            <div className="resetBtn">Page {this.props.pageNo + 1}
                                of {this.props.totalPages}</div>
                            <FormGroup>
                                <InputGroup className="goToBtn">
                                    <FormControl
                                        inputRef={input => this.textInput = input}
                                        type="text"/>
                                    <InputGroup.Button>
                                        <Button onClick={() => this.gotoButton()}>Go
                                            to</Button>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>
                        </div>}
                        {this.props.pageNo + 1 < this.props.totalPages ?
                            <Pager.Item next href="#" onClick={() => this.nextPage()}>Next
                            </Pager.Item>
                            :
                            <Pager.Item next href="#" disabled
                                        onClick={() => this.nextPage()}>Next
                            </Pager.Item>
                        }
                    </Pager>
                </div>
            );
        else {
            return null;
        }
    }

    /**
     * Used to jump on given page in inputbox.
     */
    gotoButton() {
        let pageNo = this.textInput.value;
        if (pageNo.length) {
            pageNo = parseInt(pageNo);
            if (pageNo > 0 && pageNo <= this.props.totalPages) {
                this.firePageChangeEvent(pageNo - 1);
            }
        }
    }

    firePageChangeEvent(pageNo) {
        if (this.props.changePage) {
            this.props.changePage(pageNo);
        } else   this.props.getList(pageNo);
    }

    /**
     * Used to go to Prev page.
     * Conditions for Filters and Default.
     */
    prevPage() {
        this.firePageChangeEvent(this.props.pageNo - 1)
    }

    /**
     * Used to go to Next page.
     * Conditions for Filters and Default.
     */
    nextPage() {
        this.firePageChangeEvent(this.props.pageNo + 1)
    }
}