import React from "react";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { ButtonToolbar } from "react-bootstrap";
import { DepotReport } from "../../utils";
import { AuthenticationService } from "../../../../../services/authorization";
import {
  fetchDepotReport,
  cityByRegion,
  fetchDepotbyCity,
} from "../../services/reportService";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { Paginator,DatePicker } from "../../../../../shared/widgets";
import { connect } from "react-redux";
import { RegionSelector } from "../../../../../shared/components";
import { routes } from "../../../../../routing/routes";
import { Button } from "react-bootstrap";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props/";
import * as moment from "moment";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

class DeportReport extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    this.authService = AuthenticationService.getInstance();
    this.state = {
      pageNo: 0,
      selectedDepot: "",
      selectedDepotName: "",
      cityId: "",
      totalPages: 0,
      totalItems: 0,
      data: this.props.data,
      showSelectModal: false,
      showSRSelectModal: false,
      loadingstatus: null,
      placeHolder: "Search Depot Name",
      searchString: "",
      sortOrder: null,
      sortKey: null,
      selectedDate: m.subtract(0, "day"),
      timeSelected: ['00:00',new Date()]

    };
    this.getList = this.getList.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.toTimeChange = this.toTimeChange.bind(this);
    this.handleDataSorting = this.handleDataSorting.bind(this);
    this.toDateChange = this.toDateChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  fetchDepotNameData(depot) {
    fetchDepotbyCity(depot)
      .then((res) => {
        if (this.mounted) {
          this.setState({ depots: res });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  render() {
    return (
      <div className="device-page">
        <PageWrapper.Header heading="Vehicle IN/OUT " hideS={true}>
          <div className="pull-right">
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
            <div className="date-time-margin">

              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.toDateChange}
                withoutDefaultBorder={false}
                todaySelectable={true}
              />
              </div>
              <TimeRangePicker format={"hh:mm a"} disableClock={true} minutePlaceholder={'mm'} hourPlaceholder={'hh'} clearIcon={null} onChange={this.toTimeChange} value={this.state.timeSelected} />
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    this.setDate();
                  }}
                >
                  Save Dates
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container">
              <div className="form-group">
                <p className="filters-label">Filter By:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <RegionSelector
                      filterKey={routes.REPORT}
                      onRegionChange={this.handleRegionChange}
                    />
                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        this.onFilterChange(value, "city");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />
                    <SelectFilter
                      value={this.state.selectedDepotName}
                      onChange={(value) => {
                        this.handleSearchChange(value);
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Depot"
                      options={this.state.depots}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header
              columns={DepotReport}
              onSortToggle={this.handleDataSorting}
              type={"depot"}
            />
            <tbody>
              {this.state.data &&
                this.state.data.map((data) => {
                  return (
                    <tr
                      onClick={(e) => {
                        this.navigateToDepot(
                          data.depotName,
                          this.sorting(data.inVehicles),
                          this.sorting(data.outVehicles),
                          this.sorting(data.unknownVehicles)
                        );
                      }}
                      key={data.depotName}
                      className="gradeA odd"
                      role="row"
                    >
                      <td className="list-item">{data.cityName}</td>
                      <td className="list-item">{data.depotName}</td>
                      <td className="list-item">{data.inVehicles.length}</td>
                      <td className="list-item">{data.outVehicles.length}</td>
                      <td className="list-item">
                        {data.unknownVehicles.length}
                      </td>
                    </tr>
                  );
                })}

              {(!this.state.data ||
                (this.state.data && this.state.data.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="6">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.data && (
            <Paginator
              pageNo={this.state.pageNo}
              totalPages={this.state.totalPages}
              getList={this.getList}
            />
          )}
        </PageWrapper.Body>
      </div>
    );
  }

  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchDepotReport(
        this.state.selectedRegion,
        this.state.selectedCity,
        pageNo,
        this.state.searchString,
        this.getSeconds(this.state.timeSelected[0]),
        this.getSeconds(this.state.timeSelected[1]),
        this.state.sortOrder,
        this.state.sortKey
      );
    }
  }

  handleRegionChange(regionId) {
    this.setState(
      {
        selectedRegion: regionId,
        selectedCity: null,
        selectedDepotName: null,
        searchString: "",
      },
      () => {
        cityByRegion(regionId)
          .then((res) => {
            let all_cities = [];
            res.forEach((item, index) => {
              all_cities.push({ id: index, name: item });
            });
            if (this.mounted) {
              this.setState({ cities: all_cities, depots: []});
            }
            this.fetchDepotReport(
              regionId,
              this.state.selectedCity,
              0,
              this.state.searchString,
              this.getSeconds(this.state.timeSelected[0]),
              this.getSeconds(this.state.timeSelected[1]),
              this.state.sortOrder,
              this.state.sortKey
            );
          })
          .catch((err) => {
            this.props.initiateNotification({ type: 'error', message: 'Error while fetching city' });
            console.log("error", err);
          });
      }
    );
  }
  getSeconds(hms){
    if(typeof(hms) == 'string'){
      let formattedDate = this.state.selectedDate.startOf("day").format("x");
      var timeParts = hms.split(":");
      return parseInt(formattedDate)+((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
    }
    else if(typeof(hms) == 'object'){
      let date=new Date(hms)
      const seconds = Math.floor(date.getTime());
      return seconds;
    }
    return 0;
  }

  setDate() {
    this.fetchDepotReport(
      this.state.selectedRegion,
      this.state.selectedCity,
      0,
      this.state.searchString,
      this.getSeconds(this.state.timeSelected[0]),
      this.getSeconds(this.state.timeSelected[1]),
      this.state.sortOrder,
      this.state.sortKey
    );
  }


  /**
   * sort data for the giver key in given order
   * @param order
   * @param key
   */
  handleDataSorting(order, key) {
    if (key == "inVehicles") {
      key = "in";
    } else if (key == "outVehicles") {
      key = "out";
    } else {
      key = "unknown";
    }
    this.setState({
      sortOrder: order,
      sortKey: key,
    });
    this.fetchDepotReport(
      this.state.selectedRegion,
      this.state.selectedCity,
      0,
      this.state.searchString,
      this.getSeconds(this.state.timeSelected[0]),
      this.getSeconds(this.state.timeSelected[1]),
      order,
      key
    );
  }

  toDateChange(date) {
    if(date){
      var iscurrentDate = date.isSame(new Date(), "day");
      if(iscurrentDate){
        this.setState({ selectedDate:date,timeSelected: ['00:00',moment(new Date()).format('hh:mm')]},()=>{
          this.setDate();
        });
      }
      else{
        this.setState({ selectedDate: date,timeSelected: ['00:00','23:59']},()=>{
          this.setDate();
        });
      }
    }
    else{
      this.setState({ selectedDate:null },()=>{
        this.setDate();
      });
    }
  }

  toTimeChange(date) {
    if(date){
      this.setState({ timeSelected: date});
    }
    else{
      this.setState({ timeSelected:null });
    }
  }

  handleSearchChange(key) {
    this.setState(
      {
        searchString: key && key.name ? key.name : "",
        selectedDepotName: key && key.name ? key.name : "",
      },
      () => {
        this.fetchDepotReport(
          this.state.selectedRegion,
          this.state.selectedCity,
          0,
          this.state.searchString,
          this.getSeconds(this.state.timeSelected[0]),
          this.getSeconds(this.state.timeSelected[1]),
          this.state.sortOrder,
          this.state.sortKey
        );
      }
    );
  }
  sorting(data) {
    let key = "timestamp";
    return data.sort((a, b) => {
      let returnValue;
      returnValue = b[key] - a[key];
      return returnValue;
    });
  }
  navigateToDepot(depotName, inVehicles, outVehicles, unknownVehicles) {
    this.props.history.push(`${this.props.pathname}/${depotName}/`, {
      data: {
        inVehicles: inVehicles,
        outVehicles: outVehicles,
        unknownVehicles: unknownVehicles,
      },
    });
  }

  fetchDepotReport(
    region,
    city,
    pageNo,
    searchKey,
    startTime,
    endTime,
    sortOrder,
    sortKey
  ) {
    this.props.updateLoader(true);
    fetchDepotReport(
      region,
      city,
      pageNo,
      searchKey,
      startTime,
      endTime,
      sortOrder,
      sortKey
    )
      .then((data) => {
        this.setState({
          data: data.content,
          totalPages: data.totalPages,
          pageNo: data.pageable.pageNumber,
          totalItems: data.totalElements,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.initiateNotification({ type: 'error', message: err.developerMessage ? err.developerMessage : 'Error while fetching data' });
        this.props.updateLoader(false);
        this.setState({
          data: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
  onFilterChange(value, masterType) {
    this.setState({ searchString: "" });
    if (masterType) {
      this.setState(
        {
          selectedCity: value && value.name ? value.name : null,
          selectedDepot: value && value.name && value.id ? value.id : null,
          selectedDepotName: value && value.name && value.id ? value.id : null,
        },
        () => {
          this.fetchDepotReport(
            this.state.selectedRegion,
            this.state.selectedCity,
            0,
            this.state.searchString,
            this.getSeconds(this.state.timeSelected[0]),
            this.getSeconds(this.state.timeSelected[1]),
            this.state.sortOrder,
            this.state.sortKey
          );
          this.fetchDepotNameData(
            this.state.selectedCity
              ? this.state.selectedCity.toLowerCase()
              : null
          );
        }
      );
    }
  }
}
export default connect(null, defaultMapDispatchToProps)(DeportReport);
