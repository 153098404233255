import React from "react";
import './sortingView.css';

export class SortingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortOrder: this.props.defaultSort || sortOption.ASC
        }
    }

    componentDidMount() {
        if(!this.props.type){
            this.props.onToggle(this.state.sortOrder);
        }
    }

    render() {
        return <span className="sort-view-wrapper" onClick={()=> {
            let newState;
            if (this.state.sortOrder == sortOption.ASC) {
                newState = sortOption.DESC
            } else {
                newState = sortOption.ASC;
            }
            this.setState({
                sortOrder: newState
            });
            this.props.onToggle(newState);
        }
        }><span className="sort-icon-wrapper "><span className={this.state.sortOrder == sortOption.ASC ? "sort-icon sort-arrow-up" : "sort-icon sort-arrow-up deactive"}
                      aria-hidden="true"/>
    <span className={this.state.sortOrder == sortOption.DESC ? "sort-icon sort-arrow-down" : "sort-icon sort-arrow-down deactive"}
          aria-hidden="true"/></span></span>

    }
}
export const sortOption = {
    ASC: 'ASC',
    DESC: 'DESC'
};