import {actions} from './action-list';
export function setNotificationMessage(value) {
    return {
        type: actions.NOTIFICATION,
        value: value
    }
}

export function setNofificationRegion(value) {
    return {
        type: actions.NOTIFICATION_REGION,
        value: value
    }
}
