import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { fetchVehicleMaster } from '../../../../../../src/services/vehicleMasterService'
import { MessageType } from "../../../../../Constant";

import { upsertServiceCategory, upsertServiceProvider, getCityData } from '../../../../../services/vehicleMasterService';
import validateServiceCategory from "../../validator/serviceCategoryValidator";


export class ServiceCategoryCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceName:props.serviceName?props.serviceName:'',
      selectedRegion:null,
      selectAllRegions:false,
      regionOptions:[],
      form: {},
      stayOpen: false,
      removeSelected: true,
      rtl: false,
      positionOptions: [],
      loading:false,
      selectedAgencyNames:[],
      agencyNamesOptions:[],
      displayName:''
    };
    this.createServiceCategory = this.createServiceCategory.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchCityNames();
    this.updateFormErrorState();
    if(this.state.selectedRegion){
      this.handleChange("region", this.state.selectedRegion);
    }
  }

   /**
   * validates trip form data
   * @param target
   */
   updateFormErrorState() {
    let formState = validateServiceCategory(this.state);
    this.setState( { form: formState, } );
  }




  componentWillUnmount() {
    this.mounted = false;
  }

  createServiceCategory(){
    const payload = {
      agencyNames:this.state.selectedAgencyNames.map((el)=>el['name']),
      displayName: this.state.displayName,
      cityId: this.state.selectedRegion.cityId
    }
    if(this.props.serviceName){
      payload['serviceId'] = this.props.serviceId;
    }
    if(this.props.activeTab == 'category'){
      payload['serviceName'] = this.state.serviceName;
    }else{
      payload['serviceProviderName'] = this.state.serviceName;
    }
    let query = null;
    const mode = this.props.serviceName ? true : false;
    if(mode){
      if(this.props.activeTab == 'category'){
        payload['serviceId'] = `${this.props.serviceId}`;
      }else{
        payload['serviceProviderId'] = `${this.props.serviceProviderId}`;
      }
    }
    if(this.props.activeTab == 'category'){
      query = upsertServiceCategory(payload,mode);
    }else{
      query = upsertServiceProvider(payload,mode);
    }
    query
    .then((response) => {
        if (this.mounted) {
          this.setState({ loading: false });
          this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
        }
      })
      .catch((err) => {
        this.props.initiateNotification({
         type: MessageType.ERROR,
         message: err.description ? err.description : "Error occurred "
      });
      });
  }

  handleChange(key, value) {
    if (value && key === "region") {
      this.setState(
        {
          selectedRegion: value,
        },
        () => {
          this.updateFormErrorState();
          this.fetchAgencyWithCity(value);
        }
      );
    }else if(value && key == 'agency'){
      this.setState(
        {
          selectedAgencyNames: value
        },
        () => {
          this.updateFormErrorState();
        }
      );
    }
  }

  fetchAgencyWithCity = (value) =>{
    fetchVehicleMaster("agency",value, 0, 1000)
    .then((res)=>{
      this.setState({agencyNamesOptions:res.content},()=>{
        this.updateFormErrorState();
      })
    })
    .catch((err)=>{
      this.props.onComplete( MessageType.ERROR, err.description ? err.description : "Error occurred " );
    })
  } 

  handleSelectAllChange(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.handleChange("agency", this.state.agencyNamesOptions);
    } else {
      this.handleChange("agency", []);
    }
    this.setState(
      {
        selectAllRegions: isChecked,
      },
      () => {
        this.updateFormErrorState();
      }
    );
  }

  fetchCityNames() {
    getCityData()
        .then((response) => {
          if(response && response.content.length){
            let all_timezones = [];
            response.content.forEach((item) => {
                all_timezones.push({ name: item.name,cityId:item.id });
            });
            const upsertState = {
              regionOptions:all_timezones
            };
            this.setState(upsertState,()=>{
              this.updateFormErrorState();
            })
          }else{
            this.setState({ regionOptions: [] });
          }
        })
        .catch((err) => {
          if (err.status === 401 || err.message === "Invalid token") {
            this.props.onComplete(MessageType.ERROR, "Invalid token");
          } else if (this.mounted) {
            this.props.onComplete(
              MessageType.ERROR,
              err.description
                ? err.description
                : "Error occurred in while getting data for cities"
            );
          }
        });
  }

  getAgency = ()=>{
    return (
      <div className="col-sm-12">
        <div className="form-group">
          <label
            htmlFor="name7"
            className={this.props.activeTab == 'category' ? 'col-sm-3 form-label':'col-sm-4 '+"text-left pl-2 form-label"}
          >
            <span>Agency</span>
          </label>
          <div className={this.props.activeTab == 'category' ? 'col-sm-9':'col-sm-8'}>
            <Select
              type="region"
              value={this.state.selectedAgencyNames}
              onChange={(value) => {
                this.handleChange("agency", value);
              }}
              multi
              valueKey="name"
              labelKey="name"
              placeholder="Select Agency"
              options={this.state.agencyNamesOptions}
            />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={this.state.selectAllRegions}
                onChange={(value) => {
                  this.handleSelectAllChange(value);
                }}
              />
              <span>Select All</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="row">
        <form className="form-horizontal">
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className={this.props.activeTab == 'category' ? 'col-sm-3 form-label':'col-sm-4 '+"text-left pl-2 form-label"}
              >
                <span>{this.props.activeTab =='category'?'Service Name':'Service Provider Name'}</span>
              </label>
              <div className={this.props.activeTab == 'category' ? 'col-sm-9':'col-sm-8'}>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="serviceName"
                  placeholder={this.props.activeTab =='category'?'Service Name':'Service Provider Name'}
                  onChange={(value) => {
                    this.setState(
                      { serviceName: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  defaultValue={
                      this.state.serviceName
                  }
                  readOnly={this.props.serviceName ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className={this.props.activeTab == 'category' ? 'col-sm-3 form-label':'col-sm-4 '+"text-left pl-2 form-label"}
              >
                <span>Display Name</span>
              </label>
              <div className={this.props.activeTab == 'category' ? 'col-sm-9':'col-sm-8'}>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  id="displayName"
                  placeholder='Display Name'
                  onChange={(value) => {
                    this.setState(
                      { displayName: value.target.value.trim() },
                      () => {
                        this.updateFormErrorState();
                      }
                    );
                  }}
                  readOnly={this.props.updateSelection}
                  defaultValue={
                    this.props.updateSelection && ( 
                        this.props.updateSelection.displayName 
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label
                htmlFor="name7"
                className={this.props.activeTab == 'category' ? 'col-sm-3 form-label':'col-sm-4 '+"text-left pl-2 form-label"}
              >
                <span>City</span>
              </label>
              <div className={this.props.activeTab == 'category' ? 'col-sm-9':'col-sm-8'}>
                  <SelectFilter
                      value={this.state.selectedRegion}
                      type='city'
                      onChange={(value) => {
                        this.handleChange("region", value);
                      }}
                      valueKey="name"
                      labelKey="name"
                      clearable={true}
                      placeholder="Select City"
                      options={this.state.regionOptions}
                  />
              </div>
            </div>
          </div>
          {this.getAgency()}
          <div className="col-sm-12 form-view">
            <Button onClick={() => this.props.onClose()}>Close</Button>
            <Button
              bsStyle="primary"
              onClick={() => {
                this.setState({ loading: true });
                this.createServiceCategory();
              }}
              disabled={this.state.form.invalid || this.state.loading}
            >
              {this.state.loading ? "Saving..." : "Save changes"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
