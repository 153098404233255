import React from "react";
import { connect } from "react-redux";
import { Modal, ButtonToolbar } from "react-bootstrap";
import {
  Paginator,
  Search as SearchBar,
  DropdownWrapper,
  ToggleView,
} from "../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../shared/layout";
import { RegionSelector, ConfirmationModal } from "../../../shared/components";
import { VehicleDetailView, VehicleCreate } from "./views/";
import {
  fetchVehiclesForRegion,
  downLoadVehicleData,
  updateVehicleStatus,
} from "../../../services/vehicleService";
import {
  fetchVehicleMaster,
  fetchVehicleMetaData,
} from "../../../services/vehicleMasterService";
import {
  AuthenticationService,
  OPERATIONS,
} from "../../../services/authorization";
import { templateStrings, MessageType } from "../../../Constant";
import { routes } from "../../../routing/routes";
import { defaultMapDispatchToProps } from "../../../redux/map-to-props";
import Config from "../../../config";
import "./vehicle.css";
import { SelectFilter } from "../../../shared/widgets/selectFilter/selectFilter";
import { transformedText } from "../../../shared/utils.js";

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    let email = localStorage.getItem("userName");
    this.state = {
      searchString: "",
      email: email,
      showHeaderButton: true,
      pageNo: 0,
      type: this.props.match.params.typeId,
      totalPages: 0,
      isUserAViewer: false,
      placeHolder: "Search Vehicle",
      exportOptions: [
        {
          key: "data",
          value: "Export Data ",
        },
        {
          key: "format",
          value: "Export Format",
        },
      ],
      showStatusChangeConfirmModal: false,
      exportButtonLoader: false,
    };
    this.supportedTypes = [".xlsx"];
    this.close = this.close.bind(this);
    this.getList = this.getList.bind(this);
    this.navigateToDetail = this.navigateToDetail.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateRightsForRegion(this.state.selectedRegion);
    this.fetchMasterData("city", null);
    this.fetchVehicleData("applications");
  }

  updateRightsForRegion(region) {
    this.setState({
      canWrite: this.authService.hasRightForOperationOnRouteInRegion(
        routes.VEHICLE,
        region,
        OPERATIONS.WRITE
      ),
    });
  }

  updateStateForRegion(regionId) {
    this.updateRightsForRegion(regionId);
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          switch (masterType) {
            case "city": {
              this.setState({ cities: res.content });
              break;
            }
            case "agency": {
              this.setState({ agencies: res.content });
              break;
            }
            case "operator": {
              this.setState({ operators: res.content });
              break;
            }
            case "depot": {
              this.setState({ depots: res.content });
              break;
            }
          }
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  fetchVehicleData(masterType) {
    fetchVehicleMetaData(masterType)
      .then((res) => {
        if (this.mounted) {
          this.setState({
            appList: res,
          });
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching cities",
      });
    }
  }

  render() {
    return (
      <div>
        <PageWrapper.Header
          heading="Vehicle"
          onAdd={this.open.bind(this, null)}
          showButton={this.state.showHeaderButton && this.state.canWrite}
        >
          <DropdownWrapper
            title={
              this.state.exportButtonLoader ? (
                <>
                  <i className="fa fa-spinner fa-spin"></i>
                  <span>{""}</span>
                  <span>Exporting...</span>
                </>
              ) : (
                "Export"
              )
            }
            disabled={this.state.exportButtonLoader}
            menue={this.state.exportOptions}
            onSelect={(value, subValue) => {
              switch (value) {
                case "data": {
                  this.exportVehicle();
                  break;
                }
                case "format": {
                  this.exportVehicle("format");
                  break;
                }
              }
            }}
          ></DropdownWrapper>
          <div className="pull-right search-container">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container">
              <div className="form-group">
                <p className="filters-label">Filter By:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <RegionSelector
                      filterKey={routes.VEHICLE}
                      onRegionChange={this.handleRegionChange}
                    />

                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        console.log("value", value);
                        this.onFilterChange(value, "city");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />

                    <SelectFilter
                      value={this.state.selectedAgency}
                      onChange={(value) => {
                        this.onFilterChange(value, "agency");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Agency"
                      options={this.state.agencies}
                      disabled={!this.state.selectedCity}
                    />

                    <SelectFilter
                      value={this.state.selectedOperator}
                      onChange={(value) => {
                        this.onFilterChange(value, "operator");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Operator"
                      options={this.state.operators}
                      disabled={!this.state.selectedCity}
                    />

                    <SelectFilter
                      value={this.state.selectedDepot}
                      onChange={(value) => {
                        this.onFilterChange(value, "depot");
                      }}
                      clearable={true}
                      valueKey="name"
                      labelKey="name"
                      placeholder="Depot"
                      options={this.state.depots}
                      disabled={!this.state.selectedCity}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header columns={templateStrings.vehicles} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.map((data) => {
                    return this.displayList(data);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          <Paginator
            pageNo={this.state.pageNo}
            totalPages={this.state.totalPages}
            getList={this.getList}
          />

          {this.displayModal()}
        </PageWrapper.Body>
        {this.state.showStatusChangeConfirmModal &&
          this.displayConfirmationModal()}
      </div>
    );
  }

  handleRegionChange(region) {
    this.setState(
      {
        selectedRegion: region,
        pageNo: 0,
        selectedCity: null,
        searchString: "",
        cityId: null,
        selectedAgency: null,
        selectedOperator: null,
        agencyId: null,
        operatorId: null,
        applicationId: null,
        selectedApplication: null,
        selectedDepot: null,
        agencies: [],
        operators: [],
        depots: [],
      },
      () => {
        this.fetchVehicles(this.state.selectedRegion, null, this.state.pageNo);
        this.updateStateForRegion(this.state.selectedRegion);
      }
    );
  }

  handleSearchChange(e) {
    this.setState({ searchString: e });
    if (e && this.state.selectedRegion) {
      this.fetchVehicles(this.state.selectedRegion, e, 0);
    } else {
      this.fetchVehicles(this.state.selectedRegion, null, 0);
    }
  }

  navigateToDetail(vehicleId) {
    this.props.history.push(`/vehicles/${vehicleId}`);
  }
  /**
   * API call to get devices.
   * @param pageNo current page number
   */
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchVehicles(
        this.state.selectedRegion,
        this.state.searchString,
        pageNo
      );
    }
  }

  onFilterChange(value, masterType) {
    switch (masterType) {
      case "city": {
        this.setState(
          {
            selectedCity: value && value.name ? value.name : null,
            cityId: value && value.id ? value.id : null,
            agencies: [],
            selectedAgency: null,
            agencyId: null,
            depotId: null,
            selectedDepot: null,
            depots: [],
            operatorId: null,
            operators: [],
            selectedOperator: null,
            searchString: "",
          },
          () => {
            this.fetchVehicles(this.state.selectedRegion, null, 0);
            if (this.state.cityId) {
              this.fetchMasterData("agency", { cityId: this.state.cityId });
              this.fetchMasterData("operator", { cityId: this.state.cityId });
              this.fetchMasterData("depot", { cityId: this.state.cityId });
            }
          }
        );
        break;
      }
      case "agency": {
        this.setState(
          {
            selectedAgency: value && value.name ? value.name : null,
            agencyId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchVehicles(this.state.selectedRegion, null, 0);
          }
        );
        break;
      }
      case "operator": {
        this.setState(
          {
            selectedOperator: value && value.name ? value.name : null,
            operatorId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchVehicles(this.state.selectedRegion, null, 0);
          }
        );
        break;
      }
      case "application": {
        this.setState(
          {
            selectedApplication: value && value.name ? value.name : null,
            applicationId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchVehicles(this.state.selectedRegion, null, 0);
          }
        );
        break;
      }
      case "depot": {
        this.setState(
          {
            selectedDepot: value && value.name ? value.name : null,
            depotId: value && value.id ? value.id : null,
          },
          () => {
            this.fetchVehicles(this.state.selectedRegion, null, 0);
          }
        );
        break;
      }
    }
  }

  /**
   * Used to show device in row in List view
   * @param item  Object of item to display
   */
  displayList(item) {
    return (
      <tr key={item.vehicleNo}>
        <td>
          {this.state.searchString && (
            <div
              dangerouslySetInnerHTML={transformedText(
                item.vehicleNo,
                this.state.searchString
              )}
            />
          )}
          {!this.state.searchString && item.vehicleNo}
        </td>
        <td>{item.vehicleCode}</td>
        <td>
          {this.state.searchString && (
            <div
              dangerouslySetInnerHTML={transformedText(
                item.deviceId,
                this.state.searchString
              )}
            />
          )}
          {!this.state.searchString && item.deviceId}
        </td>
        <td>{item.cityMaster ? item.cityMaster.name : ""}</td>
        <td>{item.agency ? item.agency.name : ""}</td>
        <td>
          <span className="protocol">
            {item.operatorMaster ? item.operatorMaster.name : ""}
          </span>
          <div className="protocol-list">
            {item.operatorMaster ? item.operatorMaster.name : ""}
          </div>
        </td>
        <td>
          <span className="protocol">{item.depot ? item.depot.name : ""}</span>
          <div className="protocol-list">
            {item.depot ? item.depot.name : ""}
          </div>
        </td>
        <td>{item.vehicleType}</td>
        {/* <td>{item.regionId}</td> */}
        <td>
          <ToggleView
            status={item.status}
            masterData={item}
            onStatusChange={this.handleToggleChange.bind(this)}
          ></ToggleView>
        </td>
        <td className="action-column">
          {this.state.canWrite && (
            <span className="table-icons edit-icon">
              <a onClick={this.open.bind(this, item)}>
                <span className="edit-view-icon" />
                <span className="protocol-list">edit</span>
              </a>
            </span>
          )}
          <span className="table-icons view-icon">
            <a
              onClick={() =>
                this.setState({
                  showInfo: true,
                  updateSelection: item,
                })
              }
            >
              <i className="fa fa-eye protocol" aria-hidden="true" />
              <span className="protocol-list">view details</span>
            </a>
          </span>
          <span className="table-icons view-icon">
            <a onClick={() => this.navigateToDetail(item.vehicleNo)}>
              <i className="fa fa-history protocol" aria-hidden="true" />
              <span className="protocol-list">view logs</span>
            </a>
          </span>
        </td>
      </tr>
    );
  }

  handleToggleChange(status, updateData) {
    this.setState({
      showStatusChangeConfirmModal: true,
      updateSelection: updateData,
    });
  }

  displayConfirmationModal() {
    return (
      <ConfirmationModal
        title="Confirmation"
        message={`Are you sure you want to change vehicle status?`}
        showModal={this.state.showStatusChangeConfirmModal}
        messageCallBack={(type, msg) => {
          if (type === MessageType.SUCCESS) {
            this.updateStatus();
          }
          this.setState({
            showStatusChangeConfirmModal: false,
            updateSelection: null,
          });
        }}
      />
    );
  }

  updateStatus() {
    updateVehicleStatus(
      this.state.updateSelection.vehicleNo,
      !this.state.updateSelection.status
    )
      .then((res) => {
        console.log(res);
        this.fetchVehicles(this.state.selectedRegion, null, this.state.pageNo);
        this.props.initiateNotification({
          type: "success",
          message: "Vehicle status updated successfully.",
        });
      })
      .catch((err) => {
        this.props.initiateNotification({
          type: "error",
          message: err.description
            ? err.description
            : "Error while fetching vehicles",
        });
      });
  }

  /**
   * fetches vehicles
   */
  fetchVehicles(region, key, pageNo) {
    this.props.updateLoader(true);
    let masterType = {
      cityId: this.state.cityId,
      agencyId: this.state.agencyId,
      operatorId: this.state.operatorId,
      depotId: this.state.depotId,
      applicationId: this.state.applicationId,
    };
    fetchVehiclesForRegion(region, key, masterType, pageNo)
      .then((res) => {
        if (this.mounted)
          this.setState({
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
          });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.props.updateLoader(false);
          this.setState({ content: [], totalPages: 0, pageNo: 0 });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching vehicles",
          });
        }
      });
  }

  /**
   * Call when Add/Update modal Open.
   */
  open(data) {
    this.setState({
      updateSelection: data,
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal() {
    return (
      <Modal
        dialogClassName="custom-vehicle-modal"
        show={this.state.showModal || this.state.showInfo}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal
              ? this.state.updateSelection
                ? "Update Vehicle"
                : "Add new Vehicle"
              : this.state.showInfo
              ? "Vehicle Details"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <VehicleCreate
              selectedRegion={this.state.selectedRegion}
              updateSelection={this.state.updateSelection}
              showInfo={this.state.showInfo}
              email={this.state.email}
              onComplete={(e, msg) => {
                if (e == MessageType.SUCCESS) {
                  this.setState({ searchString: "" });
                  this.fetchVehicles(this.state.selectedRegion, null, 0);
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                } else if (e === MessageType.ERROR && msg === "Invalid token") {
                  this.props.setTokenExpired(true);
                } else {
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                }

                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
          {this.state.showInfo && (
            <VehicleDetailView data={this.state.updateSelection} />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
      showInfo: false,
      updateSelection: null,
      value: [],
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  exportVehicle(exportKey) {
    let timeoutId;

    const hideLoader = () => {
      timeoutId = setTimeout(() => {
        this.setState({ exportButtonLoader: false });
      }, 60000);
    };

    hideLoader();
    downLoadVehicleData(
      exportKey,
      this.state.selectedRegion,
      this.props.timeSlab,
      this.props.selectedProtocol,
      this.props.selectedSort,
      this.props.selectedStatus,
      this.props.selectedFilter,
      this.state.cityId,
      this.state.agencyId,
      this.state.operatorId,
      this.state.depotId
    )
      .then(this.setState({ exportButtonLoader: true }))
      .then((res) => {
        clearTimeout(hideLoader);
        console.log(res);
        this.setState({ exportButtonLoader: false });
      })
      .catch((err) => {
        clearTimeout(hideLoader);
        this.setState({ exportButtonLoader: true });
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
          this.props.setTokenExpired(true);
        }
      });
  }
}

export default connect(null, defaultMapDispatchToProps)(VehicleList);
