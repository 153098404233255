import {META_BASE_URL, META_CALL} from "../Constant";
import {fetchWrapperWithoutToken} from "./APIutils";
export function fetchCityMetaData(city) {
    let URL = `${META_BASE_URL}${META_CALL}`;
    if (city) {
        URL = URL + `?city=${city}`
    }
    return fetchWrapperWithoutToken(URL, {
        method: 'get'
    });
}