/**
 * Component for Sidebar
 */

import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { MultilevelLink, ConfirmationModal } from "../../../shared/components";
import { toggleSideBar } from "../../../redux/actions/sideToggleActions";
import { logoutUser } from "../../../redux/actions/authActions";
import { AuthenticationService } from "../../../services/authorization";
import { MessageType } from "../../../Constant";
import { routes, extraRoutes } from "../../../routing/routes";
import { routeIcon } from "../../../routing/route-iconMapping";
import "./index.css";
import { updateUserLoginState } from "../../../services/authorization/Authorization";
import Cookies from 'universal-cookie';
import { onLogoutUser } from '../../../services/login/loginService';
import { Scrollbars } from 'react-custom-scrollbars-2';

class Sidebar extends Component {
    cookies = new Cookies();

    constructor(props) {
        super(props);
        this.authService = AuthenticationService.getInstance();
        this.state = {
            redirectToLogin: false,
            viewPermission: this.authService.viewRights
        }

    }

    render() {
        const { redirectToLogin, viewPermission } = this.state;
        if (redirectToLogin) {
            return <Redirect to={extraRoutes.LOGIN} />;
        }
        return (
            <div className="navbar-default sidebar" role="navigation">
                <div className="sidebar-nav navbar-collapse collapse">
                <Scrollbars id="scrollbar-lib" style={{ height: 580,width:this.props.toggleShow?50:200}} autoHide={true} autoHideTimeout={0}>
                    <ul className="nav in" id="side-menu">
                        {Object.keys(routes).map((key) => {
                            if (viewPermission[routes[key]])
                                if (!routeIcon[routes[key]].children) {
                                    return <li key={routes[key]}>
                                        <NavLink exact={routes[key] === routes.HOME} to={routes[key]}
                                            activeClassName="active-link">
                                            <div className="icon-container">
                                                <span className={`sidebar-icon ${routeIcon[routes[key]].icon}`} />
                                            </div>
                                            <span>{routeIcon[routes[key]].title}</span>
                                        </NavLink>
                                    </li>;
                                }
                                else {
                                    return <MultilevelLink key={routes[key]} {...this.props} root={{
                                        path: routes[key],
                                        title: routeIcon[routes[key]].title,
                                        icon: routeIcon[routes[key]].icon
                                    }}
                                        childs={routeIcon[routes[key]].children}
                                        toggleSideBarState={this.props.toggleSideBarState}
                                        isBeta={routeIcon[routes[key]].beta}
                                        SideBarDontShow ={this.props.SideBarDontShow}
                                    />
                                }
                            else return null
                        })}
                        <li>
                            <a onClick={() => {
                                this.showConfirmationModal();
                            }}>
                                <div className="icon-container">
                                    <span className="sidebar-icon logout-icon" /></div>
                                <span>Logout</span>
                            </a>

                        </li>
                    </ul>
                    </Scrollbars>
                </div>
                <ConfirmationModal title="Logout Confirmation" message="Are you sure you want to logout?"
                    showModal={this.state.showModal} messageCallBack={(type, msg) => {
                        if (type == MessageType.SUCCESS) {
                            this.logoutUser()
                        }
                        this.setState({
                            showModal: false
                        })
                    }} />
            </div>
        );
    }


    logoutUser() {
        onLogoutUser();
         this.updateLoginState();

    }


    showConfirmationModal() {
        this.setState({
            showModal: true
        })
    }


    updateLoginState() {
        updateUserLoginState(null, null).then(() => {
            this.setState({ redirectToLogin: true });
            AuthenticationService.destroyInstance();
            this.props.logout();
        }).catch((err) => {
            console.log('error', err)
        }
        );
    }

}


const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logoutUser(null))
        },
        toggleSideBarState: () => {
            var w = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            if (w < 768) {
                dispatch(toggleSideBar(true))
            }

        }
    }
};

export default connect(null, mapDispatchToProps)(Sidebar);


