import {actions} from './action-list';
export function toggleSideBar(value) {
    return {
        type: actions.TOGGLE_SIDEBAR,
        value: value
    }
}



