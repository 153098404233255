import React from "react";
import { Route, Switch } from "react-router-dom";
import PanicReport from "./Panic-report/PanicReport";
import PanicAlertDetail from "./Panic-report/PanicAlertDetail";
export class AlertsWrapper extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.url}/panic-alerts`} component={PanicReport} />
        <Route exact path={`${match.url}/panic-alerts/:vehicleId`} component={PanicAlertDetail} />
      </Switch>
    );
  }
}
