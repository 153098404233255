import { actions } from '../../actions/action-list';
import initialState from "./initialState";

function Alert(state, action) {
    if (typeof state === 'undefined') {
        state = initialState;
      }
    if(action.type === actions.SET_SELECTED_PANIC_ALERT_ROW){
        return Object.assign({}, state, {
            selectedAlertRow: action.value
        });
    }
    return state;
}
export default Alert;