import React from "react";
import classNames from "classnames";
import moment from "moment";
import {fetchTimeValue, transformedText, timeChange,EpochtoEpochTZ} from "../../../../shared/utils.js";
import {dateFormat, DeviceStatusFilterValues} from "../../../../Constant";
import DeviceConfigure from "../../deviceConfigurationModule/views/configuration/configureDevice";

export const DeviceListItem = ({item, timeInterval, highLightString, timeParse, onViewClick, offset, onDeleteClick, showDelete, onNotification}) => (
        <tr key={item.deviceId} className="gradeA odd" role="row">
            <td className="sorting_1"><span className="protocol">
                    {highLightString && <div
                        dangerouslySetInnerHTML={transformedText(item.deviceId, highLightString)}/>}
                {!highLightString && item.deviceId}</span>
                <div
                    className="protocol-list"> {item.deviceId}</div>
            </td>

            <td>{highLightString && <div
                dangerouslySetInnerHTML={transformedText(item.vehicleNo, highLightString)}/>}
                {!highLightString && item.vehicleNo}</td>


            <td ><span className={classNames({
                'red-class': ( item.dataStatus == DeviceStatusFilterValues.RED.key),
                'green-class': ( item.dataStatus == DeviceStatusFilterValues.GREEN.key),
                'yellow-class': ( item.dataStatus == DeviceStatusFilterValues.YELLOW.key)
            })}><a>
                    <i className="fa fa-circle" aria-hidden="true">
                            </i></a>
                    </span>
            </td>
            {/*<td><span className="list-item">{item.protocol}</span></td>*/}
            <td>
                    <span
                        className="protocol">{fetchTimeValue(timeParse, parseInt(item.delay), null)}</span>
            </td>
            <td>
                    <span
                        className="protocol">{parseInt(item.lastPacketArriveAt) ? timeChange(parseInt(item.lastPacketArriveAt), offset) : 'NA'}</span>
                        <div
                    className="protocol-list"> {parseInt(item.lastPacketArriveAt) ? moment(new Date(EpochtoEpochTZ(parseInt(item.lastPacketArriveAt),localStorage.getItem('timeZone')))).format(dateFormat) : 'NA'}</div>
            </td>
            <td className="action-column">
                {/*<DeviceConfigure deviceId={item.deviceId} setNotification={onNotification} number={item.simCard}/>*/}
                {/*<ConfigurationComponent deviceId={item.deviceId} setNotification={onNotification} number={item.simCard} />*/}
                <span className="table-icons view-icon">
                        <a onClick={() =>onViewClick()}>
                            <span aria-hidden="true" className=""><i className="fa fa-eye" aria-hidden="true"/></span>

                        </a>
                    </span>
                { showDelete && <span className="table-icons view-icon">
                        <a onClick={() =>onDeleteClick()}>
                            <span aria-hidden="true" className="delete-icon"></span>
                        </a>

                    </span>}
            </td>
        </tr>
    )
    ;