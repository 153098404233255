import React from "react";
import { connect } from "react-redux";
import { templateStrings, dateFormat } from "../../../../../Constant";
import * as moment from "moment";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { fetchBSDLogs } from "../../../../../services/BSDService";
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import uuid from "uuid";

class BSDLoglist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceId: this.props.match.params.deviceId,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { selectedCity } = this.props.location.state;
      this.setState({ selectedCity });
    }
    this.mounted = true;
    this.fetchBSDLogs();
  }

  render() {
    const templatestrings = templateStrings.BSDLogs;
    return (
      <div>
        <div
          className="pull-left"
          onClick={() => {
            this.back();
          }}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
        <PageWrapper.Header
          heading={this.state.deviceId ? this.state.deviceId : "Logs"}
          hideS={true}
        ></PageWrapper.Header>
        <PageWrapper.Body>
          <TableWrapper>
            <TableWrapper.Header columns={templatestrings} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.length > 0 &&
                  this.state.content.map((data) => {
                    return this.displayList(data, templatestrings);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length === 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
        </PageWrapper.Body>
      </div>
    );
  }

  back() {
    this.props.history.push({
      pathname: `/BSD/detail`,
      state: { selectedCity: this.state.selectedCity },
    });
  }

  /**
   * fetches  data
   */
  fetchBSDLogs() {
    if (this.mounted) this.props.updateLoader(true);

    fetchBSDLogs(this.state.deviceId)
      .then((res) => {
        if (this.mounted) {
          this.setState({
            content: res,
          });
          this.props.updateLoader(false);
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.props.updateLoader(false);
      this.setState({ content: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching device log data",
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /**
   * Used to show  data in row in List view
   * @param item  Object of item to display
   */
  displayList(item, templateStrings) {
    return (
      <tr key={uuid.v4()}>
        {Object.keys(templateStrings).map((column, index) => {
          return (
            <td
              className="master-table"
              data-toggle="tooltip"
              title={
                column !== "action" && column !== "modifiedDate"
                  ? item[column]
                  : ""
              }
              key={index}
            >
              {column == "modifiedDate"
                ? moment(
                    new Date(
                      EpochtoEpochTZ(
                        parseInt(item.modifiedDate),
                        localStorage.getItem("timeZone")
                      )
                    )
                  ).format(dateFormat)
                : item[column]
                ? item[column]
                : ""}
            </td>
          );
        })}
      </tr>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(BSDLoglist);
