import React from "react";
import Select from "react-select";
import './selectFilter.css';

export class SelectFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newOptions: []
        }
    }

    componentDidMount() {
        this.updateOptions(this.props.options);
    }

    updateOptions(options) {
        let newOptions = [];
        if (this.props.showAll && options) {
            let allOption = {};
            allOption[this.props.valueKey] = 'all';
            newOptions = [allOption, ...options]
        } else {
            newOptions = options;
        }
        this.setState({newOptions: newOptions});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.options != this.props.options) {
            this.updateOptions(nextProps.options);
        }
    }

    render() {
        const {newOptions}=this.state;
        const {options, ...props}=this.props;
        return <div className={this.props.type?"select-dropdown-country":"select-dropdown"}><Select {...props}
                                                        clearable={props.clearable?props.clearable:false}
                                                        options={newOptions}
        /></div>
    }
}