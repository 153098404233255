import React, { useEffect, useState } from "react";
import "./CityView.css";
import {
  editDisplayName,
  deleteCategory,
} from "../../../../../services/vehicleMasterService";
import { MessageType } from "../../../../../Constant";
import { Modal } from "react-bootstrap";
import { ServiceCategoryCreate } from "../createView/createServiceCategory";


const CityView = ({ data }) => {
  const [tableData, setTableData] = useState(data.tableData || []);
  const [editRowId, setEditRowId] = useState(null);
  const [showModal,setShowModal] = useState(false);

  useEffect(()=>{
    setTableData(data.tableData)
  },[JSON.stringify(data.tableData)!==JSON.stringify(tableData)])

  const saveEdit = (id, editedValue,cityId) => {
    const { serviceId, serviceName,serviceProviderId } = data.info;
    const payload = {
      displayName: editedValue,
      agencyId: `${id}`,
      cityId :cityId
    };
    if(data.activeTab === 'category'){
      payload['serviceId'] = `${serviceId}`;
      payload['serviceName'] = serviceName;
    }else{
      payload['serviceProviderId'] = `${serviceProviderId}`;
    }
    const updatedData = tableData.map((row) =>
      row.agencyName === id ? { ...row, displayValue: editedValue } : row
    );
    setTableData(updatedData);
    setEditRowId(null);
    editDisplayName(payload,data.activeTab)
      .then((response) => {
        data.initiateNotification({
          type: MessageType.SUCCESS,
          message: "Updated Successfully!",
        });
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        data.onComplete({
          type: MessageType.ERROR,
          message: "Error Occured while updating",
        });
      });
  };

  const deleteRow = (id, isMultiDelete,cityId) => {
    const updatedData = tableData.filter((row) => row.agencyName !== id);
    setTableData(updatedData);
    let agencyIds;
    if (isMultiDelete) {
      agencyIds = id.map((e) => `${e.agencyId}`);
    } else {
      agencyIds = [`${id}`];
    }
    const payload = {};
    if(data.activeTab === 'category'){
      payload['serviceId'] = `${data.info.serviceId}`;
      payload['agencyIds']= agencyIds;
      payload['cityId'] = cityId
    }else{
      payload['serviceProviderId']= `${data.info.serviceProviderId}`;
      payload['agencyIds']= agencyIds;
      payload['cityId'] = cityId

    }
    deleteCategory(payload, isMultiDelete, data.activeTab)
      .then(() => {
        if(isMultiDelete){
          data.onComplete(MessageType.SUCCESS, "Deleted Successfully!");
        }else{
          data.initiateNotification({
            type: MessageType.SUCCESS,
            message: "Deleted Successfully!",
          });
          closeModal();
        }
      })
      .catch(() => {
        data.onComplete({
          type: MessageType.ERROR,
          message: "Error Occured while deleting",
        });
      });
  };

  const addAgency = () => {
    setShowModal((prev)=>!prev);
  };

  const closeModal=()=>{
    setShowModal((prev)=>false);
    data.fetchServiceReportOnUpsertOfAgency();
  }

  const displayModal = () => {
    const selectedRegion = {
      cityId:data.info.cityId,
      name:data.info.cityName
    }
    const masterDataCreationProps = {
      onComplete: (e, msg) => { 
        if(e != 'ERROR'){
          data.initiateNotification({
            type: MessageType.SUCCESS,
            message: 'Agency Added Successfully!'
          });
        }else{
          data.initiateNotification({
            type: MessageType.ERROR,
            message: msg
          });
        }
        closeModal();
       },
      onClose: closeModal,
      initiateNotification: data.initiateNotification,
      activeTab: data.activeTab,
      serviceId:data.info.serviceId,
      serviceName:data.info.serviceName || data.info.serviceProviderName,
      serviceProviderId:data.info.serviceProviderId,
      selectedRegion
    }
    const title = data.activeTab == 'category' ? 'Update Service Category' : 'Update Service Provider';
    return (
      <Modal
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ServiceCategoryCreate {...masterDataCreationProps} />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      <div className="add-agency-row">
        <div style={{ fontWeight: "bold" }}>Agencies</div>
        {tableData.length > 0 && <div>
          <button onClick={() => deleteRow(tableData, true,tableData[0].cityId)}>Delete All</button>
        </div>}
        <div>
          <button onClick={() => addAgency()}>Add Agency</button>
        </div>
      </div>
      <div className="scroll-container">
      {tableData.length>0 && <table className="editableTable">
        <thead>
          <tr>
            <th>City</th>
            <th>Agency</th>
            <th>Display Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) =>
            editRowId === row.agencyId ? (
              <EditRow
                key={row.agencyId}
                row={row}
                saveEdit={saveEdit}
                cancelEdit={() => setEditRowId(null)}
                tableData={tableData}
              />
            ) : (
              <DisplayRow
                key={row.agencyId}
                row={row}
                onEdit={() => setEditRowId(row.agencyId)}
                onDelete={() => deleteRow(row.agencyId, false,row.cityId)}
                tableData={tableData}
              />
            )
          )}
        </tbody>
      </table>
      }
      </div>
      {displayModal()}
    </div>
  );
};

const EditRow = ({ row, saveEdit, cancelEdit, tableData }) => {
  const [editedValue, setEditedValue] = useState(row.displayName);

  return (
    <tr>
      <td>{row.cityName}</td>
      <td>{row.agencyName}</td>
      <td>
        <input
          type="text"
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
        />
      </td>
      <td>
        <button onClick={() => saveEdit(row.agencyId, editedValue,row.cityId)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
          </svg>
        </button>
        {tableData && tableData.length > 1 && (
          <button onClick={cancelEdit}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="14"
              viewBox="0 0 448 512"
            >
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
            </svg>
          </button>
        )}
      </td>
    </tr>
  );
};

const DisplayRow = ({ row, onEdit, onDelete, tableData }) => {
  return (
    <tr>
      <td>{row.cityName}</td>
      <td>{row.agencyName}</td>
      <td>{row.displayName}</td>
      <td>
        <button onClick={onEdit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
          </svg>
        </button>
        {tableData && tableData.length > 1 && (
          <button onClick={onDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="14"
              viewBox="0 0 448 512"
            >
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
            </svg>
          </button>
        )}
      </td>
    </tr>
  );
};

export default CityView;
