const validateOperator = (formState) => {
  let form = {};
  if (
    !formState.operator ||
    !formState.phone ||
    !formState.type ||
    checkIdDetails(formState) ||
    (formState.selectedCity && formState.selectedCity.length === 0)
  ) {
    form._error = "Required field missing";
    form.invalid = true;
  }

  if (formState.idType && (!formState.idNumber || !formState.idType)) {
    form._error = "Required field missing";
    form.invalid = true;
    form.idError = true;
  } else {
    form.idError = false;
  }

  if (
    formState.accountHolderName &&
    (!formState.accountNumber || !formState.accountType || !formState.ifscCode)
  ) {
    form._error = "Required field missing";
    form.invalid = true;
    form.bankError = true;
  } else {
    form.bankError = false;
  }

  if (formState.operator && !formState.operator.match("^[a-zA-Z' ]*$")) {
    form._error = "Invalid Name";
    form.invalid = true;
    form.operatorNameError = true;
  }
  if (
    formState.email &&
    !formState.email.match("^[\\w-_\\.+]*[\\w-_\\.]\\@([\\w]+\\.)+[\\w]+[\\w]$")
  ) {
    form._error = "Invalid email";
    form.invalid = true;
    form.emailError = true;
  }
  if (
    formState.gstCertificate &&
    !formState.gstCertificate.match(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    )
  ) {
    form._error = "Invalid Gst Number";
    form.invalid = true;
    form.gstError = true;
  }
  if (
    formState.ifscCode &&
    !formState.ifscCode.match("^[A-Z]{4}0[A-Z0-9]{6}$")
  ) {
    form._error = "Invalid Ifsc code";
    form.invalid = true;
    form.ifscError = true;
  }
  if (
    formState.panCard &&
    !formState.panCard.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
  ) {
    form._error = "Invalid pan card number";
    form.invalid = true;
    form.panCardError = true;
  }
  if (
    !formState.editMode && 
    formState.idType &&
    formState.idNumber &&
    !checkValidIdNumber(formState)
  ) {
    form._error = "Invalid Id number";
    form.invalid = true;
    form.idNumberError = true;
  }

  return form;
};

function checkValidIdNumber(formState) {
  switch (formState.idType.value) {
    case 'Aadhar': return formState.idNumber.match("^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$") && formState.idNumber.length === 12;
    case 'DriverLicense': return formState.idNumber.match("^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$") || formState.idNumber.match("^[0-9]{2}/[0-9]{3,4}/(19|20)[0-9]{2}$");
    case 'VoterId': return formState.idNumber.match("^[A-Z]{3}[0-9]{7}$") || formState.idNumber.match("[A-Z]{5}[0-9]{4}[A-Z]{1}");    
    default: return true

  }
}

function verhoeffCheck(aadhar) {
  if (!aadhar) {
    return null;
  }
  aadhar = aadhar.toString();
  const d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
  ];
  const p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
  ];
  const j = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];
  let c = 0;
  aadhar
    .replace(/\D+/g, "")
    .split("")
    .reverse()
    .join("")
    .replace(/[\d]/g, function (u, i, o) {
      c = d[c][p[i & 7][parseInt(u, 10)]];
    });
  return c === 0;
}

function maskedAadhar(aadhar) {
  if (!aadhar) {
    return null;
  }
  aadhar = aadhar.toString();
  let regexp = /^[X]{8}[0-9]{4}$/;
  if (regexp.test(aadhar)) {
    return true;
  } else {
    return false;
  }
}
function checkIdDetails(formState) {
  if(formState.idType && formState.idType.value === 'Aadhar' && formState.editMode) {
    return false;
  }
  if (formState.idType && formState.idNumber && formState.proofId) {
    return false;
  } else {
    return true;
  }
}

export default validateOperator;
