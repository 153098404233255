import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import VehicleList from './vehicleList';
import VehicleLogList from './views/vehicleLog/vehicleLog';
import DownloadFileList from "./views/downloadFile/downloadFile";
export class VehicleWrapper extends React.Component {
    render() {
        const {match}=this.props;
        console.log("match url",match)
        return <Switch>
            <Route exact path={`${match.url}/detail`} component={VehicleList}/>
            <Route exact path={`${match.url}/:vehicleId`} component={VehicleLogList}/>
            <Route exact path={`${match.url}/file/Import`} component={DownloadFileList}/>
            {/* <Redirect from="/" to={`${match.url}/gpsQuality`}/> */}
        </Switch>

    }
}