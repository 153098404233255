import React from "react";
import { DepotVehiclesDetail } from "../../utils";
import { DropdownWrapper,Search as SearchBar,frontEndPaginator } from "../../../../../shared/widgets";
import * as moment from "moment";
import { PageWrapper, TableWrapper } from "../../../../../shared/layout";
import { connect } from "react-redux";
import {EpochtoEpochTZ} from "../../../../../shared/utils";
const paginatorInfoInVehicles = { paginator: "InVehicles" };
const FrontPaginatorInVehicles = frontEndPaginator(paginatorInfoInVehicles);
const paginatorInfoOutVehicles = { paginator: "OutVehicles" };
const FrontPaginatorOutVehicles = frontEndPaginator(paginatorInfoOutVehicles);
const paginatorInfoUknownVehicles = { paginator: "UknownVehicles" };
const FrontPaginatorUknownVehicles = frontEndPaginator(paginatorInfoUknownVehicles);
class DepotListItem extends React.Component {
  constructor(props) {
    super(props);
    this.templateStrings = DepotVehiclesDetail;
    this.state = {
      exportInOptions: [
        {
          key: "default",
          value: "Export In Vehicle",
        },
      ],
      exportOutOptions: [
        {
          key: "default",
          value: "Export Out Vehicle",
        },
      ],
      exportUknownOptions: [
        {
          key: "default",
          value: "Export Unknown Vehicle",
        },
      ],
      inVehicles:this.props.content.state.data.inVehicles,
      outVehicles:this.props.content.state.data.outVehicles,
      unknownVehicles:this.props.content.state.data.unknownVehicles,
      filteredinVehicles:this.props.content.state.data.inVehicles,
      filteredoutVehicles:this.props.content.state.data.outVehicles,
      filteredunknownVehicles:this.props.content.state.data.unknownVehicles,
      placeHolder: "Search Vehicle",
      searchStringInVehicles: "",
      searchStringOutVehicles: "",
      searchStringUknownVehicles: "",
    };    
    this.handleSearchChangeInVehicles = this.handleSearchChangeInVehicles.bind(this);
    this.handleSearchChangeOutVehicles = this.handleSearchChangeOutVehicles.bind(this);
    this.handleSearchChangeUknownVehicles = this.handleSearchChangeUknownVehicles.bind(this);

  }

  removeGMT(date) {
    var len = date.length - 9;
    return date.slice(0, len);
  }
  render() {
    return (
      <div>
        <div>
        <PageWrapper.Header heading="IN VEHICLES" hideS={true}>
          <div className="pull-right">
            <DropdownWrapper
              title="Export In Vehicle"
              menue={this.state.exportInOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    this.exportVehicleInData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div>    
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChangeInVehicles}
              value={this.state.searchStringInVehicles}
            />
          </div>      
        </PageWrapper.Header>
          <TableWrapper id="report-list">
            <TableWrapper.Header
              columns={this.templateStrings}
              type={"depot"}
            />
            <tbody>
              {this.props.paginatedDataInVehicles &&
                this.props.paginatedDataInVehicles.map((data, i) => {
                  return (
                    <tr key={i} className="gradeA odd" role="row">
                      <td className="list-item">{data.vehicleNo}</td>
                      <td className="list-item">{data.device_id}</td>
                      <td className="list-item">{data.agency}</td>
                      <td className="list-item">{data.operator}</td>
                      <td className="list-item">
                        {this.removeGMT(
                          moment(EpochtoEpochTZ(data.timestamp,localStorage.getItem('timeZone'))).toLocaleString()
                        )}
                      </td>
                    </tr>
                  );
                })}
              {(!this.state.inVehicles ||
                (this.state.inVehicles &&
                  this.state.inVehicles.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="2">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.inVehicles && (
            <FrontPaginatorInVehicles data={this.state.inVehicles} />
          )}
        </div>

        <div>
        <PageWrapper.Header heading="OUT VEHICLES" hideS={true}>
          <div className="pull-right">
            <DropdownWrapper
              title="Export Out Vehicle"
              menue={this.state.exportOutOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    this.exportVehicleOutData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div> 
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChangeOutVehicles}
              value={this.state.searchStringOutVehicles}
            />
          </div>              
        </PageWrapper.Header>
          <TableWrapper id="report-list">
            <TableWrapper.Header
              columns={this.templateStrings}
              type={"depot"}
            />
            <tbody>
              {this.props.paginatedDataOutVehicles &&
                this.props.paginatedDataOutVehicles.map((data, i) => {
                  return (
                    <tr key={i} className="gradeA odd" role="row">
                      <td className="list-item">{data.vehicleNo}</td>
                      <td className="list-item">{data.device_id}</td>
                      <td className="list-item">{data.agency}</td>
                      <td className="list-item">{data.operator}</td>
                      <td className="list-item">
                        {this.removeGMT(
                          moment(EpochtoEpochTZ(data.timestamp,localStorage.getItem('timeZone'))).toLocaleString()
                        )}
                      </td>
                      {/* <td className="list-item">
                      {moment(data.created_at).format("DD MMM YYYY")}
                    </td> */}
                    </tr>
                  );
                })}
              {(!this.state.outVehicles ||
                (this.state.outVehicles &&
                  this.state.outVehicles.length == 0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="2">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.outVehicles && (
            <FrontPaginatorOutVehicles data={this.state.outVehicles} />
          )}
        </div>

        <div>
        <PageWrapper.Header heading="UNKNOWN VEHICLES" hideS={true}>
          <div className="pull-right">
            <DropdownWrapper
              title="Export Unknown"
              menue={this.state.exportUknownOptions}
              onSelect={(value, subValue) => {
                switch (value) {
                  case "default": {
                    this.exportVehicleUnknownData();
                    break;
                  }
                }
              }}
            ></DropdownWrapper>
          </div>  
          <div className="pull-right search-container custom-search">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChangeUknownVehicles}
              value={this.state.searchStringUknownVehicles}
            />
          </div>              
        </PageWrapper.Header>
          <TableWrapper id="report-list">
            <TableWrapper.Header
              columns={this.templateStrings}
              type={"depot"}
            />
            <tbody>
              {this.props.paginatedDataUknownVehicles &&
                this.props.paginatedDataUknownVehicles.map((data, i) => {
                  return (
                    <tr key={i} className="gradeA odd" role="row">
                      <td className="list-item">{data.vehicleNo}</td>
                      <td className="list-item">{data.device_id}</td>
                      <td className="list-item">{data.agency}</td>
                      <td className="list-item">{data.operator}</td>
                      <td className="list-item">
                        {this.removeGMT(
                          moment(EpochtoEpochTZ(data.timestamp,localStorage.getItem('timeZone'))).toLocaleString()
                        )}
                      </td>
                      {/* <td className="list-item">
                      {moment(data.created_at).format("DD MMM YYYY")}
                    </td> */}
                    </tr>
                  );
                })}
              {(!this.state.unknownVehicles ||
                (this.state.unknownVehicles &&
                  this.state.unknownVehicles.length ==
                    0)) && (
                <tr className="gradeA odd noDataStyle" role="row">
                  <td className="sorting_1" colSpan="2">
                    No Data Found !
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          {this.state.unknownVehicles && (
            <FrontPaginatorUknownVehicles data={this.state.unknownVehicles} />
          )}
        </div>
      </div>
    );
  }

  exportVehicleInData() {
    var csvContent = "";
    csvContent = `Vehicle,DeviceId,Agency,Operator,Event Time` + "\n";
    if (this.state.inVehicles) {
      this.state.inVehicles.map((object) => {
        csvContent += `${object.vehicleNo},${object.device_id},${
          object.agency
        },${object.operator},${this.removeGMT(
          moment(EpochtoEpochTZ(object.timestamp,localStorage.getItem('timeZone'))).toLocaleString()
        )}\n`;
      });
    }
      var csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var csvURL = window.URL.createObjectURL(csvData);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        "InVehicle" + new Date().getTime() + ".csv"
      );
      tempLink.click();
  }

  exportVehicleOutData() {
    var csvContent = "";
    csvContent = `Vehicle,DeviceId,Agency,Operator,Event Time` + "\n";
    if (this.state.outVehicles) {
      this.state.outVehicles.map((object) => {
        csvContent += `${object.vehicleNo},${object.device_id},${
          object.agency
        },${object.operator},${this.removeGMT(
          moment(EpochtoEpochTZ(object.timestamp,localStorage.getItem('timeZone'))).toLocaleString()
        )}\n`;
      });
    }
      var csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var csvURL = window.URL.createObjectURL(csvData);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        "outVehicles" + new Date().getTime() + ".csv"
      );
      tempLink.click();
  }

  exportVehicleUnknownData() {
    var csvContent = "";
    csvContent = `Vehicle,DeviceId,Agency,Operator,Event Time` + "\n";
    if (this.state.unknownVehicles) {
      this.state.unknownVehicles.map((object) => {
        csvContent += `${object.vehicleNo},${object.device_id},${
          object.agency
        },${object.operator},${this.removeGMT(
          moment(EpochtoEpochTZ(object.this.timestamp)).toLocaleString()
        )}\n`;
      });
    }
      var csvData = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var csvURL = window.URL.createObjectURL(csvData);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute(
        "download",
        "unknownVehicles" + new Date().getTime() + ".csv"
      );
      tempLink.click();
  }
  handleSearchChangeInVehicles(key) {
    key=key.replace(/\s/g, "")
    this.setState({
      searchStringInVehicles: key,
    });
    this.setState( 
      (prevState) => {
        return {
          inVehicles: key
            ? prevState.filteredinVehicles.filter((item) => {
                return item.vehicleNo.toLowerCase().includes(key.toLowerCase());
              })
            : prevState.filteredinVehicles,
        };
      }
    );
  }

  handleSearchChangeOutVehicles(key) {
    key=key.replace(/\s/g, "")
    this.setState({
      searchStringOutVehicles: key,
    });
    this.setState( 
      (prevState) => {
        return {
          outVehicles: key
            ? prevState.filteredoutVehicles.filter((item) => {
                return item.vehicleNo.toLowerCase().includes(key.toLowerCase());
              })
            : prevState.filteredoutVehicles,
        };
      }
    );
  }

  handleSearchChangeUknownVehicles(key) {
    key=key.replace(/\s/g, "")
    this.setState({
      searchStringUknownVehicles: key,
    });
    this.setState( 
      (prevState) => {
        return {
          unknownVehicles: key
            ? prevState.filteredunknownVehicles.filter((item) => {
                return item.vehicleNo.toLowerCase().includes(key.toLowerCase());
              })
            : prevState.filteredunknownVehicles,
        };
      }
    );
  }
}
function mapStateToProps(state) {
  return {
    paginatedDataInVehicles: state.paginator[paginatorInfoInVehicles.paginator]
      ? state.paginator[paginatorInfoInVehicles.paginator].content
      : [],
    paginatedDataOutVehicles: state.paginator[paginatorInfoOutVehicles.paginator]
      ? state.paginator[paginatorInfoOutVehicles.paginator].content
      : [],
    paginatedDataUknownVehicles: state.paginator[paginatorInfoUknownVehicles.paginator]
      ? state.paginator[paginatorInfoUknownVehicles.paginator].content
      : [],
  };
}
export default connect(mapStateToProps, null)(DepotListItem);