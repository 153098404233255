import React from "react";
import ChaloLoadingBar from "./src";
import "./bar-loader.css";
import {
    hideLoading,
    ImmutableLoadingBar,
    loadingBarMiddleware,
    loadingBarReducer,
    resetLoading,
    showLoading,
} from "./src"

export {
    hideLoading,
    ImmutableLoadingBar,
    loadingBarMiddleware,
    loadingBarReducer,
    resetLoading,
    showLoading,
}
export class BarLoader extends React.Component {
    render() {
        return <div>
            <ChaloLoadingBar scope={this.props.scope} className="header-loader" updateTime={100} maxProgress={95}
                             progressIncrease={10}
                             showFastActions/>
        </div>
    }
}