//todo  replace https protocol with window.location.protocol
const ENV = "production";
const operatorBasedVehicle = true;

const API = {
  BASE_URL: process.env.REACT_APP_PROD_API_BASE_URL,
  COA_BASE_URL: process.env.REACT_APP_PROD_COA_BASE_URL,
  GPS_MATRIX_STAG_URL: process.env.REACT_APP_PROD_GPS_MATRIX_STAG_URL,
  META_CALL_BASE_URL: process.env.REACT_APP_PROD_META_CALL_BASE_URL,
};

const google_properties = {
  auth:{
    baseUrl: process.env.REACT_APP_PROD_COA_BASE_URL,
    client_id: process.env.REACT_APP_PROD_AUTH_CLIENTID,
  },
  google:{
    clientId: process.env.REACT_APP_PROD_CLIENT_ID,
    clientSecret: process.env.REACT_APP_PROD_CLIENT_SECRET,
    redirectUri: process.env.REACT_APP_PROD_REDIRECT_URI,
  },
  map: {
    api_key: process.env.REACT_APP_PROD_GOOGLE_MAP_KEY,
  },
};

const cookieKeys = {
  user: process.env.REACT_APP_PROD_COOKIE_USER,
  loginState: process.env.REACT_APP_PROD_COOKIE_LOGIN_STATE,
};
export default {
  API,
  ENV,
  google_properties,
  operatorBasedVehicle,
  cookieKeys,
};
