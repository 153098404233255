import {OverAllDelayHeader, DelayHeader} from "./views/delayHeader";
import {sortOption} from "../../../shared/widgets";
export const homeTemplateStrings = {
    heading: 'Delay Matrice',
    avg_parsing_time: 'Parsing',
    avg_response_time: 'Response Time',
    columns: {
        region: 'Region',
        health_status: 'Health Status',
        delay: {
            component: DelayHeader
        },
        overlay_delay: {
            component: OverAllDelayHeader
        },
        deviceCount: {
            display: 'Device Count',
            sortable: true,
            defaultSort: sortOption.DESC
        },
        matrixCount: {
            display: 'Matrix Count',
            // sortable: true,
            // defaultSort: sortOption.ASC
        }
    }
};