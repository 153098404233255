export const cityMasterTemplateStrings = {
    name: 'City',
    country:'Country',
    timeZone:'Time Zone',
    timeOffsetMs: 'Time Offset(ms)',
    liveTracking:'Live Tracking',
    status: 'Status',
    action: 'Action'
};

export const countryMasterTemplateStrings = {
    name: 'Country',
    countryCode: 'Country Code',
    callingCode: 'Calling Code',
    currencySymbol : 'Currency Symbol',
    currency: 'Currency',
    currencyFactor: 'Currency Factor',
    phoneNumberPossibleLengths: 'Phone no Length',
    placeValue:'Place Value',
    action: 'Action'
}

export const agencyMasterTemplateStrings = {
    name: 'Agency',
    city: 'City',
    status: 'Status',
    action: 'Action'
};
export const operatorMasterTemplateStrings = {
    id: 'Id',
    name: 'Operator',
    city: 'City',
    phone: 'Phone No',
    email: 'Email Id',
    address: 'Address',
    status: 'Status',
    profilePercent: 'Complete',
    operatorVerified: 'Verified',
    action: 'Action'
};
export const operatorViewTemplateStrings = {
    id: 'Id',
    name: 'Operator',
    city: 'City',
    type: 'Operator Type',
    phone: 'Phone No',
    email: 'Email Id',
    address: 'Address',
    gstCertificate: 'Gst Certificate',
    noOfBuses: 'No of Buses',
    idType:"ID Type",
    accountHolderName:'Account Holder Name',
    accountNumber:'Account Number',
    ifscCode:'Ifsc Code',
    accountType:'Account Type',
    profilePercent: 'Complete',
    operatorVerified: 'Verified',
    status: 'Status',
    action: 'Action'
};
export const depotMasterTemplateStrings = {
    name: 'Depot',
    city: 'City',
    radius:'Radius(m)',
    latitude:'Latitude',
    longitude:'Longitude',
    status: 'Status',
    action: 'Action'
};

export const masterType = {
    city: 'city',
    country: 'country',
    agency: 'agency',
    operator: 'operator',
    depot: 'depot',
    camera: 'camera',
    serviceCategory:'serviceCategory',
    language:'language'
};

export const cameraHeadings ={
    cameraId : "CameraID",
    vehicleNo : "Vehicle No", 
    cameraBrand : "Camera Brand", 
    cameraType : "Camera Type", 
    isActive : 'Status',
    updatedAt:"Updated At",
    action: 'Action'
}

export const cameraHeadingsInView ={
    cameraId : "Camera ID",
    vehicleNo : "Vehicle No", 
    cameraBrand : "Camera Brand", 
    cameraType : "Camera Type", 
    userId:"User Id",
    cameraPassword : "Camera Password",
    simCardNo:"Sim Card No",
    sdCard:"SD Card",
    position:"Position",
    updatedAt:"Updated At",
    createdAt:"Created At",
    isActive:"Status",
    serviceName:"Service Name",
    city:"City"
}

export const masterModalConfig = {
    city: {
        createModalTitle: 'Add New City',
        updateModalTitle: 'Update City',
        viewModalTitle: 'City Details'
    },
    country: {
        createModalTitle: 'Add New Country',
        updateModalTitle: 'Update Country',
        viewModalTitle: 'Country Details'
    },
    agency: {
        createModalTitle: 'Add New Agency',
        updateModalTitle: 'Update Agency',
        viewModalTitle: 'Agency Details'
    },
    operator: {
        createModalTitle: 'Add New Operator',
        updateModalTitle: 'Update Operator',
        viewModalTitle: 'Operator Details'

    },
    depot: {
        createModalTitle: 'Add New Depot',
        updateModalTitle: 'Update Depot',
        viewModalTitle: 'Depot Details'
    },
    camera:{
        createModalTitle: 'Add New Camera',
        updateModalTitle: 'Update Camera',
        viewModalTitle: 'Camera Details'
    },
    serviceCategory:{
        createModalTitle: 'Add New Service Category',
        updateModalTitle: 'Update Service Catgory',
        viewModalTitle: 'Service Category Details'
    },
    serviceProvider:{
        createModalTitle: 'Add New Service Provider',
        updateModalTitle: 'Update Service Provider',
        viewModalTitle: 'Service Provider Details'
    },
    language: {
        createModalTitle: 'Add New Language',
        updateModalTitle: 'Update Language',
        viewModalTitle: 'Language Details'
    }
}

export const ServiceCategoryNameHeading = {
  serviceName: "Service Name",
  serviceId: "Service Id",
  createdAt: "Created At",
  updatedAt: "Updated At",
  agencyCategoryDto: "Agencies",
  action: 'Action'
};

export const ServiceProviderNameHeading = {
    serviceProviderName: "Service Provider Name",
    serviceProviderId: "Service Provider Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    agencyProviderDto: "Agencies",
    action: 'Action'
};

export const serviceCategoryViewTemplateStrings = {
    serviceName: "Service Name",
    serviceId: "Service Id",
    createdAt: "Created At",
    updatedAt: "Updated At",
    agencyProviderDto: "Agencies",
    agencyCategoryDto: "Agencies",
    serviceProviderName: "Service Provider Name",
    serviceProviderId: "Service Provider Id",
}

export const LanguageMasterTemplateStrings = {
    cityName:'City',
    country:'Country',
    defaultUILanguage:'Defaut UI Language',
    defaultPrintLanguage:'Default Print Language',
    languages:'Selected Languages',
    action: 'Action'
};

export const ViewLanguageMasterTemplateStrings = {
    cityName:'City',
    country:'Country',
    languageCategoryDto:'Language Category Dto'
};