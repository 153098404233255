import React from 'react';
import './searchComponent.css';
export class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: this.props.value || ''}
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value != nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    render() {
        const {placeHolder, onSearchChange}=this.props;
        return (
            <div className="search__input search-bar">
                <input
                    type="text"
                    className='form-control'
                    placeholder={placeHolder || 'Search'}
                    onChange={(e)=> {
                        this.setState({value: e.target.value})
                        onSearchChange(e.target.value);
                    }}
                    value={this.state.value}
                />
                <button type=" submit"
                        className=" btn btn-default search-btn">
                    <span>Search</span></button>
                {this.state.value &&
                <button type="button"
                        className="btn btn-default clear-btn"
                        onClick={()=> {
                            this.setState({value: ''});
                            onSearchChange('');
                        }}><span>Clear</span></button>}
            </div>
        )
    }
}