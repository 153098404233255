const validateCity = (formState)=> {
    let form = {};
    if (!formState.city || !formState.selectedCountry || !formState.selectedTimeZone) {
        form._error = "Required field missing";
        form.invalid = true;
    }
    if (formState.city && !formState.city.match("^[a-zA-Z' ]*$")) {
        form._error = "Invalid Name";
        form.invalid = true;
        form.cityNameError=true;
    }
    return form;
};
export  default validateCity;