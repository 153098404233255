import React  from 'react';
import * as moment from "moment";
import classNames from "classnames";
import {EpochtoEpochTZ} from "../../../../../shared/utils";

export const ListItem = ({item, columns, navigateTo, keyId, keyId2})=>(
    <tr onClick={()=> {
        if (navigateTo) navigateTo(item[keyId], item[keyId2]);
    }}>
        {Object.keys(columns).map((column, index)=> {
            return <td
                key={index}>{column == 'fromTimestamp' ? moment(EpochtoEpochTZ(item.fromTimestamp,localStorage.getItem('timeZone'))).format('DD MMM YYYY') : item[column] ? item[column] : 0}</td>;
        })
        }
    </tr>
);


export const GraphListItem = ({ item, color }) => (
  <tr key={item.vehicleNo}>
    <td className="list-item">{item.vehicleNo}</td>
    <td className="list-item">
      <span className={color}>
        <a>
          <i className="fa fa-circle" aria-hidden="true"></i>
        </a>
      </span>
    </td>
    <td className="list-item">
      {moment(EpochtoEpochTZ(item.fromTimestamp,localStorage.getItem('timeZone'))).format("DD MMM YYYY")}
    </td>
    <td className="list-item">{item.zeroToFive}</td>
    <td className="list-item">{item.fiveToTen}</td>
    <td className="list-item">{item.tenToTwenty}</td>
    <td className="list-item">{item.twentyToThirty}</td>
    <td className="list-item">{item.thirtyToSixty}</td>
    <td className="list-item">{item.sixtyToOneTwenty}</td>
    <td className="list-item">{item.greaterThanOneTwenty}</td>
  </tr>
);