import React from "react";
import { Modal } from "react-bootstrap";
import './fileSelectModal.css';
import { connect } from "react-redux";
import Select from "react-select";

class FileSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegion: { label: props.selectedRegion, value: props.selectedRegion }
        }

    }
    componentDidMount() {
        console.log("mount")
        this.mapRegionList()

    }
    render() {
        return (
            <Modal show={this.props.showSelectModal} bsSize="large"
                onHide={() => {
                    this.setState({ invalidJson: false,
                        selectedRegion: { label: this.props.selectedRegion, value: this.props.selectedRegion }
                    });
                    this.props.onHide();
                    this.formData = null;
                    
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row detail-container modal-form-container">
                        {!this.props.hideRegion && 
                            <div className="col-sm-12 fields">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                         <label htmlFor="name7" className="control-label col-sm-3 form-label"><span>Region Id</span></label>
                                            <div className="col-sm-9">
                                                <Select
                                                    onChange={(value) => {
                                                    console.log("region change", value)
                                                    this.setState({ selectedRegion: value })
                                                    }}
                                                    options={this.state.regions}
                                                    placeholder="Select Region"
                                                    clearable={false}
                                                    value={this.state.selectedRegion}
                                                    />
                                            </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="col-sm-12 fields">
                        <div className="col-sm-12">
                            <div className=" file-upload" onChange={(e) => {
                                if (e.target.files.length > 1) {
                                    this.setState({ invalidJson: true, errorMessage: 'please select max 1 file' });
                                    e.preventDefault();
                                } else if (e.target.files.length && !this.isValidName(e.target.files[0].name)) {
                                    this.setState({
                                        invalidJson: true,
                                        errorMessage: `Sorry,${e.target.files[0].name} is invalid, allowed extension is ${this.props.supportedTypes[0]}`
                                    });
                                    e.preventDefault();
                                } else if (e.target.files.length) {

                                    this.setState({ invalidJson: false });
                                    this.onFileChange(e);
                                }
                            }}
                            >
                                <input type="file" accept={this.props.supportedTypes} multiple={false} />
                            </div>
                            {this.state.invalidJson &&
                                <div className="message-box" style={{ color: 'red' }}>{this.state.errorMessage}</div>}

                        </div>
                        </div>

                    </div>
                    <div className=" file-select-footer">
                        <button id="choose" type="submit"
                            className="btn active-button"
                            onClick={() => {
                                this.props.onUpload(this.formData, this.state.selectedRegion.value);
                            }} disabled={!this.formData || this.state.invalidJson || this.props.disableButton}>
                            <span>{this.props.buttonText || 'Upload'}</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    isValidName(sFileName) {
        var _validFileExtensions = this.props.supportedTypes;
        if (sFileName.length > 0) {
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j];
                if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
            return blnValid;
        }
    }

    // Method that checks that the browser supports the HTML5 File API

    browserSupportFileUpload() {
        var isCompatible = false;
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            isCompatible = true;
        }
        return isCompatible;
    }

    onFileChange(evt) {
        this.setState({ invalidJson: false });
        if (!this.browserSupportFileUpload()) {
            alert('The File APIs are not fully supported in this browser!');
        } else {
            var data = null;
            var file = evt.target.files[0];

            this.formData = new FormData();
            this.formData.append('file', file, file.name);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedRegion != nextProps.selectedRegion && nextProps.selectedRegion) {
            this.setState({
                selectedRegion: { label: nextProps.selectedRegion, value: nextProps.selectedRegion }
            });
        }
    }

    mapRegionList() {
        if (this.props.content) {
            this.setState({
                regions: this.props.content.map(res => {
                    return { label: res.regionId, value: res.regionId }
                })
            }, () => { console.log("regionsssss", this.state.regions) });
        }
    }

}
const mapStateToProps = state => ({
    content: state.Regions.displayData,

});

export default connect(mapStateToProps, null)(FileSelectModal);
