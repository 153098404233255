
import React from "react";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import './toggleView.css';

export class ToggleView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status:this.props.status
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status != this.state.type) {
            this.setState({
                status: nextProps.status
            })
        }
    }

    render() {
        return <label>
        <Toggle
          checked={this.state.status}
          className='custom-toggle'
          icons={false}
          onChange={(value)=>{this.props.onStatusChange(value.target.checked,this.props.masterData)}}
         />
      </label>
    }
}