import React from "react";
import {
  Paginator,
  Search as SearchBar,
  DropdownWrapper,
} from "../../../../shared/widgets";
import { PageWrapper, TableWrapper } from "../../../../shared/layout";
import { templateStrings, MessageType } from "../../../../Constant";
import { fetchVehicleMaster } from "../../../../services/vehicleMasterService";
import {
  fetchStopData,
  downLoadStopData,
} from "../../../../services/BSDService";
import { SelectFilter } from "../../../../shared/widgets/selectFilter/selectFilter";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../../redux/map-to-props";
import { Modal, ButtonToolbar } from "react-bootstrap";
import { CreateStopDetailView } from "./create/createStopDetailView";
import { routes } from "../../../../routing/routes";
import {
  AuthenticationService,
  OPERATIONS,
} from "../../../../services/authorization";

export class StopList extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    let authorities = localStorage.getItem("authorities");
    let email = localStorage.getItem("userName");
    this.state = {
      authorities: authorities,
      email: email,
      searchString: "",
      pageNo: 0,
      totalItems: 0,
      type: this.props.match.params.typeId,
      totalPages: 0,
      placeHolder: "Search",
      selectedCity: "MUMBAI",
      exportOptions: [
        {
          key: "data",
          value: "Export Data ",
        },
        {
          key: "format",
          value: "Export Format",
        },
      ],
    };
    this.getList = this.getList.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    let auth = JSON.parse(this.state.authorities);
    this.mounted = true;
    this.updateRightsForRegion(Object.values(auth).toString());
    this.fetchMasterData("city", null);
    if (this.props.location.state && this.props.location.state.selectedCity) {
      const { selectedCity } = this.props.location.state;
      this.setState({ selectedCity },()=>{
        this.fetchStopData(this.state.selectedCity, this.state.searchString, 0);
      });
    }
    else{
      this.fetchStopData(this.state.selectedCity, this.state.searchString, 0);
    }
  }

  updateRightsForRegion(region) {
    this.setState({
      canWrite: this.authService.hasRightForOperationOnRouteInRegion(
        routes.BSD,
        region,
        OPERATIONS.WRITE
      ),
    });
  }

  componentWillMount() {
    this.mounted = true;
  }

  fetchMasterData(masterType, masterParentId) {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        if (this.mounted) {
          this.setState({ cities: res.content });
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching cities",
      });
    }
  }

  render() {
    return (
      <div>
        <PageWrapper.Header
          heading="Stop"
          onAdd={this.open.bind(this, null)}
          showButton={this.state.canWrite}
        >
          {this.state.content && (
            <span> Stop Count : {this.state.totalItems}</span>
          )}
          <div className="pull-right">
            <a
              onClick={() => {
                this.fetchStopData(
                  this.state.selectedCity,
                  this.state.searchString,
                  0
                );
              }}
            >
              <span className="span-icon-refresh refresh-icon" />
            </a>
          </div>

          <DropdownWrapper
            title={
              this.state.exportButtonLoader ? (
                <>
                  <i className="fa fa-spinner fa-spin"></i>
                  <span>{""}</span>
                  <span>Exporting...</span>
                </>
              ) : (
                "Export"
              )
            }
            disabled={this.state.exportButtonLoader}
            menue={this.state.exportOptions}
            onSelect={(value, subValue) => {
              switch (value) {
                case "data": {
                  this.exportData();
                  break;
                }
                case "format": {
                  this.exportData("format");
                  break;
                }
              }
            }}
          ></DropdownWrapper>
          <div className="pull-right search-container">
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="header-button-container">
              <div className="form-group">
                <p className="filters-label">Filter By:</p>
                <div
                  className="dataTables_length wrapper-gps"
                  id="dataTables-example_length"
                >
                  <ButtonToolbar>
                    <SelectFilter
                      value={this.state.selectedCity}
                      onChange={(value) => {
                        this.onFilterChange(value);
                      }}
                      clearable={false}
                      valueKey="name"
                      labelKey="name"
                      placeholder="City"
                      options={this.state.cities}
                    />
                  </ButtonToolbar>
                </div>
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header columns={templateStrings.stop} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.map((data) => {
                    return this.displayList(data);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          <Paginator
            pageNo={this.state.pageNo}
            totalPages={this.state.totalPages}
            getList={this.getList}
          />
          {this.displayModal()}
        </PageWrapper.Body>
      </div>
    );
  }

  handleSearchChange(e) {
    this.setState({ searchString: e }, () => {
      this.fetchStopData(this.state.selectedCity, this.state.searchString, 0);
    });
  }

  /**
   * Call when Add/Update modal Open.
   */
  open(data) {
    this.setState({
      updateSelection: data,
      showModal: true,
    });
  }

  /**
   * Call when Add/Update modal Close.
   */
  close() {
    this.setState({
      showModal: false,
      updateSelection: null,
      value: [],
    });
  }

  /**
   * API call to get stopa.
   * @param pageNo current page number
   */
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.fetchStopData(
        this.state.selectedCity,
        this.state.searchString,
        pageNo
      );
    }
  }

  onFilterChange(value) {
    if (value) {
      this.setState(
        {
          selectedCity: value && value.name ? value.name : "",
        },
        () => {
          this.fetchStopData(
            this.state.selectedCity,
            this.state.searchString,
            0
          );
        }
      );
    }
  }

  /**
   * Used to show stop in row in List view
   * @param item  Object of item to display
   */
  displayList(item) {
    return (
      <tr key={item.stopId}>
        <td>{item.stopId}</td>
        <td>
          <span className="protocol">
            {item.stopName ? item.stopName : "NA"}
          </span>
          <div className="protocol-list">
            {item.stopName ? item.stopName : "NA"}
          </div>
        </td>
        <td>{item.stopNativeLanguage ? item.stopNativeLanguage : ""}</td>
        <td>
          <span className="protocol">
            {item.stopNativeName ? item.stopNativeName : ""}
          </span>
          <div className="protocol-list">
            {item.stopNativeName ? item.stopNativeName : ""}
          </div>
        </td>
        <td>
          <span className="protocol">{item.latitude ? item.latitude : ""}</span>
          <div className="protocol-list">
            {item.latitude ? item.latitude : ""}
          </div>
        </td>
        <td>
          <span className="protocol">
            {item.longitude ? item.longitude : ""}
          </span>
          <div className="protocol-list">
            {item.longitude ? item.longitude : ""}
          </div>
        </td>

        <td className="action-column">
          {this.state.canWrite && (
            <span className="table-icons edit-icon">
              <a onClick={this.open.bind(this, item)}>
                <span className="edit-view-icon" />
                <span className="protocol-list">edit</span>
              </a>
            </span>
          )}
          <span className="table-icons view-icon">
            <a onClick={() => this.navigateToDetail(item.stopId)}>
              <i className="fa fa-history protocol" aria-hidden="true" />
              <span className="protocol-list">view logs</span>
            </a>
          </span>
        </td>
      </tr>
    );
  }

  fetchStopData(city, searchKey, pageNo) {
    this.props.updateLoader(true);
    fetchStopData(city, searchKey, pageNo)
      .then((res) => {
        this.props.updateLoader(false);

        if (this.mounted && res.items)
          this.setState({
            content: res.items,
            totalPages: res.totalPages,
            pageNo: res.currentPage,
            totalItems: res.totalItems,
          });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);

        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        } else if (this.mounted) {
          this.props.updateLoader(false);
          this.setState({ content: [], totalPages: 0, pageNo: 0 });
          this.props.initiateNotification({
            type: "error",
            message: err.description
              ? err.description
              : "Error while fetching stops",
          });
        }
      });
  }

  exportData(exportKey) {
    let timeoutId;
    const hideLoader = () => {
      timeoutId = setTimeout(() => {
        this.setState({ exportButtonLoader: false });
      }, 60000);
    };
    downLoadStopData(this.state.selectedCity, exportKey)
      .then(this.setState({ exportButtonLoader: true }))
      .then((res) => {
        clearTimeout(hideLoader);
        console.log(res);
        this.setState({ exportButtonLoader: false });
      })
      .catch((err) => {
        clearTimeout(hideLoader);
        this.setState({ exportButtonLoader: false });
        console.log(err);
        if (err.status === 401 || err.message === "Invalid token") {
          console.log("unauthorized", err.description);
          this.props.setTokenExpired(true);
        }
      });
  }

  navigateToDetail(stopId) {
    this.props.history.push({
      pathname: `/BSD/stops/${stopId}`,
      state: { selectedCity: this.state.selectedCity },
    });
  }

  /**
   * Used to show Modal for "Add new stop" or "Update stop"
   */
  displayModal() {
    return (
      <Modal
        dialogClassName="custom-vehicle-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal
              ? this.state.updateSelection
                ? "Update Device"
                : "Add new Device"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CreateStopDetailView
              updateSelection={this.state.updateSelection}
              email={this.state.email}
              onComplete={(e, msg) => {
                if (e == MessageType.SUCCESS) {
                  this.setState({ searchString: "" }, () => {
                    this.fetchStopData(
                      this.state.selectedCity,
                      this.state.searchString,
                      0
                    );
                  });
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                } else if (e === MessageType.ERROR && msg === "Invalid token") {
                  this.props.setTokenExpired(true);
                } else {
                  this.props.initiateNotification({
                    type: e,
                    message: msg,
                  });
                }
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(StopList);
