import React from "react";
import "react-dates/initialize";
import { DateRangePicker, SingleDatePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./dateRangeStylesReset.css";
import * as moment from "moment";
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'

export class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.date,
            focused: null,
            border: this.props.withoutDefaultBorder
        };
        let m = moment(new Date());
        if (this.props.todaySelectable) {
            this.lastSelectableDay = m;
        } else {
            this.lastSelectableDay = m.subtract(1, 'day')
        }

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.date!=this.state.date) {
            this.setState({ date: nextProps.date })
        }
    }

    render() {
        return <SingleDatePicker date={this.state.date}
            focused={this.state.focused}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, this.lastSelectableDay)}
            onDateChange={(date) => {
                console.log("date channgrrs",date)
                this.setState({ date });
                this.props.onDateChange(date);
            }}
            displayFormat="D MMM YYYY"
            numberOfMonths={1}
            noBorder={this.state.border}
            onFocusChange={(focusedInput) => {
                this.setState({ focused: focusedInput.focused });
            }} />
    }
}

export class RangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            numberOfMonths: this.props.monthsToDisplay,
            focusedInput: null,
        };
        let m = moment(new Date());
        this.lastSelectableDay = m.subtract(1, 'day');
    }

    render() {
        return <DateRangePicker
            startDateId="startDate"
            numberOfMonths={this.state.numberOfMonths}
            endDateId="endDate"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, this.lastSelectableDay)}
            onDatesChange={({ startDate, endDate }) => {
                this.setState({ startDate, endDate });
                this.props.onDatesChange({ startDate, endDate });
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
                this.setState({ focusedInput })
            }}
            displayFormat="D MMM YYYY"
        />
    }
}

export class RangePickerforFrequency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            numberOfMonths: this.props.monthsToDisplay,
            focusedInput: null,
            minimumNights: this.props.minimumNights
        };
        let m = moment(new Date());
        this.lastSelectableDay = m.subtract(7, 'days');
    }

    render() {
        return <DateRangePicker
            startDateId="startDate"
            numberOfMonths={this.state.numberOfMonths}
            endDateId="endDate"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            minimumNights={this.state.minimumNights}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, this.lastSelectableDay)}
            onDatesChange={({ startDate, endDate }) => {
                let m1=moment(startDate)
                this.setState({ startDate, endDate:m1.add(6,'days')});
                this.props.onDatesChange({ startDate, endDate });
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
                if(focusedInput == 'endDate'){
                    this.setState({ focusedInput:null })
                }
                else if(focusedInput == 'startDate'){
                    this.setState({ focusedInput:'startDate' })
                }
                else{
                    this.setState({ focusedInput:null })
                }
            }}
            displayFormat="D MMM YYYY"
        />
    }
}

export class SingleDateTimePicker extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        date: this.props.date,
        today: new Date(),
      };
    }
    valid = function (current) {
      if (this.props.depot) {
        let limit = new Date(this.state.today);
        limit.setDate(limit.getDate() - 7);
        return current.isAfter(limit) && current.isBefore(this.state.today);
      } else if (this.props.id === "endTime") {
        let minDate = new Date(this.props.minDate);
        minDate.setDate(minDate.getDate() - 1);
        return current.isAfter(minDate) && current.isBefore(this.state.today);
      } else {
        return current.isBefore(this.props.date);
      }
    };
    render() {
      return (
        <DateTime
          isValidDate={this.valid.bind(this)}
          inputProps={{
            placeholder: this.props.placeholder
              ? this.props.placeholder
              : "Select Date",
            readOnly: this.props.depot ? false : true,
          }}
          value={
            this.props.depot && this.props.date
              ? moment(this.props.date).format("DD/MM/YYYY")
              : null
          }
          onChange={(date) => {
            this.setState({ date });
            this.props.onDateChange(date);
          }}
        />
      );
    }
  }
  