import { setTokenExpired, logoutUser, refreshToken, refreshingToken } from "./actions/authActions"
import Cookies from 'universal-cookie';
import { actions } from './actions/action-list';

let buffer = []; // create an empty array which will be populated with all actions dispatched by Redux

const refreshTokenMiddleware = (store) => (next) => (action) => {

   

    // buffer.push(action);
    // if (action.type === 'TOKEN_EXPIRE') {
    //   let theStore = store.getState();
    //   console.log("store",theStore)
    //   if (
    //     localStorage.getItem('token')  &&
    //     localStorage.getItem('refreshToken')      ) {
    //     if (!theStore.Auth.pendingRefreshingToken) {
    //       store.dispatch(refreshingToken(true));
    //       console.log("store",theStore)

    //       store.dispatch(refreshToken(localStorage.getItem('refreshToken'))).then(() => {
    //         store.dispatch({
    //           type: 'TOKEN_REFRESHED'
    //         });

    //         //get the action before the last INVALID_TOKEN (the one which got denied because of token expiration)
    //         let pos = buffer.map(e => e.type).indexOf('TOKEN_EXPIRE') - 1;

    //         // count back from the invalid token dispatch, and fire off the last dispatch again which was
    //         // a function. These are to be dispatched, and have the dispatch function passed through to them.
    //         for (var i = pos; i > -1; i -= 1) {
    //           if (typeof buffer[i] === 'function') {
    //             store.dispatch({
    //               type: 'RESEND',
    //               action: buffer[i](store.dispatch)
    //             });
    //             break;
    //           }
    //         }
    //         buffer = [];
    //       });
    //     }
    //   }
    // } else if (action.type === 'REFRESH_EXPIRED') {
    //   buffer = [];
    //   store.dispatch(logoutUser());
    // } else {
    //   if (buffer.length > 20) {
    //     //remove all items but keep the last 20 which forms the buffer, else the array would keep growing
    //     buffer.splice(0, buffer.length - 20);
    //   }
    //   return next(action);
    // }

    // const cookies = new Cookies();
    // console.log("cookie", cookies.get("isloggedIn"));
    //         if (cookies.get("isloggedIn")==='false' && action.type!=='TOKEN_EXPIRE' && localStorage.getItem("token")) {

    //             console.log("state",store.getState())

    //             store.dispatch(setTokenExpired(true))
    //                         // return next(action)
    //         }

    // next(action)
}
export default refreshTokenMiddleware;
