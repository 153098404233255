import {actions} from "../../actions/action-list";
import initialState from "./initialState";

function User(state = initialState, action) {
    switch (action.type) {
        case actions.USERS_FETCHED:
            return Object.assign({}, state, {
                users: action.value
            });
        case actions.USERS_LOADING:
            return Object.assign({}, state, {
                loading: action.value
            });
        default:
            return state;
    }
}
export default User;
