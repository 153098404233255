const validateServiceCategory = (formState)=> {
    let form = {};
    if ( !formState.serviceName || !formState.selectedRegion || formState.selectedRegion.length ===0) {
        form._error = "Required field missing";
        form.invalid = true;
    }
    if(!formState.displayName){
        form._error = "Required field missing";
        form.invalid = true;
    }
    return form;
};
export  default validateServiceCategory; 